<div class="d-flex justify-content-between">
    <h2 mat-dialog-title>Create Allowed Value For Property</h2>
    <ng-container *ngFor="let language of languageService.supportedLanguages">
        <button
            *ngIf="languageService.formSelectedLanguage !== language"
            class="btn btn-sm btn-rose btn-just-icon btn-round d-block btn-lang"
            (click)="languageService.changeLanguage(language)"
        >
            {{ language.abvreiation }}
        </button>
    </ng-container>
</div>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-sm-6">
                <app-multi-language
                    [placeholder]="'Label'"
                    [controlName]="'allowedValue'"
                    [translations]="formGroup.get('translations')"
                ></app-multi-language>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <input matInput placeholder="Internal Value" formControlName="valueIdentifier" />
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'valueIdentifier')">
                        Required internal value
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button cdkFocusInitial (click)="create()" [loading]="isLoading">Create</button>
    </div>
</mat-dialog-actions>
