import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'
import { ProductVariationGroupFormComponent } from './product-variation-group-form.component'
import { LabelGroupDropDownModule } from '@app/shared/label-group-drop-down/label-group-drop-down.module'
import { ProductVariationGroupDetailComponent } from './product-variation-group-detail/product-variation-group-detail.component'
import { PipesModule } from '@app/shared/pipes/pipes.module'

@NgModule({
    imports: [CommonFormsModule, ServerPagingContainerModule, LabelGroupDropDownModule, PipesModule],
    declarations: [ProductVariationGroupFormComponent, ProductVariationGroupDetailComponent],
    exports: [ProductVariationGroupFormComponent, ProductVariationGroupDetailComponent],
})
export class ProductVariationGroupFormModule {}
