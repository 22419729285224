import { Component, OnInit, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { MyErrorStateMatcher } from '@app/models/myErrorStateMatcher'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

@Component({
    selector: 'app-currency',
    templateUrl: './currency.component.html',
    styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit {
    numberMask = createNumberMask({
        prefix: '$ ',
        allowDecimal: true,
        decimalLimit: 6,
    })

    @Input() placeholder: string
    @Input() controlName: string
    @Input() group: FormGroup
    @Input() disabled = false

    matcher = new MyErrorStateMatcher()

    constructor() {}

    ngOnInit() {
        if (this.disabled) {
            this.group.get(this.controlName).disable()
        }
    }
}
