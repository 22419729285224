import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PipesModule } from '../pipes/pipes.module'
import { CostLineListModule } from '../cost-line-list/cost-line-list.module'
import { MaterialModule } from '@app/material.module'
import { SelectionItemDetailsComponent } from './selection-item-details.component'
import { ImagesModule } from '../images/images.module'

@NgModule({
    imports: [CommonModule, CostLineListModule, PipesModule, MaterialModule, ImagesModule],
    declarations: [SelectionItemDetailsComponent],
    exports: [SelectionItemDetailsComponent],
})
export class SelectionItemDetailsModule {}
