export class StaticHelper {
    static selectAnyTranslations(item: any) {
        if (!item || item === null) {
            return
        }

        if (item.constructor === Array) {
            for (const arrItem of item) {
                StaticHelper.selectAnyTranslations(arrItem)
            }
        } else {
            for (const property in item) {
                if (property === 'translations') {
                    item.selectedTranslation = item.translations.find((translation) => translation.culture === 'en-CA')
                } else if (typeof item[property] === 'object') {
                    StaticHelper.selectAnyTranslations(item[property])
                }
            }
        }
    }
}
