import {
    UnitType,
    CostLineTranslation,
    SelectionItemProductLinkCostLineDto,
    ProductPriceProductLinkCostLineDto,
    NestedProductCurrencyPriceDto,
    NestedLightProductDto,
    ProductPriceProductLinkCostLineInput,
} from '@app/services/api.services'
import { Guid } from 'guid-typescript'
import { BaseCostLineModel } from '../BaseCostLineModel'

export class ProductPriceProductLinkCostLineModel extends BaseCostLineModel {
    get product(): NestedLightProductDto {
        return this.linkedProductPrice.product
    }

    linkedProductPrice?: NestedProductCurrencyPriceDto | null

    static createFromDto(
        costLine: ProductPriceProductLinkCostLineDto,
        isCopy = false
    ): ProductPriceProductLinkCostLineModel {
        const costLineModel = new ProductPriceProductLinkCostLineModel(
            costLine.id,
            costLine.quantity,
            costLine.linkedProductCurrencyPrice.unitType,
            costLine.linkedProductCurrencyPrice.unitCostAmount,
            costLine.linkedProductCurrencyPrice.unitMarkupAmount,
            costLine.linkedProductCurrencyPrice.unitMarkupPercent,
            costLine.linkedProductCurrencyPrice.isUnitMarkupPercent,
            false,
            costLine.linkedProductCurrencyPrice.product.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: costLine.linkedProductCurrencyPrice.product.useClientDisplayName
                            ? t.clientDisplayName
                            : costLine.linkedProductCurrencyPrice.product.productName,
                        culture: t.culture,
                    })
            ),
            costLine.linkedProductCurrencyPrice
        )

        costLineModel.selectedTranslation = costLineModel.translations.find(
            (t) => t.culture === costLine.linkedProductCurrencyPrice.product.selectedTranslation.culture
        )

        if (isCopy) {
            costLineModel.id = null
            costLineModel.translations.forEach((translation, index) => {
                translation.id = null
                costLineModel.translations[index] = translation
            })
        }

        return costLineModel
    }

    constructor(
        id: string,
        quantity: number,
        unitType: UnitType,
        unitCostAmount: number,
        unitMarkupAmount: number,
        unitMarkupPercent: number,
        isUnitMarkupPercent: boolean,
        isVisibleToClients: boolean,
        translations: CostLineTranslation[],
        linkedProductPrice: NestedProductCurrencyPriceDto
    ) {
        super(
            id,
            quantity,
            unitType,
            unitCostAmount,
            unitMarkupAmount,
            unitMarkupPercent,
            isUnitMarkupPercent,
            isVisibleToClients,
            translations
        )

        this.linkedProductPrice = linkedProductPrice
    }

    clone(generateNewId: boolean): ProductPriceProductLinkCostLineModel {
        const costLine = Object.assign(
            new ProductPriceProductLinkCostLineModel(
                this.id,
                this.quantity,
                this.unitType,
                this.unitCostAmount,
                this.unitMarkupAmount,
                this.unitMarkupPercent,
                this.isUnitMarkupPercent,
                this.isVisibleToClients,
                this.translations,
                this.linkedProductPrice
            ),
            this
        )
        if (generateNewId) {
            costLine.id = Guid.create().toString()
            costLine.isEdited = false
            costLine.isAdded = true
        }

        return costLine
    }

    createInput(): ProductPriceProductLinkCostLineInput {
        const input = new ProductPriceProductLinkCostLineInput()

        input.quantity = this.quantity
        input.linkedProductCurrencyPriceId = this.linkedProductPrice.id
        input.unitCostAmount = this.linkedProductPrice.unitCostAmount
        input.unitMarkupAmount = this.linkedProductPrice.unitMarkupAmount
        input.unitAmount = this.linkedProductPrice.unitAmount

        return input
    }

    getName(): string {
        return this.selectedTranslation.description
    }

    isUnitCostEditable(): boolean {
        return false
    }

    isUnitMarkupEditable(): boolean {
        return false
    }

    isDescriptionEditable(): boolean {
        return false
    }

    isTakeOffsSupported(): boolean {
        return false
    }

    isUnitTypeEditable(): boolean {
        return false
    }

    isLinkedCostLine(): boolean {
        return true
    }

    createDto(): SelectionItemProductLinkCostLineDto {
        const costLine = new ProductPriceProductLinkCostLineDto()
        costLine.linkedProductCurrencyPrice = this.linkedProductPrice
        this.populateCommonDtoFieldsFromProduct(costLine)
        return costLine
    }

    createProductPriceProductLinkCostLineInput() {
        throw new Error('not implemented')
    }
}
