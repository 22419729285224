<nav class="navbar navbar-expand-lg bg-dark main-navbar" color-on-scroll="500" data-color="orange">
    <div class="container">
        <div class="navbar-wrapper">
            <div class="logo-img">
                <img src="/assets/img/logoSymbol50.png" />
            </div>
            <a class="navbar-brand">Presti</a>
        </div>
        <div *ngIf="accountManageService.isInClientViewMode" class="client-view-mode-wrapper">
            <div class="client-view-mode-btn" (click)="exitClientMode()">
                <ng-container *ngIf="accountManageService.viewingClient; else noClient">
                    Exit client portal view of {{ accountManageService.viewingClient.name }}
                </ng-container>
                <ng-template #noClient> Exit client portal view </ng-template>
            </div>
        </div>
        <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <li
                    class="nav-item"
                    routerLinkActive="active"
                    *ngIf="!accountManageService.viewingPropertyInClientViewMode"
                >
                    <a class="nav-link" [routerLink]="['/portal/home']">
                        {{ 'CLIENTNAVBAR.PROPERTIES' | translate }}
                    </a>
                </li>

                <!-- <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" (click)="navigateToSelections()">
                        Selections
                    </a>
                </li> -->

                <!-- <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/support']">
                        Support
                    </a>
                </li> -->

                <li
                    class="nav-item"
                    routerLinkActive="active"
                    *ngIf="!accountManageService.viewingPropertyInClientViewMode"
                >
                    <a class="nav-link" [routerLink]="['/portal/profile/']">
                        {{ 'CLIENTNAVBAR.PROFILE' | translate }}
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" href="javascript:0;" (click)="switchLanguage()">
                        <span>{{ languageToDisplay }}</span>
                    </a>
                </li>

                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" href="javascript:0;" (click)="logout()">
                        {{ 'CLIENTNAVBAR.LOGOUT' | translate }}
                    </a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown"
                        style="height: 50px;" aria-haspopup="true" aria-expanded="false">
                        <i class="material-icons">notifications</i>
                        <span class="notification">5</span>
                        <span class="d-lg-none d-md-block">Some Actions</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#">Mike John responded to your email</a>
                        <a class="dropdown-item" href="#">You have 5 new tasks</a>
                        <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                        <a class="dropdown-item" href="#">Another Notification</a>
                        <a class="dropdown-item" href="#">Another One</a>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</nav>
<div [class.client-view-mode]="accountManageService.isInClientViewMode">
    <div>
        <!--   you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
        <router-outlet #mainoutlet="outlet"></router-outlet>
    </div>
</div>
