import { TemplateRoomDto, TemplateCategoryDto, TemplateType, TemplateSectionDto } from '@app/services/api.services'
import { SelectionGroupModel, SelectionItemModel } from './SelectionGroupModel'
import { TemplateRoomModel } from './TemplateRoomModel'
import { TemplateCategoryModel } from './TemplateCategoryModel'
import { SectionModel } from './SectionModel'
import { Guid } from 'guid-typescript'

export class TemplateSectionModel extends SectionModel {
    templateId?: string | null
    index?: number | null
    selectionGroups: SelectionGroupModel[] = []
    isDisabled: boolean
    templateType: TemplateType
    isAdded: boolean
    isEdited: boolean
    isDeleted: boolean
    isCollapsed: boolean

    static createFromDto(templateSectionDto: TemplateSectionDto, templateType: TemplateType): any {
        const section = new TemplateSectionModel(templateSectionDto.templateRoom, templateSectionDto.templateCategory)

        section.selectionGroups = templateSectionDto.selectionGroups.map((selectionGroup) =>
            SelectionGroupModel.createFromDto(selectionGroup)
        )

        section.templateType = templateType

        return section
    }

    constructor(templateRoom: TemplateRoomDto, templateCategory: TemplateCategoryDto) {
        super()
        if (templateRoom) {
            this.templateRoom = TemplateRoomModel.createFromDto(templateRoom)
        }
        if (templateCategory) {
            this.templateCategory = TemplateCategoryModel.createFromDto(templateCategory)
        }
    }

    get templateSectionName(): string {
        return this.templateRoom
            ? this.templateRoom.selectedTranslation.name
            : this.templateCategory.selectedTranslation.name
    }

    get templateSectionCategoryName(): string {
        return this.templateCategory ? this.templateCategory.selectedTranslation.name : 'N/A'
    }

    get id() {
        if (this.templateRoom) {
            return this.templateRoom.id
        } else {
            return this.templateCategory.id
        }
    }

    getSelectionItem(selectionItemId: string): SelectionItemModel {
        return this.getAllSelectionItems().find((selectionItem) => selectionItem.id === selectionItemId)
    }

    get numberOfSelectionRequired(): number {
        return this.selectionGroups.reduce((sum, selectionGroup) => {
            return selectionGroup.minimumOptionChoice + sum
        }, 0)
    }

    get numberOfRequiredSelectionsMade(): number {
        return this.selectionGroups.reduce((sum, selectionGroup) => {
            const selectionsMade = selectionGroup.getAllSelections().length
            return selectionsMade > selectionGroup.minimumOptionChoice
                ? sum + selectionGroup.minimumOptionChoice
                : sum + selectionsMade
        }, 0)
    }

    get clientSelectionMessage(): string {
        const selectionsRequired = this.numberOfSelectionRequired
        const selectionsMade = this.numberOfRequiredSelectionsMade

        if (selectionsMade === selectionsRequired) {
            return `All required selections have been made`
        }

        const selectionsRemaining = selectionsRequired - selectionsMade

        return `${selectionsRemaining} required selections remain`
    }

    get selectedOptionsTotalPrice(): number {
        return this.selectionGroups.reduce(
            (sum, selectionGroup) => selectionGroup.getTotalPriceForSelections() + sum,
            0
        )
    }

    get selectedOptionsTotalPriceWithTaxes(): number {
        return this.selectionGroups.reduce(
            (sum, selectionGroup) => selectionGroup.getTotalPriceForSelectionsWithTaxes() + sum,
            0
        )
    }

    // get basePrice(): number {
    //     return this.selectionGroups.reduce((sum, selectionGroup) => selectionGroup.includedPrice + sum, 0)
    // }

    // get basePricePlusSelections(): number {
    //     return this.basePrice + this.selectedOptionsTotalClientPrice
    // }

    get selectedOptionsTotalClientPrice(): number {
        return this.selectionGroups.reduce(
            (sum, selectionGroup) => selectionGroup.getTotalClientPriceForSelections() + sum,
            0
        )
    }

    get imageUrl(): string {
        if (this.templateRoom) {
            return this.templateRoom.imageUrl
        }

        if (this.templateCategory) {
            return this.templateCategory.imageUrl
        }
        return ''
    }

    createNewSelectionGroupVersion(selectionGroup: SelectionGroupModel): SelectionGroupModel {
        throw new Error('Method not implemented.')
    }

    createDto(): TemplateSectionDto {
        const section = new TemplateSectionDto()

        if (this.templateRoom) {
            section.templateRoom = this.templateRoom.createDto()
            section.selectionGroups = this.selectionGroups.map((selectionGroup) => selectionGroup.createDto())
        } else if (this.templateCategory) {
            section.templateCategory = this.templateCategory.createDto()
            section.selectionGroups = this.selectionGroups.map((selectionGroup) => selectionGroup.createDto())
        }

        section.isDisabled = this.isDisabled

        return section
    }

    getTotalPriceForDefaultSelections(): number {
        const defaultOptions = this.getAllSelectionItems().filter((option) => option.isDefault)
        return defaultOptions.reduce((sum, option) => {
            return sum + option.totalPrice
        }, 0)
    }

    removeSelectionGroup(selectionGroupIndex: number) {
        this.isEdited = true
        this.selectionGroups[selectionGroupIndex].isDeleted = true
    }

    addSelectionGroup(): SelectionGroupModel {
        const selectionGroupModel = this.createSelectionGroup()
        this.isEdited = true
        this.selectionGroups.push(selectionGroupModel)
        return selectionGroupModel
    }

    copySelectionGroupToSelection(selectionGroupToCopy: SelectionGroupModel) {
        const selectionGroupCopied = selectionGroupToCopy.clone(true)
        selectionGroupCopied.isAdded = true
        selectionGroupCopied.isEdited = false
        this.selectionGroups.push(selectionGroupCopied)
    }

    createSelectionGroup(): SelectionGroupModel {
        const selectionGroupModel = new SelectionGroupModel(Guid.create().toString(), 1, 1, null, [], true)
        return selectionGroupModel
    }

    getAllCostLines(): any[] {
        return this.selectionGroups
            .map((option) => option.getAllCostLines())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    getAllSelectionItems(): SelectionItemModel[] {
        return this.selectionGroups
            .map((option) => option.getAllSelectionItems())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    // todo rename to getSelectedItems
    getSelectedOptions(): SelectionItemModel[] {
        return this.getAllSelectionItems().filter((o) => o.isSelected)
    }

    getNewSelectedOptions(): SelectionItemModel[] {
        return this.getAllSelectionItems().filter((o) => o.isSelected && o.isSelectionSaved)
    }

    moveSelectionGroupUp(selectionGroupIndex: number) {
        let otherTemplateSectionToSwap = selectionGroupIndex - 1
        if (selectionGroupIndex === 0) {
            otherTemplateSectionToSwap = this.selectionGroups.length - 1
        }
        this._swapSection(selectionGroupIndex, otherTemplateSectionToSwap)
    }

    moveSelectionGroupDown(templateSectionIndex: number) {
        let otherTemplateSectionToSwap = templateSectionIndex + 1
        if (templateSectionIndex === this.selectionGroups.length - 1) {
            otherTemplateSectionToSwap = 0
        }
        this._swapSection(templateSectionIndex, otherTemplateSectionToSwap)
    }

    private _swapSection(firstSectionIndex: number, secondSectionIndex) {
        const sectionToSwap = this.selectionGroups[firstSectionIndex]
        this.selectionGroups[firstSectionIndex] = this.selectionGroups[secondSectionIndex]
        this.selectionGroups[secondSectionIndex] = sectionToSwap
    }
}
