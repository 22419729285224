import {
    PackageDto,
    PackageTranslation,
    ImageMediaDto,
    PackageDiscount,
    GetPackageListQuery,
    PackagesService,
    PaginatedResult,
    PackageLookupDto,
    PackageSelectionGroupDto,
    TemplateType,
    TemplatePackageLookupDto,
} from '@app/services/api.services'
import { BaseTranslatableModel } from '../template/BaseTranslatableModel'
import { ImageModel } from '../common/ImageModel'
import { PackageSelectionModel } from './packageSelectionModel'
import { AppliedPackageModel } from './appliedPackageModel'
import { PagingDataSource } from '../interfaces/dataSourceInterface'
import { Observable } from 'rxjs'
import { Guid } from 'guid-typescript'

export class PackageDataSource extends PagingDataSource<PackageLookupDto, GetPackageListQuery> {
    constructor(private service: PackagesService) {
        super()
    }

    getEntitiesObservable(query: GetPackageListQuery): Observable<PaginatedResult> {
        return this.service.packages(query)
    }
}

export class PackageModel extends BaseTranslatableModel<PackageTranslation> {
    private _isLocked: boolean
    private _isApplied: boolean

    id?: string | null
    internalName?: string | null
    packageSelections?: PackageSelectionModel[] = []
    displayImage?: ImageModel
    supportedCurrency: string
    packageDiscountType: PackageDiscount
    packageDiscountAmount: number
    minimumSelectionsRequiredToApplyDiscount: number
    showPackageSelectionThumbnails: boolean
    packageVersionId: string
    versionNo: number

    static createFromDto(packageDto: PackageDto): PackageModel {
        const packageModel = new PackageModel(
            packageDto.id,
            packageDto.internalName,
            packageDto.isLocked,
            packageDto.displayImage,
            packageDto.translations,
            packageDto.isApplied
        )
        packageModel.packageSelections = packageDto.packageSelections.map((packageSelection) =>
            PackageSelectionModel.createFromDto(packageSelection)
        )
        packageModel.supportedCurrency = packageDto.supportedCurrency
        packageModel.packageDiscountType = packageDto.packageDiscountType
        packageModel.minimumSelectionsRequiredToApplyDiscount = packageDto.minimumSelectionsRequiredToApplyDiscount
        packageModel.packageDiscountAmount = packageDto.packageDiscountAmount
        packageModel.selectedTranslation = packageDto.selectedTranslation
        packageModel.showPackageSelectionThumbnails = packageDto.showPackageSelectionThumbnails
        packageModel.packageVersionId = packageDto.packageVersionId
        packageModel.versionNo = packageDto.versionNo

        return packageModel
    }

    constructor(
        id: string,
        internalName: string,
        isLocked: boolean,
        displayImage: ImageMediaDto,
        translations: PackageTranslation[],
        isApplied: boolean = false
    ) {
        super(translations)
        this.id = id
        this.internalName = internalName
        this._isLocked = isLocked
        this._isApplied = isApplied

        if (displayImage && displayImage !== null) {
            this.displayImage = ImageModel.createFromDto(displayImage)
        } else {
            this.displayImage = ImageModel.placeholderImage
        }

        if (this.translations === null || this.translations.length === 0) {
            this.initTranslations(this.translations, () => new PackageTranslation())
        }
    }

    get isApplied() {
        return this._isApplied
    }

    get isLocked() {
        return this._isLocked
    }

    get totalCost(): number {
        let cost = 0

        if (this.packageSelections) {
            this.packageSelections.forEach((selection) => {
                cost += selection.selectionItem.totalPrice
            })
        }

        return cost
    }

    apply() {
        this._isApplied = true
    }

    unapply() {
        this._isApplied = false
    }

    createDto(): PackageDto {
        const packageDto = new PackageDto()

        packageDto.id = this.id
        packageDto.internalName = this.internalName
        packageDto.packageSelections = this.packageSelections.map((p, i) => p.createDto(i))
        packageDto.displayImage = this.displayImage ? this.displayImage.createDto() : null
        packageDto.translations = this.translations
        packageDto.supportedCurrency = this.supportedCurrency
        packageDto.packageDiscountType = this.packageDiscountType
        packageDto.minimumSelectionsRequiredToApplyDiscount = this.minimumSelectionsRequiredToApplyDiscount
        packageDto.packageDiscountAmount = this.packageDiscountAmount
        packageDto.showPackageSelectionThumbnails = this.showPackageSelectionThumbnails
        packageDto.packageVersionId = this.packageVersionId
        packageDto.versionNo = this.versionNo

        return packageDto
    }

    createTemplatePackageLookupDto(templateType: TemplateType): TemplatePackageLookupDto {
        const templatePackageLookupDto = new TemplatePackageLookupDto()

        templatePackageLookupDto.id = this.id
        templatePackageLookupDto.internalName = this.internalName
        templatePackageLookupDto.translations = this.translations
        templatePackageLookupDto.selectedTranslation = this.selectedTranslation
        templatePackageLookupDto.isAvailable = false
        templatePackageLookupDto.isApplied = false
        templatePackageLookupDto.isFrom = templateType
        return templatePackageLookupDto
    }

    getAllPackageSelectionGroups(): PackageSelectionGroupDto[] {
        return this.packageSelections.map((selection) => selection.selectionGroup)
    }

    createAppliedPackage(): AppliedPackageModel {
        const appliedPackage = new AppliedPackageModel()

        appliedPackage.loadPackage(this)

        return appliedPackage
    }

    generateIdsForEntities() {
        this.id = Guid.create().toString()

        this.translations.forEach((translation) => {
            translation.id = Guid.create().toString()
        })

        this.packageSelections.forEach((packageSelection) => {
            packageSelection.id = Guid.create().toString()

            packageSelection.translations.forEach((translation) => {
                translation.id = Guid.create().toString()
            })
        })
    }

    moveSelectionUp(optionIndex: number) {
        let otherOptionToSwap = optionIndex - 1
        if (optionIndex === 0) {
            otherOptionToSwap = this.packageSelections.length - 1
        }
        this._swapSelections(optionIndex, otherOptionToSwap)
    }

    moveSelectionDown(optionIndex: number) {
        let otherOptionToSwap = optionIndex + 1
        if (optionIndex === this.packageSelections.length - 1) {
            otherOptionToSwap = 0
        }
        this._swapSelections(optionIndex, otherOptionToSwap)
    }

    canAddSelectionGroup(selectionGroupId: string, selectionGroupVersionId: string): boolean {
        const selectionGroupWithDifferentVersion = this.packageSelections.find(
            (packageSelection) =>
                packageSelection.selectionGroup.id !== selectionGroupId &&
                packageSelection.selectionGroup.selectionGroupVersionsId === selectionGroupVersionId
        )

        if (!selectionGroupWithDifferentVersion) {
            return true
        }

        return false
    }

    private _swapSelections(firstSectionIndex: number, secondSectionIndex) {
        const sectionToSwap = this.packageSelections[firstSectionIndex]
        this.packageSelections[firstSectionIndex] = this.packageSelections[secondSectionIndex]
        this.packageSelections[secondSectionIndex] = sectionToSwap
    }
}
