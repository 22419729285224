import { Injectable } from '@angular/core'
import { LabelGroupDto, LabelGroupsService, LabelGroupType, GetSpecificLabelGroupsQuery } from './api.services'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LabelGroupService {
    loadedLabelGroups: LabelGroupDto[] = []

    constructor(private labelGroupsService: LabelGroupsService) {}

    getLabelGroupById(id: string, forceReload = false): Observable<LabelGroupDto> {
        if (!forceReload) {
            const labelGroup = this.loadedLabelGroups.find((l) => l.id === id)
            if (labelGroup) {
                return of(labelGroup)
            }
        }

        return this.labelGroupsService.get(id).pipe(
            map((labelGroupDto) => {
                this.loadedLabelGroups.push(labelGroupDto)
                return labelGroupDto
            })
        )
    }

    getLabelGroupByType(type: LabelGroupType, forceReload = false): Observable<LabelGroupDto> {
        if (!forceReload) {
            const labelGroup = this.loadedLabelGroups.find((l) => l.labelGroupType === type)
            if (labelGroup) {
                return of(labelGroup)
            }
        }

        const query = new GetSpecificLabelGroupsQuery()
        query.labelGroupTypes = [type]
        return this.labelGroupsService.getLabelGroups(query).pipe(
            map((labelGroupDtos) => {
                this.loadedLabelGroups = this.loadedLabelGroups.concat(labelGroupDtos)
                return labelGroupDtos[0]
            })
        )
    }
}
