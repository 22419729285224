<div class="d-flex">
    <div style="flex-grow: 1">
        <ng-container *ngFor="let translation of translations.controls; let i = index">
            <div [formGroup]="translation" *ngIf="translation.get('culture').value === selectedLanguage.culture">
                <mat-form-field *ngIf="!useTextArea">
                    <input
                        type="text"
                        [formControlName]="controlName"
                        matInput
                        [placeholder]="placeHolderName(translation.get('culture').value)"
                    />
                </mat-form-field>

                <mat-form-field *ngIf="useTextArea">
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        [formControlName]="controlName"
                        [placeholder]="placeHolderName(translation.get('culture').value)"
                        cdkAutosizeMaxRows="5"
                    ></textarea>
                    <mat-error *ngIf="isFieldValid(translation, controlName)">
                        Required {{ placeholder | lowercase }}
                    </mat-error>
                </mat-form-field>
            </div>
        </ng-container>
        <mat-error *ngFor="let errorTranslation of missingTranslations()">
            Required {{ placeHolderName(errorTranslation.get('culture').value) }}
        </mat-error>
    </div>

    <div *ngIf="!useGlobalLanguageSelect">
        <ng-container *ngFor="let language of languageService.supportedLanguages">
            <button
                *ngIf="selectedLanguage !== language"
                class="btn btn-sm float-left btn-rose btn-just-icon btn-round d-block btn-lang ml-2"
                (click)="selectedLanguage = language"
            >
                {{ language.abvreiation }}
            </button>
        </ng-container>
    </div>
</div>
