import { ServiceSupplierPriceVersionDto, UnitType } from '@app/services/api.services'
import { UnitPriceModel } from '../pricing/UnitPriceModel'

export class ServiceSupplierPriceVersionModel extends UnitPriceModel {
    id?: string | null
    projectName: string | null
    projectId: number | null
    supplierName: string
    supplierId: string
    serviceRateId: string
    versionNo: number
    isLatestVersion: boolean
    isArchived: boolean
    internalName = ''
    serviceUnit: UnitType

    constructor(
        id: string,
        versionNo: number,
        unitCostAmout: number,
        unitMarkupAmount: number,
        unitAmount: number,
        serviceUnit: UnitType,
        isLatestVersion: boolean,
        internalName?: string
    ) {
        super(unitCostAmout, unitMarkupAmount, false)
        this.id = id
        this.versionNo = versionNo
        this.isLatestVersion = isLatestVersion
        this.unitCostAmount = unitCostAmout
        this.unitMarkupAmount = unitMarkupAmount
        this.unitAmount = unitAmount
        this.serviceUnit = serviceUnit

        if (internalName) {
            this.internalName = internalName
        } else {
            this.internalName = `Version ${versionNo}`
        }
    }

    static createFromDto(
        productSupplierPriceVersionDto: ServiceSupplierPriceVersionDto
    ): ServiceSupplierPriceVersionModel {
        const productSupplierPriceVersion = new ServiceSupplierPriceVersionModel(
            productSupplierPriceVersionDto.id,
            productSupplierPriceVersionDto.versionNo,
            productSupplierPriceVersionDto.unitCostAmount,
            productSupplierPriceVersionDto.unitMarkupAmount,
            productSupplierPriceVersionDto.unitAmount,
            productSupplierPriceVersionDto.serviceUnit,
            productSupplierPriceVersionDto.isLatestVersion,
            productSupplierPriceVersionDto.internalName
        )

        return productSupplierPriceVersion
    }
}
