import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PackageApplierDialogComponent } from './package-applier-dialog.component'
import { PackageModel } from '@app/models/packages/packageModel'
import { PropertyTemplate, PackagesService } from '@app/services/api.services'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { AppliedPackageModel } from '@app/models/packages/appliedPackageModel'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class PackageApplierDialogService {
    constructor(private dialog: MatDialog, private packagesService: PackagesService) {}

    showDialog(propertyTemplate: TemplateModel, packageId: string): Observable<AppliedPackageModel> {
        const dialogRef = this.dialog.open(PackageApplierDialogComponent, {
            width: '500px',
            data: {
                appliedPackageObservable: this.packagesService
                    .packageTemplate(packageId, propertyTemplate.id, propertyTemplate.templateType)
                    .pipe(map((packageDto) => PackageModel.createFromDto(packageDto).createAppliedPackage())),
                propertyTemplate,
                displayModeOnly: false,
            },
        })

        return dialogRef.afterClosed()
    }

    showDisplayOnlyDialog(propertyTemplate: TemplateModel, packageModel: PackageModel) {
        const dialogRef = this.dialog.open(PackageApplierDialogComponent, {
            width: '500px',
            data: {
                appliedPackageObservable: of(packageModel.createAppliedPackage()),
                propertyTemplate,
                displayModeOnly: true,
            },
        })

        return dialogRef.afterClosed()
    }
}
