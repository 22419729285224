import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    EventEmitter,
    ElementRef,
    TemplateRef,
    HostListener,
} from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { tap, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { PaginationModel } from '@app/services/api.services'
import { fromEvent } from 'rxjs'
import { PagingPersistService } from '@app/services/paging-persistent.service'
import { DataSource } from '@angular/cdk/table'

export enum PagingDisplayMode {
    Dialog,
    Card,
    ListOnly,
}

@Component({
    selector: 'app-server-paging-container',
    templateUrl: './server-paging-container.component.html',
    styleUrls: ['./server-paging-container.component.scss'],
})
export class ServerPagingContainerComponent<EntityModel> implements OnInit {
    @Input() customInputs: TemplateRef<any>
    @Input() mobileList: TemplateRef<any>
    @Output() reload = new EventEmitter<any>()
    inMobileMode = false

    _dataSource: DataSource<EntityModel>
    get dataSource(): DataSource<EntityModel> {
        return this._dataSource
    }

    @Input('dataSource')
    set dataSource(value: DataSource<EntityModel>) {
        this._dataSource = value
    }

    PagingDisplayModeTypes = PagingDisplayMode

    @Input() displayMode: PagingDisplayMode = PagingDisplayMode.Card
    @Input() entityName: string
    @Input() entityDisplayName: string
    @Input() isLoaded: boolean
    @Input() isFailed: boolean
    @Input() length: number
    @Input() defaultSortColumn: string
    @Input() matSort: MatSort
    @Input() filterPredicate: (data, filterValue: string) => boolean
    @Input() sortingLogic: (item, property) => any
    @Output() retryReload = new EventEmitter()
    @Input() persistedPagingKey: string

    @Input() customTitle: string

    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild('input') input: ElementRef
    paginationModel: PaginationModel

    constructor(private pagingPersistService: PagingPersistService) {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.getScreenSize()
            if (this.persistedPagingKey) {
                this.paginator.pageIndex = this.paginationModel.page
                this.paginator.pageSize = this.paginationModel.pageSize
                if (this.paginationModel.sortOrder && this.paginationModel.sortOrder !== null) {
                    this.matSort.active = this.paginationModel.sortOrder
                    this.matSort.direction = this.paginationModel.ascSortDirection ? 'asc' : 'desc'

                    this.matSort.sortChange
                        .pipe(
                            tap((sort) => {
                                this._persistPageIndex()
                            })
                        )
                        .subscribe()
                }

                this.input.nativeElement.value = this.paginationModel.searchString
            }

            fromEvent(this.input.nativeElement, 'keyup')
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    tap(() => {
                        this.paginator.pageIndex = 0
                        this._persistPageIndex()
                        this.reload.emit()
                    })
                )
                .subscribe()

            // this.paginator.pageSize = this.paginationModel.pageSize;

            this.paginator.page
                .pipe(
                    tap(() => {
                        this._persistPageIndex()
                        this.reload.emit()
                    })
                )
                .subscribe()
        })
    }

    resetPageIndex() {
        if (this.paginator) {
            this.paginator.pageIndex = 0
        }
    }

    ngOnInit() {
        if (!this.entityDisplayName || this.entityDisplayName.length === 0) {
            this.entityDisplayName = this.entityName
        }

        this._loadStartingPagingValues()
    }

    generatePaginationModel(): PaginationModel {
        if (!this.paginator) {
            this._loadStartingPagingValues()
        }

        this.paginationModel.page = this.paginator ? this.paginator.pageIndex : 0
        this.paginationModel.pageSize = this.paginator ? this.paginator.pageSize : this.paginationModel.pageSize
        this.paginationModel.searchString = this.input ? this.input.nativeElement.value : ''

        if (this.matSort) {
            this.paginationModel.ascSortDirection = this.matSort.direction === 'asc'
            this.paginationModel.sortOrder = this.matSort.active
        } else {
            this.paginationModel.ascSortDirection = false
            this.paginationModel.sortOrder = ''
        }

        return this.paginationModel
    }

    private _loadStartingPagingValues() {
        if (this.persistedPagingKey && this.pagingPersistService.hasPagingValue(this.persistedPagingKey)) {
            this.paginationModel = this.pagingPersistService.getPagingValue(this.persistedPagingKey)
        } else {
            this.paginationModel = new PaginationModel({
                ascSortDirection: false,
                page: 0,
                pageSize: 10,
                searchString: '',
                sortOrder: null,
            })
        }
    }

    private _persistPageIndex() {
        this.pagingPersistService.savePagingInfo(this.persistedPagingKey, this.generatePaginationModel())
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        const width = window.innerWidth
        if (width < 768) {
            // md breakpoint
            this.inMobileMode = true
        } else {
            this.inMobileMode = false
        }
    }
}
