<h2 mat-dialog-title>{{ isEditing ? 'Edit Task' : 'Add Task' }}</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-6">
            <div *ngIf="model.useTimePicker">
                <label>Start Time</label><br />
                <ngx-timepicker-field
                    [format]="24"
                    #startTimePicker
                    [defaultTime]="timesheetTask.startTime"
                    [(ngModel)]="timesheetTask.startTime"
                >
                </ngx-timepicker-field>
            </div>
            <mat-form-field *ngIf="!model.useTimePicker">
                <mat-select [(ngModel)]="timesheetTask.startTime" placeholder="Start Time">
                    <mat-option *ngFor="let time of model.selectableTimes" [value]="time.value">
                        {{ time.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-6">
            <div *ngIf="model.useTimePicker">
                <label>End Time</label><br />
                <ngx-timepicker-field
                    [format]="24"
                    [defaultTime]="timesheetTask.endTime"
                    [(ngModel)]="timesheetTask.endTime"
                ></ngx-timepicker-field>
            </div>

            <mat-form-field *ngIf="!model.useTimePicker">
                <mat-select [(ngModel)]="timesheetTask.endTime" name="item" placeholder="End Time">
                    <mat-option *ngFor="let time of model.selectableTimes" [value]="time.value">
                        {{ time.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 mt-2">
            <mat-checkbox [(ngModel)]="isBreak">Is Break</mat-checkbox>
        </div>

        <div class="col-12">
            <mat-form-field *ngIf="model.selectableProjects">
                <mat-select
                    [(ngModel)]="timesheetTask.project"
                    placeholder="Project"
                    [compareWith]="compareById"
                    [disabled]="isBreak"
                >
                    <mat-option>None</mat-option>
                    <mat-option
                        *ngFor="let project of model.selectableProjects | orderBy: { property: 'name', direction: 1 }"
                        [value]="project"
                    >
                        {{ project.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field>
                <mat-select
                    [(ngModel)]="timesheetTask.property"
                    [compareWith]="compareById"
                    placeholder="Property"
                    [disabled]="isBreak"
                >
                    <mat-option>None</mat-option>
                    <ng-container *ngIf="timesheetTask.project && timesheetTask.project.properties">
                        <mat-option
                            *ngFor="
                                let property of timesheetTask.project.properties
                                    | orderBy: { property: 'name', direction: 1 }
                            "
                            [value]="property"
                        >
                            {{ property.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12">
            <app-timehseet-property-task-select [task]="timesheetTask"></app-timehseet-property-task-select>
        </div>

        <div class="col-12">
            <mat-form-field>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    placeholder="Description"
                    [(ngModel)]="timesheetTask.description"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<div mat-dialog-actions [ngClass]="isEditing ? 'edit-mode' : 'add-mode'">
    <mat-checkbox
        [(ngModel)]="model.clearProjectAndPropertyOnNewTask"
        *ngIf="!isEditing"
        (change)="saveTimesheetSettings()"
        >Clear project and property on add</mat-checkbox
    >
    <mat-checkbox [(ngModel)]="model.useTimePicker" (change)="saveTimesheetSettings()">Use time picker</mat-checkbox>
    <button *ngIf="isEditing" color="warn" mat-button (click)="delete()">Delete</button>
    <div class="w-100 d-flex justify-content-between">
        <button mat-button (click)="close()">Close</button>

        <div>
            <button *ngIf="!isEditing" mat-button [disabled]="isSubmitting" (click)="add(false)">Add</button>
            <button *ngIf="!isEditing" mat-button cdkFocusInitial [loading]="isSubmitting" (click)="add(true)">
                Add & Save
            </button>
            <button *ngIf="isEditing" mat-button (click)="completeEdit(false)">Done</button>
            <button *ngIf="isEditing" mat-button cdkFocusInitial (click)="completeEdit(true)">Done & Save</button>
        </div>
    </div>
</div>
