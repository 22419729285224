<div class="d-flex justify-content-between">
    <h2 mat-dialog-title>Create New Property</h2>
    <ng-container *ngFor="let language of languageService.supportedLanguages">
        <button
            *ngIf="languageService.formSelectedLanguage !== language"
            class="btn btn-sm btn-rose btn-just-icon btn-round d-block btn-lang"
            (click)="languageService.changeLanguage(language)"
        >
            {{ language.abvreiation }}
        </button>
    </ng-container>
</div>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <app-multi-language
                    [placeholder]="'Label Name'"
                    [controlName]="'label'"
                    [translations]="formGroup.get('translations')"
                ></app-multi-language>
            </div>
            <div class="col-sm-6 col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Type" formControlName="propertyDefinitionType">
                        <mat-option *ngFor="let type of enumListService.propertyDefinitionTypes" [value]="type.value">
                            {{ type.display }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'propertyDefinitionType')">
                        Required type
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6 col-md-4">
                <mat-form-field>
                    <mat-select
                        placeholder="Subtype"
                        formControlName="propertyDefinitionSubType"
                        [disabled]="isSubTypesDisabled"
                    >
                        <mat-option *ngFor="let type of availableSubTypes" [value]="type.value">
                            {{ type.display }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'propertyDefinitionSubType')">
                        Required subtype
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-4">
                <mat-form-field>
                    <input matInput placeholder="Label Identifier" formControlName="labelIdentifier" />
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'labelIdentifier')">
                        Required label identifier
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="isCategoricalPropertyDefinition">
            <div class="col-12">
                <h4>Allowed Values</h4>
                <table mat-table [dataSource]="dataSource" formArrayName="allowedValues">
                    <ng-container matColumnDef="label">
                        <th mat-header-cell *matHeaderCellDef>Label</th>
                        <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
                            <app-multi-language
                                [placeholder]="'Label'"
                                [controlName]="'allowedValue'"
                                [translations]="allowedValuesFormArray.controls[i].get('translations')"
                            ></app-multi-language>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="internalValue">
                        <th mat-header-cell *matHeaderCellDef>Internal Value</th>
                        <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
                            <mat-form-field>
                                <input matInput placeholder="Internal Value" formControlName="valueIdentifier" />
                                <mat-error *ngIf="isAllowedValueFieldValid(i, 'valueIdentifier')">
                                    Required internal value
                                </mat-error>
                            </mat-form-field>
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
                        <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                            <button (click)="remove(i)" class="btn btn-link btn-danger">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef class="action-column">
                            <button (click)="addAllowedValue()" mat-icon-button color="primary">
                                <i class="material-icons">add</i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button cdkFocusInitial (click)="create()" [loading]="isCreating">Create</button>
    </div>
</mat-dialog-actions>
