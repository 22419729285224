import { Component, OnInit, ElementRef } from '@angular/core'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
    selector: 'app-layout',
    templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit {
    private toggleButton: any
    private sidebarVisible: boolean
    private _router: Subscription

    constructor(private router: Router, private element: ElementRef) {
        this.sidebarVisible = false
    }
    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement

        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0]
        this._router = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.sidebarClose()
            })
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton
        const body = document.getElementsByTagName('body')[0]
        setTimeout(function () {
            toggleButton.classList.add('toggled')
        }, 500)
        body.classList.add('nav-open')

        this.sidebarVisible = true
    }
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0]
        this.toggleButton.classList.remove('toggled')
        this.sidebarVisible = false
        body.classList.remove('nav-open')
    }
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen()
        } else {
            this.sidebarClose()
        }
    }
}
