import { NgModule } from '@angular/core'
import { CostLineDialogComponent } from './cost-line-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'

@NgModule({
    imports: [CommonFormsModule],
    exports: [CostLineDialogComponent],
    declarations: [CostLineDialogComponent],
    entryComponents: [CostLineDialogComponent],
})
export class CostLineDialogModule {}
