import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { empty, of, Observable, ReplaySubject, EMPTY } from 'rxjs'
import { Router, ActivatedRoute } from '@angular/router'
import { map } from 'rxjs/operators'
import { NgxPermissionsService } from 'ngx-permissions'
import { LoggedInUserDetailsDto, AuthService, RefreshTokenDto, BuilderClientDto, BuyerDto } from './api.services'
import { AccessToken } from '@app/models/auth/accessToken'

export class UserInfo {
    email: string
    assignedPolicies: string[]
    firstName: string
    lastName: string
    userType: string
    id: string
    phone: string
    phoneExt: string

    get name(): string {
        return `${this.firstName} ${this.lastName}`
    }

    get phoneNumber(): string {
        if (!this.phone) {
            return
        }
        return this.phoneExt ? `${this.phone} ext:${this.phoneExt}` : this.phone
    }

    static createFromJson(json) {
        const userInfo = new UserInfo()
        userInfo.email = json.email
        userInfo.firstName = json.firstName
        userInfo.lastName = json.lastName
        userInfo.userType = json.userType
        userInfo.id = json.id
        userInfo.phone = json.phone
        userInfo.phoneExt = json.phoneExtension
        return userInfo
    }

    static createFromDto(dto: LoggedInUserDetailsDto) {
        const userInfo = new UserInfo()
        userInfo.email = dto.email
        userInfo.firstName = dto.firstName
        userInfo.lastName = dto.lastName
        userInfo.userType = dto.userType
        userInfo.id = dto.id
        userInfo.phone = dto.phone
        userInfo.phoneExt = dto.phoneExtension
        return userInfo
    }
}

@Injectable()
export class AccountManageService {
    private _accessToken: AccessToken

    public accountInfoSource = new ReplaySubject<UserInfo>(undefined)
    public assignedRolesSource = new ReplaySubject<string[]>(undefined)

    accountInfo = this.accountInfoSource.asObservable()
    userName: string
    isLoggedIn = false
    private _viewingClient: BuyerDto
    private _inClientViewMode: boolean

    userType: any
    get accessToken(): AccessToken {
        return this._accessToken
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private permissionService: NgxPermissionsService
    ) {
        const accessTokenString = localStorage.getItem('access_token')

        if (accessTokenString !== null) {
            try {
                this._accessToken = AccessToken.createFromString(accessTokenString)
            } catch {}
        }

        const viewingClient = localStorage.getItem('viewing_client')
        if (viewingClient) {
            this._viewingClient = BuyerDto.fromJS(JSON.parse(viewingClient))
        }
        this._inClientViewMode = localStorage.getItem('view_as_client') === 'true'

        const rawUser = localStorage.getItem('user')

        if (rawUser !== null) {
            const user = JSON.parse(rawUser)
            this.userName = `${user.firstName} ${user.lastName}`
            this.userType = user.userType
            this.accountInfoSource.next(UserInfo.createFromJson(user))
            if (user.assignedPolicies) {
                this.permissionService.loadPermissions(user.assignedPolicies)
            }
            this.isLoggedIn = true
        } else {
            console.log('User not logged in')
            if (window.location.href.indexOf('reset-password') < 0) this.router.navigate(['/login'])
            this.isLoggedIn = false
        }
    }

    gotoLogin() {
        this.router.navigate(['/login'])
    }

    logout(): Observable<void> {
        if (this.isLoggedIn) {
            localStorage.clear()
            this.exitClientViewMode()
            this.accountInfoSource.complete()
            if (!this.accessToken || !this.accessToken.refreshToken) {
                this.router.navigate(['/login'])
                return of()
            }
            const refreshTokenDto = new RefreshTokenDto()
            refreshTokenDto.token = this.accessToken.refreshToken
            this._accessToken = undefined
            return this.authService.revokeRefreshToken(refreshTokenDto).pipe(
                map(
                    () => {
                        this.router.navigate(['/login'])
                    },
                    (error) => {
                        console.log('logout on server failed', error)
                    }
                )
            )
        }
        return EMPTY
    }

    refreshUser(user: LoggedInUserDetailsDto) {
        this.accountInfoSource.next(UserInfo.createFromDto(user))
        if (user.assignedPolicies) {
            this.permissionService.loadPermissions(user.assignedPolicies)
        }
        localStorage.setItem('user', JSON.stringify(user))
        this.userType = user.userType
        if (this.userType === 'Employee') {
            this._handleLoginForEmployee()
        }
        this.isLoggedIn = true
        this.userName = `${user.firstName} ${user.lastName}`
    }

    storeToken(accessToken: AccessToken) {
        this._accessToken = accessToken
        localStorage.setItem('access_token', accessToken.toString())
        localStorage.setItem('fdsa', accessToken.toString())
    }

    setUserInfo(user: any) {
        this.refreshUser(user)
    }

    startClientViewMode(client: BuyerDto) {
        localStorage.setItem('view_as_client', JSON.stringify(true))
        this._inClientViewMode = true
        if (client) {
            localStorage.setItem('viewing_client', JSON.stringify(client))
            this._viewingClient = client
        }
    }

    exitClientViewMode() {
        localStorage.removeItem('view_as_client')
        localStorage.removeItem('viewing_client')
        this._inClientViewMode = false
        this._viewingClient = null
    }

    get isInClientViewMode(): boolean {
        return this._inClientViewMode
    }

    get viewingClient(): BuyerDto {
        if (!this.isInClientViewMode) {
            return null
        }
        return this._viewingClient
    }

    get viewingPropertyInClientViewMode(): boolean {
        return this.isInClientViewMode && !this.viewingClient
    }

    _handleLoginForEmployee() {}
}
