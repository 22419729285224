import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { UploadMediaFormSettings } from '@app/models/dialogs/uploadMediaFormSettings'
import { DialogResult } from '@app/models/dialogResult'
import { MediaDto } from '@app/services/api.services'
import { UploadMediaDialogComponent } from './upload-media-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class UploadMediaFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: UploadMediaFormSettings): Observable<DialogResult<MediaDto[]>> {
        const dialogRef = this.dialog.open(UploadMediaDialogComponent, {
            width: '1600px',
            data: setting,
        })

        return dialogRef.afterClosed()
    }
}
