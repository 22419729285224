export class FileSelectSettings {
    pickDocument = true
    pickImage = true
    requiredImageDimensions = false
    requiredHeight: number
    requiredWidth: number
    defaultLabelIds: string[] = []
    defaultProjectId?: number
    lockLabelSelect = false
    isPrivate = false

    resetFilters() {
        this.defaultLabelIds = []
        this.defaultProjectId = null
        this.lockLabelSelect = false
    }
}
