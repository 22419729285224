import { Injectable } from '@angular/core'
import { ImageService } from '@app/services/api.services'
import { Observable, ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'

export class ImageRenderSettings {
    constructor(public height: number, public width: number) {}

    static getThumbnailSettings(): ImageRenderSettings {
        return new ImageRenderSettings(75, 75)
    }
}

@Injectable({
    providedIn: 'root',
})
export class ImageCdnService {
    private _imageCdnUrlObservable = new ReplaySubject<string>()

    constructor(imageService: ImageService) {
        console.log('Loading image cdn url')

        imageService.imageCdnUrl().subscribe(
            (imageCdnUrl) => {
                this._imageCdnUrlObservable.next(imageCdnUrl)
            },
            (err) => {
                console.log('Failed to load image cdn url')
            }
        )
    }

    generateCdnThumbnailUrl(imageUrl: string): Observable<string> {
        return this.generateCdnUrl(imageUrl, ImageRenderSettings.getThumbnailSettings())
    }

    generateCdnUrl(imageUrl: string, imageRenderSettings: ImageRenderSettings): Observable<string> {
        const imageFileName = imageUrl.replace(/^.*[\\\/]/, '')

        return this._imageCdnUrlObservable.pipe(
            map((url) => {
                if (url) {
                    return `${url}/${imageFileName}?w=${imageRenderSettings.width}&h=${imageRenderSettings.height}&auto=format`
                } else {
                    // No cdn is enabled so just use the image url
                    return imageUrl
                }
            })
        )
    }
}
