import { UnitType, CostLineTranslation, ProductCostLineDto, ProductCostLineInput } from '@app/services/api.services'
import { BaseCostLineModel } from '../BaseCostLineModel'
import { CostLineDialogModel } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { Guid } from 'guid-typescript'

export class ProductCostLineModel extends BaseCostLineModel {
    static createFromDto(costLine: ProductCostLineDto, isCopy = false): ProductCostLineModel {
        const costLineModel = new ProductCostLineModel(
            costLine.id,
            costLine.quantity,
            costLine.unitType,
            costLine.unitCostAmount,
            costLine.unitMarkupAmount,
            costLine.unitMarkupPercent,
            costLine.isUnitMarkupPercent,
            costLine.isVisibleToClients,
            []
        )

        costLineModel.unitCostAmount = costLine.unitCostAmount
        costLineModel.translations = costLine.translations.map((t) => new CostLineTranslation(t))
        costLineModel.selectedTranslation = costLine.selectedTranslation
        costLineModel.unitType = costLine.unitType
        costLineModel.hasRetailAmount = costLine.hasRetailAmount
        costLineModel.retailAmount = costLine.retailAmount
        costLineModel.retailDiscountPercent = costLine.retailDiscountPercent
        if (isCopy) {
            costLineModel.id = null
            costLineModel.translations.forEach((translation, index) => {
                translation.id = null
                costLineModel.translations[index] = translation
            })
        }

        return costLineModel
    }

    static createFromDialogModel(costLineDialogModel: CostLineDialogModel): ProductCostLineModel {
        const costLine = new ProductCostLineModel(
            Guid.create().toString(),
            costLineDialogModel.quantity,
            costLineDialogModel.unitType,
            costLineDialogModel.unitPrice.unitCostAmount,
            costLineDialogModel.unitPrice.unitMarkupAmount,
            costLineDialogModel.unitPrice.unitMarkupPercent,
            costLineDialogModel.unitPrice.isUnitMarkupPercent,
            false,
            costLineDialogModel.translations
        )
        costLine.hasRetailAmount = costLineDialogModel.unitPrice.hasRetailAmount
        costLine.retailAmount = costLineDialogModel.unitPrice.retailAmount
        costLine.retailDiscountPercent = costLineDialogModel.unitPrice.retailDiscountPercent
        return costLine
    }

    constructor(
        id: string,
        quantity: number,
        unitType: UnitType,
        unitCostAmount: number,
        unitMarkupAmount: number,
        unitMarkupPercent: number,
        isUnitMarkupPercent: boolean,
        isVisibleToClients: boolean,
        translations: CostLineTranslation[]
    ) {
        super(
            id,
            quantity,
            unitType,
            unitCostAmount,
            unitMarkupAmount,
            unitMarkupPercent,
            isUnitMarkupPercent,
            isVisibleToClients,
            translations
        )

        if (!this.translations || this.translations.length === 0) {
            this.initTranslations(this.translations, () => new CostLineTranslation())
        }
    }

    createInput(): ProductCostLineInput {
        const input = new ProductCostLineInput()

        input.quantity = this.quantity
        input.translations = this.translations

        input.unitCostAmount = this.unitCostAmount
        input.unitMarkupAmount = this.unitMarkupAmount
        input.unitMarkupPercent = this.unitMarkupPercent
        input.isUnitMarkupPercent = this.isUnitMarkupPercent

        input.unitType = this.unitType
        input.hasRetailAmount = this.hasRetailAmount
        input.retailAmount = this.retailAmount
        input.retailDiscountPercent = this.retailDiscountPercent

        return input
    }

    getName(): string {
        return this.selectedTranslation.description
    }

    isUnitCostEditable(): boolean {
        return true && this.isEditing
    }

    isUnitMarkupEditable(): boolean {
        return true && this.isEditing
    }

    isDescriptionEditable(): boolean {
        return true && this.isEditing
    }

    isTakeOffsSupported(): boolean {
        return false
    }

    isUnitTypeEditable(): boolean {
        return true && this.isEditing
    }

    isLinkedCostLine(): boolean {
        return false
    }

    createDto(): ProductCostLineDto {
        const costLine = new ProductCostLineDto()
        this.populateCommonDtoFieldsFromStandalone(costLine)
        costLine.unitType = this.unitType

        return costLine
    }

    createProductCostLineInput() {
        throw new Error('not implemented')
    }
}
