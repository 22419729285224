import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'
import { ProductVariationGroupListComponent } from './product-variation-group-list.component'

@NgModule({
    imports: [CommonFormsModule, ServerPagingContainerModule],
    declarations: [ProductVariationGroupListComponent],
    entryComponents: [ProductVariationGroupListComponent],
})
export class ProductVariationGroupListModule {}
