<div #buttonDiv class="fixed-form-controls">
    <ng-container *ngIf="showLanguageButtons">
        <ng-container *ngFor="let language of this.fixedFormControlsService.languageService.supportedLanguages">
            <button
                *ngIf="language !== selectLanguage"
                type="button"
                (click)="changeLanguage(language)"
                color="accent"
                mat-fab
                class="d-block"
            >
                {{ language.abvreiation }}
            </button>
        </ng-container>
    </ng-container>
    <ng-container
        *ngFor="
            let fixedButtons of fixedFormControlsService.fixedButtons
                | orderBy: { property: 'sortyPriority', direction: 1 }
        "
    >
        <ng-container *ngIf="!fixedButtons.isHidden">
            <ng-container *ngIf="fixedButtons.policies.length > 0; else noPolicy">
                <button
                    *ngxPermissionsOnly="fixedButtons.policies"
                    type="button"
                    (click)="fixedButtons.click()"
                    [disabled]="fixedButtons.isDisabled"
                    mat-fab
                    class="d-block mt-2"
                    [color]="fixedButtons.color"
                >
                    <i class="material-icons" matTooltip="{{ fixedButtons.tooltip }}">{{ fixedButtons.icon }}</i>
                </button>
            </ng-container>

            <ng-template #noPolicy>
                <button
                    type="button"
                    (click)="fixedButtons.click()"
                    [disabled]="fixedButtons.isDisabled"
                    mat-fab
                    [color]="fixedButtons.color"
                    class="d-block mt-2"
                >
                    <i class="material-icons" matTooltip="{{ fixedButtons.tooltip }}">{{ fixedButtons.icon }}</i>
                </button>
            </ng-template>
        </ng-container>
    </ng-container>
</div>
