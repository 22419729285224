import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { AppliedPackageSelectionModel } from '@app/models/packages/appliedPackageModel'
import { SelectionItemModel } from '@app/models/template/SelectionGroupModel'
import { PackageSelectionModel } from '@app/models/packages/packageSelectionModel'
import { ImageCdnService } from '@app/shared/images/image-cdn.service'

@Component({
    selector: 'app-package-selection-card',
    templateUrl: './package-selection-card.component.html',
    styleUrls: ['./package-selection-card.component.scss'],
})
export class PackageSelectionCardComponent implements OnInit {
    @Output() showDetails = new EventEmitter<string>()
    @Output() replaceSelectionItem = new EventEmitter<AppliedPackageSelectionModel>()
    @Output() selected = new EventEmitter<SelectionItemModel>()

    @Input() appliedPackageSelection: AppliedPackageSelectionModel
    @Input() selectionItem: SelectionItemModel
    @Input() showPackageSelectionThumbnails: boolean

    constructor(public imageCdnService: ImageCdnService) {}

    ngOnInit() {}

    select() {
        this.selected.emit(this.isSelected ? this.selectionItem : this.appliedPackageSelection.selectionItem)
    }

    get isSelected(): boolean {
        return this.isSelectionItem
            ? this.appliedPackageSelection.selectionItem.id === this.selectionItem.id
            : this.appliedPackageSelection.isSelected
    }

    get selectionItemId() {
        return this.isAppliedPackageSelection ? this.appliedPackageSelection.selectionItem.id : this.selectionItem.id
    }

    get isSelectionItem(): boolean {
        return this.selectionItem !== undefined
    }

    get isAppliedPackageSelection(): boolean {
        return !this.isSelectionItem
    }

    get canReplaceWithOtherSelection(): boolean {
        return (
            this.isAppliedPackageSelection && this.appliedPackageSelection.packageSelection.canReplaceWithOtherSelection
        )
    }

    get hasToSelect(): boolean {
        return this.isAppliedPackageSelection && this.appliedPackageSelection.packageSelection.hasToSelect
    }

    get title(): string {
        return this.isSelectionItem ? this.selectionItem.getName() : this.appliedPackageSelection.packageSelection.title
    }

    get price(): number {
        return this.isSelectionItem
            ? this.selectionItem.totalPrice
            : this.appliedPackageSelection.packageSelection.price
    }

    get imageUrl(): string {
        return this.isSelectionItem
            ? this.selectionItem.getDisplayImageUrl()
            : this.appliedPackageSelection.selectionItem.getDisplayImageUrl()
    }
}
