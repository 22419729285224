import { ImageMediaDto, MediaDto, DocumentMediaDto, ProjectDto, LabelDto } from '@app/services/api.services'
import { AppSettings } from '@app/app.settings'
import { ImageModel } from './ImageModel'

export class DocumentModel {
    id: string
    filePath: string
    uploadedFileName: string
    culture: string
    isAdded: boolean
    isDeleted: boolean

    static createFromDto(documentMediaDto: DocumentMediaDto): DocumentModel {
        const imageModel = new DocumentModel(
            documentMediaDto.id,
            documentMediaDto.filePath,
            documentMediaDto.uploadedFileName,
            documentMediaDto.culture
        )
        return imageModel
    }

    static createFromMediaDto(media: MediaDto): DocumentModel {
        return new DocumentModel(media.id, media.filePath, media.uploadedFileName, media.culture)
    }

    constructor(id: string, filePath: string, uploadedFileName: string, culture: string) {
        this.id = id
        this.filePath = filePath
        this.uploadedFileName = uploadedFileName
        this.culture = culture
    }

    createDto(): DocumentMediaDto {
        const documentMedia = new DocumentMediaDto()
        documentMedia.id = this.id
        documentMedia.filePath = this.filePath
        documentMedia.uploadedFileName = this.uploadedFileName
        documentMedia.culture = this.culture
        return documentMedia
    }
}
