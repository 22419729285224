import { NotifyService } from './../../../../services/notify.service'
import EmailNoteContent from './email-note-content'
import { EmailNoteFormDialogSettings } from './emailNoteFormSettings'
import { Component, OnInit, Inject } from '@angular/core'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import {
    LanguageType,
    ProjectsService,
    ProjectDto,
    MediaDto,
    NoteType,
    NotesService,
    CreateNoteCommand,
    NoteCommand,
    DocumentMediaDto,
    ImageMediaDto,
} from '@app/services/api.services'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { EnumListService } from '@app/services/enum-list.service'
import { DialogResult, DialogResultActions } from '@app/models/dialogResult'
import { AccountManageService, UserInfo } from '@app/services/accountManage.service'
import { mergeMap, tap } from 'rxjs/operators'
import { MediaPickerDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { MediaPickerDialogService } from '@app/shared/dialog/media-picker-dialog/media-picker-dialog.service'
import { CommonComponent } from '@app/models/formComponent'
import { Observable, zip } from 'rxjs'

@Component({
    selector: 'app-email-note-form-dialog',
    templateUrl: './email-note-form-dialog.component.html',
    styleUrls: ['./email-note-form-dialog.component.scss'],
})
export class EmailNoteFormDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup
    userInfo: UserInfo

    project: ProjectDto
    projects$: Observable<ProjectDto[]>
    attachments: MediaDto[] = []
    isLoading = true
    displayPackageDropdown = true
    isSubmitting = false

    attachedDocument?: DocumentMediaDto | null
    attachedImage?: ImageMediaDto | null

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: EmailNoteFormDialogSettings,
        private dialogRef: MatDialogRef<EmailNoteFormDialogComponent>,
        private notesService: NotesService,
        private projectsService: ProjectsService,
        public enumListService: EnumListService,
        private formBuilder: FormBuilder,
        private accountManageService: AccountManageService,
        private mediaPickerDialogService: MediaPickerDialogService,
        private notifyService: NotifyService
    ) {
        super()
    }

    ngOnInit() {
        this.isLoading = true

        this.displayPackageDropdown = this.settings.hasProject

        if (this.displayPackageDropdown) {
            this.projects$ = this.projectsService.getAll()
        }
        this._setupformControl()
    }

    get dialogTitle(): string {
        switch (this.settings.noteType) {
            case NoteType.ThankYouForCalling:
                return 'Send Thank You for Calling Email'
            case NoteType.ThankYouForVisiting:
                return 'Send Thank You for Visiting Email'
            case NoteType.NoResponseEmail:
                return 'Send No Response Email'
            case NoteType.AgentRegistered:
            default:
                return 'Send agent registered email'
        }
    }

    get language(): LanguageType {
        return this.formGroup.get('languageType').value
    }

    get projectName(): string {
        return this.project
            ? this.project.name
            : this.language === LanguageType.English
            ? 'PROJECT NAME GOES HERE'
            : 'NOM DU PROJET VA ICI'
    }

    get currentUserContact(): string {
        let phoneString = ''
        if (this.userInfo.phoneNumber) {
            phoneString =
                this.language === LanguageType.English
                    ? ` or ${this.userInfo.phoneNumber}`
                    : ` ou ${this.userInfo.phoneNumber}`
        }
        return `${this.userInfo.email}${phoneString}`
    }

    get attachmentNames(): string {
        if (this.attachments && this.attachments.length > 0) {
            let attachmentString = ''
            this.attachments.forEach((value, i) => {
                if (i > 0) {
                    attachmentString += ', '
                }
                attachmentString += value.uploadedFileName
            })
            return attachmentString
        }
        return null
    }

    onSubmitClick(): void {
        if (this.formGroup.valid) {
            this.isSubmitting = true

            const command = new CreateNoteCommand()

            command.noteType = this.settings.noteType

            this._populateCommand(command)
            this.notesService
                .create(command)
                .pipe(
                    mergeMap((noteId) => {
                        return this.notesService.get(noteId)
                    })
                )
                .subscribe((entity) => {
                    this.isSubmitting = false
                    this.dialogRef.close(new DialogResult(DialogResultActions.Create, entity))
                })
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    addAttachment() {
        const settings = new MediaPickerDialogSettings(true, true)
        settings.canUploadMultipleFiles = true
        if (this.project) {
            settings.fileSelectSettings.defaultProjectId = this.project.id
        }

        this.mediaPickerDialogService.showDialog(settings).subscribe((medias) => {
            if (medias) {
                this.attachments = this.attachments.concat(medias)
            }
        })
    }

    get isProjectSelectedAndRequired(): boolean {
        return this.settings.hasProject && this.project !== undefined
    }

    removeAttachment(attachment: MediaDto) {
        this.attachments = this.attachments.filter((attach) => attach.id !== attachment.id)
    }

    fillForm(language) {
        this.formGroup.get('languageType').setValue(language)

        this._fillContent()

        this.isLoading = false
    }

    onProjectChange(projectId) {
        this.projects$.subscribe((projects: ProjectDto[]) => {
            const project = projects.find((p) => p.id == projectId)

            this.project = project
            this._fillContent()
        })
    }

    private _populateCommand(command: NoteCommand) {
        command.description = this.formGroup.get('description')?.value as string
        command.subject = this.formGroup.get('subject')?.value as string

        command.projectId = this.formGroup.get('projectId')?.value

        command.builderClientId = this.settings.client?.id
        command.brokerId = this.settings.broker?.id

        if (this.attachedDocument) {
            command.attachedDocument = this.attachedDocument
        }

        if (this.attachedImage) {
            command.attachedImage = this.attachedImage
        }
    }

    private _setupformControl() {
        this.formGroup = this.formBuilder.group({
            subject: ['', [Validators.required]],
            description: [''],
            languageType: this.settings.selectedLanguage,
        })

        const observablesToLoad = []

        if (this.displayPackageDropdown) {
            this.formGroup.addControl('projectId', this.formBuilder.control(null))

            const projectId = this.settings.selectedProjectId
            this.formGroup.get('projectId').setValue(this.settings.selectedProjectId)

            if (projectId) {
                observablesToLoad.push(
                    this.projectsService.project(projectId).pipe(
                        tap((project) => {
                            this.project = project
                        })
                    )
                )
            }
        }

        observablesToLoad.push(
            this.accountManageService.accountInfo.pipe(
                tap((userInfo) => {
                    this.userInfo = userInfo
                })
            )
        )

        zip(...observablesToLoad).subscribe(
            () => {
                this._fillContent()
                this.isLoading = false
            },
            (error) => {
                console.log(error)
                this.notifyService.fail('Failed to load')
                this.dialogRef.close()
            }
        )
    }

    private _fillContent() {
        let content: { subject: string; description: string }

        switch (this.settings.noteType) {
            case NoteType.ThankYouForCalling:
            case NoteType.ThankYouForVisiting:
                content = this._getThankYouEmailContent()
                break
            case NoteType.NoResponseEmail:
                content = this._getNoResponseEmailContent()
                break
            case NoteType.AgentRegistered:
                content = this._getAgentRegistered()
        }

        this.formGroup.get('subject').setValue(content.subject)
        this.formGroup.get('description').setValue(content.description)
    }

    private _getAgentRegistered(): { subject: string; description: string } {
        let { subject, description } = EmailNoteContent.generateBrokerRegisteredContent(
            {
                brokerName: this.settings.broker.name,
                projectName: this.project?.name,
                comissionPercent: 2,
            },
            this.language
        )

        return { subject, description }
    }

    private _getThankYouEmailContent(): { subject: string; description: string } {
        let subject = this.language === LanguageType.English ? 'Thank you for ' : 'Merci de votre '
        let description = ''
        let clientName = this.settings.client.name

        if (this.settings.noteType === NoteType.ThankYouForCalling) {
            subject += this.language === LanguageType.English ? 'calling' : 'appel'

            description =
                this.language === LanguageType.English
                    ? `${clientName}, we hope you had a pleasant call with ${this.userInfo.name} who can be reached at ${this.currentUserContact} if you have any further questions.` :
                     `${clientName}, nous espérons que vous avez eu un appel agréable avec ${this.userInfo.name}. Vous pouvez le contacter à ${this.currentUserContact} si vous avez d'autres questions.`
        } else {
            subject += this.language === LanguageType.English ? 'visiting' : 'visite'

            description =
                this.language === LanguageType.English
                    ? `${clientName}, we hope you had a pleasant visit at ${this.projectName} sales office with ${this.userInfo.name} who can be reached at ${this.currentUserContact} if you have any further questions.`
                    : `${clientName}, nous espérons que vous avez eu une agréable visite au bureau des ventes de ${this.projectName} avec ${this.userInfo.name}. Vous pouvez le contacter à ${this.currentUserContact} si vous avez d'autres questions.`
        }

        return { subject, description }
    }

    private _getNoResponseEmailContent(): { subject: string; description: string } {
        const subject =
            this.language === LanguageType.English
                ? `${this.projectName} - We attempted to contact you`
                : `${this.projectName} - Nous avons tenté de vous contacter`

        const description =
            this.language === LanguageType.English
                ? `Good Afternoon ${this.settings.client.name}, thank you for registering online through our ${this.projectName} Project website. We attempted to contact you and left you a message but unfortunately, we were not able to get in touch. Please don't hesitate to contact ${this.userInfo.name} at ${this.currentUserContact} if you have any further questions regarding our project and would like to schedule a visit us. Hope to speak with you soon Have a great day.`
                : `Bonjour ${this.settings.client.name}, merci de vous être inscrit en ligne sur notre site Web ${this.projectName}  Project. Nous avons tenté de vous contacter et vous avons laissé un message mais malheureusement, nous n'avons pas pu vous contacter. N'hésitez pas à contacter ${this.userInfo.name} au ${this.currentUserContact} si vous avez d'autres questions concernant notre projet et souhaitez planifier une visite avec nous. J'espère vous parler bientôt Passez une bonne journée.`

        return { subject, description }
    }
}
