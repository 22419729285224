import { NgModule } from '@angular/core'
import { CustomPropertiesListDialogComponent } from './custom-properties-list-dialog.component'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '../../../material.module'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'

@NgModule({
    declarations: [CustomPropertiesListDialogComponent],
    imports: [CommonModule, MaterialModule, ServerPagingContainerModule],
    entryComponents: [CustomPropertiesListDialogComponent],
})
export class CustomPropertiesListDialogModule {}
