import { BaseTranslatableModel } from './BaseTranslatableModel'
import { TemplateRoomTranslation, TemplateRoomDto } from '@app/services/api.services'

export class TemplateRoomModel extends BaseTranslatableModel<TemplateRoomTranslation> {
    constructor(id: string, internalName: string, imageUrl: string, translations: TemplateRoomTranslation[]) {
        super(translations)
        this.id = id
        this.internalName = internalName
        this.imageUrl = imageUrl
    }

    id?: string | null
    imagePath: string
    imageUrl: string
    internalName: string

    get name(): string {
        return this.selectedTranslation.name
    }

    static createFromDto(templateRoom: TemplateRoomDto): TemplateRoomModel {
        const templareRoomModel = new TemplateRoomModel(
            templateRoom.id,
            templateRoom.internalName,
            templateRoom.templateRoomImage ? templateRoom.templateRoomImage.filePath : '',
            templateRoom.translations
        )
        templareRoomModel.selectedTranslation = templateRoom.selectedTranslation

        if (templateRoom.templateRoomImage) {
            // this._imageId = templateRoom.templateRoomImage.id;
        }

        return templareRoomModel
    }

    createDto(): TemplateRoomDto {
        const templateRoomDto = new TemplateRoomDto()
        templateRoomDto.id = this.id
        templateRoomDto.internalName = this.internalName
        templateRoomDto.translations = this.translations
        return templateRoomDto
    }
}
