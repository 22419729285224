import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { AddressFormModule } from '@app/shared/address-form/address-form.module'
import { TextMaskModule } from 'angular2-text-mask'
import { TableContainerModule } from '@app/shared/table-container/table-container.module'
import { TemplateRoomFormComponent } from './template-room-form.component'

@NgModule({
    imports: [CommonFormsModule, AddressFormModule, TextMaskModule, TableContainerModule],
    declarations: [TemplateRoomFormComponent],
    exports: [TemplateRoomFormComponent],
    entryComponents: [TemplateRoomFormComponent],
})
export class TemplateRoomFormModule {}
