import { Injectable } from '@angular/core'
import { BaseFormDialogService } from '@app/shared/dialog/form-dialog/BaseFormDialogService'
import { MatDialog } from '@angular/material/dialog'
import {
    ProductVariationGroupDialogSettings,
    ProductVariationGroupFormComponent,
} from './product-variation-group-form.component'
import { Observable } from 'rxjs'
import { ProductVariationGroupDto } from '@app/services/api.services'
import { FormDialogSettings } from '@app/models/dialogs/formDialogSettings'

@Injectable({
    providedIn: 'root',
})
export class ProductVariationGroupFormDialogService extends BaseFormDialogService {
    constructor(matDialog: MatDialog) {
        super(matDialog)
    }

    showProductVariationGroupDialog(
        settings: ProductVariationGroupDialogSettings
    ): Observable<ProductVariationGroupDto> {
        const formDialogSettings = new FormDialogSettings(
            ProductVariationGroupDto,
            ProductVariationGroupFormComponent,
            true
        )
        formDialogSettings.injectPropertiesIntoComponent = (component) => {
            component.settings = settings
        }
        return this.showFormDialog(formDialogSettings)
    }
}
