import { Component, OnInit, Input } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { NotifyService } from '@app/services/notify.service'

export class ConfirmDialogResult {
    result: any
    success: boolean

    constructor(result: boolean, success: boolean) {
        this.result = result
        this.success = success
    }
}

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    @Input('title') title: string
    @Input('yesButtonText') yesButtonText: string
    @Input('noButtonText') noButtonText: string
    @Input('message') message: string
    @Input() displayLoadingUntilLoaded: boolean = false
    @Input() observableLoad: Observable<any>

    isLoading = false

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, private notifyService: NotifyService) {}

    ngOnInit() {}

    callLoad() {
        this.isLoading = true
        this.observableLoad.subscribe(
            (result) => {
                this.isLoading = false
                return this.dialogRef.close(new ConfirmDialogResult(result, true))
            },
            (error) => {
                console.log(error)
                this.isLoading = false
                this.notifyService.fail('Operation failed')
            }
        )
    }
}
