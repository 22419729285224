import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CustomPropertyFormDialogComponent } from './custom-property-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class CustomPropertyFormDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog() {
        const dialogRef = this.dialogRef.open(CustomPropertyFormDialogComponent, {
            width: '800px',
        })

        return dialogRef.afterClosed()
    }
}
