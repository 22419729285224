import {
    PropertyValueTranslationDto,
    PropertyValueDto,
    PropertyDefinitionType,
    PropertyAllowedValueDto,
    PropertyDefinitionLookupDto,
    PropertyDefinitionSubType,
    PropertyValueTranslationInput,
    PropertyDefinitionDto,
    ProductPropertyValueInput,
} from 'app/services/api.services'
import { BaseTranslatableModel } from '../template/BaseTranslatableModel'

export class PropertyValueModel extends BaseTranslatableModel<PropertyValueTranslationDto> {
    id?: string | null
    labelIdentifier?: string | null
    value?: string | null
    hasTranslationValues?: boolean
    propertyDefinitionId: string
    type: PropertyDefinitionType
    subType: PropertyDefinitionSubType
    allowedValues: PropertyAllowedValueDto[]

    propertyValueUnitId?: number | undefined

    static subTypesWithUnits = [
        PropertyDefinitionSubType.NumericMeasurementLength,
        PropertyDefinitionSubType.NumericMeasurementCubic,
        PropertyDefinitionSubType.NumericMeasurementArea,
    ]

    static createFromDto(propertyValueDto: PropertyValueDto): PropertyValueModel {
        const propertyValue = new PropertyValueModel(
            propertyValueDto.id,
            propertyValueDto.labelIdentifier,
            propertyValueDto.value,
            propertyValueDto.type,
            propertyValueDto.subType,
            propertyValueDto.hasTranslationValues,
            propertyValueDto.translations
        )

        propertyValue.propertyDefinitionId = propertyValueDto.propertyDefinitionId
        propertyValue.propertyValueUnitId = propertyValueDto.propertyValueUnitId

        if (propertyValueDto.type === PropertyDefinitionType.Categorical && propertyValueDto.allowedValues) {
            propertyValue.allowedValues = propertyValueDto.allowedValues.sort(function (a, b) {
                return ('' + a.selectedTranslation.allowedValue).localeCompare(b.selectedTranslation.allowedValue)
            })
        }

        return propertyValue
    }

    hasSelectableUnits() {
        return PropertyValueModel.subTypesWithUnits.findIndex((t) => t === this.subType) >= 0
    }

    static createFromPropertyDefinitionDto(propertyDefinitionDto: PropertyDefinitionDto): PropertyValueModel {
        const propertyValue = new PropertyValueModel(
            null,
            propertyDefinitionDto.labelIdentifier,
            null,
            propertyDefinitionDto.propertyDefinitionType,
            propertyDefinitionDto.propertyDefinitionSubType,
            true,
            []
        )
        propertyValue.propertyDefinitionId = propertyDefinitionDto.id
        propertyValue.translations = propertyDefinitionDto.translations.map((translation) => {
            return new PropertyValueTranslationDto({
                culture: translation.culture,
                label: translation.label,
                value: null,
            })
        })
        return propertyValue
    }

    static createFromPropertyDefinitionLookupDto(dto: PropertyDefinitionLookupDto): PropertyValueModel {
        const propertyValue = new PropertyValueModel(
            null,
            dto.labelIdentifier,
            null,
            dto.propertyDefinitionType,
            dto.propertyDefinitionSubType,
            false,
            null
        )
        propertyValue.propertyDefinitionId = dto.id
        return propertyValue
    }

    constructor(
        id: string,
        labelIdentifier: string,
        value: string,
        type: PropertyDefinitionType,
        subType: PropertyDefinitionSubType,
        hasTranslationValues: boolean,
        translations: PropertyValueTranslationDto[]
    ) {
        super(translations)
        this.id = id
        this.labelIdentifier = labelIdentifier
        this.value = value
        this.type = type
        this.subType = subType
        this.hasTranslationValues = hasTranslationValues
    }

    get inputLabel(): string {
        let label = this.labelIdentifier
        if (this.type === PropertyDefinitionType.Numeric) {
            // label += ` ${this.units.display}`
        }
        return label
            .toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
    }

    createProductPropertyValueInput(): ProductPropertyValueInput {
        const input = new ProductPropertyValueInput()
        input.propertyDefinitionId = this.propertyDefinitionId
        input.propertyValueUnitId = this.propertyValueUnitId
        if (this.hasTranslationValues) {
            input.translationValues = this.translations.map(
                (t) =>
                    new PropertyValueTranslationInput({
                        value: t.value,
                        culture: t.culture,
                    })
            )
        } else {
            input.value = this.value
        }
        return input
    }

    // createPropertyValueInput(): PropertyValueInput {
    //     const input = new PropertyValueInput()
    //     input.id = this.id
    //     input.propertyDefinitionId = this.propertyDefinitionId
    //     if (this.hasTranslationValues) {
    //         input.translationValues = this.translations.map(
    //             (t) =>
    //                 new PropertyValueTranslationInput({
    //                     value: t.value,
    //                     culture: t.culture,
    //                 })
    //         )
    //     } else {
    //         input.value = this.value
    //     }
    //     return input
    // }
}
