import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TableContainerModule } from '../table-container/table-container.module'
import { RouterModule } from '@angular/router'
import { CostLineListComponent } from './cost-line-list.component'

@NgModule({
    imports: [CommonModule, CommonFormsModule, TableContainerModule, RouterModule],
    declarations: [CostLineListComponent],
    exports: [CostLineListComponent],
})
export class CostLineListModule {}
