import { query } from '@angular/animations'
import { ProductsService, PaginatedResult, GetProductListQuery, ProductLookupDto } from '@app/services/api.services'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'

export class ProductsDataSource extends PagingDataSource<ProductLookupDto, GetProductListQuery> {
    constructor(private service: ProductsService) {
        super()
    }

    getEntitiesObservable(query: GetProductListQuery): Observable<PaginatedResult> {
        return this.service.query(query)
    }
}
