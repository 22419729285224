import { Injectable } from '@angular/core'
import { NotifyService } from '@app/services/notify.service'
import { TimesheetModel } from '@app/models/timesheet/TimesheetModel'
import { UserConfigurationService } from '@app/services/user-configuration.service'
import { TimesheetDto } from '@app/services/api.services'

@Injectable({
    providedIn: 'root',
})
export class TimesheetSaveService {
    constructor(private notifyService: NotifyService, private userConfiguratioService: UserConfigurationService) {}

    validateTimesheet(timesheet: TimesheetModel): boolean {
        const result = timesheet.isTimesheetValid()
        if (!result.isValid) {
            this.notifyService.fail(
                result.invalidDays.join() + ' invalid day' + (result.invalidDays.length > 1 ? 's' : '')
            )
            return false
        }

        return true
    }

    cacheTimesheet(timesheet: TimesheetDto) {
        this.userConfiguratioService.setConfigValue(this.getTimesheetKey(timesheet), timesheet)
    }

    retrieveCachedTimesheet(timesheet: TimesheetDto): TimesheetDto {
        const cachedTimesheet = this.userConfiguratioService.retrieveConfigValue(this.getTimesheetKey(timesheet))
        if (cachedTimesheet) {
            return TimesheetDto.fromJS(cachedTimesheet)
        }
        return null
    }

    getTimesheetKey(timesheet: TimesheetDto) {
        return 'timesheet_' + timesheet.id
    }
}
