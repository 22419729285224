import { Injectable } from '@angular/core'
import { PropertyValueUnitModel } from '@app/models/propertyValue/propertyValueUnitModel'
import { Observable } from 'rxjs'
import { map, share, shareReplay } from 'rxjs/operators'
import {
    PropertyDefinitionSubType,
    PropertyDefinitionType,
    PropertyValuesService,
    PropertyValueUnitLengthDto,
    PropertyValueUnitMassDto,
    PropertyValueUnitVolumeDto,
} from './api.services'

@Injectable({
    providedIn: 'root',
})
export class PropertyValueUnitsService {
    propertyValueMassUnitData: Observable<PropertyValueUnitMassDto[]>
    propertyValueVolumneUnitData: Observable<PropertyValueUnitVolumeDto[]>
    propertyValueLengthUnitData: Observable<PropertyValueUnitLengthDto[]>

    constructor(propertyValuesService: PropertyValuesService) {
        this.propertyValueMassUnitData = propertyValuesService.getPropertyValueMassUnits().pipe(shareReplay(1))
        this.propertyValueVolumneUnitData = propertyValuesService.getPropertyValueVolumeUnits().pipe(shareReplay(1))
        this.propertyValueLengthUnitData = propertyValuesService.getPropertyValueLengthUnits().pipe(shareReplay(1))
    }

    getPropertyValueUnit(id: number, subType: PropertyDefinitionSubType): Observable<PropertyValueUnitModel> {
        return this.getPropertyValueUnits(subType).pipe(
            map((propertyValues) => propertyValues.find((p) => p.id === id))
        )
    }

    getPropertyValueUnits(subType: PropertyDefinitionSubType): Observable<PropertyValueUnitModel[]> {
        switch (subType) {
            case PropertyDefinitionSubType.NumericMeasurementLength:
            case PropertyDefinitionSubType.NumericMeasurementArea:
            case PropertyDefinitionSubType.NumericMeasurementCubic:
                return this._getPropertyValueLengthUnits(subType)
            case PropertyDefinitionSubType.NumericMeasurementMass:
                return this._getPropertyValueMassUnits()
            case PropertyDefinitionSubType.NumericMeasurementVolume:
                return this._getPropertyValueVolumeUnits()
        }
    }

    private _getPropertyValueMassUnits(): Observable<PropertyValueUnitModel[]> {
        return this.propertyValueMassUnitData.pipe(
            map((propertyValueUnits) =>
                propertyValueUnits.map((p) => new PropertyValueUnitModel(p.id, p.name, p.abbreviation))
            )
        )
    }

    private _getPropertyValueVolumeUnits(): Observable<PropertyValueUnitModel[]> {
        return this.propertyValueVolumneUnitData.pipe(
            map((propertyValueUnits) =>
                propertyValueUnits.map((p) => new PropertyValueUnitModel(p.id, p.name, p.abbreviation))
            )
        )
    }

    private _getPropertyValueLengthUnits(type: PropertyDefinitionSubType): Observable<PropertyValueUnitModel[]> {
        return this.propertyValueLengthUnitData.pipe(
            map((propertyValueUnits) =>
                propertyValueUnits.map((propertyValueUnit) => this._convertLength(type, propertyValueUnit))
            )
        )
    }

    private _convertLength(type: PropertyDefinitionSubType, value: PropertyValueUnitLengthDto): PropertyValueUnitModel {
        switch (type) {
            case PropertyDefinitionSubType.NumericMeasurementLength:
                return new PropertyValueUnitModel(value.id, value.name, value.abbreviation)
            case PropertyDefinitionSubType.NumericMeasurementArea:
                return new PropertyValueUnitModel(value.id, value.squareName, value.squareAbbreviation)
            case PropertyDefinitionSubType.NumericMeasurementCubic:
                return new PropertyValueUnitModel(value.id, value.cubicName, value.cubicAbbreviation)
        }
    }
}
