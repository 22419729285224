<div class="row" *ngIf="!isInDialogMode">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-rose card-header-text">
                <div class="card-text">
                    <h4 class="card-title">Brand</h4>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="form"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isInDialogMode">
    <ng-container *ngTemplateOutlet="form"></ng-container>
</ng-container>

<ng-template #form>
    <form [formGroup]="formControl" class="form-horizontal">
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <input type="text" matInput placeholder="Brand Name" formControlName="name" />
                    <mat-error *ngIf="isFieldValid('name')"> Required brand name </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>
