import { EmailNoteFormDialogSettings } from './email-note-form-dialog/emailNoteFormSettings'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { NoteFormDialogComponent } from './note-form-dialog.component'
import { Observable } from 'rxjs'
import { NoteDto } from '@app/services/api.services'
import { DialogResult } from '@app/models/dialogResult'
import { NoteFormDialogSettings } from '@app/models/dialogs/noteFormDialogSettings'
import { EmailNoteFormDialogComponent } from './email-note-form-dialog/email-note-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class NoteFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(setting: NoteFormDialogSettings): Observable<DialogResult<NoteDto>> {
        const dialogRef = this.dialog.open(NoteFormDialogComponent, {
            width: '800px',
            data: setting,
        })

        return dialogRef.afterClosed()
    }

    showEmailDialog(setting: EmailNoteFormDialogSettings): Observable<DialogResult<NoteDto>> {
        const dialogRef = this.dialog.open(EmailNoteFormDialogComponent, {
            width: '800px',
            data: setting,
        })

        return dialogRef.afterClosed()
    }
}
