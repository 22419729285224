<h2 mat-dialog-title>{{ isCreatingNewPrice ? 'Creating New Price' : 'Viewing Supplier Prices' }}</h2>
<mat-dialog-content>
    <ng-container *ngIf="supplierPrices">
        <div class="row">
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>Supplier</mat-label>
                    <mat-select [(ngModel)]="selectedSupplier" [disabled]="isCreatingNewPrice">
                        <mat-option
                            *ngFor="
                                let supplierPrice of supplierPrices
                                    | orderBy: { property: 'supplierName', direction: 1 }
                            "
                            [value]="supplierPrice"
                        >
                            {{ supplierPrice.supplierName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-4" *ngIf="isForProjectOnly">
                <mat-form-field>
                    <mat-label>Project</mat-label>
                    <mat-select [(ngModel)]="selectedProject">
                        <mat-option
                            *ngFor="let supplierProject of selectedSupplier.projectPrices"
                            [value]="supplierProject"
                        >
                            {{ supplierProject.projectName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>Price Version</mat-label>
                    <mat-select [(ngModel)]="selectedPriceVersion" [disabled]="isCreatingNewPrice">
                        <mat-option
                            *ngFor="
                                let supplierPriceVersion of supplierPriceVersions
                                    | orderBy: { property: 'versionNo', direction: 1 }
                            "
                            [value]="supplierPriceVersion"
                        >
                            V{{ supplierPriceVersion.versionNo }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <mat-checkbox [(ngModel)]="isForProjectOnly" *ngIf="selectedPriceHasProjectPrices"
                    >Show for Project Only</mat-checkbox
                >
                <span *ngIf="!selectedPriceHasProjectPrices">This supplier has no project prices yet</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr />
            </div>
        </div>
        <ng-container *ngIf="selectedLoadedPriceVersion">
            <div class="row">
                <div class="col-12">
                    <h4>Selected Price Details</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <mat-form-field>
                        <input
                            type="text"
                            matInput
                            placeholder="Internal Name"
                            [(ngModel)]="selectedLoadedPriceVersion.internalName"
                            [disabled]="!isCreatingNewPrice"
                        />
                    </mat-form-field>
                </div>
            </div>
            <app-service-rate-supplier-unit-price
                [isCreatingNewPrice]="isCreatingNewPrice"
                [price]="selectedLoadedPriceVersion"
            ></app-service-rate-supplier-unit-price>
        </ng-container>
    </ng-container>

    <mat-spinner class="my-5 mx-auto" *ngIf="isLoading"></mat-spinner>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="warn" [mat-dialog-close]>Close</button>

    <div *ngIf="isCreatingNewPrice">
        <button mat-stroked-button class="mr-2" color="warn" [disabled]="isSavingNewlyCreatingPrice" (click)="clear()">
            Cancel
        </button>
        <button mat-stroked-button [loading]="isSavingNewlyCreatingPrice" (click)="create()">Create</button>
    </div>

    <ng-container *ngIf="!isCreatingNewPrice">
        <div>
            <button [disabled]="isCreatingNewPrice" mat-stroked-button [matMenuTriggerFor]="menu">Add...</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="addNewVersion()">
                    <span>Add Version</span>
                </button>
                <button mat-menu-item (click)="addSupplier()">
                    <span>Add Supplier</span>
                </button>
                <button mat-menu-item (click)="addProject()">
                    <span>Add Project</span>
                </button>
            </mat-menu>
        </div>
    </ng-container>
</mat-dialog-actions>
