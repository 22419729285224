import { Injectable } from '@angular/core'
import { EmployeeSettingsService } from './api.services'
import { EmployeeApplicationSettingsModel } from '@app/models/employees/EmployeeApplicationSettingsModel'
import { Observable, ReplaySubject, BehaviorSubject } from 'rxjs'
import { tap, flatMap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class EmployeeSettingsStoreService {
    private _cachedSettingsSubject: BehaviorSubject<EmployeeApplicationSettingsModel>

    constructor(private employeeSettings: EmployeeSettingsService) {}

    getSettings(): Observable<EmployeeApplicationSettingsModel> {
        if (this._cachedSettingsSubject) {
            return this._cachedSettingsSubject
        }

        return this.employeeSettings.employeeSettings().pipe(
            flatMap((settings) => {
                this._cachedSettingsSubject = new BehaviorSubject(EmployeeApplicationSettingsModel.create(settings))
                return this._cachedSettingsSubject
            })
        )
    }

    refreshSettings() {
        this.employeeSettings.employeeSettings().subscribe((settings) => {
            this._cachedSettingsSubject.next(EmployeeApplicationSettingsModel.create(settings))
        })
    }

    saveSettings(settings: EmployeeApplicationSettingsModel): Observable<void> {
        return this.employeeSettings.edit(settings.createDto()).pipe(
            tap(() => {
                this._cachedSettingsSubject.next(settings)
            })
        )
    }
}
