<app-table-container
    #tableContainer
    [searchableColumns]="displayedColumns"
    [isFailed]="isFailed"
    [displayMode]="PagingDisplayModeTypes.Dialog"
    [isLoaded]="isLoaded"
    [entiyName]="entityName"
>
    <table mat-table [dataSource]="tableContainer.dataSource" matSort>
        <ng-container *ngFor="let displayColumn of settings.displayProperties">
            <ng-container
                *ngIf="displayColumn.isInTranslation"
                [matColumnDef]="'selectedTranslation.' + displayColumn.propertyName"
            >
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ displayColumn.displayName }}</th>
                <td mat-cell *matCellDef="let row">
                    {{ row.selectedTranslation[displayColumn.propertyName] }}
                </td>
            </ng-container>

            <ng-container *ngIf="!displayColumn.isInTranslation" [matColumnDef]="displayColumn.propertyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ displayColumn.displayName }}</th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="displayColumn.isCurrency; else notCurrency">
                        {{ row[displayColumn.propertyName] | currency }}
                    </ng-container>
                    <ng-template #notCurrency>
                        {{ row[displayColumn.propertyName] }}
                    </ng-template>
                </td></ng-container
            >
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="action-column" align="center">Action</th>
            <td mat-cell *matCellDef="let row" align="center">
                <button (click)="select(row)" mat-icon-button color="success">
                    <mat-icon>{{ settings.actionColumnButtonIcon }}</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <ng-container
        *ngIf="tableContainer.dataSource && tableContainer.dataSource.data.length == 0 && settings.emptyResultMessage"
    >
        <div class="no-results">
            {{ settings.emptyResultMessage }}
        </div>
    </ng-container>
</app-table-container>
<!-- </mat-dialog-content> -->
<!-- <mat-dialog-actions align="end">
    <div class="d-flex justify-content-between w-100">
        <button mat-button color="warn" [mat-dialog-close]="false">Close</button>
        <div>
            <button class="mr-2" *ngIf="settings.showAddButton" mat-stroked-button (click)="addAndCloseDialog()">
                Add
            </button>
            <button mat-stroked-button cdkFocusInitial (click)="done()">Done</button>
        </div>
    </div>
</mat-dialog-actions> -->
