import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PhoneNumberComponent } from './phone-number/phone-number.component'
import { TextMaskModule } from 'angular2-text-mask'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { CurrencyComponent } from './currency/currency.component'
import { MultiLanguageComponent } from './multi-language/multi-language.component'
import { PercentComponent } from './percent/percent.component'
import { HoursComponent } from './hours/hours.component'
import { QuantityComponent } from './quantity/quantity.component'
import { MaterialModule } from '@app/material.module'
import { CurrencyModelComponent } from './currency-model/currency-model.component'
import { QuantityInputComponent } from './quantity-input/quantity-input.component'
import { CountryCurrencySelectComponent } from './country-currency-select/country-currency-select.component'
import { PercentInputFormControlComponent } from './percent-input-form-control/percent-input-form-control.component'

@NgModule({
    imports: [CommonModule, FormsModule, MaterialModule, TextMaskModule, ReactiveFormsModule],
    declarations: [
        PhoneNumberComponent,
        CurrencyComponent,
        MultiLanguageComponent,
        PercentComponent,
        HoursComponent,
        QuantityComponent,
        CurrencyModelComponent,
        QuantityInputComponent,
        CountryCurrencySelectComponent,
        PercentInputFormControlComponent,
    ],
    exports: [
        PhoneNumberComponent,
        CurrencyComponent,
        MultiLanguageComponent,
        PercentComponent,
        HoursComponent,
        QuantityComponent,
        CurrencyModelComponent,
        QuantityInputComponent,
        CountryCurrencySelectComponent,
        PercentInputFormControlComponent,
    ],
})
export class InputsModule {}
