import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '@app/material.module'
import { LabelGroupDropDownComponent } from './label-group-drop-down.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LabelFormDialogModule } from './label-form-dialog/label-form-dialog.module'

@NgModule({
    imports: [CommonModule, MaterialModule, FormsModule, LabelFormDialogModule],
    declarations: [LabelGroupDropDownComponent],
    exports: [LabelGroupDropDownComponent],
})
export class LabelGroupDropDownModule {}
