import { TaxRateModel } from '@app/models/common/TaxRateModel'
import { BaseCostLineModel } from '@app/models/costLines/BaseCostLineModel'

export abstract class SelectionItemBaseCostLineModel extends BaseCostLineModel {
    loadedTaxRate: TaxRateModel

    loadTaxRateIntoCostLine(assignedTaxRate: TaxRateModel) {
        this.loadedTaxRate = assignedTaxRate
        this._pstTaxAmount = this.totalPrice * (assignedTaxRate.pst / 100)
        this._gstTaxAmount = this.totalPrice * (assignedTaxRate.gst / 100)
    }
}
