import { Component, Input, OnInit } from '@angular/core'
import { ProductCurrencyPriceModel } from '@app/models/Products/ProductVersionPriceModel'

@Component({
    selector: 'app-product-supplier-unit-price',
    templateUrl: './product-supplier-unit-price.component.html',
    styleUrls: ['./product-supplier-unit-price.component.scss'],
})
export class ProductSupplierUnitPriceComponent implements OnInit {
    @Input() isCreatingNewPrice: boolean
    @Input() currencyPrice: ProductCurrencyPriceModel

    constructor() {}

    ngOnInit(): void {}
}
