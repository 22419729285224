import { Component, OnInit, ElementRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { Router, NavigationEnd, RouterOutlet } from '@angular/router'
import { filter } from 'rxjs/operators'
import { AccountService } from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'
import { AccountManageService } from '@app/services/accountManage.service'

@Component({
    selector: 'app-client-portal-layout',
    templateUrl: './client-portal-layout.component.html',
    styleUrls: ['./client-portal-layout.component.scss'],
})
export class ClientPortalLayoutComponent implements OnInit {
    private _toggleButton: any
    private _sidebarVisible: boolean
    private _router: Subscription
    languageToDisplay: string

    constructor(
        private router: Router,
        private element: ElementRef,
        private accountService: AccountService,
        private languageService: LanguageService,
        public accountManageService: AccountManageService
    ) {
        this._sidebarVisible = false
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement

        this._toggleButton = navbar.getElementsByClassName('navbar-toggler')[0]
        this._router = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.sidebarClose()
            })

        const body = document.getElementsByTagName('body')[0]
        body.classList.add('off-canvas-sidebar')

        this.languageToDisplay = this.languageService.getTranslateToggleLanguage()
    }

    switchLanguage() {
        this.languageService.toggleUserLanguage()
        this.languageToDisplay = this.languageService.getTranslateToggleLanguage()
    }

    navigateToSelections() {
        // this.router.navigate(['/portal/selections', property.id]);
        // this.clientPortalSelectionService.getSelectedProperty().subscribe(property => {
        //   this.router.navigate(['portal/selections',property.id])
        // });
    }

    sidebarOpen() {
        const toggleButton = this._toggleButton
        const body = document.getElementsByTagName('body')[0]
        setTimeout(function () {
            toggleButton.classList.add('toggled')
        }, 500)
        body.classList.add('nav-open')

        this._sidebarVisible = true
    }

    sidebarClose() {
        const body = document.getElementsByTagName('body')[0]
        this._toggleButton.classList.remove('toggled')
        this._sidebarVisible = false
        body.classList.remove('nav-open')
    }

    sidebarToggle() {
        if (this._sidebarVisible === false) {
            this.sidebarOpen()
        } else {
            this.sidebarClose()
        }
    }

    logout() {
        this.accountService.logout()
        this.accountManageService.exitClientViewMode()
        this.router.navigate(['/login'])
    }

    getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : ''
    }

    exitClientMode() {
        this.accountManageService.exitClientViewMode()
        this.router.navigate([''])
    }
}
