import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { TemplateService } from '@app/services/api.services'
import { catchError, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class TemplateStoreService {
    template: BehaviorSubject<TemplateModel> = new BehaviorSubject<TemplateModel>(null)

    loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    private _template: TemplateModel
    private _showByCategory = false
    constructor(private templateService: TemplateService) {}

    setCurrentTemplate(template: TemplateModel) {
        this.loading.next(false)
        this.template.next(template)
        this._template = template
    }

    getTemplate(): TemplateModel {
        return this._template
    }

    get showByCategory(): boolean {
        return this._showByCategory
    }

    set showByCategory(value: boolean) {
        this._showByCategory = value
        this.reloadTemplate().subscribe()
    }

    updateTemplateSections(): Observable<TemplateModel> {
        return this.templateService
            .template(this.getTemplate().id, this.getTemplate().templateType, this.showByCategory)
            .pipe(
                map((template) => {
                    const templateModel = TemplateModel.createFromDto(template)
                    this._template.templateSections = templateModel.templateSections
                    return templateModel
                })
            )
    }

    reloadTemplate(): Observable<TemplateModel> {
        this.loading.next(true)
        return this.templateService
            .template(this.getTemplate().id, this.getTemplate().templateType, this.showByCategory)
            .pipe(
                map((template) => {
                    const templateModel = TemplateModel.createFromDto(template)
                    this.setCurrentTemplate(templateModel)
                    return templateModel
                })
            )
    }
}
