<h2 mat-dialog-title>{{ model.isEdit ? 'Edit Cost Line' : 'Add Cost Line' }}</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup" autocomplete="off" *ngIf="formGroup">
        <div class="row">
            <div class="col-12">
                <ng-container *ngIf="model.isLinkedEntity; else descriptionInput">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Description" [value]="model.description" disabled />
                    </mat-form-field>
                </ng-container>

                <ng-template #descriptionInput>
                    <app-multi-language
                        [placeholder]="'Description'"
                        [controlName]="'description'"
                        [useGlobalLanguageSelect]="false"
                        [translations]="formGroup.get('translations')"
                    >
                    </app-multi-language>
                </ng-template>
            </div>
        </div>
    </form>

    <div class="row">
        <div class="col-4">
            <mat-form-field>
                <mat-select placeholder="Unit Type" [(ngModel)]="model.unitType" [disabled]="model.isLinkedEntity">
                    <mat-option *ngFor="let unitType of enumListService.unitType" [value]="unitType.value">
                        {{ unitType.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-4">
            <mat-slide-toggle *ngIf="!model.isLinkedEntity" [(ngModel)]="model.unitPrice.isUnitMarkupPercent"
                >Use Percentage Markup
            </mat-slide-toggle>
        </div>
        <div class="col-4" *ngIf="!model.isLinkedEntity && model.displayRetailPriceControls">
            <mat-slide-toggle [(ngModel)]="model.unitPrice.hasRetailAmount">Use Retail Price</mat-slide-toggle>
        </div>
    </div>

    <div
        class="row"
        *ngIf="!model.isLinkedEntity && model.displayRetailPriceControls && model.unitPrice.hasRetailAmount"
    >
        <div class="col-4">
            <app-currency-model
                [disabled]="model.isLinkedEntity"
                [(inputModel)]="model.unitPrice.retailAmount"
                placeholder="Retail Price"
            >
            </app-currency-model>
        </div>
        <div class="col-4">
            <app-percent
                [disabled]="model.isLinkedEntity"
                [(inputModel)]="model.unitPrice.retailDiscountPercent"
                placeholder="Retail Discount Percent"
            >
            </app-percent>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <app-currency-model
                [(inputModel)]="model.unitPrice.unitCostAmount"
                [disabled]="model.isLinkedEntity || model.unitPrice.hasRetailAmount"
                placeholder="Unit Cost"
            >
            </app-currency-model>
        </div>

        <div class="col-4">
            <app-currency-model
                [(inputModel)]="model.unitPrice.unitMarkupAmount"
                placeholder="Unit Markup"
                [disabled]="model.isLinkedEntity || model.unitPrice.isUnitMarkupPercent"
            >
            </app-currency-model>
        </div>
        <div class="col-4">
            <app-percent
                [(inputModel)]="model.unitPrice.unitMarkupPercent"
                [disabled]="model.isLinkedEntity || !model.unitPrice.isUnitMarkupPercent"
                placeholder="Percentage Markup"
            >
            </app-percent>
        </div>
    </div>

    <div class="row mt-2 mb-2">
        <div class="col-4">
            <app-currency-model
                [(inputModel)]="model.unitPrice.unitAmount"
                placeholder="Unit Amount"
                [disabled]="model.isLinkedEntity || model.unitPrice.isUnitMarkupPercent"
            >
            </app-currency-model>
        </div>

        <div class="col-4">
            <ng-container *ngIf="model.useTakeOff; else quantityInput">
                <mat-form-field>
                    <input
                        type="text"
                        disabled="true"
                        matInput
                        placeholder="Take Off Quantity"
                        [value]="model.takeOff.formattedName"
                    />
                </mat-form-field>
            </ng-container>

            <ng-template #quantityInput>
                <mat-form-field>
                    <input type="number" matInput placeholder="Quantity" [(ngModel)]="model.quantity" />
                </mat-form-field>
            </ng-template>
        </div>

        <div class="col-4">
            <app-currency-model
                [(inputModel)]="model.totalPrice"
                placeholder="Total Price (Unit Amount x Quantity)"
                disabled="true"
            >
            </app-currency-model>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" [mat-dialog-close]>Close</button>

        <div>
            <button
                class="mr-2"
                *ngIf="model.allowUseOfTakeOff && !model.useTakeOff"
                type="button"
                (click)="selectTakeOff()"
                mat-button
            >
                Use Take Off
            </button>
            <button
                class="mr-2"
                *ngIf="model.allowUseOfTakeOff && model.useTakeOff"
                type="button"
                mat-button
                (click)="clearTakeOff()"
            >
                Clear Take Off
            </button>
            <button *ngIf="!model.isEdit" type="button" mat-stroked-button (click)="addCostLine()">Add</button>
            <button *ngIf="model.isEdit" type="button" mat-stroked-button (click)="editCostLine()">Update</button>
            <button
                *ngIf="model.isEdit && showUpdateAllBtns"
                type="button"
                mat-stroked-button
                (click)="editCostLine(true)"
            >
                Update All
            </button>
        </div>
    </div>
</mat-dialog-actions>
