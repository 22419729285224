import { Moment } from 'moment'
import { PropertySelectionDto } from './../../services/api.services'
export class PropertySelectionModel {
    id: string
    isLocked: boolean
    isDetached: boolean
    isDisabled: boolean
    hasSelections: boolean
    lockedByEmployeeName: string
    lockedOnDateTimeUtc: Moment
    propertyName: string
    notAssigned: boolean
    notSelectedAndAssigned: boolean
    versionNo: number

    static createFromDto(propertySelection: PropertySelectionDto): PropertySelectionModel {
        return new PropertySelectionModel(
            propertySelection.id,
            propertySelection.isLocked,
            propertySelection.isDetached,
            propertySelection.isDisabled,
            propertySelection.hasSelections,
            propertySelection.lockedByEmployeeName,
            propertySelection.lockedOnDateTimeUtc,
            propertySelection.propertyName,
            propertySelection.versionNo,
            propertySelection.notAssigned,
            propertySelection.notSelectedAndAssigned
        )
    }

    constructor(
        id: string,
        isLocked: boolean,
        isDetached: boolean,
        isDisabled: boolean,
        hasSelections: boolean,
        lockedByEmployeeName: string,
        lockedOnDateTimeUtc: Moment,
        propertyName: string,
        versionNo: number,
        notAssigned: boolean,
        notSelectedAndAssigned: boolean
    ) {
        this.id = id
        this.isLocked = isLocked
        this.isDetached = isDetached
        this.isDisabled = isDisabled
        this.lockedByEmployeeName = lockedByEmployeeName
        this.lockedOnDateTimeUtc = lockedOnDateTimeUtc
        this.hasSelections = hasSelections
        this.propertyName = propertyName
        this.versionNo = versionNo
        this.notAssigned = notAssigned
        this.notSelectedAndAssigned = notSelectedAndAssigned
    }

    get selectionName(): string {
        return `${this.propertyName} - v${this.versionNo}${this._getStatus()}`
    }

    private _getStatus(): string {
        if (this.isLocked) {
            return ' (Property selection locked)'
        }

        if (this.hasSelections) {
            return ' (Selections made)'
        }

        return ''
    }
}
