<div class="row" *ngIf="!isInDialogMode">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-rose card-header-text">
                <div class="card-text">
                    <h4 class="card-title">Label Group</h4>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="form"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isInDialogMode">
    <ng-container *ngTemplateOutlet="form"></ng-container>
</ng-container>

<ng-template #form>
    <form [formGroup]="formControl" class="form-horizontal">
        <div class="row">
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-label>Internal Name</mat-label>
                    <input type="text" matInput formControlName="name" />
                    <mat-error *ngIf="isFieldValid('name')"> Required internal name </mat-error>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-4">
                <app-multi-language
                    [placeholder]="'Name'"
                    [controlName]="'name'"
                    [translations]="formControl.get('translations')"
                ></app-multi-language>
            </div>
            <div class="col-12 col-md-4">
                <mat-form-field>
                    <mat-select placeholder="Label Group Type" formControlName="labelGroupType">
                        <mat-option
                            *ngFor="let labelGroupType of enumListService.labelGroupTypes"
                            [value]="labelGroupType.value"
                        >
                            {{ labelGroupType.display }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <ng-container *ngIf="formControl" [formGroup]="formControl">
                    <h4>Labels</h4>
                    <table mat-table [dataSource]="dataSource" formArrayName="labels">
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
                                <ng-container *ngIf="row.isEditing; else name">
                                    <app-multi-language
                                        [placeholder]="'Name'"
                                        [controlName]="'name'"
                                        [translations]="labelsFormArray.controls[i].get('translations')"
                                    ></app-multi-language>
                                </ng-container>
                                <ng-template #name>
                                    {{ row.selectedTranslation.name }}
                                </ng-template>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
                            <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                                <button *ngIf="row.isEditing" (click)="row.doneEditing()" class="btn btn-link">
                                    <mat-icon>done</mat-icon>
                                </button>
                                <ng-container *ngIf="!row.isEditing">
                                    <div class="d-flex">
                                        <button (click)="row.edit()" type="button" class="btn btn-link mr-1">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                        <button (click)="removeLabel(i)" class="btn btn-link btn-danger">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="action-column">
                                <button (click)="addLabel()" mat-icon-button color="primary">
                                    <i class="material-icons">add</i>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                    </table>
                </ng-container>
            </div>
        </div>
    </form>
</ng-template>
