import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { EmployeeDto, EmployeesService } from '../api.services'
import { LocalSearchTableContainer } from '@app/shared/search-dialog/models/searchTableContainer'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'

@Injectable({
    providedIn: 'root',
})
export class EmployeePickerService {
    constructor(private employeesService: EmployeesService, private searchDialogService: SearchDialogService) {}

    pickEmployee(): Observable<EmployeeDto> {
        const searchTableContainer = new LocalSearchTableContainer(this.employeesService.employees(), 'Employees', [
            new DisplayColumn('Name', 'name'),
            new DisplayColumn('Employee #', 'employeeNumber'),
        ])

        return this.searchDialogService.showLocalDialog(searchTableContainer)
    }
}
