import { Component, OnInit, Input } from '@angular/core'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'

@Component({
    selector: 'app-client-nested-selection-groups',
    templateUrl: './client-nested-selection-groups.component.html',
    styleUrls: ['./client-nested-selection-groups.component.scss'],
})
export class ClientNestedSelectionGroupsComponent implements OnInit {
    @Input() nestedSelectionGroups: SelectionGroupModel[] = []
    @Input() parentSelectionGroup: SelectionGroupModel

    constructor() {}

    ngOnInit() {}
}
