<h2 mat-dialog-title>{{ 'Upload' }}</h2>
<mat-dialog-content>
    <ng-container *ngIf="selectedFile; else fileSelect">
        <div class="text-align">
            {{ selectedFile.name }}
        </div>
    </ng-container>

    <ng-template #fileSelect>
        <input type="file" [multiple]="false" class="d-none" (change)="onFilesAdded()" #file />
        <div class="center">
            <ngx-file-drop (onFileDrop)="dropped($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <a href="javascript:void(0);" (click)="selectFile()">
                        Drop files here or click here to browse for a file
                    </a>
                </ng-template>
            </ngx-file-drop>
        </div>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-stroked-button [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button class="ml-2" [loading]="isLoading" (click)="onSubmit()">Add</button>
    </div>
</mat-dialog-actions>
