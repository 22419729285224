import { CommonMedia } from '@app/services/api.services'
import { FileSelectSettings } from './fileSelectSettings'

export class ImgCropperDialogSettings {
    width: number
    height: number
    resizeToWdith: number
    isPersistEdit: boolean
    maintainAspectRatio = true
    aspectRatio: number
    imageToCrop: any
    imageFormat: string
}

export class MediaPickerDialogSettings {
    requiredSelections: number | null = null
    media: CommonMedia
    fileSelectSettings: FileSelectSettings
    canUploadMultipleFiles = false

    constructor(pickDocument: boolean, pickImage: boolean) {
        this.fileSelectSettings = new FileSelectSettings()
        this.fileSelectSettings.pickDocument = pickDocument
        this.fileSelectSettings.pickImage = pickImage
    }
}
