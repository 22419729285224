import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Language } from '@app/models/language'
import { PropertyValueModel } from '@app/models/propertyValue/propertyValueModel'
import {
    AssociatedPropertyValueDto,
    CreateOrEditMultipleProductPropertyValuesCommand,
    GetAssociatedPropertyValuesQuery,
    MultipleProductPropertyValueInput,
    ProductNameDto,
    ProductsService,
    PropertyAllowedValueDto,
    PropertyDefinitionDto,
    PropertyDefinitionLookupDto,
    PropertyDefinitionService,
    PropertyDefinitionSubType,
    PropertyDefinitionType,
    PropertyValuesService,
} from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'
import { NotifyService } from '@app/services/notify.service'
import { EMPTY, forkJoin, Observable, of, zip } from 'rxjs'
import { map, tap } from 'rxjs/operators'

export class ProductPropertyValueModel {
    productId: string
    productName: string
    propertyValue: PropertyValueModel

    constructor(productId: string, productName: string, propertyValue: PropertyValueModel) {
        this.productId = productId
        this.productName = productName
        this.propertyValue = propertyValue
    }
}

@Component({
    selector: 'app-multi-product-property-value-selection-dialog',
    templateUrl: './multi-product-property-value-selection-dialog.component.html',
    styleUrls: ['./multi-product-property-value-selection-dialog.component.scss'],
})
export class MultiProductPropertyValueSelectionDialogComponent implements OnInit {
    isLoading = true
    isSaving = false
    allowedValues: PropertyAllowedValueDto[]
    propertyDefinitionDto: PropertyDefinitionDto
    productPropertyValues: ProductPropertyValueModel[] = []
    displayedColumns = ['productName', 'value']
    selectedLanguage: Language

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            productIds: string[]
            propertyDefinition: PropertyDefinitionLookupDto
        },
        private dialogRef: MatDialogRef<MultiProductPropertyValueSelectionDialogComponent>,
        private productsService: ProductsService,
        private propertyDefinitionService: PropertyDefinitionService,
        private languageService: LanguageService,
        private notifyService: NotifyService,
        private propertyValuesService: PropertyValuesService
    ) {}

    ngOnInit(): void {
        this.languageService.languageObservable.subscribe((language) => {
            this.selectedLanguage = language
        })
        this.selectedLanguage = this.languageService.formSelectedLanguage
        forkJoin([this._loadProducts(), this._loadPropertyDefinition(), this._loadExistingPropertyValues()]).subscribe(
            (results) => {
                const products = results[0]
                const existingValues = results[2] as AssociatedPropertyValueDto[]

                this.productPropertyValues = products.map((product) => {
                    let newProductPropertyValue: ProductPropertyValueModel = null

                    const existingValue = existingValues.find((p) => p.productId === product.id)

                    if (
                        this.data.propertyDefinition.propertyDefinitionSubType ===
                        PropertyDefinitionSubType.TextTranslation
                    ) {
                        newProductPropertyValue = new ProductPropertyValueModel(
                            product.id,
                            product.productName,
                            PropertyValueModel.createFromDto(
                                existingValue ? existingValue.propertyValue : this.propertyDefinitionDto
                            )
                        )
                    } else {
                        newProductPropertyValue = new ProductPropertyValueModel(
                            product.id,
                            product.productName,
                            existingValue
                                ? PropertyValueModel.createFromDto(existingValue.propertyValue)
                                : PropertyValueModel.createFromPropertyDefinitionLookupDto(this.data.propertyDefinition)
                        )

                        if (
                            this.data.propertyDefinition.propertyDefinitionType === PropertyDefinitionType.Categorical
                        ) {
                            newProductPropertyValue.propertyValue.allowedValues = this.allowedValues
                        }
                    }

                    return newProductPropertyValue
                })

                this.isLoading = false
            }
        )
    }

    private _loadExistingPropertyValues(): Observable<AssociatedPropertyValueDto[]> {
        const query = new GetAssociatedPropertyValuesQuery()
        query.includeProductIds = this.data.productIds
        query.includePropertyDefinitionIds = [this.data.propertyDefinition.id]
        return this.propertyValuesService.getAssociatedPropertyValues(query)
    }

    private _loadProducts(): Observable<ProductNameDto[]> {
        return this.productsService.getProductNames(this.data.productIds)
    }

    private _loadPropertyDefinition(): Observable<any> {
        if (this.data.propertyDefinition.propertyDefinitionType === PropertyDefinitionType.Categorical) {
            return this.propertyDefinitionService.propertyDefinitionAllowedValues(this.data.propertyDefinition.id).pipe(
                tap((allowedValues) => {
                    this.allowedValues = allowedValues
                })
            )
        } else if (
            this.data.propertyDefinition.propertyDefinitionSubType === PropertyDefinitionSubType.TextTranslation
        ) {
            return this.propertyDefinitionService.propertyDefinition(this.data.propertyDefinition.id).pipe(
                tap((propertyDefinitionDto) => {
                    this.propertyDefinitionDto = propertyDefinitionDto
                })
            )
        } else {
            return of(undefined)
        }
    }

    savePropertyValues() {
        this.isSaving = true
        const command = new CreateOrEditMultipleProductPropertyValuesCommand()

        command.productPropertyValues = this.productPropertyValues.map((productPropertValue) => {
            const input = new MultipleProductPropertyValueInput()

            input.productId = productPropertValue.productId
            input.productPropertyValueInput = productPropertValue.propertyValue.createProductPropertyValueInput()

            return input
        })

        this.productsService.bulkEditProductProperties(command).subscribe(
            (propertyValueOutputs) => {
                this.notifyService.success('Successfully set products property values')
                this.dialogRef.close(this.data.propertyDefinition)
            },
            (error) => {
                this.isSaving = false
            }
        )
    }
}
