import { EventEmitter, Input, Output, OnInit, Directive } from '@angular/core'
import { ImageMediaDto } from '@app/services/api.services'
import { ImageCdnService, ImageRenderSettings } from './image-cdn.service'
import { Observable } from 'rxjs'
import { flatMap } from 'rxjs/operators'

@Directive()
export abstract class BaseImageDisplay {
    @Input() hideCheckBox = true

    @Input() isChecked = false
    @Output() onCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    srcObservable: Observable<string>

    private _src: string
    get src(): string {
        return this._src
    }

    @Input('src')
    set src(value: string) {
        this._src = value
        this.srcObservable = this.getRenderSettings().pipe(
            flatMap((settings) => this.imageCdnService.generateCdnUrl(value, settings))
        )
    }

    constructor(private imageCdnService: ImageCdnService) {}

    abstract getRenderSettings(): Observable<ImageRenderSettings>
}
