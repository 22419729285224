import { Component, OnInit, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSelectionList } from '@angular/material/list'
import { SelectionGroupUsingProductDto } from '@app/services/api.services'

export class SelectionGroupUsingProductOption {
    selected: boolean
    selectionGroup: SelectionGroupUsingProductDto

    constructor(selected: boolean, selectionGroup: SelectionGroupUsingProductDto) {
        this.selected = selected
        this.selectionGroup = selectionGroup
    }
}

@Component({
    selector: 'app-select-selection-groups-to-use-price-dialog',
    templateUrl: './select-selection-groups-to-use-price-dialog.component.html',
    styleUrls: ['./select-selection-groups-to-use-price-dialog.component.scss'],
})
export class SelectSelectionGroupsToUsePriceDialogComponent implements OnInit {
    selectionGroups: SelectionGroupUsingProductOption[] = []

    @ViewChild('selectionList') selectionList: MatSelectionList

    constructor(
        public dialogRef: MatDialogRef<SelectSelectionGroupsToUsePriceDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectionGroups: SelectionGroupUsingProductDto[]
        }
    ) {
        this.selectionGroups = data.selectionGroups.map(
            (selectionGroup) => new SelectionGroupUsingProductOption(false, selectionGroup)
        )
    }

    ngOnInit() {}

    done() {
        this.dialogRef.close(this.selectionList.selectedOptions.selected.map((selectedOption) => selectedOption.value))
    }
}
