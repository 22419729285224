import { SelectionDetailsDto } from './../../services/api.services'
import { Moment } from 'moment'

export class SelectionDetailsModel {
    selectedItemId: string | null
    nameOfUserWhoMadeSelection: string | null
    selectedDateTime: Moment
    depedantSelectedItemId: string
    nestedSelectionDetails: SelectionDetailsModel[] = []
    selectionItemId: string

    static createFromDto(selectionDetailsDto: SelectionDetailsDto): SelectionDetailsModel {
        const selectionDetailsModel = new SelectionDetailsModel(
            selectionDetailsDto.selectedItemId,
            selectionDetailsDto.nameOfUserWhoMadeSelection,
            selectionDetailsDto.selectedDateTime,
            selectionDetailsDto.depedantSelectedItemId,
            selectionDetailsDto.selectionItemId
        )

        if (selectionDetailsDto.nestedSelectionDetails != null) {
            const nestedSelections = selectionDetailsDto.nestedSelectionDetails.filter((s) => !s.isCancelled)
            selectionDetailsModel.nestedSelectionDetails = nestedSelections.map((nestedSelectionDetail) =>
                SelectionDetailsModel.createFromDto(nestedSelectionDetail)
            )
        }

        return selectionDetailsModel
    }

    constructor(
        selectedItemId: string,
        nameOfUserWhoMadeSelection: string,
        selectedDateTime: Moment,
        depedantSelectedItemId: string,
        selectionItemId: string
    ) {
        this.selectedItemId = selectedItemId
        this.nameOfUserWhoMadeSelection = nameOfUserWhoMadeSelection
        this.selectedDateTime = selectedDateTime
        this.depedantSelectedItemId = depedantSelectedItemId
        this.selectionItemId = selectionItemId
    }
}
