<div class="logo">
    <a class="simple-text logo-mini">
        <div class="logo-img">
            <img src="/assets/img/logoSymbol50.png" />
        </div>
    </a>
    <a class="simple-text logo-normal"> Presti </a>
</div>

<div class="sidebar-wrapper">
    <div class="user">
        <div class="photo">
            <img src="../assets/img/faces/generic-avatar.png" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    {{ accountService.userName }}
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let menuItem of dropDownMenuItems" class="nav-item">
                        <a [routerLink]="[menuItem.path]" *ngIf="menuItem.type === 'link'" class="nav-link">
                            <p>{{ menuItem.title }}</p>
                        </a>
                        <a
                            data-toggle="collapse"
                            href="#{{ menuItem.collapse }}"
                            *ngIf="menuItem.type === 'sub'"
                            (click)="updatePS()"
                            class="nav-link"
                        >
                            <i class="material-icons">{{ menuItem.icontype }}</i>
                            <p>
                                {{ menuItem.title }}
                                <b class="caret"></b>
                            </p>
                        </a>

                        <!--Display the submenu items-->
                        <div id="{{ menuItem.collapse }}" class="collapse" *ngIf="menuItem.type === 'sub'">
                            <ul class="nav">
                                <li
                                    routerLinkActive="active"
                                    *ngFor="let childItem of menuItem.children"
                                    class="nav-item"
                                >
                                    <a [routerLink]="getRouterLinkUrl(menuItem.path, childItem.path)" class="nav-link">
                                        <span class="sidebar-mini">{{ childItem.ab }}</span>
                                        <span class="sidebar-normal">{{ childItem.title }}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:0;" (click)="switchLanguage()" class="nav-link">
                            <p>{{ languageToDisplay }}</p>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form">
            <span class="bmd-form-group">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search..." />
                    <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="material-icons">search</i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </span>
        </form>
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="#pablo">
                    <i class="material-icons">dashboard</i>
                    <p>
                        <span class="d-lg-none d-md-block">Stats</span>
                    </p>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a
                    class="nav-link"
                    href="http://example.com"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                        <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Mike John responded to your email</a>
                    <a class="dropdown-item" href="#">You have 5 new tasks</a>
                    <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                    <a class="dropdown-item" href="#">Another Notification</a>
                    <a class="dropdown-item" href="#">Another One</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#pablo">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Account</span>
                    </p>
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav">
        <ng-container *ngFor="let menuItem of menuItems">
            <ng-template
                *ngIf="menuItem.permission && menuItem.permission.length > 0; else noPermission"
                [ngxPermissionsOnly]="[menuItem.permission]"
            >
                <ng-container *ngTemplateOutlet="listItemTemplate; context: { menuItem: menuItem }"></ng-container>
            </ng-template>
            <ng-template #noPermission>
                <ng-container *ngTemplateOutlet="listItemTemplate; context: { menuItem: menuItem }"></ng-container>
            </ng-template>
        </ng-container>
    </ul>
</div>

<ng-template #listItemTemplate let-menuItem="menuItem">
    <li routerLinkActive="active" class="nav-item">
        <!--If is a single link-->
        <a [routerLink]="[menuItem.path]" *ngIf="menuItem.type === 'link'" class="nav-link">
            <i class="material-icons">{{ menuItem.icontype }}</i>
            <p>{{ menuItem.title | translate }}</p>
        </a>
        <!--If it have a submenu-->
        <a
            data-toggle="collapse"
            href="#{{ menuItem.collapse }}"
            *ngIf="menuItem.type === 'sub'"
            (click)="updatePS()"
            class="nav-link"
        >
            <i class="material-icons">{{ menuItem.icontype }}</i>
            <p>
                {{ menuItem.title | translate }}
                <b class="caret"></b>
            </p>
        </a>

        <!--Display the submenu items-->
        <div id="{{ menuItem.collapse }}" class="collapse" *ngIf="menuItem.type === 'sub'">
            <ul class="nav">
                <ng-container *ngFor="let childItem of menuItem.children">
                    <ng-template
                        *ngIf="childItem.permission && childItem.permission.length > 0; else noChildPermission"
                        [ngxPermissionsOnly]="[childItem.permission]"
                    >
                        <ng-container
                            *ngTemplateOutlet="childListItem; context: { menuItem: menuItem, childItem: childItem }"
                        ></ng-container>
                    </ng-template>
                    <ng-template #noChildPermission>
                        <ng-container
                            *ngTemplateOutlet="childListItem; context: { menuItem: menuItem, childItem: childItem }"
                        ></ng-container>
                    </ng-template>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>

<ng-template #childListItem let-menuItem="menuItem" let-childItem="childItem">
    <li routerLinkActive="active" class="nav-item" [routerLinkActiveOptions]="{ exact: true }">
        <a [routerLink]="getRouterLinkUrl(menuItem.path, childItem.path)" class="nav-link">
            <span class="sidebar-mini">{{ childItem.ab }}</span>
            <span class="sidebar-normal">{{ childItem.title }}</span>
        </a>
    </li>
</ng-template>
