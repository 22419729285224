import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { Observable, Subject } from 'rxjs'
import { UpdateTakeOffForSelectionGroupSelectorDialogComponent } from './update-take-off-for-selection-group-selector-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class UpdateTakeOffForSelectionGroupSelectorDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(selectionGroups: SelectionGroupModel[]): Observable<Array<string>> {
        const selectionGroupIds = new Subject<Array<string>>()
        const dialogRef = this.dialog.open(UpdateTakeOffForSelectionGroupSelectorDialogComponent, {
            width: '600px',
            data: {
                selectionGroups,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            selectionGroupIds.next(result)
        })

        return selectionGroupIds
    }
}
