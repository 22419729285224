<mat-form-field *ngIf="task.hasProject || task.hasProperty">
    <mat-select [(ngModel)]="task.task" [compareWith]="compareById">
        <mat-option>None</mat-option>
        <mat-optgroup *ngIf="task.hasProject" label="Project">
            <mat-option
                *ngFor="let task of task.project.projectTasks | orderBy: { property: 'name', direction: 1 }"
                [value]="task"
            >
                {{ task.name }}
            </mat-option>
        </mat-optgroup>

        <mat-optgroup *ngIf="task.hasProperty" label="Property Tasks">
            <ng-container
                *ngFor="let task of task.propertyTasksWithoutSubTasks | orderBy: { property: 'name', direction: 1 }"
            >
                <mat-option [value]="task" *ngIf="isEnabled(task)">
                    {{ task.name }} {{ task.isBillable ? ' $' : '' }}
                </mat-option>
            </ng-container>
        </mat-optgroup>

        <mat-optgroup *ngIf="task.hasPropertySubTasks" label="Property Subtasks">
            <ng-container
                *ngFor="let task of task.propertyTasksWithSubTasks | orderBy: { property: 'name', direction: 1 }"
            >
                <mat-optgroup label="{{ task.name }}">
                    <ng-container *ngFor="let subTask of task.subTasks | orderBy: { property: 'name', direction: 1 }">
                        <mat-option *ngIf="isEnabled(subTask)" [value]="subTask">
                            {{ subTask.name }} {{ subTask.isBillable ? ' $' : '' }}
                        </mat-option>
                    </ng-container>
                </mat-optgroup>
            </ng-container>
        </mat-optgroup>
    </mat-select>
</mat-form-field>
