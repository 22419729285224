import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
    TemplateCategoriesService,
    TemplateRoomsService,
    SelectionGroupsService,
    TemplateService,
    EditTemplateSelectionsCommand,
    PackagesService,
    TemplatePackageLookupDto,
    PropertyTemplateService,
    EnablePackageOnPropertyCommand,
    AssociatePackageCommand,
    TemplateType,
} from '@app/services/api.services'
import { TemplateRoomModel } from '@app/models/template/TemplateRoomModel'
import { TemplateCategoryModel } from '@app/models/template/TemplateCategoryModel'
import { zip, Subject, Observable, of, concat, observable, from } from 'rxjs'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { TemplateSectionModel } from '@app/models/template/TemplateSectionModel'
import { map, flatMap, tap, mergeAll, concatMap } from 'rxjs/operators'
import { TemplateStoreService } from '@app/admin-pages/templates/template-store.service'
import { PackageModel } from '@app/models/packages/packageModel'

@Component({
    selector: 'app-package-association-confirmation-dialog',
    templateUrl: './package-association-confirmation-dialog.component.html',
    styleUrls: ['./package-association-confirmation-dialog.component.scss'],
})
export class PackageAssociationConfirmationDialogComponent implements OnInit {
    isLoaded = false
    templateRooms: TemplateRoomModel[]
    templateCategories: TemplateCategoryModel[]
    package: PackageModel
    selectionGroupsToAssociate: SelectionGroupModel[] = []

    areAllRoomsCategoriesSet = true
    title = 'Confirm Package Association'
    desc =
        'The following package selection groups are missing from this template. Select a template room and a template category for each one to finish package association.'
    confirmation = 'Are you sure you would like to associate this package to this template?'

    get template(): TemplateModel {
        return this.templateStoreService.getTemplate()
    }

    constructor(
        @Optional() public dialogRef: MatDialogRef<PackageAssociationConfirmationDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: { packageId: string; associatingPackage: boolean },
        templateCategoriesService: TemplateCategoriesService,
        private templateRoomService: TemplateRoomsService,
        selectionGroupsService: SelectionGroupsService,
        private templateService: TemplateService,
        private packagesService: PackagesService,
        private propertyTemplateService: PropertyTemplateService,
        private templateStoreService: TemplateStoreService
    ) {
        const categoriesSub = templateCategoriesService.templateCategories()
        const roomsSub = templateRoomService.templateRooms()
        const loadingSelectionGroupsSubject = new Subject<any>()

        if (!data.associatingPackage) {
            this.title = 'Add Selection Groups From Package'
            this.desc =
                'The following package selection groups are missing from this template. Select a template room and a template category for each one to add them to this template.'
            this.confirmation = 'All selection groups from this package are already associated to this template.'
        }

        this.packagesService.package(data.packageId).subscribe((packageDto) => {
            this.package = PackageModel.createFromDto(packageDto)
            const selectionGroupIds = this._getMissingTemplateSelectionGroupIds(
                this.templateStoreService.getTemplate(),
                this.package
            )

            if (selectionGroupIds.length === 0) {
                loadingSelectionGroupsSubject.next()
                return
            }

            selectionGroupIds.forEach((selectionGroupId) => {
                selectionGroupsService.selectionGroup(selectionGroupId).subscribe((selectionGroup) => {
                    this.selectionGroupsToAssociate.push(SelectionGroupModel.createFromDto(selectionGroup))
                    // checking if all selectionGroups have been loaded
                    if (this.selectionGroupsToAssociate.length === selectionGroupIds.length) {
                        loadingSelectionGroupsSubject.next()
                    }
                })
            })
        })

        zip(categoriesSub, roomsSub, loadingSelectionGroupsSubject).subscribe((results) => {
            this.templateCategories = results[0].map((category) => TemplateCategoryModel.createFromDto(category))
            this.templateRooms = results[1].map((room) => TemplateRoomModel.createFromDto(room))
            this.isLoaded = true
        })
    }

    ngOnInit() {}

    confirmPackageApply() {
        const TemplatePackageLookupDto = this.package.createTemplatePackageLookupDto(this.template.templateType)

        if (this.selectionGroupsToAssociate.length === 0) {
            this._savePackageAssociation(TemplatePackageLookupDto).subscribe((_) => {
                this.dialogRef.close(this.package.createTemplatePackageLookupDto(this.template.templateType))
            })
            return
        }

        for (const sg of this.selectionGroupsToAssociate) {
            if (!sg.templateRoom || !sg.templateCategory) {
                this.areAllRoomsCategoriesSet = false
                return
            }
        }

        this.areAllRoomsCategoriesSet = true

        zip(...this._addMissingTemplateSections())
            .pipe(
                tap((_) => {
                    for (let selectionGroup of this.selectionGroupsToAssociate) {
                        this.template.addToTemplateSection(selectionGroup)
                    }
                })
            )
            .pipe(
                flatMap(() => this.templateService.edit(this._generateEditTemplateSelectionCommand())),
                flatMap(() => this._savePackageAssociation(TemplatePackageLookupDto)),
                flatMap(() => this.templateStoreService.updateTemplateSections())
            )
            .subscribe(
                (_) => {
                    this.dialogRef.close(TemplatePackageLookupDto)
                },
                (error) => console.log(error)
            )
    }

    private _savePackageAssociation(templatePackage: TemplatePackageLookupDto) {
        if (this.template.templateType === TemplateType.Property) {
            return this._handlePropertyTemplatePackageAdd(templatePackage)
        } else {
            return this._handleTemplateAssociationPackageAdd(templatePackage)
        }
    }

    private _handlePropertyTemplatePackageAdd(
        templatePackage: TemplatePackageLookupDto
    ): Observable<TemplatePackageLookupDto> {
        const command = new EnablePackageOnPropertyCommand()
        command.packageId = templatePackage.id
        command.propertyTemplateId = this.template.id
        return this.propertyTemplateService.enablePackage(command).pipe(
            map((result) => {
                const existingTemplatePackage = this.template.associatedPackages.find(
                    (t) => t.id === templatePackage.id
                )
                existingTemplatePackage.isAvailable = true
                return templatePackage
            })
        )
    }

    private _handleTemplateAssociationPackageAdd(
        templatePackage: TemplatePackageLookupDto
    ): Observable<TemplatePackageLookupDto> {
        const command = new AssociatePackageCommand()
        command.packageId = templatePackage.id
        command.templateId = this.template.id
        return this.templateService.associatePackage(command).pipe(
            map((result) => {
                this.template.addAssociatedPackage(templatePackage)
                return templatePackage
            })
        )
    }

    private _getMissingTemplateSelectionGroupIds(template: TemplateModel, packageModel: PackageModel): string[] {
        const selectionGroups = template.getSelectionGroups()
        const packageSgs = packageModel.getAllPackageSelectionGroups()
        return packageSgs
            .filter((packageSg) => selectionGroups.findIndex((sg) => sg.id === packageSg.id) === -1)
            .map((sg) => sg.id)
    }

    private _addMissingTemplateSections() {
        const newTemplateRoomIds = [...new Set(this.selectionGroupsToAssociate.map((item) => item.templateRoom.id))]
        const observables = newTemplateRoomIds.map((templateRoomId) => {
            return this._createTemplateSection(templateRoomId)
        })
        return observables
    }

    private _generateEditTemplateSelectionCommand(): EditTemplateSelectionsCommand {
        const command = new EditTemplateSelectionsCommand()
        command.template = this.templateStoreService.getTemplate().createDto()
        return command
    }

    private _createTemplateSection(templateRoomId: string): Observable<TemplateSectionModel> {
        const templateRoomObservable = this.templateRoomService.templateRoom(templateRoomId)

        return templateRoomObservable.pipe(
            map((templateRoom) => {
                const templateSection = new TemplateSectionModel(templateRoom, null)
                templateSection.isAdded = true
                this.template.templateSections.push(templateSection)
                return templateSection
            })
        )
    }
}
