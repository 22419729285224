import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FixedFormControlsModule } from '@app/shared/fixed-form-controls/fixed-form-controls.module'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MaterialModule } from '@app/material.module'
import { TextMaskModule } from 'angular2-text-mask'
import { InputsModule } from '@app/shared/inputs/inputs.module'
import { NgxPermissionsModule } from 'ngx-permissions'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        FixedFormControlsModule,
        TextMaskModule,
        InputsModule,
        NgxPermissionsModule,
        TranslateModule,
    ],
    declarations: [],
})
export class CommonFormsModule {}
