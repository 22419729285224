import { SelectionItemModel } from '../../../models/template/SelectionGroupModel'
import { Component, OnInit, Input } from '@angular/core'
import { SelectionsDisplayType } from '@app/services/api.services'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'

@Component({
    selector: 'app-client-selections',
    templateUrl: './client-selections.component.html',
    styleUrls: ['./client-selections.component.scss'],
})
export class ClientSelectionsComponent implements OnInit {
    @Input() selections: SelectionGroupModel
    @Input() parentSelectionGroup: SelectionGroupModel = null

    selectionsDisplayType = SelectionsDisplayType

    constructor() {}

    ngOnInit() {
        this.selections.groupSelectionItemsByLabel()
    }
}
