import { NgModule } from '@angular/core'
import { CommonFormsModule } from '../../../../common-forms/common-forms.module'
import { CustomPropertyAllowedValueFormDialogComponent } from './custom-property-allowed-value-form-dialog.component'

@NgModule({
    declarations: [CustomPropertyAllowedValueFormDialogComponent],
    imports: [CommonFormsModule],
    entryComponents: [CustomPropertyAllowedValueFormDialogComponent],
})
export class CustomPropertyAllowedValueFormDialogModule {}
