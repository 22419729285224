import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { BrandFormComponent } from './brand-form.component'
import { AddressFormModule } from '@app/shared/address-form/address-form.module'
import { TextMaskModule } from 'angular2-text-mask'

@NgModule({
    imports: [CommonFormsModule, AddressFormModule, TextMaskModule],
    declarations: [BrandFormComponent],
    exports: [BrandFormComponent],
    entryComponents: [BrandFormComponent],
})
export class BrandFormModule {}
