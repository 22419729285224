<form [formGroup]="formControl">
    <div class="row">
        <div class="col-12" [ngClass]="getColumnClass()">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" />
            </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="getColumnClass()">
            <mat-form-field>
                <mat-label>Unit Type</mat-label>
                <mat-select formControlName="unitType">
                    <mat-option *ngFor="let unitType of enumListService.unitType" [value]="unitType.value">
                        {{ unitType.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="getColumnClass()">
            <ng-container *ngIf="model.isDynamic; else noneDynamic">
                <app-quantity
                    [placeholder]="'Value For Calculation'"
                    [group]="formControl"
                    controlName="valueForDynamicCalculation"
                    [allowDecimal]="false"
                ></app-quantity>
            </ng-container>
            <ng-template #noneDynamic>
                <app-quantity
                    [placeholder]="model.isLocalTakeOff ? 'Default Quantity' : 'Inherited Quantity'"
                    [group]="formControl"
                    controlName="defaultQuantity"
                    [allowDecimal]="false"
                ></app-quantity>
            </ng-template>
        </div>

        <div *ngIf="model.isInherited" class="col-12" [ngClass]="getColumnClass()">
            <app-quantity
                placeholder="Overridden Quantity"
                [group]="formControl"
                controlName="quantity"
                [allowDecimal]="false"
            ></app-quantity>
        </div>
    </div>

    <div class="row" *ngIf="model.isInherited">
        <div class="col-12 mb-4">
            <div class="pull-right">
                <mat-slide-toggle formControlName="isEnabled">Override takeoff quantity</mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-4">
            <mat-checkbox formControlName="isDynamic">Is Dynamic Take Off</mat-checkbox>
        </div>
        <div class="col-12 col-md-4" *ngIf="model.isDynamic">
            <mat-form-field>
                <mat-label>Arithmetic Operator</mat-label>
                <mat-select formControlName="calculationOperator">
                    <mat-option *ngFor="let operator of enumListService.calculationOperators" [value]="operator.value">
                        {{ operator.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="col-12 col-md-4 d-flex justify-content-between align-items-center"
            *ngIf="model.isDynamic && model.takeOffUsedForCalculation"
        >
            {{ model.takeOffUsedForCalculation.formattedName }}
            <button mat-icon-button (click)="editTakeOffForDynamicTakeOff()" [disabled]="model.isInherited">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
    </div>
    <div class="row" *ngIf="model.displayDynamicCalculation">
        <div class="col-12">
            {{ model.dynamicCalculationBreakdown }}
        </div>
    </div>
</form>
