import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CommonComponent } from '@app/models/formComponent'

@Component({
    selector: 'app-currency-model',
    templateUrl: './currency-model.component.html',
    styleUrls: ['./currency-model.component.scss'],
})
export class CurrencyModelComponent extends CommonComponent implements OnInit {
    numberMask = createNumberMask({
        prefix: '$ ',
        allowDecimal: true,
        decimalLimit: 2, // TO DO: Review edit: changed from 6 to 2
    })

    @Input() disabled = false
    @Input() placeholder: string

    @Input() inputModel: any
    @Output() inputModelChange = new EventEmitter<any>()

    constructor() {
        super()
    }

    ngOnInit() {}

    stripAndEmitChange(value) {
        this.inputModelChange.emit(this.stripCurrencyString(value))
    }
}
