import { NgModule } from '@angular/core'
import { TextMaskModule } from 'angular2-text-mask'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TemplateCategoryFormComponent } from './template-category-form.component'

@NgModule({
    imports: [TextMaskModule, CommonFormsModule],
    declarations: [TemplateCategoryFormComponent],
    exports: [TemplateCategoryFormComponent],
    entryComponents: [TemplateCategoryFormComponent],
})
export class TemplateCategoryFormModule {}
