<div class="d-flex justify-content-between">
    <h2 mat-dialog-title>{{ settings.isEdit ? 'Edit' : 'Add' }} {{ dialogTitle }}</h2>
    <ng-container *ngIf="settings.useLanguage">
        <ng-container *ngFor="let language of languageService.supportedLanguages">
            <button
                *ngIf="languageService.formSelectedLanguage !== language"
                class="btn btn-sm btn-rose btn-just-icon btn-round d-block btn-lang"
                (click)="languageService.changeLanguage(language)"
            >
                {{ language.abvreiation }}
            </button>
        </ng-container>
    </ng-container>
</div>

<mat-dialog-content>
    <template #formContainer></template>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button color="primary" [loading]="isLoading" (click)="onSubmitClick()">
            {{ settings.isEdit ? 'Save' : 'Add' }}
        </button>
    </div>
</mat-dialog-actions>
