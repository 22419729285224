import {
    CostLineTranslation,
    LinkedServiceDto,
    SelectionItemServiceLinkCostLineDto,
    ServiceDto,
    ServiceLookupDto,
    ServiceSupplierPriceVersionDto,
} from '@app/services/api.services'
import { SelectionItemBaseCostLineModel } from './SelectionItemBaseCostLineModel'

export class SelectionItemServiceCostLineModel extends SelectionItemBaseCostLineModel {
    isUnitTypeEditable(): boolean {
        return false
    }
    isUnitCostEditable(): boolean {
        return false
    }
    isUnitMarkupEditable(): boolean {
        return false
    }
    isDescriptionEditable(): boolean {
        return false
    }
    isTakeOffsSupported(): boolean {
        return true
    }
    isLinkedCostLine(): boolean {
        return true
    }
    getName(): string {
        return this.selectedTranslation ? this.selectedTranslation.description : ''
    }

    linkedService: LinkedServiceDto

    createDto(): SelectionItemServiceLinkCostLineDto {
        const costLine = new SelectionItemServiceLinkCostLineDto()

        costLine.linkedService = this.linkedService
        costLine.quantity = this.quantity
        costLine.useTakeOff = this.useTakeOff

        if (this.useTakeOff) {
            costLine.takeOff = this.takeOff.createDto()
        }

        return costLine
    }

    static createFromSupplierPrice(service: ServiceDto, price: ServiceSupplierPriceVersionDto) {
        const serviceCostLine = new SelectionItemServiceCostLineModel(
            null,
            1,
            price.serviceUnit,
            price.unitCostAmount,
            price.unitMarkupAmount,
            0,
            false,
            false,
            service.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: t.name,
                        culture: t.culture,
                    })
            )
        )

        const linkedService = new LinkedServiceDto()
        linkedService.serviceRateId = service.id
        linkedService.serviceRateName = service.internalName
        linkedService.supplierPriceVersionId = price.id

        serviceCostLine.linkedService = linkedService
        serviceCostLine.selectedTranslation = serviceCostLine.translations.find(
            (t) => t.culture === service.selectedTranslation.culture
        )

        return serviceCostLine
    }

    static createFromDto(costLine: SelectionItemServiceLinkCostLineDto): SelectionItemServiceCostLineModel {
        const serviceCostLine = new SelectionItemServiceCostLineModel(
            costLine.id,
            costLine.quantity,
            costLine.unitType,
            costLine.unitCostAmount,
            costLine.unitMarkupAmount,
            null,
            false,
            false,
            costLine.linkedService.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: t.name,
                        culture: t.culture,
                    })
            )
        )

        serviceCostLine.linkedService = costLine.linkedService

        serviceCostLine.selectedTranslation = serviceCostLine.translations.find(
            (t) => t.culture === costLine.linkedService.selectedTranslation.culture
        )

        return serviceCostLine
    }
}
