import { ImageMediaDto, MediaDto } from '@app/services/api.services'
import { AppSettings } from '@app/app.settings'

export class ImageModel {
    static placeholderImage: ImageModel = new ImageModel(null, AppSettings.IMAGE_PLACEHOLDER_URL, 300, 300)

    id: string
    filePath: string
    fileName: string
    height: number
    width: number
    isAdded: boolean
    isDeleted: boolean

    static createFromDto(imageMediaDto: ImageMediaDto): ImageModel {
        const imageModel = new ImageModel(
            imageMediaDto.id,
            imageMediaDto.filePath,
            imageMediaDto.height,
            imageMediaDto.width
        )
        return imageModel
    }

    static createFromMediaDto(media: MediaDto): ImageModel {
        const image = new ImageModel(media.id, media.filePath, media.height, media.width)
        image.fileName = media.uploadedFileName
        return image
    }

    constructor(id: string, filePath: string, height: number, width: number) {
        this.id = id
        this.filePath = filePath
        this.height = height
        this.width = width
    }

    createDto(): ImageMediaDto {
        // don't send placeholder to the server
        if (this === ImageModel.placeholderImage) {
            return undefined
        }
        const imageMedia = new ImageMediaDto()
        imageMedia.id = this.id
        imageMedia.filePath = this.filePath
        imageMedia.height = this.height
        imageMedia.width = this.width
        return imageMedia
    }
}
