import { Type } from '@angular/core'
import { DialogCapableFormComponentId } from '../formComponent'

export class FormDialogSettings<EntityType, ComponentType> {
    isEdit = true

    constructor(
        public entityType: Type<EntityType>,
        public componentType: Type<ComponentType>,
        public useLanguage: boolean = false,
        public width: string = '800px',
        public modelToEdit: EntityType = null,
        public injectPropertiesIntoComponent: (component: ComponentType) => void = null
    ) {}
}
