<ng-template #customTemplateTpl class="mb-4"> </ng-template>

<app-server-paging-container
    [customInputs]="customTemplateTpl"
    #tableContainer
    (addItem)="create()"
    [displayMode]="displayMode"
    persistedPagingKey="productList"
    [isLoaded]="isLoaded"
    [entityName]="'productList'"
    (reload)="filterChanges()"
    entityDisplayName="Products"
    [customTitle]="isSelectionMode ? 'Add Item' : ''"
>
    <table mat-table [dataSource]="tableContainer.dataSource" matSort (matSortChange)="filterChanges()">
        <ng-container matColumnDef="thumbnail">
            <th class="wx-75" mat-header-cell *matHeaderCellDef mat-sort-header>Thumbnail</th>
            <td class="td-notch-display" mat-cell *matCellDef="let row">
                <app-image-thumbnail *ngIf="row.thumbnailImageUrl" [src]="row.thumbnailImageUrl"></app-image-thumbnail>
            </td>
        </ng-container>

        <ng-container matColumnDef="modelNumber">
            <th class="wx-100" mat-header-cell *matHeaderCellDef mat-sort-header>Model #</th>
            <td mat-cell *matCellDef="let row">{{ row.modelNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="productAvailabilityType">
            <th class="wx-100" mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let row">{{ getProductAvailabilityDisplay(row.productAvailabilityType) }}</td>
        </ng-container>

        <ng-container matColumnDef="brand">
            <th class="wx-100" mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
            <td mat-cell *matCellDef="let row">{{ row.brand }}</td>
        </ng-container>

        <ng-container matColumnDef="productName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{ row.productName }}</td>
        </ng-container>

        <ng-container matColumnDef="numberOfActiveSupplierPrices">
            <th mat-header-cell *matHeaderCellDef mat-sort-header># of Supplier Prices</th>
            <td mat-cell *matCellDef="let row">{{ row.numberOfActiveSupplierPrices }}</td>
        </ng-container>

        <ng-container matColumnDef="numberOfActiveSupplierProjectPrices">
            <th mat-header-cell *matHeaderCellDef mat-sort-header># of Project Prices</th>
            <td mat-cell *matCellDef="let row">{{ row.numberOfActiveSupplierProjectPrices }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th class="mx-150 action-column" mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
            <td mat-cell *matCellDef="let row">
                <div class="d-flex justify-content-center">
                    <ng-container *ngIf="isSelectionMode; else editButtons">
                        <button (click)="select(row)" mat-icon-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </ng-container>

                    <ng-template #editButtons>
                        <button (click)="edit(row.id)" mat-icon-button color="success">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="duplicateProduct(row.id)" mat-icon-button matTooltip="Duplicate">
                            <mat-icon>file_copy</mat-icon>
                        </button>
                        <button
                            (click)="deactivateProduct(row.id)"
                            mat-icon-button
                            color="warn"
                            matTooltip="Deactivate"
                        >
                            <mat-icon>block</mat-icon>
                        </button>
                    </ng-template>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</app-server-paging-container>
