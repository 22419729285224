import { Component, Input, OnInit } from '@angular/core'
import { Language } from '@app/models/language'
import { PropertyValueModel } from '@app/models/propertyValue/propertyValueModel'
import {
    PropertyDefinitionService,
    PropertyDefinitionSubType,
    PropertyDefinitionType,
} from '@app/services/api.services'
import { CustomPropertyAllowedValueFormDialogService } from '@app/shared/dialog/custom-property-allowed-value-form-dialog/custom-property-allowed-value-form-dialog.service'
import { EMPTY, of, zip } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { PropertyValueUnitsService } from '@app/services/property-value-units.service'
import { PropertyValueUnitModel } from '@app/models/propertyValue/propertyValueUnitModel'

@Component({
    selector: 'app-custom-property-input',
    templateUrl: './custom-property-input.component.html',
    styleUrls: ['./custom-property-input.component.scss'],
})
export class CustomPropertyInputComponent implements OnInit {
    @Input() propertyValue: PropertyValueModel
    @Input() selectedLanguage: Language
    @Input() propertyValueUnits: PropertyValueUnitModel[] = []

    isLoading = false

    get PropertyDefinitionType() {
        return PropertyDefinitionType
    }

    get PropertyDefinitionSubType() {
        return PropertyDefinitionSubType
    }

    constructor(
        private customPropertyAllowedValueFormDialogService: CustomPropertyAllowedValueFormDialogService,
        private propertyDefinitionService: PropertyDefinitionService,
        private propertyValueUnitsService: PropertyValueUnitsService
    ) {}

    ngOnInit(): void {
        if (this.propertyValue.hasSelectableUnits()) {
            this.propertyValueUnitsService.getPropertyValueUnits(this.propertyValue.subType).subscribe((units) => {
                this.propertyValueUnits = units
            })
        }
    }

    onCategoricalPropertySelectionChange(propertyValue: PropertyValueModel) {
        if (propertyValue.value === 'addNew') {
            this._addNewAllowedValue(propertyValue)
        }
    }

    private _addNewAllowedValue(propertyValue: PropertyValueModel) {
        this.customPropertyAllowedValueFormDialogService
            .showDialog(propertyValue.propertyDefinitionId)
            .pipe(
                mergeMap((result) => {
                    if (result) {
                        this.isLoading = true
                        return zip(
                            of(result),
                            this.propertyDefinitionService.propertyDefinitionAllowedValues(
                                propertyValue.propertyDefinitionId
                            )
                        ).pipe(map(([allowedValueId, allowedValues]) => ({ allowedValueId, allowedValues })))
                    }
                    return EMPTY
                })
            )
            .subscribe(
                (results) => {
                    if (results) {
                        propertyValue.allowedValues = results.allowedValues
                        propertyValue.value = propertyValue.allowedValues.find(
                            (v) => v.id === results.allowedValueId
                        ).valueIdentifier
                        this.isLoading = false
                    }
                },
                (error) => {
                    console.log(error)
                }
            )
    }
}
