import { Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { NgZone, ViewChild, OnInit, Directive } from '@angular/core'
import { TableContainerComponent } from '../shared/table-container/table-container.component'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { MatTab } from '@angular/material/tabs'
import { NotifyService } from '../services/notify.service'
import {
    FixedFormControlsService,
    ControlMode,
    FixedButton,
} from '../shared/fixed-form-controls/fixed-form-controls.service'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { flatMap } from 'rxjs/operators'

@Directive()
export abstract class SimpleListComponent<T, Model> {
    models: Array<Model>
    isLoaded = false
    isFailed = false
    dataSource: MatTableDataSource<Model>
    retryReloadSub: Subscription

    @ViewChild(MatSort) sort: MatSort
    @ViewChild('tableContainer') tableContainer: TableContainerComponent<Model>

    constructor(public entityName: string, public notifyService: NotifyService) {}

    abstract loadEntities(): Observable<Array<Model>>

    add(entity: Model) {
        let data
        if (this.tableContainer) {
            const data = this.tableContainer.dataSource.data
            data.push(entity)
            this.tableContainer.dataSource.data = data
        } else {
            const data = this.dataSource.data
            data.push(entity)
            this.dataSource.data = data
        }
    }

    load() {
        this.isLoaded = false
        this.isFailed = false
        this.loadEntities().subscribe(
            (models: Model[]) => {
                this.models = models
                if (this.tableContainer) {
                    this.tableContainer.dataSource = new MatTableDataSource(models)
                    this.tableContainer.dataSource.sort = this.sort
                } else {
                    this.dataSource = new MatTableDataSource(models)
                }
                this.isLoaded = true
                this.isFailed = false
            },
            (result) => {
                this.isLoaded = true
                this.isFailed = true
                this.notifyService.fail(`Failed to load ${this.tableContainer.entityDisplayName}, server error`)

                if (!this.retryReloadSub) {
                    this.retryReloadSub = this.tableContainer.retryReload.subscribe(() => {
                        this.load()
                    })
                }
            }
        )
    }
}

@Directive()
export abstract class ListComponent<T, Model> extends SimpleListComponent<T, Model> implements OnInit {
    fixedFormSub: Subscription
    fixedAddButton: FixedButton
    customRouteParams: (id) => any[]
    ngZone: NgZone

    constructor(
        public router: Router,
        entityName: string,
        notifyService: NotifyService,
        public confirmDialogService: ConfirmDialogService,
        public fixedFormControlsService: FixedFormControlsService
    ) {
        super(entityName, notifyService)
        this.fixedAddButton = this.fixedFormControlsService.setupSingleAddButton()
        this.fixedFormSub = this.fixedAddButton.buttonClickedObservable.subscribe(() => this.create())
    }

    abstract deleteEntity(id: T): Observable<void>

    ngOnInit() {}

    create() {
        this.router.navigate([this.entityName + '/create'])
    }

    edit(id: T) {
        this.router
            .navigate(this.customRouteParams ? this.customRouteParams(id) : [this.entityName + '/edit/', id])
            .catch(() => {
                this.notifyService.fail('Failed to load entity')
                this.isLoaded = true
            })
        setTimeout(() => {
            this.isLoaded = false
        })
    }

    delete(id: T) {
        const ref = this
        this.confirmDialogService
            .showDialog('Are you sure you want to delete this?', 'Confirm Delete')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        return this.deleteEntity(id)
                    }
                })
            )
            .subscribe(
                () => {
                    this.load()
                },
                (error) => {
                    this.notifyService.fail('Failed to delete')
                }
            )
    }
}
