import { Component, OnInit, Inject, Input } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SelectionItemModel, SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { Router, ActivatedRoute } from '@angular/router'
import { BaseSelectionComponent } from '@app/shared/common-template-components/base-selection.component'
import { NotifyService } from '@app/services/notify.service'
import { ClientPortalSelectionService } from '@app/services/client-portal.service'
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9'
import { MediaType, SelectionGroupsService } from '@app/services/api.services'
import { ImageModel } from '@app/models/common/ImageModel'
import { DocumentModel } from '@app/models/common/DocumentModel'

@Component({
    selector: 'app-selection-item-details-dialog',
    templateUrl: './selection-item-details-dialog.component.html',
    styleUrls: ['./selection-item-details-dialog.component.scss'],
})
export class SelectionItemDetailsDialogComponent extends BaseSelectionComponent implements OnInit {
    productOptionCostLineDisplayedColumns = ['index', 'name', 'description']
    optionCostLineDisplayColumns = ['index', 'name']
    selectionItem: SelectionItemModel
    selectionGroup: SelectionGroupModel
    selectedRowIndex: number
    galleryOptions: NgxGalleryOptions[]
    galleryImages: NgxGalleryImage[]

    hasSingleProductCostLine = false
    isLoadingMedias = false

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            option: SelectionItemModel
            selectionGroup: SelectionGroupModel
            parentSelectionGroup: SelectionGroupModel
            route: ActivatedRoute
            isDisplayOnly: boolean
        },
        notifyService: NotifyService,
        clientPortalSelectionService: ClientPortalSelectionService,
        private dialogRef: MatDialogRef<SelectionItemDetailsDialogComponent>,
        router: Router,
        private selectionGroupService: SelectionGroupsService
    ) {
        super(data.route, router, notifyService, clientPortalSelectionService)
        this.selectionItem = data.option
        this.selectionGroup = data.selectionGroup
        this.parentSelectionGroup = data.parentSelectionGroup
    }

    selectRow(index) {
        this.selectedRowIndex = index
    }

    showLocalNestedGroups() {
        this.showNestedGroups(this.selectionItem)
        this.dialogRef.close()
    }

    ngOnInit() {
        if (this.selectionItem.productLinkCostLines.length === 1) {
            this.hasSingleProductCostLine = true
        }

        this.setupGallery()
    }

    selectConfirmationAndCloseDialog() {
        this.selectionConfirmationObservable().subscribe(
            (result) => {
                if (result) {
                    this.dialogRef.close()
                }
            },
            (error) => {
                this.notifyService.fail('Failed to save selection')
                console.log(error)
            }
        )
    }

    setupGallery() {
        const imagePaths = this.selectionItem.getImagePaths()
        this.galleryOptions = [
            {
                width: '260px',
                height: '400px',
                thumbnailsColumns: imagePaths.length >= 3 ? 3 : imagePaths.length,
                imageAnimation: NgxGalleryAnimation.Slide,
                thumbnailsAutoHide: true,
                previewCloseOnClick: true,
                previewKeyboardNavigation: true,
            },
            {
                breakpoint: 1200,
                width: '200px',
                height: '300px',
            },
            {
                breakpoint: 767,
                width: '390px',
                height: '600px',
            },
        ]

        this.galleryImages = imagePaths.map((path) => {
            // this is needed otherwise: sanitizing unsafe style value url() is ran automatically
            path = path.replace('\\', '/')
            return {
                small: path,
                medium: path,
                big: path,
            }
        })

        if (imagePaths.length === 1) {
            this.galleryOptions[0].imageArrows = false
            this.galleryOptions[0].thumbnails = false
            this.galleryOptions[0].previewArrows = false
        }
    }

    loadMedias() {
        this.isLoadingMedias = true
        this.selectionGroupService.getSelectionItemMedias(this.selectionItem.id).subscribe((medias) => {
            medias.forEach((media) => {
                if (media.mediaType === MediaType.Image) {
                    this.selectionItem.images.push(ImageModel.createFromMediaDto(media))
                } else {
                    this.selectionItem.documents.push(DocumentModel.createFromMediaDto(media))
                }
            })
            this.isLoadingMedias = false
        })
    }

    downloadMedia(url: string) {
        window.open(url, '_blank')
    }
}
