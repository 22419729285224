import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, of } from 'rxjs'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import {
    SelectionGroupLookupDto,
    GetSelectionGroupListQuery,
    SelectionGroupsService,
    PaginatedResult,
    TemplateType,
} from '@app/services/api.services'
import { ServerSearchTableContainer } from '@app/shared/search-dialog/models/searchTableContainer'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import { flatMap, map } from 'rxjs/operators'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { LanguageService } from '@app/services/language.service'
import { SelectionGroupFormComponent } from './selection-group-form.component'

export class SelectionGroupDataSource extends PagingDataSource<SelectionGroupLookupDto, GetSelectionGroupListQuery> {
    constructor(private service: SelectionGroupsService) {
        super()
    }

    getEntitiesObservable(query: GetSelectionGroupListQuery): Observable<PaginatedResult> {
        return this.service.selectionGroups(query)
    }
}

@Injectable({
    providedIn: 'root',
})
export class SelectionGroupDialogService {
    constructor(
        private dialog: MatDialog,
        private searchDialogService: SearchDialogService,
        private selectionGroupService: SelectionGroupsService,
        private languageService: LanguageService
    ) {}

    showSelectionGroupFormDialog(
        template: TemplateModel,
        selectionGroup: SelectionGroupModel = null
    ): Observable<SelectionGroupModel> {
        // const dialogRef = this.dialog.open(SelectionGroupFormComponent, {
        //   width: '2800px',
        //   data: {
        //     template: template,
        //     selectionGroup: selectionGroup
        //   }
        // });
        // return dialogRef.afterClosed();
        return null
    }

    showSelectionGroupPickerDialog(template: TemplateModel): Observable<SelectionGroupModel> {
        const query = new GetSelectionGroupListQuery({
            isNestedSelectionGroup: true,
            includeOnlyGlobalSelectionGroups: true,
        })
        // if (template) {
        //   query.templateIdList = [template.id];
        //   if (template.templateType !== TemplateType.Master) {
        //     query.templateIdList.push(template.parentTemplate.id);
        //     if (template.templateType === TemplateType.Property) {
        //       query.templateIdList.push(template.parentTemplate.parentTemplate.id);
        //     }
        //   }
        // }
        query.requestedCulture = this.languageService.userSelectedLanguage.culture

        let setting = new ServerSearchTableContainer<SelectionGroupLookupDto, GetSelectionGroupListQuery>(
            'Nested Selection Groups',
            query,
            new SelectionGroupDataSource(this.selectionGroupService),
            true,
            [new DisplayColumn('Name', 'name'), new DisplayColumn('Version', 'versionNo')],
            []
        )
        setting.showSelectionButton = true
        setting.showAddButton = true
        setting.showEditButton = true

        return this.searchDialogService.showServerDialog(setting).pipe(
            flatMap((result) => {
                if (result.isAddClicked) {
                    return this.showSelectionGroupFormDialog(template)
                } else if (result.isEditClicked) {
                    this.selectionGroupService.selectionGroup(result.entityToEdit.id).subscribe((selectionGroupDto) => {
                        return this.showSelectionGroupFormDialog(
                            template,
                            SelectionGroupModel.createFromDto(selectionGroupDto)
                        )
                    })
                } else if (result.isPicked) {
                    return this.selectionGroupService
                        .selectionGroup(result.selectedEntity.id)
                        .pipe(map((selectionGroup) => SelectionGroupModel.createFromDto(selectionGroup)))
                }
                return of(null)
            })
        )
    }
}
