import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { DialogCapableFormComponentId, FormComponentSettings } from '@app/models/formComponent'
import {
    LabelDto,
    ProductVariationGroupsService,
    ProductVariationGroupDto,
    CreateProductVariationGroupCommand,
    EditProductVariationGroupCommand,
    BaseProductVariationGroupCommand,
    PropertyDefinitionLookupDto,
    ProductVariationDto,
    ProductDto,
    ProductsService,
    ProductVariationValueDto,
} from '@app/services/api.services'
import { Observable } from 'rxjs'
import { FormContainerService } from '@app/services/form-container.service'
import { ActivatedRoute } from '@angular/router'
import { Validators } from '@angular/forms'
import { ProductPickerService } from '@app/services/entity-selections/product-picker.service'
import { ItemContainer, ItemState } from '@app/models/util/itemContainer'
import { ManageProductVariationPropertyDialogService } from '../manage-product-variation-property-dialog/manage-product-variation-property-dialog.service'
import { map, mergeMap } from 'rxjs/operators'
import { ProductVariationGroupDetailComponent } from './product-variation-group-detail/product-variation-group-detail.component'

export class ProductVariationGroupDialogSettings {
    constructor(public disableAddingProductVariations: boolean) {}
}

@Component({
    selector: 'app-product-variation-group-form',
    templateUrl: './product-variation-group-form.component.html',
    styleUrls: ['./product-variation-group-form.component.scss'],
})
export class ProductVariationGroupFormComponent
    extends DialogCapableFormComponentId<string, ProductVariationGroupDto>
    implements OnInit {
    settings: ProductVariationGroupDialogSettings
    isEdited = false

    get propertyDefinitionContainers(): ItemContainer<{
        id: string
        labelIdentifier: string
    }>[] {
        return this.productVariationGroupDetailComponent?.propertyDefinitionContainers ?? []
    }

    set propertyDefinitionContainers(
        values: ItemContainer<{
            id: string
            labelIdentifier: string
        }>[]
    ) {
        this.productVariationGroupDetailComponent.propertyDefinitionContainers = values
    }

    get productVariations(): ItemContainer<ProductVariationDto>[] {
        return this.productVariationGroupDetailComponent?.productVariations ?? []
    }

    set productVariations(values: ItemContainer<ProductVariationDto>[]) {
        this.productVariationGroupDetailComponent.productVariations = values
    }

    @ViewChild(ProductVariationGroupDetailComponent)
    private productVariationGroupDetailComponent: ProductVariationGroupDetailComponent

    constructor(
        private route: ActivatedRoute,
        formContainerService: FormContainerService,
        private productPickerService: ProductPickerService,
        private productVariationGroupService: ProductVariationGroupsService,
        private productsService: ProductsService,
        private manageProductVariationPropertyDialogService: ManageProductVariationPropertyDialogService
    ) {
        super(
            new FormComponentSettings(true, 'product-variation-groups', 'Product Variation Group', false),
            formContainerService
        )
    }

    ngOnInit() {
        this.model = this.route.snapshot.data['productGroup']

        if (this.model) {
            this.id = this.model.id
        }

        this._setupformControl()
    }

    canDeactivateBase(): boolean {
        return !this.formControl.dirty && !this.isEdited
    }

    hasChangesToPropertyDefinitions() {
        return (
            this.propertyDefinitionContainers.filter(
                (p) => p.state === ItemState.NewlyAdded || p.state === ItemState.Deleted
            ).length > 0
        )
    }

    addProperty() {
        this.manageProductVariationPropertyDialogService
            .showDialog({
                productVariationGroup: {
                    id: this.model.id,
                    name: this.model.name,
                    numberOfVariants: this.productVariations.length,
                    propertyDefinitions: this.propertyDefinitionContainers,
                    productVariantProductIds: this.productVariations.map((v) => v.item.productId),
                },
            })
            .subscribe((results) => {
                if (!results) {
                    return
                }
                this.propertyDefinitionContainers = results.propertyDefinitions
            })
    }

    editEntity(): Observable<void> {
        const cmd = new EditProductVariationGroupCommand()
        this._generateFromForm(cmd)

        cmd.deletedProductDefinitionIds = this.propertyDefinitionContainers
            .filter((p) => p.state === ItemState.Deleted)
            .map((p) => p.item.id)

        cmd.deletedProductVariationProductIds = this.productVariations
            .filter((p) => p.state === ItemState.Deleted)
            .map((p) => p.item.productId)

        cmd.id = this.model.id
        return this.productVariationGroupService.edit(cmd)
    }

    createEntity(): Observable<string> {
        const cmd = new CreateProductVariationGroupCommand()
        this._generateFromForm(cmd)

        cmd.name = this.formControl.get('name').value
        return this.productVariationGroupService.create(cmd)
    }

    removeProductVariation(item: ItemContainer<ProductVariationDto>) {
        item.setAsRemoved()
        this.isEdited = true
    }

    addProductVariation() {
        const productIdsToExclude = this.productVariations.map((p) => p.item.productId)
        this.productPickerService
            .pickProduct(productIdsToExclude)

            .pipe(
                mergeMap((productDto) => {
                    if (productDto) {
                        const productVariation = new ProductVariationDto()

                        productVariation.productId = productDto.id
                        productVariation.productName = productDto.productName
                        productVariation.translations = productDto.translations

                        productVariation.selectedTranslation = productDto.selectedTranslation
                        productVariation.productVariationValues = {}

                        return this.productsService.getProductProperties(productDto.id).pipe(
                            map((propertyValues) => {
                                propertyValues.forEach((propertyValue) => {
                                    const productVariationValueDto = new ProductVariationValueDto()

                                    productVariationValueDto.propertyDefinitionId = propertyValue.propertyDefinitionId
                                    productVariationValueDto.selectedTranslation = propertyValue.selectedTranslation
                                    productVariationValueDto.translations = propertyValue.translations
                                    productVariationValueDto.value = propertyValue.value

                                    productVariation.productVariationValues[
                                        propertyValue.labelIdentifier
                                    ] = productVariationValueDto
                                })

                                this.productVariations = [
                                    ...this.productVariations,
                                    new ItemContainer(productVariation, ItemState.NewlyAdded),
                                ]

                                this.isEdited = true

                                return productVariation
                            })
                        )
                    }

                    return
                })
            )
            .subscribe()
    }

    private _generateFromForm(cmd: BaseProductVariationGroupCommand) {
        cmd.init(this.formControl.value)

        cmd.addedProductDefinitionIds = this.propertyDefinitionContainers
            .filter((p) => p.state === ItemState.NewlyAdded)
            .map((p) => p.item.id)

        cmd.addedProductVariationProductIds = this.productVariations
            .filter((p) => p.state === ItemState.NewlyAdded)
            .map((p) => p.item.productId)
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new ProductVariationGroupDto()
            // TODO: update to work with new backend models
            // this.model.productVariations = []
        }

        this.formControl = this.formBuilder.group({
            name: [this.model.name, [Validators.required]],
        })
    }
}
