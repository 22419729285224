import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { PackageAssociationConfirmationDialogService } from './package-association-confirmation-dialog.service'
import { PipesModule } from '@app/shared/pipes/pipes.module'
import { PackageAssociationConfirmationDialogComponent } from './package-association-confirmation-dialog.component'

@NgModule({
    declarations: [PackageAssociationConfirmationDialogComponent],
    imports: [CommonFormsModule, PipesModule],
    providers: [PackageAssociationConfirmationDialogService],
    exports: [PackageAssociationConfirmationDialogComponent],
    entryComponents: [PackageAssociationConfirmationDialogComponent],
})
export class PackageAssociationConfirmationDialogModule {}
