import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ItemContainer } from '@app/models/util/itemContainer'
import { Observable } from 'rxjs'
import { ManageProductVariationPropertyDialogComponent } from './manage-product-variation-property-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class ManageProductVariationPropertyDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(data: {
        productVariationGroup: {
            name: string
            id: string
            numberOfVariants: number
            productVariantProductIds: string[]
            propertyDefinitions: ItemContainer<{
                id: string
                labelIdentifier: string
            }>[]
        }
    }): Observable<{
        propertyDefinitions: ItemContainer<{
            id: string
            labelIdentifier: string
        }>[]
    }> {
        const dialogRef = this.dialog.open(ManageProductVariationPropertyDialogComponent, {
            width: '800px',
            data,
        })

        return dialogRef.afterClosed()
    }
}
