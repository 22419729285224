<ng-container *ngIf="displayMode === PagingDisplayModeTypes.Dialog">
    <h2 mat-dialog-title>{{ customTitle ? customTitle : entityDisplayName }}</h2>
    <mat-dialog-content>
        <mat-form-field>
            <input #input matInput placeholder="Filter" />
        </mat-form-field>

        <ng-container *ngTemplateOutlet="customInputs"></ng-container>

        <mat-divider></mat-divider>

        <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
    </mat-dialog-content>

    <mat-dialog-actions align="end" class="d-flex justify-content-between">
        <mat-paginator
            [length]="length"
            [pageIndex]="paginationModel.page"
            [pageSize]="paginationModel.pageSize"
            [pageSizeOptions]="[5, 10, 25, 100, 250]"
        ></mat-paginator>

        <button type="button" mat-button color="warn" mat-dialog-close>Done</button>
    </mat-dialog-actions>
</ng-container>

<ng-container *ngIf="displayMode === PagingDisplayModeTypes.Card">
    <mat-form-field>
        <input #input matInput placeholder="Filter" />
    </mat-form-field>

    <ng-container *ngTemplateOutlet="customInputs"></ng-container>

    <div class="card" [ngClass]="{ 'table-loading-container': true, 'background-loading': !isLoaded || isFailed }">
        <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
                <i class="material-icons">assignment</i>
            </div>
            <h4 class="card-title">{{ entityDisplayName | translate }}</h4>
        </div>
        <div class="card-body">
            <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="displayMode === PagingDisplayModeTypes.ListOnly">
    <mat-form-field>
        <input #input matInput placeholder="Filter" />
    </mat-form-field>

    <ng-container *ngTemplateOutlet="customInputs"></ng-container>

    <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
</ng-container>

<ng-template #elseTpl>
    <div [ngClass]="{ 'table-loading-container': true }">
        <div class="table-loading-shade" *ngIf="!isLoaded || isFailed">
            <mat-spinner class="mt-1" *ngIf="!isLoaded"></mat-spinner>
            <div class="table-load-failed" *ngIf="isFailed && isLoaded">
                <p>Failed to load {{ entityDisplayName }}...</p>
                <button (click)="retryReload.emit()" class="btn btn-primary btn-round">Retry</button>
            </div>
        </div>

        <mat-paginator
            *ngIf="displayMode !== PagingDisplayModeTypes.Dialog"
            [length]="length"
            [pageIndex]="paginationModel.page"
            [pageSize]="paginationModel.pageSize"
            [pageSizeOptions]="[5, 10, 25, 100, 250]"
        ></mat-paginator>

        <ng-container *ngIf="inMobileMode && mobileList; else table">
            <ng-container *ngTemplateOutlet="mobileList"></ng-container>
        </ng-container>
        <ng-template #table>
            <div class="server-paging-table-container">
                <ng-content></ng-content>
            </div>
        </ng-template>
    </div>
</ng-template>
