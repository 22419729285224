<h2 mat-dialog-title>{{ settings.isEdit ? 'Edit note' : 'Create note' }}</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup">
        <div class="row">
    
            <div class="col-md-6" *ngIf="settings.showCientLead">
                <mat-form-field>
                    <input matInput placeholder="Name" disabled="true" value="{{ settings.attachedEntity?.name }}" />
                    <mat-icon style="cursor: pointer" matSuffix (click)="viewAttachedEntity()">details</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field>
                    <input
                        placeholder="Created On"
                        matInput
                        [owlDateTimeTrigger]="createdDatePicker"
                        [owlDateTime]="createdDatePicker"
                        formControlName="createdDateTime"
                    />
                    <mat-icon style="cursor: pointer" [owlDateTimeTrigger]="createdDatePicker" matSuffix
                        >event</mat-icon
                    >
                    <owl-date-time #createdDatePicker></owl-date-time>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'createdDateTime')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div
                class="col-md-6 ng-star-inserted d-flex flex-column align-items-center"
                *ngIf="model.noteType === NoteType.DocumentUpload"
            >
                <ng-container *ngIf="model.attachedImage">
                    <img [src]="model.attachedImage.filePath" class="display-image mx-auto mb-2" />
                </ng-container>
                <ng-container *ngIf="model.attachedDocument">
                    <div class="text-center">
                        <i class="fa fa-file fa-10x"></i>
                    </div>
                    <div class="text-center mb-2">
                        <a>{{ model.attachedDocument.uploadedFileName }}</a>
                    </div>
                </ng-container>
                <button mat-stroked-button color="primary" (click)="addAttachment()">
                    {{ model.attachedImage || model.attachedDocument ? 'Edit' : 'Add' }} Document/Image
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field>
                    <mat-select placeholder="{{ 'CLIENTLEAD.NOTETYPE' | translate }}" formControlName="noteType">
                        <mat-option *ngFor="let noteType of enumListService.noteTypes" [value]="noteType.value">
                            {{ noteType.display | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field>
                    <mat-select placeholder="Project" formControlName="projectId" name="item">
                        <mat-option *ngFor="let project of projects" [value]="project.id">
                            {{ project.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-12" *ngIf="formGroup.contains('subject')">
                <mat-form-field>
                    <input matInput placeholder="{{ 'SUBJECT' | translate }}" formControlName="subject" />
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'subject')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="formGroup.contains('followUpDateTime')">
                <mat-form-field>
                    <input
                        placeholder="{{ 'CLIENTLEAD.FOLLOWUPDATETIME' | translate }}"
                        matInput
                        [owlDateTimeTrigger]="followUpDatePicker"
                        [owlDateTime]="followUpDatePicker"
                        formControlName="followUpDateTime"
                    />
                    <mat-icon style="cursor: pointer" [owlDateTimeTrigger]="followUpDatePicker" matSuffix
                        >event</mat-icon
                    >
                    <owl-date-time #followUpDatePicker></owl-date-time>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'followUpDateTime')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="formGroup.contains('appointmentDateTime')">
                <mat-form-field>
                    <input
                        placeholder="{{ 'CLIENTLEAD.APPOINTMENTDATETIME' | translate }}"
                        matInput
                        [owlDateTimeTrigger]="appointmentDatePicker"
                        [owlDateTime]="appointmentDatePicker"
                        formControlName="appointmentDateTime"
                    />
                    <mat-icon style="cursor: pointer" [owlDateTimeTrigger]="appointmentDatePicker" matSuffix
                        >event</mat-icon
                    >
                    <owl-date-time #appointmentDatePicker></owl-date-time>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'appointmentDateTime')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="formGroup.contains('callOccuredDateTime')">
                <mat-form-field>
                    <input
                        placeholder="{{ 'CLIENTLEAD.CALLOCCUREDONDATETIME' | translate }}"
                        matInput
                        [owlDateTimeTrigger]="callOccuredDatePicker"
                        [owlDateTime]="callOccuredDatePicker"
                        formControlName="callOccuredDateTime"
                    />
                    <mat-icon style="cursor: pointer" [owlDateTimeTrigger]="callOccuredDatePicker" matSuffix
                        >event</mat-icon
                    >
                    <owl-date-time #callOccuredDatePicker></owl-date-time>
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'callOccuredDateTime')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-12">
                <mat-form-field>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="3"
                        placeholder="Description"
                        formControlName="description"
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]>{{ 'CANCEL' | translate }}</button>

        <div>
            <ng-container *ngxPermissionsOnly="['DeleteClientLeadNote']">
                <button
                    *ngIf="settings.isEdit && settings.allowDelete"
                    color="warn"
                    mat-stroked-button
                    (click)="onDeleteClick()"
                >
                    {{ 'DELETE' | translate }}
                </button>
            </ng-container>
            <button mat-button mat-stroked-button cdkFocusInitial [loading]="isSubmitting" (click)="onSubmitClick()">
                {{ (settings.isEdit ? 'SAVE' : 'CREATE') | translate }}
            </button>
        </div>
    </div>
</mat-dialog-actions>
