import { Injectable } from '@angular/core'
import { PaginationModel } from './api.services'

export class SearchPersist {
    pagingConfiguration: PaginationModel
    pageSpecificFilters: any
}

@Injectable({
    providedIn: 'root',
})
export class PagingPersistService {
    configuration: any = {}
    storageKey = 'pagingRoot'
    constructor() {
        this.configuration = JSON.parse(localStorage.getItem(this.storageKey))

        if (!this.configuration) {
            this.configuration = {}
        }
    }

    savePagingInfo(key, value: PaginationModel) {
        this._initializeIfEmpty(key)
        this.configuration[key].pagingConfiguration = value
        localStorage.setItem(this.storageKey, JSON.stringify(this.configuration))
    }

    saveFilters(key, value: any) {
        this._initializeIfEmpty(key)
        this.configuration[key].pageSpecificFilters = value
        localStorage.setItem(this.storageKey, JSON.stringify(this.configuration))
    }

    getPagingValue(key) {
        return this.configuration[key].pagingConfiguration
    }

    getFiltersValue(key) {
        return this.configuration[key].pageSpecificFilters
    }

    hasValue(key) {
        return this.configuration.hasOwnProperty(key)
    }

    hasFilterValue(key) {
        return this.configuration.hasOwnProperty(key) && this.configuration[key].pageSpecificFilters
    }

    hasPagingValue(key) {
        return this.configuration.hasOwnProperty(key) && this.configuration[key].pagingConfiguration
    }

    private _initializeIfEmpty(key: any) {
        if (!this.configuration[key]) {
            this.configuration[key] = new SearchPersist()
        }
    }

    private _save() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.configuration))
    }
}
