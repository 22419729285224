import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { SelectionItemDetailsDialogComponent } from './selection-item-details-dialog.component'
import { SelectionItemModel, SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { ActivatedRoute } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class SelectionItemDetailsDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(
        option: SelectionItemModel,
        selectionGroup: SelectionGroupModel,
        route: ActivatedRoute,
        parentSelectionGroup: SelectionGroupModel,
        isDisplayOnly: boolean = false
    ) {
        const dialogRef = this.dialog.open(SelectionItemDetailsDialogComponent, {
            width: '1200px',
            maxHeight: '90vh',
            data: { option, selectionGroup, route, parentSelectionGroup, isDisplayOnly },
        })
    }
}
