import { NgModule } from '@angular/core'
import { CustomPropertiesFormComponent } from './custom-properties-form.component'
import { CommonFormsModule } from '../../../common-forms/common-forms.module'
import { CustomPropertyInputComponent } from './custom-property-input/custom-property-input.component'
import { PipesModule } from '../pipes/pipes.module'

@NgModule({
    imports: [CommonFormsModule, PipesModule],
    declarations: [CustomPropertiesFormComponent, CustomPropertyInputComponent],
    exports: [CustomPropertiesFormComponent, CustomPropertyInputComponent],
})
export class CustomPropertiesFormModule {}
