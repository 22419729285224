import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { GenerateTimesheetReportDatePickerComponent } from './generate-timesheet-report-date-picker.component'

@Injectable({
    providedIn: 'root',
})
export class GenerateTimesheetReportDatePickerService {
    constructor(private dialog: MatDialog) {}

    showDialog() {
        this.dialog.open(GenerateTimesheetReportDatePickerComponent, {
            width: '400px',
        })
    }
}
