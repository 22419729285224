import { NgModule } from '@angular/core'
import { SimpleComponentWrapperDialogComponent } from './simple-component-wrapper-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'

@NgModule({
    declarations: [SimpleComponentWrapperDialogComponent],
    imports: [CommonFormsModule],
    exports: [SimpleComponentWrapperDialogComponent],
    entryComponents: [SimpleComponentWrapperDialogComponent],
})
export class SimpleComponentWrapperDialogModule {}
