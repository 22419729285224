import { AllowanceDto, AllowanceTranslation } from '@app/services/api.services'
import { BaseTranslatableModel } from '../template/BaseTranslatableModel'
import { SelectionGroupModel, SelectionItemModel } from '../template/SelectionGroupModel'
import { TemplateModel } from '../template/TemplateModel'

export class AllowanceModel extends BaseTranslatableModel<AllowanceTranslation> {
    id?: number

    accumlatedAmount: number
    allocatedAmount: number

    allowanceItems: AllowanceItemModel[]
    allSelectedItems: SelectionItemModel[]

    useCustomAllowanceAmount: boolean

    static createFromDto(allowanceDto: AllowanceDto, template: TemplateModel): AllowanceModel {
        const propertySelectionIdsInAllowance = allowanceDto.allowanceItems.map((p) => p.propertySelectionId)
        const selectionGroupsInAllowance = template
            .getAllSelectionGroups()
            .filter((selectionGroup) => propertySelectionIdsInAllowance.includes(selectionGroup.propertySelection.id))

        let allocatedAmount = 0

        if (allowanceDto.useCustomAllowanceAmount) {
            allocatedAmount = allowanceDto.customAllowanceAmount
        } else {
            allocatedAmount = selectionGroupsInAllowance
                .map((s) => s.pricing.includedAmount)
                .reduce((sum, value) => {
                    return sum + value
                }, 0)
        }

        const allowanceItems = allowanceDto.allowanceItems.map(
            (allowanceItemDto) =>
                new AllowanceItemModel(
                    selectionGroupsInAllowance.find(
                        (s) => s.propertySelection.id === allowanceItemDto.propertySelectionId
                    ),
                    allowanceItemDto.propertySelectionId
                )
        )

        const allowance = new AllowanceModel(
            allowanceDto.id,
            allowanceDto.translations,
            allowanceItems,
            allocatedAmount,
            allowanceDto.useCustomAllowanceAmount
        )
        allowance.selectedTranslation = allowanceDto.selectedTranslation
        return allowance
    }

    constructor(
        id: number,
        translations: AllowanceTranslation[],
        allowanceItems: AllowanceItemModel[],
        allocatedAmount: number,
        useCustomAllowanceAmount
    ) {
        super(translations)

        this.id = id

        this.allocatedAmount = allocatedAmount
        this.allowanceItems = allowanceItems
        this.allSelectedItems = allowanceItems.map((a) => a.selectedItems).reduce((arr, v) => arr.concat(v), [])

        this.accumlatedAmount = this.allSelectedItems.map((s) => s.totalPrice).reduce((sum, value) => sum + value, 0)

        this.useCustomAllowanceAmount = useCustomAllowanceAmount
    }

    get percent(): number {
        return (this.accumlatedAmount / this.allocatedAmount) * 100
    }

    get isOverAllocatedAmount(): boolean {
        return this.accumlatedAmount > this.allocatedAmount
    }

    get remainingAmount(): number {
        return Math.abs(this.allocatedAmount - this.accumlatedAmount)
    }

    createDto(): AllowanceDto {
        const allowanceDto = new AllowanceDto()

        allowanceDto.useCustomAllowanceAmount = this.useCustomAllowanceAmount
        allowanceDto.customAllowanceAmount = this.useCustomAllowanceAmount ? this.allocatedAmount : 0
        allowanceDto.translations = this.translations

        return allowanceDto
    }
}

export class AllowanceItemModel {
    selectedItems: SelectionItemModel[]
    selectionGroup: SelectionGroupModel
    propertySelectionId: string

    get hasSelectedItem(): boolean {
        return this.selectedItems !== undefined
    }

    constructor(selectionGroup: SelectionGroupModel, propertySelectionId: string) {
        this.selectionGroup = selectionGroup
        this.selectedItems = selectionGroup.getAllSelections()
        this.propertySelectionId = propertySelectionId
    }
}
