import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { QuantityDialogComponent } from './quantity-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class QuantityDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(quantity: number = 1): Observable<number | null> {
        const dialogRef = this.dialog.open(QuantityDialogComponent, {
            width: '400px',
            data: {
                quantity: quantity,
            },
        })

        return dialogRef.afterClosed()
    }
}
