import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http'
import { Observable } from 'rxjs'
import { AccountManageService } from '@app/services/accountManage.service'
import { Injectable } from '@angular/core'

@Injectable()
export class ClientViewModeInterceptor implements HttpInterceptor {
    constructor(private accountManageService: AccountManageService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.accountManageService.isInClientViewMode && this.accountManageService.viewingClient) {
            req = req.clone({
                headers: req.headers.set('Viewing-Client-Id', this.accountManageService.viewingClient.id),
            })
        }

        return next.handle(req)
    }
}
