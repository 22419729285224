<ng-container *ngIf="!appliedPackage; else loaded">
    <div class="row">
        <div class="col-12">Loading full package...</div>
    </div>
    <mat-spinner class="mt-5 mx-auto"></mat-spinner>
</ng-container>
<ng-template #loaded>
    <h2 mat-dialog-title>{{ appliedPackage.package.internalName }}</h2>
    <mat-dialog-content>
        <p *ngIf="appliedPackage.package.isApplied" class="text-warning">
            Can't make changes to because this package has been applied, please unapply it to make changes then re-apply
        </p>
        <mat-tab-group [(selectedIndex)]="displayedTab" [dynamicHeight]="true" class="invisible-tabs">
            <mat-tab label="Selections">
                <div class="p-1">
                    <h5>Included in this package:</h5>
                    <ng-container
                        *ngFor="let appliedPackageSelection of appliedPackage.requiredPackageSelections; let i = index"
                    >
                        <app-package-selection-card
                            [appliedPackageSelection]="appliedPackageSelection"
                            [showPackageSelectionThumbnails]="appliedPackage.package.showPackageSelectionThumbnails"
                            (replaceSelectionItem)="replaceSelectionItem($event)"
                            (selected)="toggleSelect(appliedPackageSelection)"
                            (showDetails)="
                                showDetail(appliedPackageSelection.packageSelection.selectionGroup.id, $event)
                            "
                        >
                        </app-package-selection-card>
                    </ng-container>
                    <h5>Optional:</h5>
                    <ng-container
                        *ngFor="let appliedPackageSelection of appliedPackage.optionalPackageSelections; let i = index"
                    >
                        <app-package-selection-card
                            [appliedPackageSelection]="appliedPackageSelection"
                            [showPackageSelectionThumbnails]="appliedPackage.package.showPackageSelectionThumbnails"
                            (replaceSelectionItem)="replaceSelectionItem($event)"
                            (selected)="toggleSelect(appliedPackageSelection)"
                            (showDetails)="
                                showDetail(appliedPackageSelection.packageSelection.selectionGroup.id, $event)
                            "
                        >
                        </app-package-selection-card>
                    </ng-container>
                </div>
            </mat-tab>
            <mat-tab label="Replacement">
                <div style="min-height: 200px" class="p-1 d-flex flex-column justify-content-center">
                    <ng-container *ngIf="selectionGroup; else loadingSelectionGroup">
                        <app-package-selection-card
                            *ngFor="let selectionItem of selectionGroup.selectionItems; let i = index"
                            [showPackageSelectionThumbnails]="appliedPackage.package.showPackageSelectionThumbnails"
                            (selected)="replaceDefaultSelectionItem(selectionItem)"
                            [appliedPackageSelection]="selectedAppliedPackageSelection"
                            [selectionItem]="selectionItem"
                            (showDetails)="showDetail(selectionGroup.id, $event)"
                        >
                        </app-package-selection-card>
                    </ng-container>

                    <button mat-stroked-button color="primary" (click)="goBack()">Go back</button>

                    <ng-template #loadingSelectionGroup>
                        <div style="min-height: 200px" class="d-flex align-items-center justify-content-center w-100">
                            <mat-spinner class="p-2"></mat-spinner>
                        </div>
                    </ng-template>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="package-price pb-4 w-100">
            <!-- <mat-divider [inset]="true"></mat-divider> -->
            <div class="d-flex justify-content-between mt-2">
                <label>Total:</label>
                <div>{{ appliedPackage.calculateTotalAmount() | currency }}</div>
            </div>
            <div class="d-flex justify-content-between">
                <label>Discount:</label>
                <div>{{ appliedPackage.calculateDiscountedAmount() * -1 | currency }}</div>
            </div>
            <div class="d-flex justify-content-between">
                <label>Total (with discount):</label>
                <div>{{ appliedPackage.calculateTotalAmountWithDiscount() | currency }}</div>
            </div>
        </div>

        <div class="d-flex w-100 justify-content-between">
            <button type="button" mat-button color="warn" (click)="dialogRef.close(false)">Cancel</button>

            <button mat-stroked-button *ngIf="!appliedPackage.package.isApplied" (click)="applyPackage()">Apply</button>

            <button mat-stroked-button *ngIf="appliedPackage.package.isApplied" (click)="unapplyPackage()">
                Unapply
            </button>
        </div>
    </mat-dialog-actions>
</ng-template>
