import { PackageModel } from './packageModel'
import { SelectionItemModel, SelectionGroupModel } from '../template/SelectionGroupModel'
import { PackageSelectionModel } from './packageSelectionModel'
import { PackageDiscount, PackageSelectionGroupDto } from '@app/services/api.services'
import { TemplateModel } from '../template/TemplateModel'

export class AppliedPackageModel {
    private _package: PackageModel
    private _appliedPackageSelections: AppliedPackageSelectionModel[] = []

    getSelectedAppliedPackageSelections(): AppliedPackageSelectionModel[] {
        return this.appliedPackageSelections.filter((p) => p.isSelected)
    }

    getMissingTemplateSelectionGroups(template: TemplateModel): PackageSelectionGroupDto[] {
        const selectionGroups = template.getSelectionGroups()
        const packageSgs = this.package.getAllPackageSelectionGroups()
        return packageSgs.filter((packageSg) => selectionGroups.findIndex((sg) => sg.id === packageSg.id) === -1)
    }

    calculateDiscountedAmount(): number {
        switch (this.package.packageDiscountType) {
            case PackageDiscount.Fixed:
                return this.package.packageDiscountAmount
            case PackageDiscount.None:
                return 0
        }
    }

    get package(): PackageModel {
        return this._package
    }

    get appliedPackageSelections(): AppliedPackageSelectionModel[] {
        return this._appliedPackageSelections
    }

    get requiredPackageSelections(): AppliedPackageSelectionModel[] {
        return this.appliedPackageSelections.filter((selection) => selection.packageSelection.hasToSelect)
    }

    get optionalPackageSelections(): AppliedPackageSelectionModel[] {
        return this.appliedPackageSelections.filter((selection) => !selection.packageSelection.hasToSelect)
    }

    get selectedPackageSelections(): AppliedPackageSelectionModel[] {
        return this.appliedPackageSelections.filter((selection) => selection.isSelected)
    }

    calculateTotalAmount(): number {
        return this.appliedPackageSelections.reduce((sum, appliedPackage) => (sum += appliedPackage.getPrice()), 0)
    }

    calculateTotalAmountWithDiscount(): number {
        return this.calculateTotalAmount() - this.calculateDiscountedAmount()
    }

    isAllRequiredSelectionsMade() {
        return (
            this.requiredPackageSelections.filter((requiredPackageSelection) => !requiredPackageSelection.isSelected)
                .length === 0
        )
    }

    loadPackage(loadPackage: PackageModel) {
        this._package = loadPackage
        this._appliedPackageSelections = loadPackage.packageSelections.map((packageSelection) =>
            packageSelection.createAppliedPackage()
        )
    }
}

export class AppliedPackageSelectionModel {
    isSelected = false
    defaultSelectionItem: SelectionItemModel
    replacedSelectionItem: SelectionItemModel
    packageSelection: PackageSelectionModel
    isDefaultSelectionItemReplaced: boolean

    get selectionItem(): SelectionItemModel {
        return this.isDefaultSelectionItemReplaced ? this.replacedSelectionItem : this.defaultSelectionItem
    }

    toggleSelect() {
        if (this.isSelected) {
            this.unselect()
        } else {
            this.isSelected = true
        }
    }

    replaceDefaultSelectionItem(selectionItem: SelectionItemModel) {
        this.isDefaultSelectionItemReplaced = true
        this.isSelected = true
        this.replacedSelectionItem = selectionItem
    }

    unselect() {
        this.isDefaultSelectionItemReplaced = false
        this.isSelected = false
        this.replacedSelectionItem = undefined
    }

    getPrice(): number {
        return this.selectionItem.totalPrice
    }
}
