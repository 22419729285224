<div
    *ngIf="showPackageSelectionThumbnails; else hidePackageSelectionThumbnails"
    class="card card-selection d-flex flex-row"
>
    <div>
        <div
            class="card card-plane-background thumbnail-xs-img hover-selection"
            [style.backgroundImage]="'url(' + (imageCdnService.generateCdnThumbnailUrl(imageUrl) | async) + ')'"
            (click)="selected.emit()"
        >
            <div class="card-overlay" *ngIf="isSelected">
                <i class="material-icons text-success">done</i>
            </div>
        </div>
    </div>

    <div class="w-100">
        <div class="d-flex justify-content-between card-package-title">
            <h4>{{ title }}</h4>
            <p>{{ price | currency }}</p>
        </div>

        <div class="d-flex justify-content-end card-package-action">
            <div class="d-flex justify-content-end">
                <button
                    color="accent"
                    *ngIf="canReplaceWithOtherSelection"
                    (click)="replaceSelectionItem.emit(appliedPackageSelection)"
                    mat-button
                >
                    Replace
                </button>
                <button color="primary" (click)="showDetails.emit(selectionItemId)" mat-button>Detail</button>
            </div>
        </div>
    </div>
</div>

<ng-template #hidePackageSelectionThumbnails>
    <div class="d-flex align-items-center pl-1 pr-1">
        <mat-checkbox [disabled]="hasToSelect" [ngModel]="isSelected" (change)="select()"
            >{{ title }} ({{ price | currency }})</mat-checkbox
        >

        <!-- <div class="w-100">
            <div class="d-flex justify-content-end card-package-action">

                <div class="d-flex justify-content-end">
                    <button color="accent" *ngIf="canReplaceWithOtherSelection"
                        (click)="replaceSelectionItem.emit(appliedPackageSelection)" mat-button>Replace</button>
                    <button color="primary" (click)="showDetails.emit(selectionItemId)" mat-button>Detail</button>
                </div>
            </div>
        </div> -->
    </div>
</ng-template>
