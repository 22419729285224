import { NgModule } from '@angular/core'
import { UploadFileDialogComponent } from './upload-file-dialog.component'
import { MaterialModule } from '@app/material.module'
import { FileSelectModule } from '@app/shared/file-select/file-select.module'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { NgxFileDropModule } from 'ngx-file-drop'

@NgModule({
    declarations: [UploadFileDialogComponent],
    imports: [CommonFormsModule, MaterialModule, FileSelectModule, NgxFileDropModule],
})
export class UploadFileDialogModule {}
