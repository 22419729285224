import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PropertyDefinitionLookupDto } from '@app/services/api.services'
import { Observable } from 'rxjs'
import { MultiProductPropertyValueSelectionDialogComponent } from './multi-product-property-value-selection-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class MultiProductPropertyValueSelectionDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(
        productIds: string[],
        propertyDefinition: PropertyDefinitionLookupDto
    ): Observable<PropertyDefinitionLookupDto> {
        const dialogRef = this.dialog.open(MultiProductPropertyValueSelectionDialogComponent, {
            width: '800px',
            data: {
                productIds,
                propertyDefinition,
            },
        })

        return dialogRef.afterClosed()
    }
}
