import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { QuantityDialogComponent } from './quantity-dialog.component'
import { MaterialModule } from '@app/material.module'
import { FormsModule } from '@angular/forms'

@NgModule({
    declarations: [QuantityDialogComponent],
    imports: [CommonModule, FormsModule, MaterialModule],
    exports: [QuantityDialogComponent],
    entryComponents: [QuantityDialogComponent],
})
export class QuantityDialogModule {}
