import {
    Component,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentFactory,
    OnDestroy,
    Inject,
} from '@angular/core'
import { FormDialogSettings } from '@app/models/dialogs/formDialogSettings'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { DialogCapableFormComponentId } from '@app/models/formComponent'
import { LanguageService } from '@app/services/language.service'
import { FormContainerService } from '@app/services/form-container.service'

@Component({
    selector: 'app-form-dialog',
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent<
    EntityType,
    IdType,
    ComponentType extends DialogCapableFormComponentId<IdType, EntityType>
> implements OnInit, OnDestroy {
    dialogTitle: string
    component: DialogCapableFormComponentId<any, EntityType>

    @ViewChild('formContainer', { read: ViewContainerRef }) container
    componentRef: any

    isLoading = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: FormDialogSettings<EntityType, ComponentType>,
        private resolver: ComponentFactoryResolver,
        public languageService: LanguageService,
        private formContainerService: FormContainerService,
        private dialogRef: MatDialogRef<FormDialogComponent<EntityType, IdType, ComponentType>>
    ) {}

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.createComponent()
        })
    }

    onSubmitClick() {
        if (!this.component.formControl.valid) {
            this.component.validateAllFormFields(this.component.formControl)
            return
        }
        this.isLoading = true
        this.component.onSubmitFromDialog().subscribe(
            (created) => {
                this.isLoading = false
                this.formContainerService.notifyService.success('Saved complete')
                if (created) {
                    this.dialogRef.close(created)
                } else {
                    this.dialogRef.close()
                }
            },
            (error) => {
                console.log(error)
                this.isLoading = false
                this.formContainerService.notifyService.fail('Error saving')
            }
        )
    }

    createComponent() {
        this.formContainerService.isDialogMode = true
        this.container.clear()
        const factory = this.resolver.resolveComponentFactory(this.settings.componentType)
        this.componentRef = this.container.createComponent(factory)
        this.componentRef.instance.isInDialogMode = true
        this.component = this.componentRef.instance as DialogCapableFormComponentId<any, EntityType>
        if (this.settings.modelToEdit) {
            this.component.model = this.settings.modelToEdit
        }
        if (this.settings.injectPropertiesIntoComponent) {
            this.settings.injectPropertiesIntoComponent(this.component as ComponentType)
        }
        this.dialogTitle = this.component.formComponentSettings.entityName
        this.formContainerService.isDialogMode = false
    }

    ngOnDestroy() {
        this.componentRef.destroy()
    }
}
