import { Moment } from 'moment'

export class CalendarEvent {
    constructor(
        public id: string,
        public title: string,
        public start: Moment,
        public end: Moment,
        public allDay: boolean,
        public className: string,
        public url: string
    ) {}
}
