import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, Subject } from 'rxjs'
import { ImgCropperDialogComponent } from './img-cropper-dialog.component'
import { ImgCropperDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { ImageMedia } from '@app/services/api.services'

@Injectable({
    providedIn: 'root',
})
export class ImgCropperDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: ImgCropperDialogSettings): Observable<File> {
        const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
            width: `${settings.width + 100}px`,
            data: settings,
        })

        dialogRef.afterClosed().subscribe((result) => {})

        return dialogRef.afterClosed()
    }

    // showEditDialog(displayCaptions: boolean, imageInfo: TranslatableImageInfo, imageWidth = 300, imageHeight = null): Observable<ImageResult> {
    //   const dialogRef = this.dialog.open(ImgCropperDialogComponent, {
    //     width: `${imageWidth + 100}px`,
    //     data: { width: imageWidth, height: imageHeight === null ? imageWidth : imageHeight, imageInfo: imageInfo, displayCaptions: displayCaptions }
    //   });

    //   return dialogRef.afterClosed();
    // }
}
