import { SelectionItemPricePipe } from './selection-item-price.pipe'
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CapitalizeFirstPipePipe } from './capitalize-first-pipe.pipe'
import { OrderByPipe } from './order-by.pipe'
import { OrderTranslationByPipe } from './order-translation-by.pipe'
import { PhonePipe } from './phone.pipe'
import { FormatTimePipe } from './format-time.pipe'
import { FormatDurationPipe } from './format-duration.pipe'
import { FormatTime24hrPipe } from './format-time-24hr.pipe'
import { PropertyValueUnitPipe } from './property-value-unit.pipe'

@NgModule({
    imports: [CommonModule],
    declarations: [
        CapitalizeFirstPipePipe,
        OrderByPipe,
        OrderTranslationByPipe,
        PhonePipe,
        FormatTimePipe,
        FormatDurationPipe,
        FormatTime24hrPipe,
        PropertyValueUnitPipe,
        SelectionItemPricePipe,
    ],
    exports: [
        CapitalizeFirstPipePipe,
        OrderByPipe,
        OrderTranslationByPipe,
        FormatTimePipe,
        FormatDurationPipe,
        FormatTime24hrPipe,
        PropertyValueUnitPipe,
        SelectionItemPricePipe,
    ],
})
export class PipesModule {}
