import { LabelGroupType } from './services/api.services'

export class AppSettings {
    public static PRODUCT_IMAGE_HEIGHT = 500
    public static PRODUCT_IMAGE_WIDTH = 500

    public static COMMON_ROOM_BANNER_IMAGE_HEIGHT = 280

    public static TEMPLATE_ROOM_BANNER_IMAGE_WIDTH_SIZES = {
        small: 350,
        medium: 540,
        large: 1120,
        largeWithDoubleHeight: 1120,
    }

    public static TEMPLATE_ROOM_BANNER_IMAGE_HEIGHT_SIZES = {
        small: AppSettings.COMMON_ROOM_BANNER_IMAGE_HEIGHT,
        medium: AppSettings.COMMON_ROOM_BANNER_IMAGE_HEIGHT,
        large: AppSettings.COMMON_ROOM_BANNER_IMAGE_HEIGHT,
        largeWithDoubleHeight: AppSettings.COMMON_ROOM_BANNER_IMAGE_HEIGHT * 2,
    }

    public static PRODUCT_LABEL_GROUP_TYPES = [
        LabelGroupType.ProductDiscipline,
        LabelGroupType.ProductRoom,
        LabelGroupType.ProductType,
    ]

    public static IMAGE_PLACEHOLDER_URL = '/assets/img/image_placeholder_square.png'

    public static DEFAULT_CURRENCY = 'CAD'

    public static DEFAULT_MIN_TIMESHEET_HOURS = 35

    public static GST_PERCENT = 5.0 / 100

    public static QST_PERCENT = 9.975 / 100
}
