import { Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component'
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component'
import { ClientPortalLayoutComponent } from './layouts/client-portal/client-portal-layout.component'
import { Injectable } from '@angular/core'
import { UserType } from '@app/services/api.services'
import { AccountManageService } from './services/accountManage.service'
import { NgxPermissionsGuard } from 'ngx-permissions'

@Injectable()
export class EmployeeAuthGuardCanActivate implements CanActivate {
    constructor(private router: Router, private accountManageService: AccountManageService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if (this.accountManageService.isLoggedIn && this.accountManageService.userType === UserType.Employee) {
            return true
        }
        if (this.accountManageService.isLoggedIn && this.accountManageService.userType === UserType.Client) {
            this.router.navigate(['/portal'])
        }
        this.router.navigate(['/login'])
        return false
    }
}

@Injectable()
export class ClientAuthGuardCanActivate implements CanActivate {
    constructor(private router: Router, private accountManageService: AccountManageService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | Observable<boolean> | Promise<boolean> {
        if (this.accountManageService.isLoggedIn) {
            return true
        }

        return false
    }
}

export const AppRoutes: Routes = [
    {
        path: 'portal',
        component: ClientPortalLayoutComponent,
        canActivate: [ClientAuthGuardCanActivate],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('./client-portal-pages/client-portal-pages.module').then((m) => m.ClientPortalPagesModule),
            },
            {
                path: 'client-properties',
                loadChildren: () =>
                    import('./client-portal-pages/client-properties/client-properties.module').then(
                        (m) => m.ClientPropertiesModule
                    ),
            },
            {
                path: 'selections',
                loadChildren: () =>
                    import('./client-portal-pages/selections/selections.module').then((m) => m.SelectionsModule),
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('./client-portal-pages/client-profile/client-profile.module').then(
                        (m) => m.ClientProfileModule
                    ),
            },
        ],
    },
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [EmployeeAuthGuardCanActivate],
        // canActivateChild: [NgxPermissionsGuard],
        // canLoad: [NgxPermissionsGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./admin-pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            // {
            //     path: 'employees',
            //     loadChildren: () => import('./admin-pages/employees/employees.module').then((m) => m.EmployeesModule),
            //     data: {
            //         permissions: {
            //             only: 'ViewEmployee',
            //         },
            //     },
            // },
            {
                path: 'properties',
                loadChildren: () =>
                    import('./admin-pages/properties/properties.module').then((m) => m.PropertiesModule),
            },
            {
                path: 'companies',
                loadChildren: () => import('./admin-pages/companies/companies.module').then((m) => m.CompaniesModule),
            },
            {
                path: 'projects',
                loadChildren: () => import('./admin-pages/projects/projects.module').then((m) => m.ProjectsModule),
            },
            {
                path: 'packages',
                loadChildren: () => import('./admin-pages/packages/packages.module').then((m) => m.PackagesModule),
            },
            {
                path: 'suppliers',
                loadChildren: () => import('./admin-pages/suppliers/suppliers.module').then((m) => m.SuppliersModule),
            },
            {
                path: 'employee-profile',
                loadChildren: () =>
                    import('./admin-pages/employee-profile/employee-profile.module').then(
                        (m) => m.EmployeeProfileModule
                    ),
            },
            {
                path: 'timesheets',
                loadChildren: () =>
                    import('./admin-pages/timesheets/timesheets.module').then((m) => m.TimesheetsModule),
            },
            {
                path: 'label-groups',
                loadChildren: () =>
                    import('./admin-pages/label-groups/label-groups.module').then((m) => m.LabelGroupsModule),
            },
            {
                path: 'products',
                loadChildren: () => import('./admin-pages/products/products.module').then((m) => m.ProductsModule),
            },
            {
                path: 'product-variation-groups',
                loadChildren: () =>
                    import('./admin-pages/product-variation-groups/product-variation-group.module').then(
                        (m) => m.ProductVariationGroupModule
                    ),
            },
            {
                path: 'brands',
                loadChildren: () => import('./admin-pages/brands/brands.module').then((m) => m.BrandsModule),
            },
            {
                path: 'services',
                loadChildren: () =>
                    import('./admin-pages/service-rates/service-rates.module').then((m) => m.ServiceRatesModule),
            },
            {
                path: 'task-templates',
                loadChildren: () =>
                    import('./admin-pages/task-templates/task-templates.module').then((m) => m.TaskTemplatesModule),
            },
            {
                path: 'templates',
                loadChildren: () => import('./admin-pages/templates/templates.module').then((m) => m.TemplatesModule),
            },
            {
                path: 'template-categories',
                loadChildren: () =>
                    import('./admin-pages/template-categories/template-categories.module').then(
                        (m) => m.TemplateCategoriesModule
                    ),
            },
            {
                path: 'template-rooms',
                loadChildren: () =>
                    import('./admin-pages/template-rooms/template-rooms.module').then((m) => m.TemplateRoomsModule),
            },
            {
                path: 'selection-groups',
                loadChildren: () =>
                    import('./admin-pages/selection-groups/selection-groups.module').then(
                        (m) => m.SelectionGroupsModule
                    ),
            },
            {
                path: 'take-offs',
                loadChildren: () => import('./admin-pages/takeoffs/take-offs.module').then((m) => m.TakeOffsModule),
            },
            {
                path: 'medias',
                loadChildren: () => import('./admin-pages/medias/medias.module').then((m) => m.MediasModule),
            },
            {
                path: 'test',
                loadChildren: () => import('./test/test/test.module').then((m) => m.TestModule),
            },
        ],
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
            },
        ],
    },
]
