import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdminLayoutComponent } from './admin-layout.component'
import { SidebarModule } from '@app/sidebar/sidebar.module'
import { NavbarModule } from '@app/shared/navbar/navbar.module'
import { RouterModule } from '@angular/router'
import { FixedFormControlsModule } from '@app/shared/fixed-form-controls/fixed-form-controls.module'

@NgModule({
    imports: [CommonModule, SidebarModule, NavbarModule, RouterModule, FixedFormControlsModule],
    declarations: [AdminLayoutComponent],
    exports: [AdminLayoutComponent],
})
export class AdminModule {}
