import {
    ProductCurrencyPriceDto,
    CostLineTranslation,
    ProductDto,
    NestedProductCurrencyPriceDto,
    ProductCostLinesAggregatePriceDto,
} from '@app/services/api.services'
import { ProductCostLineModel } from '../costLines/products/ProductCostLineModel'
import { ProductPriceProductLinkCostLineModel } from '../costLines/products/ProductPriceProductLinkCostLineModel'
import { BaseCostLineModel } from '../costLines/BaseCostLineModel'
import { UnitPriceModel } from '../pricing/UnitPriceModel'
import { Guid } from 'guid-typescript'
import { CostLineDialogModel } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { ArrayHelper } from '@app/shared/helpers/arrayHelper'

export class ProductVersionPriceModel {
    id?: string | null
    versionNo: number
    isActive: boolean
    isArchived: boolean
    isNew: boolean
    internalName = ''
    caseQuantity: number

    currencyPrices: ProductCurrencyPriceModel[] = []

    static createFromDto(productPriceDto, isCopy = false): ProductVersionPriceModel {
        const productPriceModel = new ProductVersionPriceModel(
            productPriceDto.id,
            productPriceDto.versionNo,
            productPriceDto.isActive,
            productPriceDto.internalName
        )
        productPriceModel.currencyPrices = productPriceDto.currencyPrices.map((c) =>
            ProductCurrencyPriceModel.createFromDto(c, isCopy)
        )
        productPriceModel.caseQuantity = productPriceDto.caseQuantity

        if (isCopy) {
            productPriceModel.id = Guid.create().toString()
            productPriceModel.isNew = true
        }

        return productPriceModel
    }

    constructor(id: string, versionNo: number, isActive: boolean, internalName: string = undefined) {
        this.id = id
        this.versionNo = versionNo
        this.isActive = isActive

        if (internalName) {
            this.internalName = internalName
        } else {
            this.internalName = `Version ${versionNo}`
        }
    }

    createProductVersionPriceInput() {
        throw new Error('not implemented')
    }

    createDto() {
        throw new Error('not implemented')
    }

    addNewPriceCurrency(currencyCode: string) {
        if (this.currencyPrices.findIndex((c) => c.currencyCode === currencyCode) >= 0) {
            throw new Error(`This price version already has a price for the currency ${currencyCode}`)
        }

        this.currencyPrices.push(new ProductCurrencyPriceModel(null, false, currencyCode, 0, 0, false))
    }

    duplicate(versionNo: number): ProductVersionPriceModel {
        const priceVersionCopy = new ProductVersionPriceModel(null, versionNo, false)
        priceVersionCopy.isNew = true
        priceVersionCopy.internalName = this.internalName

        this.currencyPrices.forEach((currencyPrice) => {
            priceVersionCopy.currencyPrices.push(currencyPrice.duplicate())
        })
        priceVersionCopy.caseQuantity = this.caseQuantity

        return priceVersionCopy
    }
}

export class ProductCurrencyPriceModel extends UnitPriceModel {
    id: string
    currencyCode: string
    useCostLinePricing: boolean
    costLines: ProductCostLineModel[] = []
    priceLinkCostLines: ProductPriceProductLinkCostLineModel[] = []

    static createFromDto(productCurrencyPrice: ProductCurrencyPriceDto, isCopy = false): ProductCurrencyPriceModel {
        const productPriceCurrency = new ProductCurrencyPriceModel(
            productCurrencyPrice.id,
            productCurrencyPrice.useCostLinePricing,
            productCurrencyPrice.currencyCode,
            productCurrencyPrice.unitCostAmount,
            productCurrencyPrice.isUnitMarkupPercent
                ? productCurrencyPrice.unitMarkupPercent
                : productCurrencyPrice.unitMarkupAmount,
            productCurrencyPrice.isUnitMarkupPercent
        )
        productPriceCurrency.hasRetailAmount = productCurrencyPrice.hasRetailAmount
        productPriceCurrency.retailAmount = productCurrencyPrice.retailAmount
        productPriceCurrency.retailDiscountPercent = productCurrencyPrice.retailDiscountPercent

        if (productCurrencyPrice.useCostLinePricing) {
            productPriceCurrency.costLines = productCurrencyPrice.productPriceCostLinesAggregate.costLines.map((c) =>
                ProductCostLineModel.createFromDto(c, isCopy)
            )
            productPriceCurrency.priceLinkCostLines = productCurrencyPrice.productPriceCostLinesAggregate.productLinkCostLines.map(
                (c) => ProductPriceProductLinkCostLineModel.createFromDto(c, isCopy)
            )
        }

        if (isCopy) {
            productPriceCurrency.id = null
        }

        return productPriceCurrency
    }

    constructor(
        id: string,
        useCostLinePricing: boolean,
        currencyCode: string,
        unitCostAmount: number,
        unitMarkup: number,
        isUnitMarkupPercent: boolean
    ) {
        super(unitCostAmount, unitMarkup, isUnitMarkupPercent)

        this.id = id
        this.useCostLinePricing = useCostLinePricing
        this.currencyCode = currencyCode
    }

    moveCostLineUp(index: number) {
        ArrayHelper.moveArrayItemUp(this.costLines, index)
    }

    moveCostLineDown(index: number) {
        ArrayHelper.moveArrayItemDown(this.costLines, index)
    }

    movePriceLinkCostLineUp(index: number) {
        ArrayHelper.moveArrayItemUp(this.priceLinkCostLines, index)
    }

    movePriceLinkCostLineDown(index: number) {
        ArrayHelper.moveArrayItemDown(this.priceLinkCostLines, index)
    }

    createProductCurrencyPriceInput() {
        throw new Error('not implemented')
    }

    createDto(): ProductCurrencyPriceDto {
        const productCurrencyPriceDto = new ProductCurrencyPriceDto()
        productCurrencyPriceDto.id = this.id
        productCurrencyPriceDto.currencyCode = this.currencyCode
        productCurrencyPriceDto.useCostLinePricing = this.useCostLinePricing
        productCurrencyPriceDto.unitCostAmount = this.unitCostAmount
        productCurrencyPriceDto.unitMarkupAmount = this.unitMarkupAmount
        productCurrencyPriceDto.unitMarkupPercent = this.unitMarkupPercent
        productCurrencyPriceDto.isUnitMarkupPercent = this.isUnitMarkupPercent
        productCurrencyPriceDto.productPriceCostLinesAggregate = this.createProductCostLinesAggregtePriceDto()

        return productCurrencyPriceDto
    }

    addCostLine(costLineDialogModel: CostLineDialogModel): ProductCostLineModel {
        const costLine = ProductCostLineModel.createFromDialogModel(costLineDialogModel)
        costLine.isAdded = true
        this.costLines.push(costLine)
        return costLine
    }

    updateCostLine(costLine: ProductCostLineModel, costLineDialogModel: CostLineDialogModel) {
        const updatedCostLine = ProductCostLineModel.createFromDialogModel(costLineDialogModel)
        updatedCostLine.id = costLine.id
        updatedCostLine.isAdded = true
        updatedCostLine.selectedTranslation = costLineDialogModel.selectedTranslation
        const index = this.costLines.findIndex((c) => c.id === costLine.id)
        this.costLines[index] = updatedCostLine
    }

    addLinkedProductCostLine(
        product: ProductDto,
        productPrice: ProductCurrencyPriceDto
    ): ProductPriceProductLinkCostLineModel {
        const nestedProductCostLineDto = new NestedProductCurrencyPriceDto(productPrice)
        nestedProductCostLineDto.product = product

        const costLine = new ProductPriceProductLinkCostLineModel(
            null,
            1,
            productPrice.unitType,
            productPrice.unitCostAmount,
            productPrice.unitMarkupAmount,
            productPrice.unitMarkupPercent,
            false,
            false,
            product.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: product.useClientDisplayName ? t.clientDisplayName : product.productName,
                        culture: t.culture,
                    })
            ),
            nestedProductCostLineDto
        )
        costLine.isAdded = true
        this.priceLinkCostLines.push(costLine)
        return costLine
    }

    removeProductCostLine(costLine: BaseCostLineModel) {
        costLine.isDeleted = true
    }

    removeLinkedProductCostLine(costLine: BaseCostLineModel) {
        costLine.isDeleted = true
    }

    createProductCostLinesAggregratePriceInput() {
        throw new Error('not implemented')
    }

    createProductCostLinesAggregtePriceDto(): ProductCostLinesAggregatePriceDto {
        const ProductCostLinesAggregtePriceDto = new ProductCostLinesAggregatePriceDto()
        ProductCostLinesAggregtePriceDto.costLines = this.costLines.map((costline) => costline.createDto())
        ProductCostLinesAggregtePriceDto.productLinkCostLines = this.priceLinkCostLines.map((costLine) =>
            costLine.createDto()
        )
        return ProductCostLinesAggregtePriceDto
    }

    duplicate(): ProductCurrencyPriceModel {
        const productCurrencyPrice = new ProductCurrencyPriceModel(
            null,
            this.useCostLinePricing,
            this.currencyCode,
            this.unitCostAmount,
            this.isUnitMarkupPercent ? this.unitMarkupPercent : this.unitMarkupAmount,
            this.isUnitMarkupPercent
        )
        productCurrencyPrice.hasRetailAmount = this.hasRetailAmount
        productCurrencyPrice.retailAmount = this.retailAmount
        productCurrencyPrice.retailDiscountPercent = this.retailDiscountPercent
        if (this.useCostLinePricing) {
            this.costLines.forEach((costLine) => {
                const newCostLine = new ProductCostLineModel(
                    null,
                    costLine.quantity,
                    costLine.unitType,
                    costLine.unitCostAmount,
                    costLine.unitMarkupAmount,
                    costLine.unitMarkupPercent,
                    costLine.isUnitMarkupPercent,
                    costLine.isVisibleToClients,
                    []
                )
                newCostLine.hasRetailAmount = costLine.hasRetailAmount
                newCostLine.retailAmount = costLine.retailAmount
                newCostLine.retailDiscountPercent = costLine.retailDiscountPercent
                newCostLine.isAdded = true
                newCostLine.translations = []
                costLine.translations.forEach((translation) => {
                    const newTranslation = new CostLineTranslation({
                        culture: translation.culture,
                        description: translation.description,
                    })
                    newCostLine.translations.push(newTranslation)
                    if (costLine.selectedTranslation.id === translation.id) {
                        newCostLine.selectedTranslation = newTranslation
                    }
                })
                productCurrencyPrice.costLines.push(newCostLine)
            })
            this.priceLinkCostLines.forEach((costLine) => {
                const newCostLine = new ProductPriceProductLinkCostLineModel(
                    null,
                    costLine.quantity,
                    costLine.unitType,
                    costLine.unitCostAmount,
                    costLine.unitMarkupAmount,
                    costLine.unitMarkupPercent,
                    costLine.isUnitMarkupPercent,
                    costLine.isVisibleToClients,
                    [],
                    costLine.linkedProductPrice
                )
                newCostLine.isAdded = true
                newCostLine.translations = []
                costLine.translations.forEach((translation) => {
                    const newTranslation = new CostLineTranslation({
                        culture: translation.culture,
                        description: translation.description,
                    })
                    newCostLine.translations.push(newTranslation)
                    if (costLine.selectedTranslation.id == translation.id) {
                        newCostLine.selectedTranslation = newTranslation
                    }
                })
                productCurrencyPrice.priceLinkCostLines.push(newCostLine)
            })
        }
        return productCurrencyPrice
    }
}
