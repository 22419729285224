<ng-container [ngSwitch]="propertyValue.type">
    <ng-container *ngSwitchCase="PropertyDefinitionType.Text">
        <ng-container *ngIf="propertyValue.subType === PropertyDefinitionSubType.TextRegular; else translationField">
            <mat-form-field>
                <mat-label>{{ propertyValue.inputLabel }}</mat-label>
                <input type="text" matInput [(ngModel)]="propertyValue.value" />
            </mat-form-field>
        </ng-container>
        <ng-template #translationField>
            <ng-container *ngFor="let translation of propertyValue.translations; let i = index">
                <mat-form-field *ngIf="translation.culture === selectedLanguage.culture">
                    <mat-label>{{ translation.label }}</mat-label>
                    <input type="text" matInput [(ngModel)]="translation.value" />
                </mat-form-field>
            </ng-container>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="PropertyDefinitionType.Numeric">
        <div class="row">
            <div class="col-8">
                <mat-form-field>
                    <mat-label>{{ propertyValue.inputLabel }}</mat-label>
                    <input type="number" matInput [(ngModel)]="propertyValue.value" />
                </mat-form-field>
            </div>
            <div class="col-4">
                <mat-form-field>
                    <mat-label>Units</mat-label>
                    <mat-select [(ngModel)]="propertyValue.propertyValueUnitId">
                        <mat-option *ngFor="let unit of propertyValueUnits" [value]="unit.id">{{
                            unit.abbreviation
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="PropertyDefinitionType.Categorical">
        <mat-form-field>
            <mat-label>{{ propertyValue.inputLabel }}</mat-label>
            <mat-select
                [(ngModel)]="propertyValue.value"
                (selectionChange)="onCategoricalPropertySelectionChange(propertyValue)"
            >
                <mat-option>None</mat-option>
                <mat-option
                    *ngFor="let allowedValue of propertyValue.allowedValues"
                    [value]="allowedValue.valueIdentifier"
                    >{{ allowedValue.selectedTranslation.allowedValue }}</mat-option
                >
                <mat-option value="addNew">Add New</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</ng-container>
