import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { ReusableTakeoffFormComponent } from './reusable-takeoff-form/reusable-takeoff-form.component'
import { TakeOffFormComponent } from './take-off-form/take-off-form.component'

@NgModule({
    declarations: [TakeOffFormComponent, ReusableTakeoffFormComponent],
    imports: [CommonFormsModule],
    exports: [ReusableTakeoffFormComponent, TakeOffFormComponent],
    entryComponents: [ReusableTakeoffFormComponent, TakeOffFormComponent],
})
export class TakeOffFormsModule {}
