import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProjectsService, ProjectDto } from '../api.services'
import { LocalSearchTableContainer } from '@app/shared/search-dialog/models/searchTableContainer'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'

@Injectable({
    providedIn: 'root',
})
export class ProjectPickerService {
    constructor(private projectsService: ProjectsService, private searchDialogService: SearchDialogService) {}

    pickProject(): Observable<ProjectDto> {
        const searchTableContainer = new LocalSearchTableContainer(this.projectsService.getAll(), 'Projects', [
            new DisplayColumn('Project Name', 'name'),
        ])
        return this.searchDialogService.showLocalDialog(searchTableContainer)
    }
}
