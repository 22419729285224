import { Component, OnInit } from '@angular/core'
import PerfectScrollbar from 'perfect-scrollbar'

import { User } from '../models/user'
import { AccountManageService } from '../services/accountManage.service'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { LanguageService } from '@app/services/language.service'
import { UserConfigurationService } from '@app/services/user-configuration.service'

declare const $: any

export interface RouteInfo {
    path: string
    title: string
    type: string
    icontype: string
    collapse?: string
    children?: ChildrenItems[]
    permission?: string
}

export interface ChildrenItems {
    path: string
    title: string
    ab: string
    type?: string
    permission?: string
}

export const EMPLOYEEROUTES: RouteInfo[] = [
    {
        path: '/employee-profile',
        title: 'Profile',
        type: 'link',
        icontype: 'dashboard',
    },
    {
        path: '/timesheets/personal',
        title: 'Personal Timesheets',
        type: 'link',
        icontype: 'clock',
    },
    {
        path: '/login',
        title: 'Sign out',
        type: 'link',
        icontype: 'clock',
    },
]
export const ROUTES: RouteInfo[] = [
    // {
    //     path: '/dashboard',
    //     title: 'SIDEBAR.DASHBOARD',
    //     type: 'link',
    //     icontype: 'dashboard',
    // },
    // {
    //     path: '/employees',
    //     title: 'SIDEBAR.EMPLOYEES',
    //     type: 'link',
    //     icontype: 'people',
    //     permission: 'ViewEmployee',
    // },
    // {
    //     path: '/clients',
    //     title: 'SIDEBAR.CLIENTS',
    //     type: 'link',
    //     icontype: 'people',
    //     permission: 'ViewClient'
    // },
    {
        path: '/brokers',
        title: 'Brokers',
        type: 'link',
        icontype: 'people',
        permission: 'ViewRealEstateBroker',
    },
    {
        path: '/purchase-orders',
        title: 'Purchase Orders',
        type: 'link',
        icontype: 'list',
        permission: 'ViewPurchaseOrder',
    },
    {
        path: '/timesheets',
        title: 'Timesheets',
        type: 'sub',
        icontype: 'timer',
        collapse: 'timesheets',
        permission: 'ViewTimesheet',
        children: [
            {
                path: 'report',
                title: 'Timesheet Report',
                ab: 'R',
                permission: 'ViewTimesheetReport',
            },
            {
                path: 'employees',
                title: 'Employees Timesheets',
                ab: 'E',
            },
        ],
    },
    {
        path: '/properties',
        title: 'Properties',
        type: 'link',
        icontype: 'home',
        permission: 'ViewProperty',
    },
    {
        path: '/companies',
        title: 'Companies',
        type: 'link',
        icontype: 'business',
        permission: 'ViewCompany',
    },
    {
        path: '/suppliers',
        title: 'Suppliers',
        type: 'link',
        icontype: 'business',
        permission: 'ViewSupplier',
    },
    {
        path: '/services',
        title: 'Services',
        type: 'link',
        icontype: 'business',
        permission: 'ViewService',
    },
    {
        path: '/projects',
        title: 'Projects',
        type: 'link',
        icontype: 'trending_up',
        permission: 'ViewProject',
    },
    {
        path: '/products',
        title: 'Products',
        type: 'link',
        icontype: 'storefront',
        permission: 'ViewProduct',
    },
    {
        path: '/brands',
        title: 'Brands',
        type: 'link',
        icontype: 'branding_watermark',
        permission: 'ViewProduct',
    },
    {
        path: '/label-groups',
        title: 'Labels',
        type: 'link',
        icontype: 'label',
        permission: 'ViewProduct',
    },
    {
        path: '/accounting-codes',
        title: 'Accounting Codes',
        type: 'link',
        icontype: 'label',
        permission: 'ViewAccountingCode',
    },
    {
        path: '/product-variation-groups',
        title: 'Product Variation Groups',
        type: 'link',
        icontype: 'group_work',
        permission: 'ViewProduct',
    },
    {
        path: '',
        title: 'Templates',
        type: 'sub',
        icontype: 'label',
        collapse: 'template',
        permission: 'ViewTemplate',
        children: [
            {
                path: 'templates',
                title: 'Project Templates',
                ab: 'TE',
            },
            {
                path: 'packages',
                title: 'Packages',
                ab: 'PK',
            },
            {
                path: 'selection-groups',
                title: 'Selection Groups',
                ab: 'SG',
            },
            {
                path: 'selection-groups/nested',
                title: 'Nested Selection Groups',
                ab: 'NSG',
            },
            {
                path: 'template-categories',
                title: 'Section Categories',
                ab: 'SC',
            },
            {
                path: 'template-rooms',
                title: 'Section Rooms',
                ab: 'SR',
            },
            {
                path: 'take-offs',
                title: 'Take Offs',
                ab: 'TO',
            },
        ],
    },
    {
        path: '/medias',
        title: 'Medias',
        type: 'link',
        icontype: 'folder',
        permission: 'ViewMedia',
    },
]

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    public menuItems: any[]
    public dropDownMenuItems: any[]
    public languageToDisplay: string

    constructor(
        public accountService: AccountManageService,
        private router: Router,
        private languageService: LanguageService
    ) {}

    logout() {
        this.accountService.logout().subscribe(() => {})
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false
        }
        return true
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter((menuItem) => menuItem)
        this.dropDownMenuItems = EMPLOYEEROUTES.filter((menuItem) => menuItem)
        this.languageToDisplay = this.languageService.getTranslateToggleLanguage()
    }

    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper')
            const ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true })
        }
    }

    getRouterLinkUrl(menuItemPath: string, childItemPath: string) {
        var urlPath = [menuItemPath]
        // getting rid of the frontslash because it get's serialized to %2f
        if (childItemPath.indexOf('/') >= 0) {
            urlPath.push(childItemPath.slice(0, childItemPath.indexOf('/')))
            urlPath.push(childItemPath.slice(childItemPath.indexOf('/') + 1, childItemPath.length))
        } else {
            urlPath.push(childItemPath)
        }
        return urlPath
    }

    isMac(): boolean {
        let bool = false
        if (
            navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
            navigator.platform.toUpperCase().indexOf('IPAD') >= 0
        ) {
            bool = true
        }
        return bool
    }

    switchLanguage() {
        this.languageService.toggleUserLanguage()
        this.languageToDisplay = this.languageService.getTranslateToggleLanguage()
    }
}
