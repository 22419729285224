import { Component, OnInit, Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ServerSearchTableContainer, SearchDialogResult } from '../models/searchTableContainer'
import { ServerPaginationListComponent } from '@app/models/serverPaginationComponent'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { PaginationModel } from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { NotifyService } from '@app/services/notify.service'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'

@Component({
    selector: 'app-server-search-table',
    templateUrl: './server-search-table.component.html',
    styleUrls: ['./server-search-table.component.scss'],
})
export class ServerSearchTableComponent<IdType, ModelType, QueryType>
    extends ServerPaginationListComponent<IdType, ModelType, QueryType>
    implements OnInit {
    private _hasTranslatedColumns: boolean
    private _result: SearchDialogResult<ModelType>

    displayedColumns: string[]
    PagingDisplayModeTypes = PagingDisplayMode

    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: ServerSearchTableContainer<ModelType, QueryType>,
        private languageService: LanguageService,
        public dialogRef: MatDialogRef<ServerSearchTableComponent<IdType, ModelType, QueryType>>,
        confirmDialogService: ConfirmDialogService,
        notifyService: NotifyService
    ) {
        super(null, settings.entityName, confirmDialogService, notifyService)

        this._result = new SearchDialogResult<ModelType>()

        this.displayedColumns = this.settings.displayProperties.map((displayProperty) =>
            displayProperty.isInTranslation
                ? `selectedTranslation.${displayProperty.propertyName}`
                : displayProperty.propertyName
        )
        this.displayedColumns.push('action')

        if (settings.displayProperties.findIndex((displayProperty) => displayProperty.isInTranslation) > 0) {
            this._hasTranslatedColumns = true
        }

        this.query = settings.query
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel())
    }

    loadDataSource(): PagingDataSource<ModelType, QueryType> {
        return this.settings.server
    }

    loadEntities(paginationModel: PaginationModel) {
        const query: any = Object.assign(this.query, paginationModel)

        if (this._hasTranslatedColumns) {
            query.requestedCulture = this.languageService.userSelectedLanguage.culture
        }

        this.dataSource.loadEntities(query, this.settings.mappingFunc, (p) => {
            if (this.settings.excludeList) {
                if (this.settings.excludeList.findIndex((id) => id === p.id) === -1) {
                    return p
                }
            } else {
                return p
            }
        })
    }

    deleteEntity(): Observable<void> {
        throw new Error('Method not implemented.')
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.load()
        })
    }

    select(entity: any) {
        this._result.isPicked = true
        this._result.selectedEntities.push(entity)
        if (this.settings.closeOnSelect) {
            this.dialogRef.close(this._result)
        }
    }

    edit(entity: any) {
        this._result.isEditClicked = true
        this._result.entityToEdit = entity
        this.dialogRef.close(this._result)
    }

    addAndCloseDialog() {
        this._result.isAddClicked = true
        this.dialogRef.close(this._result)
    }

    done() {
        this.dialogRef.close(this._result)
    }
}
