import { BaseTranslatableModel } from '../template/BaseTranslatableModel'
import { PackageSelectionTranslation, PackageSelectionDto, PackageSelectionGroupDto } from '@app/services/api.services'
import { AppliedPackageSelectionModel } from './appliedPackageModel'
import { ImageModel } from '@app/models/common/ImageModel'
import { SelectionItemModel } from '@app/models/template/SelectionGroupModel'

export class PackageSelectionModel extends BaseTranslatableModel<PackageSelectionTranslation> {
    private _useSelectionItemName: boolean
    private _isSelected: boolean | null
    private _isDefaultReplaced: boolean | null
    id?: string | null

    canReplaceWithOtherSelection: boolean
    hasToSelect: boolean
    provideDisplayImage: boolean
    displayImage: ImageModel
    selectionItem: SelectionItemModel
    replacedSelectionItem: SelectionItemModel
    selectionGroup: PackageSelectionGroupDto

    static createFromDto(packageSelectionDto: PackageSelectionDto): PackageSelectionModel {
        const packageSelectionModel = new PackageSelectionModel(
            packageSelectionDto.id,
            packageSelectionDto.useSelectionItemName,
            packageSelectionDto.canReplaceWithOtherSelection,
            packageSelectionDto.hasToSelect,
            packageSelectionDto.translations,
            SelectionItemModel.createFromDto(packageSelectionDto.selectionItem, 0),
            packageSelectionDto.selectionGroup,
            packageSelectionDto.isSelected,
            packageSelectionDto.isDefaultReplaced,
            packageSelectionDto.replacedSelectionItem != undefined
                ? SelectionItemModel.createFromDto(packageSelectionDto.replacedSelectionItem, 0)
                : undefined
        )
        packageSelectionModel.provideDisplayImage = packageSelectionDto.provideDisplayImage
        packageSelectionModel.displayImage = packageSelectionDto.displayImage
            ? ImageModel.createFromDto(packageSelectionDto.displayImage)
            : ImageModel.placeholderImage

        return packageSelectionModel
    }

    constructor(
        id: string,
        useSelectionItemName: boolean,
        canReplaceWithOtherSelection: boolean,
        hasToSelect: boolean,
        translations: PackageSelectionTranslation[],
        selectionItem: SelectionItemModel,
        selectionGroup: PackageSelectionGroupDto,
        isSelected = false,
        isDefaultReplaced = false,
        replacedSelectionItem: SelectionItemModel = undefined
    ) {
        super(useSelectionItemName ? [] : translations)

        this.id = id
        this._useSelectionItemName = useSelectionItemName
        this._isSelected = isSelected
        this._isDefaultReplaced = isDefaultReplaced
        this.canReplaceWithOtherSelection = canReplaceWithOtherSelection
        this.hasToSelect = hasToSelect
        this.displayImage = ImageModel.placeholderImage
        this.selectionGroup = selectionGroup
        this.selectionItem = selectionItem
        this.replacedSelectionItem = replacedSelectionItem
    }

    get isSelected(): boolean {
        return this._isSelected
    }

    get isDefaultReplaced(): boolean {
        return this._isDefaultReplaced
    }

    get useSelectionItemName(): boolean {
        return this._useSelectionItemName
    }

    set useSelectionItemName(value: boolean) {
        this._useSelectionItemName = value

        if (value) {
            this.translations = []
        } else {
            this._setupTranslations()
        }
    }

    get imageUrl(): string {
        return null
    }

    get price(): number {
        return this.selectionItem.totalPrice
    }

    createAppliedPackage(): AppliedPackageSelectionModel {
        const appliedPackage = new AppliedPackageSelectionModel()
        appliedPackage.packageSelection = this
        appliedPackage.defaultSelectionItem = this.selectionItem
        appliedPackage.replacedSelectionItem = this.replacedSelectionItem
        appliedPackage.isSelected = this.isSelected
        return appliedPackage
    }

    createDto(sortPriority: number): PackageSelectionDto {
        const packageSelectionDto = new PackageSelectionDto()

        packageSelectionDto.id = this.id
        packageSelectionDto.useSelectionItemName = this.useSelectionItemName
        packageSelectionDto.canReplaceWithOtherSelection = this.canReplaceWithOtherSelection
        packageSelectionDto.hasToSelect = this.hasToSelect
        packageSelectionDto.provideDisplayImage = this.provideDisplayImage
        packageSelectionDto.displayImage = this.displayImage ? this.displayImage.createDto() : null
        packageSelectionDto.selectionItem = this.selectionItem.createDto()
        packageSelectionDto.translations = this.translations

        packageSelectionDto.selectionGroup = this.selectionGroup
        packageSelectionDto.sortPriority = sortPriority

        return packageSelectionDto
    }

    get title(): string {
        if (this.useSelectionItemName) {
            return this.selectionItem.getName()
        }
        return this.selectedTranslation ? this.selectedTranslation.name : ''
    }

    private _setupTranslations() {
        if (this.translations === undefined || this.translations === null || this.translations.length === 0) {
            this.initTranslations(this.translations, () => {
                const translation = new PackageSelectionTranslation()
                translation.name = ''
                return translation
            })
        }
    }
}
