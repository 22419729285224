import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { CustomPropertyAllowedValueFormDialogComponent } from './custom-property-allowed-value-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class CustomPropertyAllowedValueFormDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(propertyDefinitionId: string) {
        const dialogRef = this.dialogRef.open(CustomPropertyAllowedValueFormDialogComponent, {
            width: '800px',
            data: {
                propertyDefinitionId,
            },
        })

        return dialogRef.afterClosed()
    }
}
