import { TaxRateDto } from '@app/services/api.services'

export class TaxRateModel {
    private _id: string
    private _name: string
    private _gst: number
    private _pst: number

    constructor(taxRateDto: TaxRateDto) {
        this._id = taxRateDto.id
        this._name = taxRateDto.name
        this._gst = taxRateDto.gst
        this._pst = taxRateDto.pst
    }
    get id(): string {
        return this._id
    }
    get name(): string {
        return this._name
    }
    get gst(): number {
        return this._gst
    }
    get pst(): number {
        return this._pst
    }
    get totalTax(): number {
        return this._gst + this._pst
    }

    createDto(): TaxRateDto {
        const taxRateDto = new TaxRateDto()
        taxRateDto.id = this._id
        taxRateDto.name = this._name
        taxRateDto.gst = this._gst
        taxRateDto.pst = this._pst
        taxRateDto.totalTaxRate = this.totalTax

        return taxRateDto
    }
}
