import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PackageAssociationConfirmationDialogComponent } from './package-association-confirmation-dialog.component'
import { Observable } from 'rxjs'
import { ConfirmDialogResult } from '@app/shared/confirm-dialog/confirm-dialog.component'
import { TemplatePackageLookupDto } from '@app/services/api.services'

@Injectable({
    providedIn: 'root',
})
export class PackageAssociationConfirmationDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(packageId: string, associatingPackage = true): Observable<TemplatePackageLookupDto> {
        const dialogRef = this.dialog.open(PackageAssociationConfirmationDialogComponent, {
            width: '500px',
            data: {
                packageId,
                associatingPackage,
            },
        })

        return dialogRef.afterClosed()
    }
}
