import { PagingDisplayMode } from './../server-pagination-container/server-paging-container.component'
import { Component, OnInit, Input, Output, ViewChild, EventEmitter, AfterViewInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { UserConfigurationService } from '@app/services/user-configuration.service'

@Component({
    selector: 'app-table-container',
    templateUrl: './table-container.component.html',
    styleUrls: ['./table-container.component.scss'],
})
export class TableContainerComponent<T> implements OnInit {
    PagingDisplayModeTypes = PagingDisplayMode

    _dataSource: MatTableDataSource<T>
    get dataSource(): MatTableDataSource<T> {
        return this._dataSource
    }

    @Input('dataSource')
    set dataSource(value: MatTableDataSource<T>) {
        this._dataSource = value
        this.dataSource.paginator = this.paginator

        if (this.filterPredicate) {
            this.dataSource.filterPredicate = this.filterPredicate
        } else {
            const ref = this
            this.dataSource.filterPredicate = (data: any, filter: string) => {
                const accumulator = (currentTerm, key) => {
                    if (
                        !ref.searchableColumns ||
                        key === 'selectedTranslation' ||
                        ref.searchableColumns.find((column) => column === key)
                    ) {
                        return key === 'selectedTranslation'
                            ? currentTerm + this._getTranslationName(data.selectedTranslation)
                            : currentTerm + data[key]
                    }
                    return currentTerm
                }
                const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase()
                // Transform the filter by converting it to lowercase and removing whitespace.
                const transformedFilter = filter.trim().toLowerCase()
                return dataStr.indexOf(transformedFilter) !== -1
            }
        }

        if (this.matSort) {
            this.dataSource.sort = this.matSort
        }

        if (this.sortingLogic) {
            this.dataSource.sortingDataAccessor = this.sortingLogic
        }
    }

    @Input() entiyName: string
    @Input() entityDisplayName: string
    @Input() isLoaded: boolean
    @Input() isFailed: boolean
    @Input() displayMode: PagingDisplayMode = PagingDisplayMode.Card
    @Input('searchableColumns') searchableColumns: string[]
    @Input() customTitle: string

    @Input() defaultSortColumn: string
    @Input() matSort: MatSort
    @Input() filterPredicate: (data, filterValue: string) => boolean
    @Input() sortingLogic: (item, property) => any
    @Output() retryReload = new EventEmitter()
    @ViewChild(MatPaginator) paginator: MatPaginator
    @ViewChild(MatSort) sort: MatSort
    pageSize = 10

    constructor(private userConfigService: UserConfigurationService) {
        this.pageSize = userConfigService.retrieveConfigValue('defaultPageSize')
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase()

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage()
        }
    }
    ngOnInit() {
        if (!this.entityDisplayName || this.entityDisplayName.length === 0) {
            this.entityDisplayName = this.entiyName
        }
    }

    pageSizeChanged(event) {
        this.userConfigService.setConfigValue('defaultPageSize', event.pageSize)
    }

    private _getTranslationName(translation) {
        if (translation.name) {
            return translation.name
        }
        return translation.productName
    }
}
