<table mat-table multiTemplateDataRows [dataSource]="tasks">
    <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td class="wx-100" mat-cell *matCellDef="let row; let i = dataIndex">{{ i + 1 }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td style="width: 200px" mat-cell *matCellDef="let row">
            <span [class.font-weight-bold]="row.item.hasTimeSubTasks">{{ row.item.name }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let row">{{ row.item.description }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="allocatedHours">
        <th mat-header-cell *matHeaderCellDef>Allocated Hours</th>
        <td class="wx-100" mat-cell *matCellDef="let row">{{ row.item.allocatedHours }}</td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="billOnAllocatedHoursExceeded">
        <th mat-header-cell *matHeaderCellDef>Bill When Exceeded</th>
        <td class="wx-100" mat-cell *matCellDef="let row">
            {{ row.item.billOnAllocatedHoursExceededFormatted }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="totalTimesheetTasksHours">
        <th mat-header-cell *matHeaderCellDef>Timesheet Hours</th>
        <td class="wx-100" mat-cell *matCellDef="let row">
            {{ row.item.totalTimesheetTasksHours }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="totalTimesheetTasksBillableHours">
        <th mat-header-cell *matHeaderCellDef>Billable Hours</th>
        <td class="wx-100" mat-cell *matCellDef="let row">
            {{ row.item.totalTimesheetTasksBillableHours }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="totalTimesheetTasksBillableAmount">
        <th mat-header-cell *matHeaderCellDef>Billable Amount</th>
        <td class="wx-100" mat-cell *matCellDef="let row">
            {{ row.item.totalTimesheetTasksBillableAmount | currency }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="hasSubTasks">
        <th mat-header-cell *matHeaderCellDef>Has Sub tasks</th>
        <td class="wx-100" mat-cell *matCellDef="let row">
            {{ row.item.hasTimeSubTasks ? 'Yes' : 'No' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="action-column" align="center">
            {{ 'ACTION' | translate }}
        </th>
        <td class="text-center" mat-cell *matCellDef="let row; let i = dataIndex">
            <div class="d-flex justify-content-center">
                <button mat-icon-button color="success" matTooltip="Show Menu" (click)="showActions(row)">
                    <mat-icon>menu</mat-icon>
                </button>
                <button
                    *ngIf="row.isDeleted"
                    mat-icon-button
                    color="warn"
                    matTooltip="Undo Delete"
                    (click)="undoRemove(row)"
                >
                    <mat-icon>undo</mat-icon>
                </button>

                <button *ngIf="row.item.hasTimeSubTasks" mat-icon-button matTooltip="Expand" (click)="expandRow(row)">
                    <mat-icon *ngIf="expandedTask !== row">expand_more</mat-icon>
                    <mat-icon *ngIf="expandedTask === row">expand_less</mat-icon>
                </button>
            </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <div class="d-flex justify-content-center">
                <button mat-icon-button color="success" matTooltip="Add" (click)="add()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let row" [attr.colspan]="tableColumns.length">
            <div
                *ngIf="!isSubTasks"
                class="task-element-detail row w-100"
                [@detailExpand]="row == expandedTask ? 'expanded' : 'collapsed'"
            >
                <div class="col-12 pt-2">
                    <h4>Subtasks for {{ row.item.name }}</h4>
                </div>

                <div class="col-12 pb-2">
                    <app-task-list
                        *ngIf="row.item.subTasks && row.item.subTasks.length; else noSubTasks"
                        class="w-100"
                        [isSubTasks]="true"
                        [hideFooter]="true"
                        [hideActions]="true"
                        [tasks]="row.item.subTasks"
                    ></app-task-list>

                    <ng-template #noSubTasks>No subtasks have been added, edit the task to add some</ng-template>
                </div>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>

    <tr
        [class]="[element.getRowColor(), 'task-element-row']"
        [class.task-expanded-row]="expandedTask === element"
        mat-row
        *matRowDef="let element; columns: tableColumns; let i = dataIndex"
    ></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="task-detail-row"></tr>

    <ng-container *ngIf="!hideFooter">
        <tr mat-footer-row *matFooterRowDef="tableColumns"></tr>
    </ng-container>
</table>
