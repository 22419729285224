import { Component, Inject, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { DialogResult, DialogResultActions } from '@app/models/dialogResult'
import { UploadMediaFormSettings } from '@app/models/dialogs/uploadMediaFormSettings'
import { FileSelectComponent } from '@app/shared/file-select/file-select.component'

@Component({
    selector: 'app-upload-media-form-dialog',
    templateUrl: './upload-media-form-dialog.component.html',
    styleUrls: ['./upload-media-form-dialog.component.scss'],
})
export class UploadMediaDialogComponent {
    @ViewChild('fileSelect', { static: false }) fileSelectComponent: FileSelectComponent

    isLoading = false
    buttonText = 'Add'

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: UploadMediaFormSettings,
        private dialogRef: MatDialogRef<UploadMediaDialogComponent>
    ) {
        if (settings.isEdit) {
            this.buttonText = 'Edit'
            this.isLoading = false
        }
    }

    onSubmitClick() {
        this.isLoading = true
        this.dialogRef.disableClose = true
        this.fileSelectComponent.setting.isPrivate = this.settings.isPrivate
        this.fileSelectComponent.onSubmitClick().subscribe((medias) => {
            if (medias) {
                if (this.settings.isEdit) {
                    this.dialogRef.close(new DialogResult(DialogResultActions.Edit, medias))
                } else if (medias.length === this.fileSelectComponent.filesToUpload.length) {
                    this.dialogRef.close(new DialogResult(DialogResultActions.Create, medias))
                }
            }
            this.isLoading = false
            this.dialogRef.disableClose = false
        })
    }

    fileSelected(isSelected: boolean) {
        this.isLoading = !isSelected
    }
}
