<ng-container *ngIf="data.modalTitle">
    <h2 mat-dialog-title>{{ data.modalTitle }}</h2>
</ng-container>
<mat-dialog-content>
    <mat-nav-list *ngFor="let modalButton of data.buttons">
        <a mat-list-item (click)="closeModalAndClickButtonModal(modalButton.onClick)">
            <span mat-line>{{ modalButton.displayName | translate }}</span>
        </a>
    </mat-nav-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button color="warn" [mat-dialog-close]>
        {{ 'CANCEL' | translate }}
    </button>
</mat-dialog-actions>
