import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MediaUploadService } from '@app/services/media-upload.service'
import { NotifyService } from '@app/services/notify.service'
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'

export class UploadFileDialogSettings {
    url: string

    constructor(url: string) {
        this.url = url
    }
}

@Component({
    selector: 'app-upload-file-dialog',
    templateUrl: './upload-file-dialog.component.html',
    styleUrls: ['./upload-file-dialog.component.scss'],
})
export class UploadFileDialogComponent implements OnInit {
    @ViewChild('file') file
    isLoading = false
    selectedFile: File

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UploadFileDialogSettings,
        public dialogRef: MatDialogRef<UploadFileDialogComponent>,
        private mediaUploadService: MediaUploadService,
        private notifyService: NotifyService
    ) {
        this.dialogRef.disableClose = true
    }

    ngOnInit(): void {
        this.isLoading = true
    }

    selectFile() {
        this.file.nativeElement.click()
    }

    dropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.notifyService.fail('Can only upload one file at a time.')
            return
        }

        if (files.length === 1 && !files[0].fileEntry.isFile) {
            const fileEntry = files[0].fileEntry as FileSystemDirectoryEntry
            this.notifyService.fail(`${fileEntry.name} is not a file`)
        }

        const fileSystemFile = files.filter((f) => f.fileEntry.isFile).map((f) => f.fileEntry as FileSystemFileEntry)[0]
        fileSystemFile.file((file) => this._setFileReadyForUpload(file))
    }

    onFilesAdded() {
        const files: FileList = this.file.nativeElement.files
        if (files.length > 1) {
            this.notifyService.fail('Can only upload one file at a time.')
            return
        }

        this._setFileReadyForUpload(files[0])
    }

    private _setFileReadyForUpload(file: File) {
        this.selectedFile = file
        this.isLoading = false
    }

    onSubmit() {
        this.isLoading = true

        this.mediaUploadService.uploadFileToUrl(this.selectedFile, this.data.url).subscribe(
            (result) => {
                if (!result) {
                    this.dialogRef.close(false)
                } else if (result.isComplete) {
                    this.dialogRef.close(true)
                }
            },
            (error) => {
                console.log(error)
                this.dialogRef.close(false)
            }
        )
    }
}
