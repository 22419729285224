import { NgModule } from '@angular/core'
import { ProductListComponent } from './product-list.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'
import { ImagesModule } from '@app/shared/images/images.module'

@NgModule({
    declarations: [ProductListComponent],
    imports: [CommonFormsModule, ServerPagingContainerModule, ImagesModule],
    exports: [ProductListComponent],
    entryComponents: [ProductListComponent],
})
export class ProductListModule {}
