import { NgModule } from '@angular/core'
import { SimpleEntityCreateFormDialogComponent } from './simple-entity-create-form-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { InputsModule } from '../inputs/inputs.module'

@NgModule({
    imports: [CommonFormsModule, InputsModule],
    exports: [SimpleEntityCreateFormDialogComponent],
    declarations: [SimpleEntityCreateFormDialogComponent],
    entryComponents: [SimpleEntityCreateFormDialogComponent],
})
export class SimpleEntityCreateFormDialogModule {}
