<h2 mat-dialog-title>{{ isEdit ? 'Edit Task' : 'Add Task' }}</h2>
<mat-dialog-content *ngIf="formGroup">
    <div class="row" [formGroup]="formGroup">
        <div class="col-12">
            <mat-form-field>
                <input type="text" matInput placeholder="Name" formControlName="name" />
                <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'name')">
                    Required task template name
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5"
                    placeholder="Description"
                    formControlName="description"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="col-12" *ngIf="this.data.settings.allowSubTasks">
            <mat-checkbox formControlName="hasTimeSubTasks">Has subtasks (Can't be changed afterwards)</mat-checkbox>
        </div>

        <ng-container *ngIf="!formGroup.get('hasTimeSubTasks').value">
            <div class="col-4">
                <app-quantity
                    [group]="formGroup"
                    placeholder="Allocated Hours"
                    controlName="allocatedHours"
                    [allowDecimal]="false"
                ></app-quantity>
            </div>
            <div class="col-8">
                <mat-checkbox formControlName="billOnAllocatedHoursExceeded"
                    >Bill On Allocated Hours Exceeded</mat-checkbox
                >
            </div>
        </ng-container>

        <div class="col-12" *ngIf="formGroup.get('hasTimeSubTasks').value">
            <app-task-list
                (onTasksChanges)="updatedSubTasks($event)"
                [isSubTasks]="true"
                [tasks]="model.subTasks"
            ></app-task-list>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" (click)="dialogRef.close()">Cancel</button>
        <button type="button" mat-stroked-button (click)="addOrEdit()">{{ isEdit ? 'Done' : 'Add' }}</button>
    </div>
</mat-dialog-actions>
