import { TemplateCategoryModel } from './TemplateCategoryModel'
import { TemplateRoomModel } from './TemplateRoomModel'

export class BaseSectionModel {
    templateRoom?: TemplateRoomModel | null
    templateCategory?: TemplateCategoryModel | null

    get title(): string {
        if (this.templateRoom) {
            return this.templateRoom.selectedTranslation.name
        }

        if (this.templateCategory) {
            return this.templateCategory.selectedTranslation.name
        }
        return ''
    }

    get description(): string {
        if (this.templateRoom) {
            return this.templateRoom.selectedTranslation.description
        }
        return ''
    }
}

export class SectionModel extends BaseSectionModel {}
