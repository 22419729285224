<div class="row">
    <div class="col-12">
        <input type="file" [multiple]="canSelectMultipleFiles" class="d-none" (change)="onFilesAdded()" #file />
        <div class="center" *ngIf="filesToUpload.length === 0 && !isEdit">
            <ngx-file-drop
                (onFileDrop)="dropped($event)"
                (onFileOver)="fileOver($event)"
                (onFileLeave)="fileLeave($event)"
            >
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <a href="javascript:void(0);" (click)="selectFile()">
                        Drop files here or click here to browse for a file
                    </a>
                </ng-template>
            </ngx-file-drop>
        </div>
    </div>
    <form *ngIf="formGroup" [formGroup]="formGroup" class="w-100">
        <ng-container *ngIf="filesToUpload || isEdit">
            <div class="col-12 mt-2" *ngIf="multipleFilesSelected">Uploading files {{ filesToUploadNames }}</div>
            <div class="col-12">
                <mat-form-field>
                    <mat-select placeholder="Project" formControlName="projectId">
                        <mat-option *ngFor="let project of projects" [value]="project.id">
                            {{ project.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12">
                <mat-form-field>
                    <mat-select placeholder="Language" formControlName="culture">
                        <mat-option *ngFor="let language of languages" [value]="language.culture">
                            {{ language.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12" *ngIf="!multipleFilesSelected">
                <mat-form-field>
                    <input matInput placeholder="Filename" formControlName="filename" />
                </mat-form-field>
            </div>

            <div class="col-12" *ngIf="!multipleFilesSelected">
                <mat-form-field>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="2"
                        formControlName="internalDescription"
                        placeholder="Display Name"
                        cdkAutosizeMaxRows="5"
                    ></textarea>
                </mat-form-field>
            </div>

            <div class="col-12">
                <app-label-group-drop-down
                    [labelGroupType]="LabelGroupType.ImageType"
                    [isDisabled]="setting.lockLabelSelect"
                    [defaultLabelIds]="setting.defaultLabelIds"
                    [isMultiSelect]="true"
                    [placeholder]="'Label'"
                    [(selectedLabels)]="selectedLabels"
                >
                </app-label-group-drop-down>
            </div>
            <ng-container *ngIf="!multipleFilesSelected">
                <div class="col-6">
                    <mat-checkbox formControlName="hasCaptions">Has Captions </mat-checkbox>
                </div>
                <div class="col-6">
                    <app-multi-language
                        [translations]="formGroup.get('translations')"
                        [controlName]="'caption'"
                        [placeholder]="'Caption'"
                        [useGlobalLanguageSelect]="false"
                    >
                    </app-multi-language>
                </div>
            </ng-container>
        </ng-container>
        <div class="col-12 d-flex justify-content-center" *ngIf="!multipleFilesSelected">
            <img *ngIf="mediaToUploadPlaceholder" [src]="mediaToUploadPlaceholder" class="display-image" />
            <div *ngIf="(filesToUpload[0] || isEdit) && !mediaToUploadPlaceholder" class="text-center">
                <i class="fa fa-file fa-10x"></i>
            </div>
        </div>

        <div class="col-12 justify-content-center d-flex" *ngIf="!multipleFilesSelected && filesToUpload[0]">
            <div class="text-center">
                {{ filesToUpload[0].size / 1024 | number: '1.0-0' }} (KB)
                {{ image ? '- ' + image.width + ' x ' + image.height : '' }}
            </div>
        </div>

        <div
            class="col-12 justify-content-center d-flex"
            *ngIf="mediaToUploadPlaceholder && !isEdit && !multipleFilesSelected"
        >
            <button mat-raised-button class="btn btn-primary btn-round" (click)="cropImage(filesToUpload[0])">
                Crop
            </button>
        </div>
    </form>
</div>
