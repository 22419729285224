import { Injectable } from '@angular/core'
import {
    MartialStatus,
    ProductAvailabilityType,
    LanguageType,
    PropertyType,
    UnitType,
    TypeOfSupplier,
    NoteType,
    PropertyDirection,
    PropertyAvailability,
    SelectionsDisplayType,
    PaymentType,
    TimesheetTaskType,
    PackageDiscount,
    TemplateType,
    LabelGroupType,
    LeadStatus,
    BuilderClientStatus,
    CalculationOperator,
    LotType,
    SelectionGroupStatus,
    PropertyDefinitionType,
    PropertyDefinitionSubType,
    LeadPropertyStatus,
} from './api.services'

export class EnumWrap {
    constructor(public display: string, public value: string) {}
}

@Injectable()
export class EnumListService {
    public disciplineLabelGroupId = 'b66e0a7f-4380-4fe6-a6c7-ba8103df77ac'
    public roomLabelGroupId = 'c1a2cfb3-b136-49c3-9462-a9fa58cb8b74'
    public productTypeLabelGroupId = 'f7cb9792-2b11-4597-971e-42874715ceb7'

    public productSwatchLabelId = '59d10f9e-1250-4d4b-8f31-7787776761ab'
    public productDisplayLabelId = 'f11496ab-cdfc-4670-8d4e-8135f350bac5'

    public martialStatuses = [
        new EnumWrap('Single', MartialStatus.Single),
        new EnumWrap('Married', MartialStatus.Married),
        new EnumWrap('Divorced', MartialStatus.Divoreced),
        new EnumWrap('Windowed', MartialStatus.Windowed),
        new EnumWrap('Other', MartialStatus.Other),
        new EnumWrap('Prefer not to say', MartialStatus.PreferNotToSay),
    ]

    public languages = [new EnumWrap('English', LanguageType.English), new EnumWrap('French', LanguageType.French)]

    public typeOfSuppliers = [
        new EnumWrap('Both', TypeOfSupplier.Both),
        new EnumWrap('Hardware', TypeOfSupplier.Hardware),
        new EnumWrap('Labour', TypeOfSupplier.Labour),
    ]

    public builderClientStatuses = [
        new EnumWrap('Authorized', BuilderClientStatus.Authorized),
        new EnumWrap('Inactive', BuilderClientStatus.Inactive),
        new EnumWrap('Prospect', BuilderClientStatus.Prospect),
        new EnumWrap('Purchased', BuilderClientStatus.Purchased),
    ]

    public propertyTypes = [
        new EnumWrap('Commercial', PropertyType.Commercial),
        new EnumWrap('Condo', PropertyType.Condo),
        new EnumWrap('House', PropertyType.House),
        new EnumWrap('Town House', PropertyType.TownHouse),
        new EnumWrap('Office', PropertyType.Office),
    ]

    public lotTypes = [
        new EnumWrap('Owned By Buyer', LotType.OwnedByBuyer),
        new EnumWrap('Owned By Builder', LotType.OwnedByBuilder),
    ]

    public unitType = [
        new EnumWrap('Box', UnitType.Box),
        new EnumWrap('Cubic Yard', UnitType.CuYd),
        new EnumWrap('Days', UnitType.Days),
        new EnumWrap('Each', UnitType.Each),
        new EnumWrap('Gallons', UnitType.Gal),
        new EnumWrap('Hours', UnitType.Hours),
        new EnumWrap('Pounds', UnitType.Lbs),
        new EnumWrap('Linear Feet', UnitType.Lf),
        new EnumWrap('Months', UnitType.Months),
        new EnumWrap('Square Feet', UnitType.Sf),
        new EnumWrap('Sheet', UnitType.Sheet),
        new EnumWrap('Square', UnitType.Square),
    ]

    public calculationOperators = [
        new EnumWrap('Divide', CalculationOperator.Divide),
        new EnumWrap('Multiply', CalculationOperator.Multiply),
    ]

    public productAvailabilityType = [
        new EnumWrap('Standalone', ProductAvailabilityType.Standalone),
        new EnumWrap('Accessory', ProductAvailabilityType.Accessory),
        new EnumWrap('Color', ProductAvailabilityType.Color),
        new EnumWrap('Format', ProductAvailabilityType.Format),
        new EnumWrap('Finish', ProductAvailabilityType.Finish),
        new EnumWrap('All', ProductAvailabilityType.All),
    ]

    public clientLeadStatuses = [
        new EnumWrap('Open', LeadStatus.Open),
        new EnumWrap('Closed', LeadStatus.Closed),
        new EnumWrap('Not Interested', LeadStatus.NotInterested),
    ]

    public noteTypes = [
        new EnumWrap('Comment', NoteType.Comment),
        new EnumWrap('Follow Up', NoteType.FollowUp),
        new EnumWrap('Appointment', NoteType.Appointment),
        new EnumWrap('Other', NoteType.Other),
        new EnumWrap('Inquiry', NoteType.Inquiry),
        new EnumWrap('Left Message', NoteType.LeftMessage),
        new EnumWrap('No Answer', NoteType.NoAnswer),
        new EnumWrap('Made Contact', NoteType.MadeContact),
        new EnumWrap('Call', NoteType.Call),
        new EnumWrap('Document Upload', NoteType.DocumentUpload),
        //new EnumWrap('Thank You For Visiting', NoteType.ThankYouForVisiting)
    ]

    public propertyAvailabilityTypes = [
        new EnumWrap('Available', PropertyAvailability.Available),
        new EnumWrap('Reserved', PropertyAvailability.Reserved),
        new EnumWrap('Sold', PropertyAvailability.Sold),
    ]

    public propertyDirectionTypes = [
        new EnumWrap('North', PropertyDirection.North),
        new EnumWrap('East', PropertyDirection.East),
        new EnumWrap('South', PropertyDirection.South),
        new EnumWrap('West', PropertyDirection.West),
        new EnumWrap('North-East', PropertyDirection.NorthEast),
        new EnumWrap('North-West', PropertyDirection.NorthWest),
        new EnumWrap('South-East', PropertyDirection.SouthEast),
        new EnumWrap('South-West', PropertyDirection.SouthWest),
    ]

    public selectionsDisplayTypes = [
        new EnumWrap('Show selections as swatches', SelectionsDisplayType.DisplaySelectionAsSwatches),
        new EnumWrap('Show selections as image and text', SelectionsDisplayType.DisplaySelectionsAsImageAndText),
        new EnumWrap('Show selections with just text', SelectionsDisplayType.DisplaySelectionsInList),
    ]

    public paymentTypes = [
        new EnumWrap('Cash', PaymentType.Cash),
        new EnumWrap('Cheque', PaymentType.Cheque),
        new EnumWrap('Credit', PaymentType.Credit),
        new EnumWrap('Wire', PaymentType.Wire),
    ]

    public timeSheetTaskTypes = [
        new EnumWrap('Regular', TimesheetTaskType.Regular),
        new EnumWrap('Unpaid Break', TimesheetTaskType.UnpaidBreak),
        new EnumWrap('Paid Break', TimesheetTaskType.PaidBreak),
        new EnumWrap('Paid Vacation', TimesheetTaskType.PaidVacation),
        new EnumWrap('Non-Paid Vacation', TimesheetTaskType.NonPaidVacation),
        new EnumWrap('Paid Sick Day', TimesheetTaskType.PaidSickDay),
        new EnumWrap('Non-Paid Sick Day', TimesheetTaskType.NonPaidSickDay),
        new EnumWrap('Statutory Holiday', TimesheetTaskType.StatutoryHoliday),
    ]

    public packageDiscountTypes = [
        new EnumWrap('None', PackageDiscount.None),
        new EnumWrap('Fixed', PackageDiscount.Fixed),
    ]

    public templateTypes = [
        new EnumWrap('Master', TemplateType.Master),
        new EnumWrap('Model', TemplateType.Model),
        new EnumWrap('Property', TemplateType.Property),
    ]

    public selectionGroupStatusTypes = [
        new EnumWrap('Draft', SelectionGroupStatus.Draft),
        new EnumWrap('Published', SelectionGroupStatus.Published),
    ]

    public labelGroupTypes = [
        new EnumWrap('Selection Item', LabelGroupType.SelectionItem),
        new EnumWrap('Product Variation Colors', LabelGroupType.ProductVariationColor),
        new EnumWrap('Product Variation Finish', LabelGroupType.ProductVariationFinish),
        new EnumWrap('Product Variation Size', LabelGroupType.ProductVariationSize),
        new EnumWrap('Custom', LabelGroupType.Custom),
        new EnumWrap('Image Type', LabelGroupType.ImageType),
        new EnumWrap('Product Discipline', LabelGroupType.ProductDiscipline),
        new EnumWrap('Product Room', LabelGroupType.ProductRoom),
        new EnumWrap('Product Type', LabelGroupType.ProductType),
    ]


    public propertyDefinitionTypes = [
        new EnumWrap('Text', PropertyDefinitionType.Text),
        new EnumWrap('Numerical', PropertyDefinitionType.Numeric),
        new EnumWrap('Categorical', PropertyDefinitionType.Categorical),
    ]

    public propertyDefinitionSubtypes = [
        new EnumWrap('Single Value', PropertyDefinitionSubType.CategoricalSingleValue),
        new EnumWrap('Currency', PropertyDefinitionSubType.NumericCurrency),
        new EnumWrap('Length', PropertyDefinitionSubType.NumericMeasurementLength),
        new EnumWrap('Area', PropertyDefinitionSubType.NumericMeasurementArea),
        new EnumWrap('Cubic', PropertyDefinitionSubType.NumericMeasurementCubic),
        new EnumWrap('Volume', PropertyDefinitionSubType.NumericMeasurementVolume),
        new EnumWrap('Mass', PropertyDefinitionSubType.NumericMeasurementMass),
        new EnumWrap('Unspecified', PropertyDefinitionSubType.NumericUnspecified),
        new EnumWrap('Translation', PropertyDefinitionSubType.TextTranslation),
        new EnumWrap('Regular', PropertyDefinitionSubType.TextRegular),
    ]



    public leadPropertyStatusTypes = [
        new EnumWrap('Inactive', LeadPropertyStatus.Inactive),
        new EnumWrap('Prospect', LeadPropertyStatus.Prospect),
        new EnumWrap('Purchased', LeadPropertyStatus.Purchased),
        new EnumWrap('Reserved', LeadPropertyStatus.Reserved),
        new EnumWrap('Wait list', LeadPropertyStatus.WaitList),
    ]

    findDisplayName(enumList: EnumWrap[], value): string {
        return enumList.find((enumWrap) => enumWrap.value.toUpperCase() === value.toUpperCase()).display
    }

    constructor() {}
}
