<h2 mat-dialog-title>Update selection groups using this takeoff</h2>
<mat-dialog-content>
    <mat-selection-list #selectionList>
        <mat-list-option
            *ngFor="let option of selectionGroupOptions"
            [value]="option.selectionGroup.id"
            [(selected)]="option.selected"
        >
            <h3 matLine>{{ option.selectionGroup.name }}</h3>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Cancel</button>
        <button class="mr-2" mat-stroked-button (click)="finishSelection()">Done</button>
    </div>
</mat-dialog-actions>
