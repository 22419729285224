import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { Language } from '../models/language'
import { ITranslation } from './api.services'
import { TranslateService } from '@ngx-translate/core'
import { UserConfigurationService } from './user-configuration.service'

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    languageObservable: ReplaySubject<Language> = new ReplaySubject<Language>()
    formSelectedLanguage: Language

    supportedLanguages = [new Language('en-CA', 'English', 'EN'), new Language('fr-CA', 'French', 'FR')]
    userSelectedLanguage = this.supportedLanguages[0]

    constructor(
        private translateService: TranslateService,
        private userConfigurationService: UserConfigurationService
    ) {
        this.formSelectedLanguage = this.supportedLanguages[0]

        const culture = this.userConfigurationService.retrieveConfigValuewithDefault(
            'userSelectedLanguage',
            this.supportedLanguages[0].culture
        )
        this.userSelectedLanguage = this.supportedLanguages.find(
            (supportedLanguage) => supportedLanguage.culture === culture
        )

        setTimeout(() => {
            this.translateService.use(this.userSelectedLanguage.abvreiation.toLowerCase())
        }, 500)
    }

    changeLanguage(language: Language) {
        this.formSelectedLanguage = language
        this.languageObservable.next(language)
    }

    toggleFormLanguage() {
        if (this.supportedLanguages[0].culture === this.formSelectedLanguage.culture) {
            this.changeLanguage(this.supportedLanguages[1])
        } else {
            this.changeLanguage(this.supportedLanguages[0])
        }
    }

    toggleUserLanguage() {
        if (this.supportedLanguages[0].culture === this.userSelectedLanguage.culture) {
            this.userSelectedLanguage = this.supportedLanguages[1]
            this.translateService.use('fr')
        } else {
            this.userSelectedLanguage = this.supportedLanguages[0]
            this.translateService.use('en')
        }
        this.userConfigurationService.setConfigValue('userSelectedLanguage', this.userSelectedLanguage.culture)
    }

    getTranslateToggleLanguage() {
        return this.userSelectedLanguage.culture === 'en-CA' ? 'Français' : 'English'
    }

    lookByCulture(culture: string): Language {
        return this.supportedLanguages.find((language) => language.culture === culture)
    }

    initTranslations<T>(translations: ITranslation[], createTran: () => ITranslation) {
        for (let index = 0; index < this.supportedLanguages.length; index++) {
            const translationToUse = this.supportedLanguages[index]
            const translation = createTran()
            translation.culture = translationToUse.culture
            translations.push(translation)
        }
    }

    selectAnyTranslations(item: any) {
        if (!item || item === null) {
            return
        }

        if (item.constructor === Array) {
            for (const arrItem of item) {
                
                this.selectAnyTranslations(arrItem)
            }
        } else {
            for (const property in item) {
                if (property === 'translations') {
                    item.selectedTranslation = item.translations.find((translation) => translation.culture === 'fr-CA')
                } else if (typeof item[property] === 'object') {
                    this.selectAnyTranslations(item[property])
                }
            }
        }
    }
}
