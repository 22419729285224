import { Component, OnInit, AfterViewInit, Output, EventEmitter, HostListener, Input } from '@angular/core'
import { FixedFormControlsService, ControlMode } from './fixed-form-controls.service'
import { Router } from '@angular/router'
import { Language } from '@app/models/language'

@Component({
    selector: 'app-fixed-form-controls',
    templateUrl: './fixed-form-controls.component.html',
    styleUrls: ['./fixed-form-controls.component.scss'],
})
export class FixedFormControlsComponent implements OnInit, AfterViewInit {
    @Input() hide = true

    fixedPosition = 5
    isButtonDisabled = false

    showLanguageButtons = false
    selectLanguage: Language

    @HostListener('window:keydown', ['$event'])
    hotkeyChangeLanguage($event) {
        if ($event.altKey && $event.code === 'KeyT') {
            this.fixedFormControlsService.languageService.toggleFormLanguage()
        } else if ($event.altKey && $event.code === 'KeyS') {
            //this.addClick();
        }
    }

    constructor(public fixedFormControlsService: FixedFormControlsService, router: Router) {
        // router.events.subscribe(() => {
        //   this.calculateFixedPosition();
        // });

        this.selectLanguage = fixedFormControlsService.languageService.supportedLanguages[0]

        fixedFormControlsService.languageService.languageObservable.subscribe((language) => {
            this.selectLanguage = language
        })

        fixedFormControlsService.showLanguageButtonObservable.subscribe((value) => {
            this.showLanguageButtons = value
        })
    }

    ngOnInit() {
        this.calculateFixedPosition()
    }

    ngAfterViewInit() {
        //  setTimeout(this.calculateFixedPosition(), 200);
        this.calculateFixedPosition()
    }

    changeLanguage(language: Language) {
        this.fixedFormControlsService.languageService.changeLanguage(language)
    }

    private calculateFixedPosition() {
        const position = window.innerHeight + window.scrollY + 85 - document.body.scrollHeight
        if (position >= 0) {
            this.fixedPosition = position
        } else if (position >= -10) {
            this.fixedPosition = position + 10
        } else {
            this.fixedPosition = 10
        }
    }
}
