import { NgModule } from '@angular/core'
import { PackageApplierDialogComponent } from './package-applier-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TranslateModule } from '@ngx-translate/core'
import { PackageSelectionCardComponent } from './package-selection-card/package-selection-card.component'
import { ImagesModule } from '../images/images.module'

@NgModule({
    declarations: [PackageApplierDialogComponent, PackageSelectionCardComponent],
    imports: [CommonFormsModule, TranslateModule, ImagesModule],
    exports: [PackageApplierDialogComponent],
    entryComponents: [PackageApplierDialogComponent],
})
export class PackageApplierDialogModule {}
