import { Component, OnInit, Input } from '@angular/core'
import { BaseImageDisplay } from '../BaseImageDisplay'
import { ImageCdnService, ImageRenderSettings } from '../image-cdn.service'
import { basename } from 'path'
import { Observable, of } from 'rxjs'

@Component({
    selector: 'app-image-thumbnail',
    templateUrl: './image-thumbnail.component.html',
    styleUrls: ['./image-thumbnail.component.scss'],
})
export class ImageThumbnailComponent extends BaseImageDisplay implements OnInit {
    constructor(imageCdnService: ImageCdnService) {
        super(imageCdnService)
    }

    getRenderSettings(): Observable<ImageRenderSettings> {
        return of(ImageRenderSettings.getThumbnailSettings())
    }

    ngOnInit() {}
}
