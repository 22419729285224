import { NgModule } from '@angular/core'
import { MaterialModule } from '@app/material.module'
import { CommonModule } from '@angular/common'
import { SelectionGroupSelectionDialogComponent } from './selection-group-selection-dialog.component'
import { SelectionItemDetailsModule } from '../selection-item-details/selection-item-details.module'

@NgModule({
    imports: [CommonModule, MaterialModule, SelectionItemDetailsModule],
    declarations: [SelectionGroupSelectionDialogComponent],
    exports: [SelectionGroupSelectionDialogComponent],
    entryComponents: [SelectionGroupSelectionDialogComponent],
})
export class SelectionGroupSelectionDialogModule {}
