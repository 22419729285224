<h1 mat-dialog-title>Crop Image</h1>
<mat-dialog-content>
    <div class="row">
        <div class="col-12 justify-content-center d-flex">
            <image-cropper
                class="image-cropper"
                #cropper
                [hidden]="isCropped"
                [style.width]="width"
                [imageFileChanged]="setting.imageToCrop"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="aspectRatio"
                [resizeToWidth]="resizeToWdith"
                [format]="setting.imageFormat"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
            <img class="cropped-img" *ngIf="isCropped" [src]="base64" [style.width]="width" />
        </div>
    </div>

    <div class="row mt-2">
        <div class="col-12" align="center">
            <ng-container *ngIf="!isCropped; else cropped">
                <button mat-raised-button class="btn btn-primary btn-round" (click)="isCropped = true">Crop</button>
            </ng-container>
            <ng-template #cropped>
                <button mat-raised-button class="btn btn-primary btn-round" (click)="isCropped = false">Re-Crop</button>
                <button mat-raised-button [mat-dialog-close]="croppedFile" class="btn btn-primary btn-round">
                    Confirm
                </button>
            </ng-template>
        </div>
    </div>
</mat-dialog-content>
