import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
    EligiblePropertyDefinitionDto,
    GetEligiblePropertyDefinitionsForProductVariationGroupQuery,
    ProductVariationGroupsService,
} from 'app/services/api.services'
import { ItemContainer, ItemState } from 'app/models/util/itemContainer'
import { NotifyService } from 'app/services/notify.service'
import { CustomPropertiesListDialogService } from '@app/shared/dialog/custom-properties-list-dialog/custom-properties-list-dialog.service'
import { MultiProductPropertyValueSelectionDialogService } from '@app/shared/dialog/multi-product-property-value-selection-dialog/multi-product-property-value-selection-dialog.service'
import { mergeMap } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

@Component({
    selector: 'app-manage-product-variation-property-dialog',
    templateUrl: './manage-product-variation-property-dialog.component.html',
    styleUrls: ['./manage-product-variation-property-dialog.component.scss'],
})
export class ManageProductVariationPropertyDialogComponent implements OnInit {
    propertyDefinitions: ItemContainer<EligiblePropertyDefinitionDto>[] = []
    isLoading = true
    displayedColumns: string[] = ['propertyLabel', 'numberOfProducts', 'action']

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            productVariationGroup: {
                name: string
                id: string
                numberOfVariants: number
                productVariantProductIds: string[]
                propertyDefinitions: ItemContainer<{
                    id: string
                    labelIdentifier: string
                }>[]
            }
        },
        private productVariationGroupsService: ProductVariationGroupsService,
        private notifyService: NotifyService,
        private dialogRef: MatDialogRef<ManageProductVariationPropertyDialogComponent>,
        private customPropertiesListDialogService: CustomPropertiesListDialogService,
        private multiProductPropertyValueSelectionDialogService: MultiProductPropertyValueSelectionDialogService
    ) {}

    ngOnInit(): void {
        this._loadPropertyDefinitions()
    }

    add(item: ItemContainer<EligiblePropertyDefinitionDto>) {
        item.setAsAdd()
    }

    addPropertyDefinition() {
        this.customPropertiesListDialogService
            .showDialog('Variation Group', [])
            .pipe(
                mergeMap((propertyDefinition) => {
                    if (propertyDefinition) {
                        return this.multiProductPropertyValueSelectionDialogService.showDialog(
                            this.data.productVariationGroup.productVariantProductIds,
                            propertyDefinition
                        )
                    }
                    return EMPTY
                })
            )
            .subscribe((propertyDefinitionSelected) => {
                if (propertyDefinitionSelected) {
                    this._loadPropertyDefinitions(true)
                }
            })
    }

    done() {
        const results = {
            propertyDefinitions: this.propertyDefinitions
                .filter((p) => p.state !== ItemState.NotAdded)
                .map(
                    (propertyDefinition) =>
                        new ItemContainer(
                            {
                                id: propertyDefinition.item.propertyDefinitionId,
                                labelIdentifier: propertyDefinition.item.selectedTranslation.label,
                            },
                            propertyDefinition.state,
                            propertyDefinition.initialState
                        )
                ),
        }
        return this.dialogRef.close(results)
    }

    private _loadPropertyDefinitions(refresh = false) {
        const query = new GetEligiblePropertyDefinitionsForProductVariationGroupQuery()
        query.productVariantProductIds = this.data.productVariationGroup.productVariantProductIds
        query.productVariationGroupId = this.data.productVariationGroup.id
        this.productVariationGroupsService.getEligiblePropertyDefinitionsForProductVariationGroup(query).subscribe(
            (propertyDefinitions) => {
                this.isLoading = false
                this.propertyDefinitions = propertyDefinitions.map((t) => {
                    // check if item exists if it's a refresh so we don't wipe out the selection

                    if (refresh) {
                        const existingItem = this.propertyDefinitions.find(
                            (p) => p.item.propertyDefinitionId === t.propertyDefinitionId
                        )

                        if (existingItem) {
                            return new ItemContainer(t, existingItem.state, existingItem.initialState)
                        }
                    }

                    const existingPropertyDefiniton = this.data.productVariationGroup.propertyDefinitions.find(
                        (p) => p.item.id === t.propertyDefinitionId
                    )

                    return new ItemContainer(
                        t,
                        existingPropertyDefiniton ? existingPropertyDefiniton.state : ItemState.NotAdded
                    )
                })
            },
            (error) => {
                this.isLoading = false
                this.notifyService.fail('Failed to load property definitions')
            }
        )
    }
}
