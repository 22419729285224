import { Injectable } from '@angular/core'
import { Subject, ReplaySubject, Observable } from 'rxjs'
import { Router, ResolveStart } from '@angular/router'
import { LanguageService } from '@app/services/language.service'

export enum ControlMode {
    add,
    edit,
    hide,
}

export class FixedButton {
    private _buttonClickedSubject: Subject<void>
    buttonClickedObservable: Observable<void>
    icon: string
    isDisabled: boolean
    isHidden: boolean
    color: string
    policies: string[]
    sortyPriority = 0
    tooltip: string = null

    constructor(
        icon: string,
        sortPriority: number,
        color: string = 'primary',
        isDisabled: boolean = false,
        isHidden = false
    ) {
        this.icon = icon
        this.isDisabled = isDisabled
        this.isHidden = isHidden
        this.color = color
        this.sortyPriority = sortPriority
        this._buttonClickedSubject = new Subject<void>()
        this.buttonClickedObservable = this._buttonClickedSubject.asObservable()
    }

    disable() {
        this.isDisabled = true
    }

    enable() {
        this.isDisabled = false
    }
    click() {
        this._buttonClickedSubject.next()
    }
}

@Injectable({
    providedIn: 'root',
})
export class FixedFormControlsService {
    showLanguageButtonObservable: ReplaySubject<boolean> = new ReplaySubject<boolean>()
    fixedButtons: FixedButton[] = []

    constructor(router: Router, public languageService: LanguageService) {
        // every component that wishes to use this needs to set it up, this way no components will need to do a tear down
        router.events.subscribe((event) => {
            if (event instanceof ResolveStart) {
                this.showLanguageButtonObservable.next(false)
                this.fixedButtons = []
            }
        })
    }

    getEventClickObservable(): Observable<void> {
        return this.fixedButtons[0].buttonClickedObservable
    }

    setupButton(
        icon: string,
        sortyPriority: number,
        color: string,
        isHidden: boolean = false,
        policies: string[] = []
    ): FixedButton {
        const fixedButton = new FixedButton(icon, sortyPriority, color, false, isHidden)
        fixedButton.policies = policies
        this.fixedButtons.push(fixedButton)
        return fixedButton
    }

    setupSingleAddButton(icon: string = 'add', policies: string[] = []): FixedButton {
        return this._setupFixedButton(icon, 'primary', policies)
    }

    setupSingleEditButton(icon: string = 'save', policies: string[] = []): FixedButton {
        return this._setupFixedButton(icon, 'primary', policies)
    }

    showLanguageButton(value: boolean) {
        this.showLanguageButtonObservable.next(value)
    }

    private _setupFixedButton(icon: string, color: string, policies: string[]): FixedButton {
        const fixedButton = new FixedButton(icon, 999, color)
        fixedButton.policies = policies
        this.fixedButtons.push(fixedButton)
        return fixedButton
    }
}
