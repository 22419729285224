import { MatDialog } from '@angular/material/dialog'
import { FormDialogSettings } from '@app/models/dialogs/formDialogSettings'
import { Observable } from 'rxjs'
import { FormDialogComponent } from './form-dialog.component'

export abstract class BaseFormDialogService {
    constructor(protected dialog: MatDialog) {}

    protected showFormDialog<EntityType, ComponentType>(
        settings: FormDialogSettings<EntityType, ComponentType>
    ): Observable<EntityType> {
        const dialogRef = this.dialog.open(FormDialogComponent, {
            width: settings.width,
            data: settings,
        })

        return dialogRef.afterClosed()
    }

    protected showFormDialogUsingId<EntityType, ComponentType, IdType>(
        settings: FormDialogSettings<EntityType, ComponentType>
    ): Observable<IdType> {
        const dialogRef = this.dialog.open(FormDialogComponent, {
            width: settings.width,
            data: settings,
        })

        return dialogRef.afterClosed()
    }
}
