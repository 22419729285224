import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'
import { TakeOffDto, GetTakeOffsListQuery, PaginatedResult, TakeOffsService } from '@app/services/api.services'
import { TakeOffModel } from '@app/models/template/TakeOffModel'

export class TakeOffsDataSource extends PagingDataSource<TakeOffDto, GetTakeOffsListQuery> {
    constructor(private service: TakeOffsService) {
        super()
    }

    getEntitiesObservable(query: GetTakeOffsListQuery): Observable<PaginatedResult> {
        return this.service.takeOffs(query)
    }
}

export class TakeOffModelDataSource extends PagingDataSource<TakeOffModel, GetTakeOffsListQuery> {
    constructor(private service: TakeOffsService) {
        super()
    }

    getEntitiesObservable(query: GetTakeOffsListQuery): Observable<PaginatedResult> {
        return this.service.takeOffs(query)
    }
}
