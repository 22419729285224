import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import {
    CreateServiceSupplierPriceVersionCommand,
    ProjectDto,
    ServiceRatePricesService,
    ServiceSupplierPriceVersionDto,
    SupplierPriceDto,
    SupplierPricesDto,
    SupplierProjectPriceDto,
    UnitType,
} from '@app/services/api.services'
import { NotifyService } from '@app/services/notify.service'
import { CostLineType } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { ServiceSupplierPriceVersionModel } from '@app/models/services/ServiceSupplierPriceVersionModel'
import { mergeMap } from 'rxjs/operators'
import { ProjectPickerService, SupplierPickerService } from '@app/services/entity-selections'

@Component({
    selector: 'app-service-rate-supplier-price-form-dialog',
    templateUrl: './service-rate-supplier-price-form-dialog.component.html',
    styleUrls: ['./service-rate-supplier-price-form-dialog.component.scss'],
})
export class ServiceSupplierPriceFormDialogComponent implements OnInit {
    private _selectedProject: SupplierProjectPriceDto
    private _selectedSupplier: ServiceSupplierPriceVersionDto
    private _selectedPriceVersion: SupplierPriceDto
    private _loadedPrices: ServiceSupplierPriceVersionModel[] = []
    private _latestVersionToRevertTo: SupplierPriceDto
    private _isForProjectOnly: boolean
    costLineType = CostLineType
    projects: ProjectDto[]

    get selectedProject(): SupplierProjectPriceDto {
        return this._selectedProject
    }

    set selectedProject(value: SupplierProjectPriceDto) {
        this._selectedProject = value
        this.supplierPriceVersions = value.prices
        this.selectedPriceVersion = value.prices.find((s) => s.isLatestVersion)
    }

    get selectedSupplier(): SupplierPricesDto {
        return this._selectedSupplier
    }

    set selectedSupplier(value: SupplierPricesDto) {
        this._selectedSupplier = value
        this.supplierPriceVersions = value.prices
        this.selectedPriceVersion = value.prices.find((s) => s.isLatestVersion)
    }

    get selectedPriceVersion(): SupplierPriceDto {
        return this._selectedPriceVersion
    }

    set selectedPriceVersion(value: SupplierPriceDto) {
        this._selectedPriceVersion = value
        this._loadServicePrice()
    }

    set isForProjectOnly(value: boolean) {
        this._isForProjectOnly = value
        if (this.selectedSupplier.projectPrices.length > 0)
            this.selectedProject = this.selectedSupplier.projectPrices[0]
    }

    get isForProjectOnly(): boolean {
        return this._isForProjectOnly
    }

    get selectedPriceHasProjectPrices(): boolean {
        return (
            this.selectedSupplier &&
            this.selectedSupplier.projectPrices &&
            this.selectedSupplier.projectPrices.length > 0
        )
    }

    supplierPrices: SupplierPricesDto[] = []
    selectedLoadedPriceVersion: ServiceSupplierPriceVersionModel
    supplierPriceVersions: SupplierPriceDto[] = []
    isLoading = true
    isSavingNewlyCreatingPrice = false
    isCreatingNewPrice = false

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            createNewPrice: boolean
            serviceRateId: string
            selectedProjectId: number | null
            selectedSupplier: { id: string; name: string }
            selectedPriceId?: string | null
        },
        private notifyService: NotifyService,
        private servicePricesService: ServiceRatePricesService,
        private projectPickerService: ProjectPickerService,
        private supplierPickerService: SupplierPickerService
    ) {
        //  this.selectedPrice = data.serviceSupplierPriceVersionModel

        this.servicePricesService.getSupplierPricesForProduct(data.serviceRateId).subscribe((supplierPrices) => {
            this.supplierPrices = supplierPrices
            this.isLoading = false
            if (this.data.createNewPrice) {
            } else {
                this._selectPrice(this.data.selectedSupplier.id, this.data.selectedProjectId, this.data.selectedPriceId)
            }
        })
    }

    ngOnInit(): void {
        if (this.data.createNewPrice) {
            this.addSupplier()
        }
    }

    clear() {
        let prices: SupplierPriceDto[]
        if (this.selectedProject) {
            prices = this.selectedProject.prices
        } else {
            prices = this.selectedSupplier.prices
        }

        prices.splice(
            prices.findIndex((s) => s.isLatestVersion),
            1
        )

        this._latestVersionToRevertTo.isLatestVersion = true
        this.selectedPriceVersion = this._latestVersionToRevertTo
        this._latestVersionToRevertTo = undefined
        this.isCreatingNewPrice = false
    }

    create() {
        const command = new CreateServiceSupplierPriceVersionCommand()

        this.isSavingNewlyCreatingPrice = true

        command.serviceRateId = this.selectedLoadedPriceVersion.serviceRateId
        command.supplierId = this.selectedSupplier.supplierId
        command.projectId = this.selectedProject?.projectId

        command.serviceUnit = this.selectedLoadedPriceVersion.serviceUnit
        command.unitCostAmount = this.selectedLoadedPriceVersion.unitCostAmount
        command.unitMarkupAmount = this.selectedLoadedPriceVersion.unitMarkupAmount

        this.servicePricesService
            .create(this.selectedLoadedPriceVersion.serviceRateId, command)
            .pipe(
                mergeMap((priceId) => {
                    return this.servicePricesService.getServicePrice(
                        this.selectedLoadedPriceVersion.serviceRateId,
                        priceId
                    )
                })
            )
            .subscribe(
                (price) => {
                    this.isSavingNewlyCreatingPrice = false
                    this.isCreatingNewPrice = false
                    this.selectedPriceVersion.id = price.id
                    this.selectedLoadedPriceVersion = ServiceSupplierPriceVersionModel.createFromDto(price)
                    this.notifyService.success('Successfully created price')
                },
                (err) => {
                    console.log(err)
                    this.isSavingNewlyCreatingPrice = false
                    this.notifyService.fail('Failed to create price')
                }
            )
    }

    addNewVersion() {
        this._addNewPrice(
            {
                id: this.selectedSupplier.supplierId,
                name: this.selectedSupplier.supplierName,
            },
            this.selectedProject
                ? {
                      id: this.selectedProject.projectId,
                      name: this.selectedProject.projectName,
                  }
                : undefined
        )
    }

    addSupplier() {
        this.supplierPickerService.pickSupplier().subscribe(
            (supplier) => {
                this._addNewPrice({
                    id: supplier.id,
                    name: supplier.name,
                })
            },
            (error) => {
                this.notifyService.fail('Failed to add supplier', error)
            }
        )
    }

    addProject() {
        this.projectPickerService.pickProject().subscribe(
            (project) => {
                if (this.isCreatingNewPrice) {
                    // todo adjust version no
                    this.selectedLoadedPriceVersion.projectId = project.id
                    this.selectedLoadedPriceVersion.projectName = project.name

                    return
                }

                this._addNewPrice(
                    {
                        id: this.data.selectedSupplier.id,
                        name: this.data.selectedSupplier.name,
                    },
                    {
                        id: project.id,
                        name: project.name,
                    }
                )
            },
            (error) => {
                this.notifyService.fail('Failed to add supplier', error)
            }
        )
    }

    private _addNewPrice(
        supplier: { id: string; name: string },
        project: { id: number; name: string } | undefined = undefined
    ) {
        let supplierPrice = this.supplierPrices.find((s) => s.supplierId === supplier.id)

        this.isCreatingNewPrice = true

        // Supplier price doesn't exist
        if (supplierPrice && supplierPrice.supplierId !== this.selectedSupplier?.supplierId) {
            this.selectedSupplier = this.supplierPrices.find((s) => s.supplierId === supplierPrice.supplierId)
        } else if (!supplierPrice) {
            supplierPrice = this._createNewSupplierPrice(supplier)
            this.supplierPrices.push(supplierPrice)
            this.selectedSupplier = supplierPrice
            this.supplierPriceVersions = []
        }

        let projectPrice: SupplierProjectPriceDto = null
        if (project) {
            let projectPrice = supplierPrice.projectPrices.find((p) => p.projectId === project.id)

            if (!projectPrice) {
                projectPrice = this._createNewSupplierProjectPrice(projectPrice, project)
                supplierPrice.projectPrices.push(projectPrice)
                this.supplierPriceVersions = []
            }
            this.selectedProject = projectPrice
            this._isForProjectOnly = true
        }

        this._latestVersionToRevertTo = this.supplierPriceVersions.find((s) => s.isLatestVersion)

        const newPrice = new ServiceSupplierPriceVersionModel(
            null,
            this._latestVersionToRevertTo ? this._latestVersionToRevertTo.versionNo + 1 : 1,
            0,
            0,
            0,
            UnitType.Hours,
            true,
            null
        )

        newPrice.supplierId = supplier.id
        newPrice.serviceRateId = this.data.serviceRateId
        newPrice.projectId = projectPrice?.projectId

        const supplierPriceDto = new SupplierPriceDto()
        supplierPriceDto.isLatestVersion = true
        supplierPriceDto.versionNo = newPrice.versionNo

        if (project) {
            this.selectedProject.prices.push(supplierPriceDto)
            this.supplierPriceVersions = this.selectedProject.prices
        } else {
            this.selectedSupplier.prices.push(supplierPriceDto)
            this.supplierPriceVersions = this.selectedSupplier.prices
        }

        this.selectedPriceVersion = supplierPriceDto

        if (this._latestVersionToRevertTo) this._latestVersionToRevertTo.isLatestVersion = false
        this.selectedLoadedPriceVersion = newPrice
    }

    private _createNewSupplierProjectPrice(
        projectPrice: SupplierProjectPriceDto,
        project: { id: number; name: string }
    ) {
        projectPrice = new SupplierProjectPriceDto()
        projectPrice.projectId = project.id
        projectPrice.projectName = project.name
        projectPrice.prices = []
        return projectPrice
    }

    private _createNewSupplierPrice(supplier: { id: string; name?: string | null }) {
        const supplierPrice = new SupplierPricesDto()
        supplierPrice.supplierId = supplier.id
        supplierPrice.supplierName = supplier.name
        supplierPrice.prices = []
        supplierPrice.projectPrices = []
        return supplierPrice
    }

    private _selectPrice(supplierId: string, projectId: number | null, priceId: string | null) {
        this.selectedSupplier = this.supplierPrices.find((s) => s.supplierId === supplierId)

        if (projectId) {
            this._isForProjectOnly = true
            this.selectedProject = this.selectedSupplier.projectPrices.find((p) => p.projectId === projectId)
            this.selectedPriceVersion = this.selectedProject.prices.find((p) => p.id === priceId)
            this.supplierPriceVersions = this.selectedProject.prices
        } else {
            this.selectedPriceVersion = this.selectedSupplier.prices.find((p) => p.id === priceId)
            this.supplierPriceVersions = this.selectedSupplier.prices
        }

        this._loadServicePrice()
    }

    private _loadServicePrice() {
        if (this.isCreatingNewPrice) {
            // ignore because we're creating a new price so no need to load it
            return
        }

        const price = this._loadedPrices.find((p) => p.id === this.selectedPriceVersion.id)

        if (price) {
            this.selectedLoadedPriceVersion = price
            return
        }

        this.isLoading = true
        this.selectedLoadedPriceVersion = undefined

        this.servicePricesService
            .getServicePrice(this.data.serviceRateId, this.selectedPriceVersion.id)
            .subscribe((loadedPrice) => {
                this.selectedLoadedPriceVersion = ServiceSupplierPriceVersionModel.createFromDto(loadedPrice)
                this._loadedPrices.push(this.selectedLoadedPriceVersion)
                this.isLoading = false
            })
    }
}
