import { Injectable } from '@angular/core'
import { TimesheetDto } from './api.services'

@Injectable({
    providedIn: 'root',
})
export class UserConfigurationService {
    configuration: any
    storageKey = 'userConfiguration'
    constructor() {
        this.configuration = JSON.parse(localStorage.getItem(this.storageKey))

        if (!this.configuration) {
            this.configuration = {
                defaultPageSize: 10,
            }
        }
    }

    setConfigValue(key, value) {
        this.configuration[key] = value
        localStorage.setItem(this.storageKey, JSON.stringify(this.configuration))
    }

    retrieveConfigValue(key) {
        return this.configuration[key]
    }

    retrieveConfigValuewithDefault(key, defaultValue) {
        if (this.configuration.hasOwnProperty(key)) {
            return this.configuration[key]
        }

        return defaultValue
    }
}
