import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ClientPortalNavbarComponent } from './client-portal-navbar.component'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'

@NgModule({
    imports: [CommonModule, MatButtonModule, RouterModule],
    declarations: [ClientPortalNavbarComponent],
    exports: [ClientPortalNavbarComponent],
})
export class ClientPortalNavbarModule {}
