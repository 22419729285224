import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
    selector: 'app-quantity-dialog',
    templateUrl: './quantity-dialog.component.html',
    styleUrls: ['./quantity-dialog.component.scss'],
})
export class QuantityDialogComponent implements OnInit {
    quantity: number

    constructor(@Optional() @Inject(MAT_DIALOG_DATA) data: { quantity: number }) {
        this.quantity = data.quantity
    }

    ngOnInit() {}
}
