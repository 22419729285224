import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export class SelectionConfig {
    constructor(
        public optionList: any[],
        public displayProperty: string,
        public selectedOption: any,
        public placeholder: String
    ) {}
}

@Component({
    selector: 'app-selection-dialog',
    templateUrl: './selection-dialog.component.html',
    styleUrls: ['./selection-dialog.component.scss'],
})
export class SelectionDialogComponent implements OnInit {
    constructor(@Inject(MAT_DIALOG_DATA) public selectionConfig: SelectionConfig) {}

    ngOnInit() {}
}
