import {
    PropertyDefinitionLookupDto,
    GetPropertyDefinitionListQuery,
    PropertyDefinitionService,
} from '../../../../app/services/api.services'
import { PagingDataSource } from '../../../../app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'

export class PropertyDefinitionDataSource extends PagingDataSource<
    PropertyDefinitionLookupDto,
    GetPropertyDefinitionListQuery
> {
    constructor(private service: PropertyDefinitionService) {
        super()
    }

    getEntitiesObservable(query: GetPropertyDefinitionListQuery): Observable<PropertyDefinitionLookupDto> {
        return this.service.propertyDefinitions(query)
    }
}
