import { AfterViewInit, Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { ServerPaginationListComponent } from '@app/models/serverPaginationComponent'
import {
    PropertyValueDto,
    GetPropertyDefinitionListQuery,
    PropertyDefinitionLookupDto,
    PaginationModel,
    PropertyDefinitionService,
    PropertyDefinitionType,
    PropertyDefinitionSubType,
} from '@app/services/api.services'
import { EnumListService } from '@app/services/enum-list.service'
import { LanguageService } from '@app/services/language.service'
import { NotifyService } from '@app/services/notify.service'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'
import { Observable } from 'rxjs'
import { CustomPropertyFormDialogService } from '../custom-property-form-dialog/custom-property-form-dialog.service'
import { PropertyDefinitionDataSource } from './property-definition.datasource'

@Component({
    selector: 'app-custom-properties-list-dialog',
    templateUrl: './custom-properties-list-dialog.component.html',
    styleUrls: ['./custom-properties-list-dialog.component.scss'],
})
export class CustomPropertiesListDialogComponent
    extends ServerPaginationListComponent<string, PropertyDefinitionLookupDto, GetPropertyDefinitionListQuery>
    implements OnInit, AfterViewInit {
    displayedColumns = ['label', 'type', 'subType', 'action']
    displayMode: PagingDisplayMode = PagingDisplayMode.ListOnly
    isLoading = false
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            addingPropertyToEntity: string
            propertyIdsToExclude: any[]
        },
        private dialogRef: MatDialogRef<CustomPropertiesListDialogComponent>,
        private customPropertyFormDialogService: CustomPropertyFormDialogService,
        public router: Router,
        public confirmDialogService: ConfirmDialogService,
        public notifyService: NotifyService,
        private propertyDefinitionService: PropertyDefinitionService,
        private languageService: LanguageService,
        private enumListService: EnumListService
    ) {
        super(router, 'products', confirmDialogService, notifyService)
        this.query = new GetPropertyDefinitionListQuery()
    }

    ngOnInit(): void {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.load()
        })
    }

    getPropertyDefinitionType(type: PropertyDefinitionType) {
        return this.enumListService.findDisplayName(this.enumListService.propertyDefinitionTypes, type)
    }

    getPropertyDefinitionSubType(subType: PropertyDefinitionSubType) {
        return this.enumListService.findDisplayName(this.enumListService.propertyDefinitionSubtypes, subType)
    }

    addNew() {
        this.customPropertyFormDialogService.showDialog().subscribe((id) => {
            if (id) {
                this.filterChanges()
            }
        })
    }

    addPropertyValue(propertyValue: PropertyValueDto) {
        this.dialogRef.close(propertyValue)
    }

    loadDataSource(): PagingDataSource<PropertyDefinitionLookupDto, GetPropertyDefinitionListQuery> {
        return new PropertyDefinitionDataSource(this.propertyDefinitionService)
    }
    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel)
        query.requestedCulture = this.languageService.userSelectedLanguage.culture
        if (this.data.propertyIdsToExclude && this.data.propertyIdsToExclude.length > 0) {
            this.dataSource.loadEntities(query, null, (p) => {
                if (this.data.propertyIdsToExclude.findIndex((id) => id === p.id) === -1) {
                    return p
                }
            })
        } else {
            this.dataSource.loadEntities(query)
        }
    }
    deleteEntity(id: string): Observable<void> {
        throw new Error('Method not implemented.')
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel())
    }
}
