import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TimesheetModel, TimesheetTaskModel } from '@app/models/timesheet/TimesheetModel'
import { NotifyService } from '@app/services/notify.service'
import { TimesheetsService, TimesheetTaskType } from '@app/services/api.services'
import { TimesheetSaveService } from '../timesheet/timesheet-save.service'
import { UserConfigurationService } from '@app/services/user-configuration.service'
import { TimesheetBaseDirective } from '../timesheet-base.component'
import { EnumListService } from '@app/services/enum-list.service'

@Component({
    selector: 'app-timesheet-task-dialog',
    templateUrl: './timesheet-task-dialog.component.html',
    styleUrls: ['./timesheet-task-dialog.component.scss'],
})
export class TimesheetTaskDialogComponent extends TimesheetBaseDirective implements OnInit {
    timesheetTask: TimesheetTaskModel
    isEditing: boolean
    isBreak: boolean
    isSubmitting = false
    validationCallback: Function

    constructor(
        public dialogRef: MatDialogRef<TimesheetTaskDialogComponent>,
        private notifyService: NotifyService,
        timesheetSaveService: TimesheetSaveService,
        private timesheetsService: TimesheetsService,
        userConfigurationService: UserConfigurationService,
        enumListService: EnumListService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            timesheet: TimesheetModel
            isEditing: boolean
            timesheetTask: TimesheetTaskModel
            validationCallback: Function
        }
    ) {
        super(userConfigurationService, timesheetSaveService, enumListService)
        this.model = data.timesheet
        this.isEditing = data.isEditing
        this.timesheetTask = data.timesheetTask
        this.validationCallback = data.validationCallback
    }

    ngOnInit() {
        this.loadTimesheetSettings()
    }

    compareById(f1: any, f2: any): boolean {
        return f1 && f2 && f1.id === f2.id
    }

    completeEdit(save: boolean) {
        if (!this.validationCallback(this.timesheetTask)) {
            return
        }
        this.timesheetTask.editComplete()

        if (save) {
            if (this.timesheetSaveService.validateTimesheet(this.model)) {
                this.timesheetsService.edit(this.model.createDto()).subscribe((updatedTime) => {
                    this.model.applyUpdatesToTimesheet(updatedTime)
                    this.notifyService.success('Timesheet has been saved')
                })
            }
        } else {
            this.cacheTimesheetState()
        }
        this.dialogRef.close()
    }

    delete() {
        this.model.removeTask(this.timesheetTask)
        this.cacheTimesheetState()
        this.dialogRef.close()
    }

    add(save: boolean) {
        if (this.isBreak) {
            this.model.addTask(TimesheetTaskType.UnpaidBreak)
        } else {
            if (!this.validationCallback(this.model.taskToAdd)) {
                return
            }
            this.model.addTask(TimesheetTaskType.Regular)
        }

        if (save) {
            if (!this.timesheetSaveService.validateTimesheet(this.model)) {
                return
            }

            this.isSubmitting = true
            return this.timesheetsService.edit(this.model.createDto()).subscribe((updatedTime) => {
                this.model.applyUpdatesToTimesheet(updatedTime)
                this.notifyService.success('Task has been added and saved')
                this.isSubmitting = false
            })
        } else {
            this.cacheTimesheetState()
            this.notifyService.success('Task has been added')
        }
    }

    close() {
        if (this.isEditing) {
            this.timesheetTask.revertChanges()
        }
        this.dialogRef.close()
    }

    change(test) {
        console.log(test)
    }

    private _add(save: boolean) {
        if (save) {
            return this.timesheetsService.edit(this.model.createDto()).subscribe((updatedTime) => {
                this.model.applyUpdatesToTimesheet(updatedTime)
                this.notifyService.success('Task has been added and saved')
            })
        } else {
            this.cacheTimesheetState()
            this.notifyService.success('Task has been added')
        }
    }
}
