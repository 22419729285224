import { CurrencyPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'
import { SelectionItemModel } from '@app/models/template/SelectionGroupModel'

@Pipe({
    name: 'selectionItemPricePipe',
})
export class SelectionItemPricePipe implements PipeTransform {
    constructor(private currencyPipe: CurrencyPipe) {}

    transform(value: number, args?: any): any {
        return value === 0 ? 'Included' : this.currencyPipe.transform(value)
    }
}
