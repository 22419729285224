<mat-form-field>
    <input
        matInput
        [textMask]="{ mask: numberMask }"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [(ngModel)]="inputModel"
        (ngModelChange)="stripAndEmitChange(inputModel)"
    />
</mat-form-field>
