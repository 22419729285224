<div class="wrapper">
    <div class="sidebar" data-color="rose" data-background-color="black" data-image="../assets/img/sidebar-3.jpg">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/sidebar-3.jpg)"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <div class="main-content">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
        <div *ngIf="!isMap()">
            <!-- <app-footer-cmp></app-footer-cmp> -->
        </div>
    </div>
    <!-- <app-fixedplugin></app-fixedplugin> -->
    <app-fixed-form-controls></app-fixed-form-controls>
</div>
