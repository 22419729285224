import { Injectable } from '@angular/core'
import { CostLineDialogComponent, CostLineType, CostLineDialogModel } from './cost-line-dialog.component'
import { Observable } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { CostLineTranslation } from '@app/services/api.services'

import { BaseCostLineModel } from '@app/models/costLines/BaseCostLineModel'
import { TemplateModel } from '@app/models/template/TemplateModel'

@Injectable({
    providedIn: 'root',
})
export class CostLineDialogService {
    constructor(private dialog: MatDialog) {}

    showAddMaterialCostLineDialog() {
        const costLineDialog = new CostLineDialogModel([])
        costLineDialog.displayRetailPriceControls = true
        costLineDialog.initTranslations(costLineDialog.translations, () => CostLineTranslation.fromJS({}))
        const dialogRef = this.dialog.open(CostLineDialogComponent, {
            width: '1000px',
            data: {
                costLineDialog: costLineDialog,
            },
        })
        return dialogRef.afterClosed()
    }

    showAddCostLineDialog(
        allowTakeOff: boolean = false,
        useOnlyGlobalTakeOffs: boolean = false,
        template: TemplateModel = null
    ): Observable<CostLineDialogModel> {
        const costLineDialog = new CostLineDialogModel([])
        costLineDialog.allowUseOfTakeOff = allowTakeOff
        costLineDialog.useOnlyGlobalTakeOffs = useOnlyGlobalTakeOffs
        costLineDialog.template = template
        costLineDialog.initTranslations(costLineDialog.translations, () => CostLineTranslation.fromJS({}))
        const dialogRef = this.dialog.open(CostLineDialogComponent, {
            width: '1000px',
            data: {
                costLineDialog: costLineDialog,
            },
        })
        return dialogRef.afterClosed()
    }

    showEditCostLineDialog(
        costLine: BaseCostLineModel,
        displayRetailPriceControls = false,
        useOnlyGlobalTakeOffs: boolean = false,
        template: TemplateModel = null,
        showUpdateAll = true
    ): Observable<CostLineDialogModel> {
        const costLineDialogModel = new CostLineDialogModel(costLine.translations.map((t) => t))
        costLineDialogModel.unitPrice.unitCostAmount = costLine.unitCostAmount
        costLineDialogModel.unitPrice.isUnitMarkupPercent = costLine.isUnitMarkupPercent
        costLineDialogModel.unitPrice.unitMarkupAmount = costLine.unitMarkupAmount
        costLineDialogModel.unitPrice.unitMarkupPercent = costLine.unitMarkupPercent
        costLineDialogModel.unitPrice.hasRetailAmount = costLine.hasRetailAmount
        costLineDialogModel.unitPrice.retailAmount = costLine.retailAmount
        costLineDialogModel.unitPrice.retailDiscountPercent = costLine.retailDiscountPercent
        costLineDialogModel.quantity = costLine.quantity
        costLineDialogModel.takeOff = costLine.takeOff
        costLineDialogModel.unitType = costLine.unitType
        costLineDialogModel.useTakeOff = costLine.useTakeOff
        costLineDialogModel.useOnlyGlobalTakeOffs = useOnlyGlobalTakeOffs
        costLineDialogModel.template = template
        costLineDialogModel.allowUseOfTakeOff = costLine.isTakeOffsSupported()
        costLineDialogModel.isLinkedEntity = costLine.isLinkedCostLine()
        costLineDialogModel.isEdit = true
        costLineDialogModel.displayRetailPriceControls = displayRetailPriceControls

        if (costLine.selectedTranslation) {
            costLineDialogModel.description = costLine.selectedTranslation.description
        }

        const dialogRef = this.dialog.open(CostLineDialogComponent, {
            width: '1000px',
            data: {
                costLineDialog: costLineDialogModel,
                showUpdateAll,
            },
        })
        return dialogRef.afterClosed()
    }
}
