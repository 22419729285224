import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '@app/material.module'
import { ImageDisplayComponent } from './image-display/image-display.component'
import { ImageDisplayListComponent } from './image-display-list/image-display-list.component'
import { ImageThumbnailComponent } from './image-thumbnail/image-thumbnail.component'

@NgModule({
    declarations: [ImageDisplayComponent, ImageDisplayListComponent, ImageThumbnailComponent],
    imports: [CommonModule, MaterialModule],
    exports: [ImageDisplayComponent, ImageDisplayListComponent, ImageThumbnailComponent],
})
export class ImagesModule {}
