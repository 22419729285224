import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import { TemplateCategory, TemplateCategoriesService, TemplateCategoryTranslation } from '@app/services/api.services'
import { ActivatedRoute } from '@angular/router'
import { FormComponent, FormComponentSettings, DialogCapableFormComponentId } from '@app/models/formComponent'
import { Observable, empty } from 'rxjs'
import { FormContainerService } from '@app/services/form-container.service'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-template-category-form',
    templateUrl: './template-category-form.component.html',
    styleUrls: ['./template-category-form.component.scss'],
})
export class TemplateCategoryFormComponent
    extends DialogCapableFormComponentId<string, TemplateCategory>
    implements OnInit, OnDestroy {
    constructor(
        private apiService: TemplateCategoriesService,
        private route: ActivatedRoute,
        formContainerService: FormContainerService
    ) {
        super(new FormComponentSettings(true, 'template-categories', 'Section Category', true), formContainerService)
    }

    editEntity(): Observable<void> {
        this._generateFromForm()
        return this.apiService.edit(this.model).pipe(map(() => undefined))
    }

    createEntity(): Observable<string> {
        this._generateFromForm()
        return this.apiService.create(this.model).pipe(map((c) => c.id))
    }

    _generateFromForm() {
        const entity = new TemplateCategory()
        entity.init(this.formControl.value)

        this.model = Object.assign(this.model, entity)
    }

    ngOnInit() {
        this.model = this.route.snapshot.data['templateCategory']
        if (this.model) {
            this.id = this.model.id
        }
        this._setupformControl()
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new TemplateCategory()

            this.model.translations = []
            this.languageService.initTranslations(this.model.translations, () => new TemplateCategoryTranslation())
        }

        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            translations: this.formBuilder.array([this._createTranslationForm(0), this._createTranslationForm(1)]),
        })
    }

    private _createTranslationForm(index) {
        return this.formBuilder.group({
            name: [this.model.translations[index].name, [Validators.required]],
            culture: this.model.translations[index].culture,
            id: this.model.translations[index].id,
        })
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }
}
