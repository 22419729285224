<div class="row" *ngIf="!isInDialogMode">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-rose card-header-text">
                <div class="card-text">
                    <h4 class="card-title">Generate Contract</h4>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="form"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isInDialogMode">
    <ng-container *ngTemplateOutlet="form"></ng-container>
</ng-container>

<ng-template #form>
    <form [formGroup]="formControl" class="form-horizontal">
        <div class="row">
            <div class="col-12">
                <h3>Recipients</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4>Primary Buyer</h4>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h4>Secondary Buyer</h4>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h4>Builder</h4>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h3>Fields</h3>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Name of the enterprise</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Address</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>City</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Postal Code</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Tel</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>The Vendor represented herin by</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Lot Number</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Phase number</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Project Name</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Situated at</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Known and designed as lot number</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Of the cadaster of</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Land registry of</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Approximate area of (sqaure feet)</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Number of rooms</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Parking space number</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>the standards of the model unit designated as follows</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Shall be essentially completed and ready for occupation on</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Attesting Notary Name</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label>Standard lot and house price</mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-md-6 col-12">
                <mat-form-field>
                    <mat-label></mat-label>
                    <input type="text" matInput />
                    <!-- <mat-error *ngIf="isFieldValid('name')">
              Required internal name
            </mat-error> -->
                </mat-form-field>
            </div>
        </div>
    </form>
</ng-template>
