import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SidebarComponent } from './sidebar.component'
import { NgxPermissionsModule } from 'ngx-permissions'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    imports: [RouterModule, CommonModule, NgxPermissionsModule, TranslateModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
})
export class SidebarModule {}
