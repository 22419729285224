import { SelectionItemDetailsDialogService } from '@app/shared/dialog/selection-item-details-dialog/selection-item-details-dialog.service'
import { BaseSelectionComponent } from '@app/shared/common-template-components/base-selection.component'
import { Directive } from '@angular/core'
import { SelectionItemModel, SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { ActivatedRoute, Router } from '@angular/router'
import { NotifyService } from '@app/services/notify.service'
import { ClientPortalSelectionService } from '@app/services/client-portal.service'
@Directive()
export abstract class BaseSelectionItemComponent extends BaseSelectionComponent {
    constructor(
        private selectionItemDetailsDialogService: SelectionItemDetailsDialogService,
        private activatedRoute: ActivatedRoute,
        router: Router,
        notifyService: NotifyService,
        clientPortalSelectionService: ClientPortalSelectionService
    ) {
        super(activatedRoute, router, notifyService, clientPortalSelectionService)
    }

    showDetails(selectionItem: SelectionItemModel, selectionGroup: SelectionGroupModel) {
        this.selectionItemDetailsDialogService.showDialog(
            selectionItem,
            selectionGroup,
            this.activatedRoute,
            this.parentSelectionGroup
        )
    }
}
