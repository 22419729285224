import { Component, OnInit } from '@angular/core'
import { AccountService, EmployeeSettingsService } from './services/api.services'
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions'
import { AccountManageService } from './services/accountManage.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(
        private accountService: AccountService,
        private accountManagerService: AccountManageService,
        private employeeSettingsService: EmployeeSettingsService,
        translate: TranslateService
    ) {
        translate.setDefaultLang('en')
        const prestiLang = localStorage.getItem('prestiLang')
        translate.use(prestiLang === 'en' || prestiLang === 'fr' ? prestiLang : 'en')
    }

    ngOnInit() {
        if (this.accountManagerService.isLoggedIn) {
            this.accountService.userInfo().subscribe((user) => {
                this.accountManagerService.refreshUser(user)
            })
        }
    }
}
