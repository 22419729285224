import { UnitType, TakeOffDto, TakeOffQuantityDto, CalculationOperator } from '@app/services/api.services'

export class TakeOffModel {
    static maxDynamicTakeOffDepth = 1

    id: string
    name: string
    defaultQuantity: number
    isInherited: boolean
    unitType: UnitType
    takeOffQuantity: TakeOffQuantityModel
    inheritedTakeOffQuantity: TakeOffQuantityModel
    isDynamic = false
    calculationOperator: CalculationOperator
    takeOffUsedForCalculation: TakeOffModel
    dynamicTakeOffDepth: number
    valueForDynamicCalculation: number

    static createFromDto(takeOffDto: TakeOffDto): TakeOffModel {
        const takeOffModel = new TakeOffModel(
            takeOffDto.id,
            takeOffDto.name,
            takeOffDto.defaultQuantity,
            takeOffDto.unitType,
            takeOffDto.takeOffQuantity != null
        )
        takeOffModel.isDynamic = takeOffDto.isDynamic
        takeOffModel.calculationOperator = takeOffDto.calculationOperator
        takeOffModel.dynamicTakeOffDepth = takeOffDto.dynamicTakeOffDepth
        if (takeOffDto.isDynamic) {
            takeOffModel.valueForDynamicCalculation = takeOffDto.defaultQuantity
        }
        if (takeOffDto.takeOffUsedForCalculation) {
            takeOffModel.takeOffUsedForCalculation = TakeOffModel.createFromDto(takeOffDto.takeOffUsedForCalculation)
        }
        if (takeOffDto.takeOffQuantity) {
            takeOffModel.takeOffQuantity = TakeOffQuantityModel.createFromDto(takeOffDto.takeOffQuantity)
        }
        if (takeOffDto.inheritedTakeOffQuantity) {
            takeOffModel.inheritedTakeOffQuantity = TakeOffQuantityModel.createFromDto(
                takeOffDto.inheritedTakeOffQuantity
            )
        }
        return takeOffModel
    }

    constructor(id: string, name: string, quantity: number, unitType: UnitType, isInherited: boolean) {
        this.id = id
        this.name = name
        this.defaultQuantity = quantity
        this.isInherited = isInherited
        this.unitType = unitType
    }

    get formattedName(): string {
        return `${this.name} (${this.dynamicQuantity})`
    }

    get inheritedQuantity(): number {
        if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            return this.inheritedTakeOffQuantity.quantity
        }
        return this.defaultQuantity
    }

    get inheritedDynamicQuantity(): number {
        if (this.isDynamic) {
            return this.calculatedInheritedDynamicValue
        }
        return this.inheritedQuantity
    }

    get isLocalTakeOff(): boolean {
        return !this.isInherited
    }

    get isTakeOffQuantityEnabled(): boolean {
        return this.takeOffQuantity && this.takeOffQuantity.isEnabled
    }

    get dynamicQuantity(): number {
        if (this.isDynamic) {
            return this.calculatedDynamicValue
        }
        return this.quantity
    }

    get quantity(): number {
        if (this.takeOffQuantity && this.takeOffQuantity.isEnabled) {
            return this.takeOffQuantity.quantity
        }

        if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            return this.inheritedTakeOffQuantity.quantity
        }

        return this.defaultQuantity
    }

    get displayDynamicCalculation(): boolean {
        return (
            this.isDynamic &&
            this.takeOffUsedForCalculation &&
            this.calculationOperator &&
            this.valueForDynamicCalculation !== undefined &&
            this.valueForDynamicCalculation != null
        )
    }

    get valueForCalculation(): number {
        let valueForCalculation = this.valueForDynamicCalculation
        if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            valueForCalculation = this.inheritedTakeOffQuantity.quantity
        }
        return valueForCalculation
    }

    get dynamicCalculationBreakdown(): string {
        let valueForCalculation = this.valueForDynamicCalculation
        if (this.takeOffQuantity && this.takeOffQuantity.isEnabled) {
            valueForCalculation = this.takeOffQuantity.quantity
        } else if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            valueForCalculation = this.inheritedTakeOffQuantity.quantity
        }

        let calculation = `${this.takeOffUsedForCalculation.dynamicQuantity}${this.takeOffUsedForCalculation.unitType} `
        calculation += `${this.calculationOperatorString} ${valueForCalculation} = ${this.calculatedDynamicValue}${this.unitType}`

        return calculation
    }

    get calculationOperatorString(): string {
        if (!this.calculationOperator) {
            return ''
        }
        return this.calculationOperator === CalculationOperator.Divide ? '/' : '*'
    }

    get calculatedInheritedDynamicValue(): number {
        if (this.calculationOperator === CalculationOperator.Divide) {
            let divisor = this.valueForDynamicCalculation
            if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
                divisor = this.inheritedTakeOffQuantity.quantity
            }
            return this.takeOffUsedForCalculation.dynamicQuantity / divisor
        }
        let multiplier = this.valueForDynamicCalculation
        if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            multiplier = this.inheritedTakeOffQuantity.quantity
        }
        return this.takeOffUsedForCalculation.dynamicQuantity * multiplier
    }

    get calculatedDynamicValue(): number {
        if (this.calculationOperator === CalculationOperator.Divide) {
            let divisor = this.valueForDynamicCalculation
            if (this.takeOffQuantity && this.takeOffQuantity.isEnabled) {
                divisor = this.takeOffQuantity.quantity
            } else if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
                divisor = this.inheritedTakeOffQuantity.quantity
            }
            return this.takeOffUsedForCalculation.dynamicQuantity / divisor
        }
        let multiplier = this.valueForDynamicCalculation
        if (this.takeOffQuantity && this.takeOffQuantity.isEnabled) {
            multiplier = this.takeOffQuantity.quantity
        } else if (this.inheritedTakeOffQuantity && this.inheritedTakeOffQuantity.isEnabled) {
            multiplier = this.inheritedTakeOffQuantity.quantity
        }
        return this.takeOffUsedForCalculation.dynamicQuantity * multiplier
    }

    createDto(): TakeOffDto {
        const takeOffDto = new TakeOffDto()
        takeOffDto.id = this.id
        takeOffDto.name = this.name
        takeOffDto.defaultQuantity = this.defaultQuantity
        takeOffDto.unitType = this.unitType
        return takeOffDto
    }

    clone(): TakeOffModel {
        const takeOff = new TakeOffModel(this.id, this.name, this.defaultQuantity, this.unitType, this.isInherited)
        takeOff.isDynamic = this.isDynamic
        takeOff.calculationOperator = this.calculationOperator
        if (takeOff.isDynamic) {
            takeOff.valueForDynamicCalculation = this.valueForDynamicCalculation
        }
        if (this.takeOffUsedForCalculation) {
            takeOff.takeOffUsedForCalculation = this.takeOffUsedForCalculation.clone()
        }
        if (this.takeOffQuantity) {
            takeOff.takeOffQuantity = this.takeOffQuantity.clone()
        }
        if (this.inheritedTakeOffQuantity) {
            takeOff.inheritedTakeOffQuantity = this.inheritedTakeOffQuantity.clone()
        }

        return takeOff
    }
}

export class TakeOffQuantityModel {
    takeOffId: string
    quantity: number
    isEnabled: boolean
    unitType: UnitType
    isPersisted: boolean

    static createFromDto(takeOffDto: TakeOffQuantityDto): TakeOffQuantityModel {
        const takeOffModel = new TakeOffQuantityModel(
            takeOffDto.takeOffId,
            takeOffDto.quantity,
            takeOffDto.isEnabled,
            takeOffDto.isPersisted
        )
        return takeOffModel
    }

    constructor(takeOffId: string, quantity: number, isEnabled: boolean, isPersisted: boolean) {
        this.takeOffId = takeOffId
        this.quantity = quantity
        this.isEnabled = isEnabled
        this.isPersisted = isPersisted
    }

    createDto(): TakeOffQuantityDto {
        const takeOffQuantityDto = new TakeOffQuantityDto()
        takeOffQuantityDto.takeOffId = this.takeOffId
        takeOffQuantityDto.quantity = this.quantity
        takeOffQuantityDto.isEnabled = this.isEnabled
        return takeOffQuantityDto
    }

    clone(): TakeOffQuantityModel {
        const takeOffQuantity = new TakeOffQuantityModel(
            this.takeOffId,
            this.quantity,
            this.isEnabled,
            this.isPersisted
        )
        return takeOffQuantity
    }
}
