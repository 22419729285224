export enum ItemState {
    NotAdded,
    NewlyAdded,
    Edited,
    Deleted,
    AlreadyAdded,
}

export class ItemContainer<T> {
    initialState: ItemState

    private _stateBeforeDeleted: ItemState
    constructor(public item: T, public state: ItemState, initialState?: ItemState | undefined) {
        if (initialState) {
            this.initialState = state
        } else {
            this.initialState = state
        }
    }

    static createAlreadyAdded<ItemType>(item: ItemType): ItemContainer<ItemType> {
        return new ItemContainer(item, ItemState.AlreadyAdded)
    }

    static createNewlyAdded<ItemType>(item: ItemType): ItemContainer<ItemType> {
        return new ItemContainer(item, ItemState.NewlyAdded)
    }

    get isAdded() {
        return this.state === ItemState.NewlyAdded
    }

    get isNotChanged() {
        return this.state === ItemState.AlreadyAdded
    }

    get isEdited() {
        return this.state === ItemState.Edited
    }

    get isDeleted() {
        return this.state === ItemState.Deleted
    }

    get isNotAdded() {
        return this.state === ItemState.NotAdded
    }

    canBeAdded() {
        return this.state === ItemState.NotAdded || this.state === ItemState.Deleted
    }

    canBeRemoved() {
        return (
            this.state === ItemState.NewlyAdded ||
            this.state === ItemState.AlreadyAdded ||
            this.state === ItemState.Edited
        )
    }

    setAsAdd() {
        if (this.initialState === ItemState.AlreadyAdded) {
            this.state = ItemState.AlreadyAdded
        } else {
            this.state = ItemState.NewlyAdded
        }
    }

    setAsEdited() {
        if (this.state === ItemState.NewlyAdded) {
            return // If it's newly added then we wouldn't set it as edited because it wouldn't of been persisted yet
        }
        this.state = ItemState.Edited
    }

    reverseAsRemoved() {
        this.state = this._stateBeforeDeleted
    }

    setAsRemoved() {
        this._stateBeforeDeleted = this.state
        if (this.initialState === ItemState.AlreadyAdded) {
            this.state = ItemState.Deleted
        } else {
            this.state = ItemState.NotAdded
        }
    }

    getChipColor(): string {
        switch (this.state) {
            case ItemState.NewlyAdded:
            case ItemState.Edited:
                return 'primary'
            case ItemState.Deleted:
                return 'warn'

            default:
                return 'default'
        }
    }

    getRowColor(): string {
        switch (this.state) {
            case ItemState.NewlyAdded:
                return 'added-row'
            case ItemState.Deleted:
                return 'deleted-row'
            case ItemState.Edited:
                return 'edited-row'
            default:
                return 'default-row'
        }
    }

    getSubText(): string {
        switch (this.state) {
            case ItemState.NewlyAdded:
                return 'Added'
            case ItemState.Deleted:
                return 'Deleted'
            case ItemState.Edited:
                return 'Edited'
            default:
                return undefined
        }
    }
}
