import { Injectable } from '@angular/core'
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpUserEvent,
} from '@angular/common/http'
import { Observable, throwError, BehaviorSubject, of } from 'rxjs'
import { catchError, switchMap, finalize, filter, take, mergeMap } from 'rxjs/operators'
import { AuthService, RefreshTokenDto, AccessTokenDto } from '@app/services/api.services'
import { AccountManageService } from '@app/services/accountManage.service'
import { AccessToken } from '@app/models/auth/accessToken'

@Injectable()
export class BearerTokenInjectorInterceptor implements HttpInterceptor {
    constructor(private accountManageService: AccountManageService, private authService: AuthService) {}

    isRefreshingToken: boolean = false
    tokenSubject: BehaviorSubject<AccessTokenDto> = new BehaviorSubject<AccessTokenDto>(null)

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any
    > {
        const accessToken = this.accountManageService.accessToken

        if (req.url.includes('refresh')) {
            // let the refresh go through
            console.log('Refresh token invalid')
            return next.handle(req).pipe(
                catchError(() => {
                    // if the refresh is invalid then we sign out the user
                    return <any>this.accountManageService.logout()
                })
            )
        }

        if (accessToken) {
            return of(accessToken).pipe(
                mergeMap((token) => {
                    // if (token.isTokenExpired) { // pre-check to avoid 401 errors
                    //   return this.handleRefresh(req, next);
                    // }

                    return next.handle(this._addTokenToRequest(req, token)).pipe(
                        catchError((err) => {
                            if (err instanceof HttpErrorResponse) {
                                switch ((<HttpErrorResponse>err).status) {
                                    case 401:
                                        //return <any>this.accountManageService.logout();
                                        return this._handleRefresh(req, next)
                                    default:
                                        return throwError(err)
                                }
                            } else {
                                return throwError(err)
                            }
                        })
                    )
                })
            )
        } else {
            return next.handle(req).pipe(
                catchError((err) => {
                    if (err instanceof HttpErrorResponse) {
                        switch ((<HttpErrorResponse>err).status) {
                            case 401:
                                return <any>this.accountManageService.logout()
                            default:
                                return throwError(err)
                        }
                    } else {
                        return throwError(err)
                    }
                })
            )
        }
    }

    private _addTokenToRequest(request: HttpRequest<any>, token: AccessToken): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: `Bearer ${token.token}` } })
    }

    private _handleRefresh(request: HttpRequest<any>, next: HttpHandler): any {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true
            console.log('Refreshing token')

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null)

            const refreshToken = new RefreshTokenDto()
            refreshToken.token = this.accountManageService.accessToken.refreshToken
            return this.authService.refreshAccessToken(refreshToken).pipe(
                catchError(() => {
                    return <any>this.accountManageService.logout()
                }),
                finalize(() => {
                    this.isRefreshingToken = false
                }),
                mergeMap((accessToken: AccessTokenDto) => {
                    if (accessToken) {
                        this.accountManageService.storeToken(AccessToken.createFromDto(accessToken))
                        console.log('Token is refreshed')
                        this.tokenSubject.next(accessToken)
                        return next.handle(this._addTokenToRequest(request, this.accountManageService.accessToken))
                    }

                    return <any>this.accountManageService.logout()
                })
            )
        } else {
            console.log('Token is already being refreshed')
            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap(() => {
                    return next.handle(this._addTokenToRequest(request, this.accountManageService.accessToken))
                })
            )
        }
    }
}
