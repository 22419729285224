import { Injectable } from '@angular/core'
import { CalendarEventLoader } from '@app/models/interfaces/calendarEventLoader'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { CalendarEvent } from '@app/models/calendarEvent'
import { NoteDto, NoteType, BuilderClientsService, NotesService, GetAllNoteListQuery } from '../api.services'
import { map, mergeMap } from 'rxjs/operators'
import { DialogResultActions, DialogResult } from '@app/models/dialogResult'
import { NoteFormDialogSettings } from '@app/models/dialogs/noteFormDialogSettings'
import { NoteFormDialogService } from '@app/admin-pages/notes/note-form-dialog/note-form-dialog.service'
@Injectable({
    providedIn: 'root',
})
export class ClientLeadNoteEventLoaderService implements CalendarEventLoader {
    clientLeadNotes: NoteDto[]
    transformedCalendarEvents: CalendarEvent[]

    constructor(
        private clientLeadsService: BuilderClientsService,
        private notesService: NotesService,
        private noteFormDialogService: NoteFormDialogService
    ) {}

    addCalendarEvent(start: moment.Moment): Observable<DialogResult<CalendarEvent>> {
        const settings = new NoteFormDialogSettings()

        settings.allowNoteTypeSelection = false
        settings.allowClientLeadIdSelection = true
        settings.isEdit = false
        settings.startOnDateTime = start
        settings.requiredNoteTypeSelection = NoteType.FollowUp

        return this.noteFormDialogService.showDialog(settings).pipe(
            map((result) => {
                if (!result) {
                    return
                }
                return new DialogResult(DialogResultActions.Create, this._convertToCalendarEvent(result.entity))
            })
        )
    }

    editCalendarEvent(id: any): Observable<DialogResult<CalendarEvent>> {
        const clientLeadNoteIndex = this.clientLeadNotes.findIndex((note) => note.id === id)
        const calendarEventIndex = this.transformedCalendarEvents.findIndex((event) => event.id === id)
        const calendarEvent = this.transformedCalendarEvents[calendarEventIndex]

        const settings = new NoteFormDialogSettings()

        settings.model = this.clientLeadNotes[clientLeadNoteIndex]

        return this.clientLeadsService.clientLead(settings.model.builderClientId).pipe(
            mergeMap((client) => {
                settings.attachedEntity = { builderClientId: settings.model.builderClientId, name: client.name }
                settings.allowNoteTypeSelection = false
                settings.allowClientLeadIdSelection = false
                settings.isEdit = true
                settings.allowDelete = true
                settings.showCientLead = true

                return this.noteFormDialogService.showDialog(settings).pipe(
                    map((result) => {
                        if (!result) {
                            return
                        }
                        if (result.action === DialogResultActions.Delete) {
                            this.clientLeadNotes.splice(clientLeadNoteIndex, 1)
                            this.transformedCalendarEvents.splice(calendarEventIndex, 1)
                            return new DialogResult(DialogResultActions.Delete, calendarEvent)
                        } else if (result.action === DialogResultActions.Edit) {
                            this.clientLeadNotes[clientLeadNoteIndex] = result.entity

                            if (result.entity.noteType === NoteType.FollowUp) {
                                calendarEvent.start = result.entity.followUpDateTime
                                calendarEvent.end = result.entity.followUpDateTime
                            } else if (result.entity.noteType === NoteType.Appointment) {
                                calendarEvent.start = result.entity.appointmentDateTime
                                calendarEvent.end = result.entity.appointmentDateTime
                            }

                            calendarEvent.title = result.entity.subject

                            return new DialogResult(DialogResultActions.Edit, calendarEvent)
                        }
                    })
                )
            })
        )
    }

    getCalendarEvents(fromDateTime: moment.Moment, toDateTime: moment.Moment): Observable<CalendarEvent[]> {
        const query = new GetAllNoteListQuery()
        query.fromDateTime = fromDateTime
        query.toDateTime = toDateTime
        return this.notesService.getAllBuilderNotes(query).pipe(
            map((clientLeadNotes) => {
                this.clientLeadNotes = clientLeadNotes
                this.transformedCalendarEvents = clientLeadNotes.map((clientLeadNote) =>
                    this._convertToCalendarEvent(clientLeadNote)
                )
                return this.transformedCalendarEvents
            })
        )
    }

    private _convertToCalendarEvent(clientLeadNote: NoteDto): CalendarEvent {
        return new CalendarEvent(
            clientLeadNote.id,
            clientLeadNote.subject,
            clientLeadNote.noteType === NoteType.Appointment
                ? clientLeadNote.appointmentDateTime
                : clientLeadNote.followUpDateTime,
            clientLeadNote.followUpDateTime,
            false,
            clientLeadNote.noteType === NoteType.Appointment ? 'appointment-event' : 'followup-event',
            ''
        )
    }
}
