import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ButtonListDialogComponent } from './button-list-dialog.component'
import { ModalButton } from './models/modalButton'

@Injectable({
    providedIn: 'root',
})
export class ButtonListDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(buttons: ModalButton[], modalTitle: string = null) {
        const dialogRef = this.dialogRef.open(ButtonListDialogComponent, {
            width: '800px',
            data: {
                buttons,
                modalTitle,
            },
        })

        return dialogRef.afterClosed()
    }
}
