<mat-dialog-content>
    <div class="row mb-4">
        <div
            *ngIf="productOptionCostLine.getImagePaths().length > 0"
            class="col-md-4 col-xl-3 d-flex align-items-center flex-column"
        >
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
        </div>
        <div class="col-md-8 col-xl-9">
            <h3>{{ productOptionCostLine.getName() }}</h3>
            <div class="mt-2 ml-2">
                <div *ngIf="priceTBD; else price">Price TBD</div>
                <ng-template #price>
                    <div>{{ productPrice | currency }}</div>
                </ng-template>
            </div>
            <div class="mt-3" *ngIf="brandName">
                <h6>Brand</h6>
                <div class="ml-2">{{ brandName }}</div>
            </div>
            <!-- <div class="mt-3" *ngIf="this.productOptionCostLine.linkedProductPrice.product.categories.length > 0">
        <h6>Categories</h6>
        <div class="ml-2">
          <button *ngFor="let category of this.productOptionCostLine.linkedProductPrice.product.categories" 
            class="btn btn-primary btn-sm" type="button">
              {{category.selectedTranslation.name}}
          </button>
        </div>
      </div>  -->
            <div class="mt-3 ml-2">
                {{ productOptionCostLine.getDescription() }}
            </div>
            <div class="mt-4 d-flex w-100" *ngIf="selectionItem.hasMedia">
                <ng-container
                    *ngIf="selectionItem.images.length == 0 && selectionItem.documents.length == 0; else loadedDocs"
                >
                    <button mat-stroked-button (click)="loadMedias()" [disabled]="isLoadingMedias">
                        View Documents
                    </button>
                </ng-container>
                <ng-template #loadedDocs>
                    <strong class="mr-2">Attached Documents:</strong>
                    <mat-chip-list>
                        <ng-container *ngFor="let image of selectionItem.images; let i = index">
                            <mat-chip class="ml-2" (click)="downloadMedia(image.filePath)">
                                {{ image.fileName }}
                            </mat-chip>
                        </ng-container>
                        <ng-container *ngFor="let doc of selectionItem.documents; let i = index">
                            <mat-chip class="ml-2" (click)="downloadMedia(doc.filePath)">
                                {{ doc.uploadedFileName }}
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </ng-template>
            </div>
        </div>
    </div>
</mat-dialog-content>
