import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { BaseImageDisplay } from '../BaseImageDisplay'
import { ImageCdnService, ImageRenderSettings } from '../image-cdn.service'
import { Observable, ReplaySubject } from 'rxjs'

@Component({
    selector: 'app-image-display',
    templateUrl: './image-display.component.html',
    styleUrls: ['./image-display.component.scss'],
})
export class ImageDisplayComponent extends BaseImageDisplay implements OnInit {
    @Input() height: number
    @Input() width: number

    constructor(imageCdnService: ImageCdnService) {
        super(imageCdnService)
    }

    ngOnInit() {
        this._settings.next(new ImageRenderSettings(this.height, this.width))
    }

    // Needs to be a subject as the inputs are only received on ngonit
    private _settings = new ReplaySubject<ImageRenderSettings>()

    getRenderSettings(): Observable<ImageRenderSettings> {
        return this._settings.asObservable()
    }
}
