import { BaseTranslatableModel } from './BaseTranslatableModel'
import { TemplateCategoryTranslation, TemplateCategoryDto } from '@app/services/api.services'

export class TemplateCategoryModel extends BaseTranslatableModel<TemplateCategoryTranslation> {
    constructor(id: string, internalName: string, imageUrl: string, translations: TemplateCategoryTranslation[]) {
        super(translations)
        this.id = id
        this.internalName = internalName
        this.imageUrl = imageUrl
    }

    id?: string | null
    imagePath: string
    imageUrl: string
    internalName: string

    get name(): string {
        return this.selectedTranslation.name
    }

    static createFromDto(templateCategory: TemplateCategoryDto): TemplateCategoryModel {
        const templateCategoryModel = new TemplateCategoryModel(
            templateCategory.id,
            '',
            null,
            templateCategory.translations
        )
        templateCategoryModel.selectedTranslation = templateCategory.selectedTranslation
        return templateCategoryModel
    }

    createDto(): TemplateCategoryDto {
        const templateRoomDto = new TemplateCategoryDto()
        templateRoomDto.id = this.id
        //  templateRoomDto.internalName = this.internalName;
        templateRoomDto.translations = this.translations
        return templateRoomDto
    }
}
