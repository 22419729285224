import { Component, OnInit } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CommonComponent, FormComponent } from '@app/models/formComponent'
import { EnumListService, EnumWrap } from '@app/services/enum-list.service'
import {
    CategoricalPropertyDefinitionAllowedValueModel,
    PropertyDefinitionModel,
} from '@app/models/propertyDefinition/propertyDefinitionModel'
import { LanguageService } from '@app/services/language.service'
import {
    CreatePropertyDefinitionCommand,
    PropertyAllowedValueTranslationDto,
    PropertyDefinitionService,
    PropertyDefinitionType,
} from '@app/services/api.services'
import { MatDialogRef } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'

@Component({
    selector: 'app-custom-property-form-dialog',
    templateUrl: './custom-property-form-dialog.component.html',
    styleUrls: ['./custom-property-form-dialog.component.scss'],
})
export class CustomPropertyFormDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup
    availableSubTypes: EnumWrap[] = []
    model: PropertyDefinitionModel
    isCreating = false
    displayedColumns = ['label', 'internalValue', 'action']
    allowedValues: CategoricalPropertyDefinitionAllowedValueModel[] = []
    deletedAllowedValues: CategoricalPropertyDefinitionAllowedValueModel[] = []
    dataSource = new MatTableDataSource<CategoricalPropertyDefinitionAllowedValueModel>()

    constructor(
        protected dialogRef: MatDialogRef<CustomPropertyFormDialogComponent>,
        private formBuilder: FormBuilder,
        public enumListService: EnumListService,
        public languageService: LanguageService,
        private propertyDefinitionService: PropertyDefinitionService
    ) {
        super()
    }

    ngOnInit(): void {
        this.model = new PropertyDefinitionModel(null, null, null, null)
        this._setupFormControl()
    }

    get isSubTypesDisabled(): boolean {
        return !this.availableSubTypes || this.availableSubTypes.length === 0
    }

    create() {
        if (this.formGroup.valid) {
            this.isCreating = true
            this.propertyDefinitionService.createPropertyDefinitions(this._generateCreateCommand()).subscribe((id) => {
                this.isCreating = false
                this.dialogRef.close(id)
            })
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    remove(index: number) {
        this.allowedValues.splice(index, 1)
        this.allowedValuesFormArray.removeAt(index)
        this._updateDataSource()
    }

    addAllowedValue() {
        const allowedValue = new CategoricalPropertyDefinitionAllowedValueModel(null, null, [])
        this.languageService.initTranslations(allowedValue.translations, () => new PropertyAllowedValueTranslationDto())
        this.languageService.selectAnyTranslations(allowedValue)

        this.allowedValues.push(allowedValue)
        const formArray = this.allowedValuesFormArray
        formArray.push(this._createAllowedValueFormControl(allowedValue, formArray.length))
        this._updateDataSource()
    }

    get isCategoricalPropertyDefinition(): boolean {
        return this.model.type === PropertyDefinitionType.Categorical
    }

    get allowedValuesFormArray(): FormArray {
        return <FormArray>this.formGroup.get('allowedValues')
    }

    isAllowedValueFieldValid(index: number, field: string) {
        const allowedValue = this.allowedValuesFormArray.controls[index].get(field)
        return !allowedValue.valid && allowedValue.touched
    }

    private _setupFormControl() {
        this.formGroup = this.formBuilder.group({
            id: [this.model.id],
            labelIdentifier: [this.model.labelIdentifier, [Validators.required]],
            propertyDefinitionType: [this.model.type, [Validators.required]],
            propertyDefinitionSubType: [this.model.subType, [Validators.required]],
            translations: this.formBuilder.array([this._createTranslationForm(0), this._createTranslationForm(1)]),
            allowedValues: this._createAllowedValuesFormArray(this.allowedValues),
        })

        this.formGroup.get('propertyDefinitionType').valueChanges.subscribe((value) => {
            this.model.type = value
            this.availableSubTypes = this.enumListService.propertyDefinitionSubtypes.filter((subType) =>
                subType.value.startsWith(value)
            )
        })
    }

    private _createAllowedValuesFormArray(allowedValues: CategoricalPropertyDefinitionAllowedValueModel[]): FormArray {
        const formArray = this.formBuilder.array([])
        formArray.controls = allowedValues.map((allowedValue, index) =>
            this._createAllowedValueFormControl(allowedValue, index)
        )
        return formArray
    }

    private _createAllowedValueFormControl(
        allowedValue: CategoricalPropertyDefinitionAllowedValueModel,
        index: number
    ) {
        const allowedValueFormGroup = this.formBuilder.group({
            id: [allowedValue.id],
            valueIdentifier: [allowedValue.valueIdentifier, [Validators.required]],
            translations: this.formBuilder.array([
                this._createAllowedValueTranslationForm(0, allowedValue),
                this._createAllowedValueTranslationForm(1, allowedValue),
            ]),
        })

        allowedValueFormGroup.get('translations').valueChanges.subscribe((value) => {
            const allowedValueModel = this.allowedValues[index]
            if (allowedValueModel) {
                allowedValueModel.translations = value
                this.languageService.selectAnyTranslations(allowedValueModel)
            }
        })
        return allowedValueFormGroup
    }

    private _createAllowedValueTranslationForm(
        index: number,
        allowedValue: CategoricalPropertyDefinitionAllowedValueModel
    ) {
        return this.formBuilder.group({
            allowedValue: [allowedValue.translations[index].allowedValue, [Validators.required]],
            culture: allowedValue.translations[index].culture,
        })
    }

    private _createTranslationForm(index: number) {
        return this.formBuilder.group({
            label: [this.model.translations[index].label, [Validators.required]],
            culture: this.model.translations[index].culture,
        })
    }

    private _generateCreateCommand(): CreatePropertyDefinitionCommand {
        const cmd = new CreatePropertyDefinitionCommand()
        cmd.init(this.formGroup.value)
        return cmd
    }

    private _updateDataSource() {
        this.dataSource.data = this.allowedValues
    }
}
