import { Pipe, PipeTransform } from '@angular/core'
import { PropertyValueModel } from '@app/models/propertyValue/propertyValueModel'
import { ProductVariationValueDto, PropertyDefinitionType } from '@app/services/api.services'
import { PropertyValueUnitsService } from '@app/services/property-value-units.service'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

@Pipe({
    name: 'propertyValueUnit',
})
export class PropertyValueUnitPipe implements PipeTransform {
    constructor(private propertyValueUnitsService: PropertyValueUnitsService) {
        // simulate http call
    }

    transform(productVariationValue: ProductVariationValueDto, args?: any): Observable<string> {
        if (PropertyValueModel.subTypesWithUnits.find((t) => t === productVariationValue.subType)) {
            return this.getNumericalUnitFormatted(productVariationValue)
        }

        return of(
            productVariationValue
                ? productVariationValue.selectedTranslation === null ||
                  productVariationValue.selectedTranslation === undefined
                    ? productVariationValue.value
                    : productVariationValue.selectedTranslation.value
                : 'N/A'
        )
    }

    private getNumericalUnitFormatted(productVariationValue: ProductVariationValueDto) {
        return this.propertyValueUnitsService
            .getPropertyValueUnit(productVariationValue.propertyValueUnitId, productVariationValue.subType)
            .pipe(
                map((p) => {
                    return `${productVariationValue.value} ${p.abbreviation}`
                })
            )
    }
}
