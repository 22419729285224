import { Component, OnInit } from '@angular/core'
import { DialogCapableFormComponentId, FormComponentSettings } from '@app/models/formComponent'
import { TakeOffModel, TakeOffQuantityModel } from '@app/models/template/TakeOffModel'
import { FormContainerService } from '@app/services/form-container.service'
import {
    UnitType,
    TemplateService,
    CreateTakeOffCommand,
    EditTakeOffCommand,
    TakeOff,
    GetTakeOffsListQuery,
    TakeOffDto,
    TakeOffsService,
    CalculationOperator,
    BaseTemplateTakeOff,
    EditTakeOffQuantityCommand,
    CreateTakeOffQuantityCommand,
} from '@app/services/api.services'
import { FormControl, Validators } from '@angular/forms'
import { EnumListService } from '@app/services/enum-list.service'
import { Observable } from 'rxjs'
import { ServerSearchTableContainer } from '@app/shared/search-dialog/models/searchTableContainer'
import { TakeOffModelDataSource } from '../reusable-takeoffs-list/takeoffs-list.datasource'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { tap } from 'rxjs/operators'

export class TakeOffFormDialogSettings {
    constructor(public template: TemplateModel) {}
}

@Component({
    selector: 'app-reusable-takeoff-form',
    templateUrl: './reusable-takeoff-form.component.html',
    styleUrls: ['./reusable-takeoff-form.component.scss'],
})
export class ReusableTakeoffFormComponent extends DialogCapableFormComponentId<string, TakeOffModel> implements OnInit {
    dialogSettings: TakeOffFormDialogSettings
    template: TemplateModel

    constructor(
        formContainerService: FormContainerService,
        public enumListService: EnumListService,
        public templateService: TemplateService,
        private takeoffsService: TakeOffsService,
        private searchDialogService: SearchDialogService
    ) {
        super(new FormComponentSettings(false, 'takeoffs', 'Take Off'), formContainerService)
    }

    ngOnInit() {
        if (this.dialogSettings) {
            this.template = this.dialogSettings.template
            this.model = this.template.editingTakeOff
        }
        if (this.model) {
            this.id = this.model.id
        } else {
            this.model = new TakeOffModel(null, '', 1, UnitType.Each, false)
        }

        this._setupFormControl()
    }

    getColumnClass() {
        return this.model.isInherited ? 'col-md-3' : 'col-md-4'
    }

    editEntity(): Observable<void> {
        if (this.model.isInherited) {
            if (this.model.takeOffQuantity.isPersisted) {
                const editTakeOffQuantityCommand = new EditTakeOffQuantityCommand()
                editTakeOffQuantityCommand.init(this.formControl.value)
                if (this.template) {
                    editTakeOffQuantityCommand.templateId = this.template.id
                }
                editTakeOffQuantityCommand.takeOffId = this.model.id
                return this.templateService.editTakeOffQuantity(editTakeOffQuantityCommand).pipe(
                    tap(() => {
                        if (this.template) {
                            this.template.completeTakeOffEdit()
                        }
                    })
                )
            } else {
                const createTakeOffQuantityCommand = new CreateTakeOffQuantityCommand()
                createTakeOffQuantityCommand.init(this.formControl.value)
                if (this.template) {
                    createTakeOffQuantityCommand.templateId = this.template.id
                }
                createTakeOffQuantityCommand.takeOffId = this.model.id
                return this.templateService.createTakeOffQuantity(createTakeOffQuantityCommand).pipe(
                    tap(() => {
                        if (this.template) {
                            this.template.completeTakeOffEdit()
                        }
                    })
                )
            }
        } else {
            const cmd = new EditTakeOffCommand()
            if (this.template) {
                cmd.templateId = this.template.id
                cmd.templateType = this.template.templateType
            }
            this._populateBaseTemplateTakeOffCommand(cmd)
            return this.templateService.editTakeOff(cmd).pipe(
                tap(() => {
                    if (this.template) {
                        this.template.completeTakeOffEdit()
                    }
                })
            )
        }
    }

    createEntity(): Observable<string> {
        const cmd = new CreateTakeOffCommand()
        if (this.template) {
            cmd.templateId = this.template.id
            cmd.templateType = this.template.templateType
        }
        this._populateBaseTemplateTakeOffCommand(cmd)
        return this.templateService.createTakeOff(cmd)
    }

    editTakeOffForDynamicTakeOff() {
        this._selectTakeOffForDynamicTakeOff()
    }

    private _selectTakeOffForDynamicTakeOff() {
        const query = new GetTakeOffsListQuery()
        if (!this.template) {
            query.getOnlyGlobalTakeOffs = true
        }

        const settings = new ServerSearchTableContainer<TakeOffModel, GetTakeOffsListQuery>(
            'TakeOffs',
            query,
            new TakeOffModelDataSource(this.takeoffsService),
            true,
            [
                new DisplayColumn('Name', 'name'),
                new DisplayColumn('Unit Type', 'unitType'),
                new DisplayColumn('Default Quantity', 'dynamicQuantity'),
            ],
            [],
            (p) => {
                return TakeOffModel.createFromDto(p)
            }
        )
        if (this.id) {
            settings.excludeList = [this.id]
        }
        this.searchDialogService.showServerDialog(settings).subscribe((result) => {
            if (result && result.isPicked) {
                if (result.selectedEntity.dynamicTakeOffDepth === TakeOffModel.maxDynamicTakeOffDepth) {
                    this.notifyService.fail(
                        'Cannot use a dynamic take off that is already calculated based off an other dynamic take off.',
                        null,
                        3000
                    )
                    if (!this.model.takeOffUsedForCalculation) {
                        this.formControl.get('isDynamic').setValue(false)
                    }
                    return
                }
                this.model.takeOffUsedForCalculation = result.selectedEntity
                return
            }
            this.formControl.get('isDynamic').setValue(false)
        })
    }

    private _populateBaseTemplateTakeOffCommand(cmd: BaseTemplateTakeOff) {
        const takeOffModel = this.formControl.value as TakeOffModel
        cmd.id = takeOffModel.id
        cmd.name = takeOffModel.name
        cmd.unitType = takeOffModel.unitType
        cmd.isDynamic = takeOffModel.isDynamic
        if (takeOffModel.isDynamic) {
            cmd.calculationOperator = takeOffModel.calculationOperator
            cmd.defaultQuantity = takeOffModel.valueForDynamicCalculation
            cmd.takeOffUsedForCalculationId = this.model.takeOffUsedForCalculation.id
            cmd.dynamicTakeOffDepth = this.model.takeOffUsedForCalculation.isDynamic
                ? this.model.takeOffUsedForCalculation.dynamicTakeOffDepth + 1
                : 0
        } else {
            cmd.defaultQuantity = takeOffModel.defaultQuantity
        }
        this._updateModel(takeOffModel)
    }

    private _setupFormControl() {
        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            name: [{ value: this.model.name, disabled: !this.model.isLocalTakeOff }, Validators.required],
            unitType: [{ value: this.model.unitType, disabled: !this.model.isLocalTakeOff }],
            defaultQuantity: [{ value: this.model.inheritedQuantity, disabled: this.model.isInherited }],
            isDynamic: [{ value: this.model.isDynamic, disabled: !this.model.isLocalTakeOff }],
            calculationOperator: [{ value: this.model.calculationOperator, disabled: !this.model.isLocalTakeOff }],
            valueForDynamicCalculation: [
                { value: this.model.valueForCalculation, disabled: !this.model.isLocalTakeOff },
            ],
        })

        if (this.model.isInherited) {
            let takeOffQuantity = this.model.takeOffQuantity

            if (takeOffQuantity == null) {
                // creating new take off quantity if it doesn't exist
                takeOffQuantity = new TakeOffQuantityModel(this.model.id, this.model.defaultQuantity, false, false)
                this.model.takeOffQuantity = takeOffQuantity
            }

            this.formControl.addControl('isEnabled', new FormControl(takeOffQuantity.isEnabled))

            this.formControl.addControl(
                'quantity',
                new FormControl({
                    value: takeOffQuantity.isEnabled
                        ? takeOffQuantity.quantity
                        : this.model.isDynamic
                        ? this.model.valueForCalculation
                        : this.model.defaultQuantity,
                    disabled: !takeOffQuantity.isEnabled,
                })
            )

            this.formControl.get('quantity').valueChanges.subscribe((value) => {
                this.model.takeOffQuantity.quantity = value
            })

            this.formControl.get('isEnabled').valueChanges.subscribe((value) => {
                this.model.takeOffQuantity.isEnabled = value
                value ? this.formControl.get('quantity').enable() : this.formControl.get('quantity').disable()
            })
        }

        this.formControl.get('isDynamic').valueChanges.subscribe((value) => {
            this.model.isDynamic = value
            if (value) {
                this._selectTakeOffForDynamicTakeOff()
            } else {
                this.model.takeOffUsedForCalculation = null
            }
        })

        this.formControl.get('calculationOperator').valueChanges.subscribe((value) => {
            this.model.calculationOperator = value
        })

        this.formControl.get('valueForDynamicCalculation').valueChanges.subscribe((value) => {
            this.model.valueForDynamicCalculation = value
        })

        this.formControl.get('unitType').valueChanges.subscribe((value) => (this.model.unitType = value))
    }

    private _updateModel(takeOffModel: TakeOffModel) {
        this.model.name = takeOffModel.name
        this.model.unitType = takeOffModel.unitType
        this.model.isDynamic = takeOffModel.isDynamic
        this.model.defaultQuantity = takeOffModel.defaultQuantity
        this.model.calculationOperator = takeOffModel.calculationOperator
        this.model.valueForDynamicCalculation = takeOffModel.valueForDynamicCalculation
    }
}
