import { Component, OnInit, Input, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { LanguageService } from '@app/services/language.service'
import { Language } from '@app/models/language'
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper'
import { MediaCaption } from '@app/services/api.services'
import { ImgCropperDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { settings } from 'cluster'

@Component({
    selector: 'app-img-cropper-dialog',
    templateUrl: './img-cropper-dialog.component.html',
    styleUrls: ['./img-cropper-dialog.component.scss'],
})
export class ImgCropperDialogComponent implements OnInit {
    isCropped = false
    width: string
    height: string
    resizeToWdith: number
    aspectRatio: number
    maintainAspectRatio: boolean
    base64: any

    croppedFile: any

    @ViewChild('cropper') imgCropper: ImageCropperComponent

    constructor(
        public languageService: LanguageService,
        @Inject(MAT_DIALOG_DATA) public setting: ImgCropperDialogSettings
    ) {
        this.resizeToWdith = setting.width
        this.aspectRatio = setting.width / setting.height
        this.maintainAspectRatio = setting.maintainAspectRatio
        this.width = `${setting.width}px`
        this.height = `${setting.height}px`
    }

    ngOnInit(): void {}

    imageCropped(event: ImageCroppedEvent) {
        this.croppedFile = event.file
        this.base64 = event.base64
    }

    imageLoaded() {
        console.log('loaded')
    }

    loadImageFailed() {
        console.log('failed')
    }

    onClick() {}
}
