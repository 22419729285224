import { Observable, Subject } from 'rxjs'
import { DisplayColumn } from './displayColumn'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'

export class SearchDialogResult<T> {
    isPicked: boolean
    isAddClicked: boolean
    isEditClicked: boolean
    isCancelled: boolean

    selectedEntities: T[] = []
    entityToEdit: T

    get selectedEntity(): T {
        return this.selectedEntities[0]
    }
}

export class LocalSearchTableContainer<EntityModel> {
    constructor(
        public entities: Observable<EntityModel[]>,
        public entityName: string,
        public displayProperties: DisplayColumn[],
        public emptyResultMessage: string = null
    ) {
        this.displayActionColumn = true
        this.closeOnSelect = true
        this.actionColumnButtonIcon = 'add'
    }

    closeOnSelect: boolean
    excludeList: Array<any>
    displayActionColumn: boolean
    actionColumnButtonIcon: string
    showAddButton: boolean
}

export class ServerSearchTableContainer<EntityModel, QueryType> {
    constructor(
        public entityName: string,
        public query: QueryType,
        public server: PagingDataSource<EntityModel, QueryType>,
        public closeOnSelect: boolean,
        public displayProperties: DisplayColumn[],
        public excludeList: Array<any>,
        public mappingFunc: (p) => void = null
    ) {}

    persistedPagingKey: string
    showSelectionButton: boolean = true // this is the + icon button, by default it is shown
    showAddButton: boolean
    showEditButton: boolean
}
