<div class="row" *ngIf="!isInDialogMode">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-rose card-header-text">
                <div class="card-text">
                    <h4 class="card-title">Section Category</h4>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="form"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isInDialogMode">
    <ng-container *ngTemplateOutlet="form"></ng-container>
</ng-container>

<ng-template #form>
    <form [formGroup]="formControl" class="form-horizontal">
        <div class="row">
            <div class="col-sm-12">
                <app-multi-language
                    [placeholder]="'Name'"
                    [controlName]="'name'"
                    [translations]="formControl.get('translations')"
                ></app-multi-language>
            </div>
        </div>
    </form>
</ng-template>
