import { Component, OnInit } from '@angular/core'
import { DialogCapableFormComponentId, FormComponentSettings } from '@app/models/formComponent'
import { ContractTemplateDto } from '@app/services/api.services'
import { FormContainerService } from '@app/services/form-container.service'
import { Observable } from 'rxjs'

export class ContractTemplateDialogSettings {
    constructor(contractTemplate: string) {}
}

@Component({
    selector: 'app-generate-contract-form',
    templateUrl: './generate-contract-form.component.html',
    styleUrls: ['./generate-contract-form.component.scss'],
})
export class GenerateContractFormComponent
    extends DialogCapableFormComponentId<string, ContractTemplateDto>
    implements OnInit {
    dialogSettings: ContractTemplateDialogSettings

    constructor(formContainerService: FormContainerService) {
        super(new FormComponentSettings(true, '', 'Generate Contract', false), formContainerService)
    }

    ngOnInit(): void {
        this.formControl = this.formBuilder.group({})
    }

    editEntity(): Observable<void> {
        throw new Error('Method not implemented.')
    }
    createEntity(): Observable<string> {
        throw new Error('Method not implemented.')
    }
}
