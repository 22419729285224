<h5 class="mt-4">Material Cost Lines</h5>

<div class="row">
    <div class="col-12">
        <app-cost-line-list
            #materialCostLine
            [costLines]="currencyPrice.costLines"
            (addCostLineClick)="addCurrencyPriceCostLine(currencyPrice)"
            (editCostLineClick)="editCurrencyPriceCostLine($event, currencyPrice)"
            (removeCostLineClick)="currencyPrice.removeProductCostLine($event)"
            (costLineUpClick)="moveCostLineUp($event, currencyPrice)"
            (costLineDownClick)="moveCostLineDown($event, currencyPrice)"
            [isEditable]="isEditable"
            [costLineType]="costLineType.ProductOption"
        >
        </app-cost-line-list>
    </div>
</div>

<h5 class="mt-4">Linked Product Cost Lines</h5>
<div class="row mb-4">
    <div class="col-12">
        <app-cost-line-list
            #linkedProductCostLine
            [costLines]="currencyPrice.priceLinkCostLines"
            (addCostLineClick)="addLinkedProductCostLine(currencyPrice)"
            (editCostLineClick)="editLinkedProductCostLine($event)"
            (removeCostLineClick)="currencyPrice.removeLinkedProductCostLine($event)"
            (costLineUpClick)="movePriceLinkCostLineUp($event, currencyPrice)"
            (costLineDownClick)="movePriceLinkCostLineDown($event, currencyPrice)"
            [isEditable]="isEditable"
            [costLineType]="costLineType.LinkedProduct"
        >
        </app-cost-line-list>
    </div>
</div>
