import {
    CostLineTranslation,
    UnitType,
    BaseProductLinkCostLineDto,
    StandaloneCostLineDto,
} from '@app/services/api.services'
import { BaseTranslatableModel } from '../template/BaseTranslatableModel'
import { TakeOffModel } from '../template/TakeOffModel'
import { ProductPriceProductLinkCostLineModel } from './products/ProductPriceProductLinkCostLineModel'

export abstract class BaseCostLineModel extends BaseTranslatableModel<CostLineTranslation> {
    isEditing: boolean
    isAdded: boolean
    isDeleted: boolean
    isEdited: boolean
    isPricingEditable: boolean

    private _id?: string | null

    private _quantity: number
    private _unitCostAmount: number
    protected _gstTaxAmount = 0
    protected _pstTaxAmount = 0
    private _unitType: UnitType
    private _unitMarkupAmount: number
    private _unitMarkupPercent: number
    private _isUnitMarkupPercent: boolean
    private _isVisibleToClients: boolean
    private _useTakeOff: boolean
    private _takeOff: TakeOffModel | null
    private _hasRetailAmount: boolean
    private _retailAmount: number
    private _retailDiscountPercent: number

    abstract isUnitTypeEditable(): boolean
    abstract isUnitCostEditable(): boolean
    abstract isUnitMarkupEditable(): boolean
    abstract isDescriptionEditable(): boolean
    abstract isTakeOffsSupported(): boolean
    abstract isLinkedCostLine(): boolean
    abstract getName(): string

    constructor(
        id: string,
        quantity: number,
        unitType: UnitType,
        unitCostAmount: number,
        unitMarkupAmount: number,
        unitMarkupPercent: number,
        isUnitMarkupPercent: boolean,
        isVisibleToClients: boolean,
        translations: CostLineTranslation[]
    ) {
        super(translations)
        this._id = id
        this._quantity = quantity
        this._unitType = unitType
        this._unitCostAmount = unitCostAmount
        this._unitMarkupAmount = unitMarkupAmount
        this._unitMarkupPercent = unitMarkupPercent
        this._isUnitMarkupPercent = isUnitMarkupPercent
        this._isVisibleToClients = isVisibleToClients
    }

    public setUnitMarkupAsPercent(markupAmount: number) {
        this._isUnitMarkupPercent = false

        if (this._isUnitMarkupPercent) {
            this._unitMarkupPercent = markupAmount
        }
    }

    public setUnitMarkupAsFixed(markupAmount: number) {
        this._isUnitMarkupPercent = true

        if (!this._isUnitMarkupPercent) {
            this._unitMarkupAmount = markupAmount
        }
    }

    get id(): string {
        return this._id
    }

    set id(v: string) {
        this._id = v
    }

    get unitType(): UnitType {
        return this._unitType
    }

    set unitType(v: UnitType) {
        this._unitType = v
    }

    get isUnitMarkupPercent(): boolean {
        return this._isUnitMarkupPercent
    }

    set isUnitMarkupPercent(v: boolean) {
        this._isUnitMarkupPercent = v
    }

    get isVisibleToClients(): boolean {
        return this._isVisibleToClients
    }

    set isVisibleToClients(v: boolean) {
        this._isVisibleToClients = v
    }

    get formattedName(): string {
        if (this.useTakeOff) {
            // if (this._quantity != this.takeOff.quantity) {
            //     return `${this.takeOff.name} (${this._quantity}) - Outdated takeoff used`;
            // }
            return this.takeOff.formattedName
        }
        return ''
    }

    get quantity(): number {
        if (this.useTakeOff) {
            return this.takeOff.dynamicQuantity
        }
        return this._quantity
    }

    set quantity(v: number) {
        this._quantity = v
    }

    get takeOff(): TakeOffModel {
        return this._takeOff
    }

    set takeOff(v: TakeOffModel) {
        this._takeOff = v
    }

    get unitCostAmount(): number {
        return this._unitCostAmount
    }

    set unitCostAmount(v: number) {
        this._unitCostAmount = v
    }

    get unitMarkupAmount(): number {
        return this._unitMarkupAmount
    }

    set unitMarkupAmount(v: number) {
        this._unitMarkupAmount = v
    }

    get unitMarkupPercent(): number {
        return this._unitMarkupPercent
    }

    set unitMarkupPercent(v: number) {
        this._unitMarkupPercent = v
    }

    get useTakeOff(): boolean {
        return this._useTakeOff
    }

    set useTakeOff(v: boolean) {
        this._useTakeOff = v
    }

    get extendedPrice(): number {
        return this.quantity * this._unitCostAmount
    }

    get taxAmount(): number {
        return this._gstTaxAmount + this._pstTaxAmount
    }

    get extendedMarkupPrice(): number {
        return this.quantity * this._unitMarkupAmount
    }

    get totalPrice(): number {
        return this.extendedMarkupPrice + this.extendedPrice
    }

    get totalPriceWithTax(): number {
        return this.extendedMarkupPrice + this.extendedPrice + this.taxAmount
    }

    get hasRetailAmount(): boolean {
        return this._hasRetailAmount
    }

    set hasRetailAmount(v: boolean) {
        this._hasRetailAmount = v
    }

    get retailAmount(): number {
        return this._retailAmount
    }

    set retailAmount(v: number) {
        this._retailAmount = v
    }

    get retailDiscountPercent(): number {
        return this._retailDiscountPercent
    }

    set retailDiscountPercent(v: number) {
        this._retailDiscountPercent = v
    }

    getClass(): string {
        if (this.isAdded) {
            return 'added-row'
        }

        if (this.isEdited) {
            return 'edited-row'
        }

        if (this.isDeleted) {
            return 'deleted-row'
        }
        return ''
    }

    protected populateCommonDtoFieldsFromProduct(costLine: BaseProductLinkCostLineDto) {
        costLine.id = this.id
        costLine.quantity = this.quantity
    }

    protected populateCommonDtoFieldsFromStandalone(costLine: StandaloneCostLineDto) {
        costLine.id = this.id
        costLine.isUnitMarkupPercent = this.isUnitMarkupPercent
        costLine.unitCostAmount = this.unitCostAmount
        costLine.unitMarkupAmount = this.unitMarkupAmount
        costLine.unitMarkupPercent = this.unitMarkupPercent
        costLine.quantity = this.quantity
        costLine.translations = this.translations
    }

    protected populateCommonProductCostLineInputFieldsFromStandalone(costLine) {
        costLine.isUnitMarkupPercent = this.isUnitMarkupPercent
        costLine.unitCostAmount = this.unitCostAmount
        costLine.unitMarkupAmount = this.unitMarkupAmount
        costLine.unitMarkupPercent = this.unitMarkupPercent
        costLine.quantity = this.quantity
        costLine.translations = this.translations
    }

    protected populateCommonProductPriceProductLinkCostLineInputFieldsFromStandalone(
        costLine: ProductPriceProductLinkCostLineModel
    ) {
        costLine.unitCostAmount = this.unitCostAmount
        costLine.unitMarkupAmount = this.unitMarkupAmount
        costLine.quantity = this.quantity
    }
}
