import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { TaskModel } from '@app/models/tasks/taskModel'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CommonComponent } from '@app/models/formComponent'
import { ItemContainer } from '@app/models/util/itemContainer'

@Component({
    selector: 'app-task-form-dialog',
    templateUrl: './task-form-dialog.component.html',
    styleUrls: ['./task-form-dialog.component.scss'],
})
export class TaskFormDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup
    model: TaskModel
    entityName = 'Task'
    isEdit: boolean

    constructor(
        public dialogRef: MatDialogRef<TaskFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { task: TaskModel; settings: { allowSubTasks: boolean; disableSubTasksEdit: boolean } },
        private formBuilder: FormBuilder
    ) {
        super()
        if (data.task) {
            this.isEdit = true
            this.model = data.task
        }
    }

    ngOnInit(): void {
        this._setupformControl()
    }

    addOrEdit() {
        if (this.formGroup.valid) {
            this._populateFromModel()
            this.dialogRef.close(this.model)
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    updatedSubTasks(tasks: ItemContainer<TaskModel>[]) {
        this.model.subTasks = tasks
    }

    private _populateFromModel() {
        this.model.name = this.formGroup.get('name').value
        this.model.description = this.formGroup.get('description').value
        this.model.allocatedHours = this.formGroup.get('allocatedHours').value
        this.model.billOnAllocatedHoursExceeded = this.formGroup.get('billOnAllocatedHoursExceeded').value
        this.model.hasTimeSubTasks = this.formGroup.get('hasTimeSubTasks').value

        if (!this.model.allocatedHours) {
            this.model.allocatedHours = 0
        }

        if (!this.model.hasTimeSubTasks) {
            this.model.subTasks = []
        }
    }

    private _setupformControl() {
        if (!this.model || this.model === null) {
            this.model = new TaskModel()
        }

        this.formGroup = this.formBuilder.group({
            name: [this.model.name, [Validators.required]],
            description: [this.model.description],
            allocatedHours: [this.model.allocatedHours],
            billOnAllocatedHoursExceeded: [this.model.billOnAllocatedHoursExceeded],
            hasTimeSubTasks: [this.model.hasTimeSubTasks],
        })

        if (this.data.settings.disableSubTasksEdit) {
            // we disable this, because it's not editable, it can only be set on creation
            this.formGroup.get('hasTimeSubTasks').disable()
        }
    }
}
