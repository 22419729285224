import { NgModule } from '@angular/core'
import { CommonModule, CurrencyPipe } from '@angular/common'
import { TemplateFinancialsComponent } from './template-financials/template-financials.component'
import { MaterialModule } from '@app/material.module'
import { ClientSelectionItemCardComponent } from './client-selection-item-card/client-selection-item-card.component'
import { ClientSelectionsComponent } from './client-selections/client-selections.component'

import { ClientSelectionNotchComponent } from './client-selection-notch/client-selection-notch.component'
import { ClientSelectionListItemComponent } from './client-selection-list-item/client-selection-list-item.component'
import { TemplateAllowancesComponent } from './template-allowances/template-allowances.component'
import { ClientNestedSelectionGroupsComponent } from './client-nested-selection-groups/client-nested-selection-groups.component'
import { ImagesModule } from '../images/images.module'
import { TemplateSelectedListComponent } from './template-selected-list/template-selected-list.component'
import { TemplateSelectionGroupListComponent } from './template-selection-group-list/template-selection-group-list.component'
import { TemplateAllowanceItemListComponent } from './template-allowance-item-list/template-allowance-item-list.component'
import { PipesModule } from '../pipes/pipes.module'

@NgModule({
    declarations: [
        TemplateFinancialsComponent,
        ClientSelectionItemCardComponent,
        ClientSelectionsComponent,
        ClientSelectionNotchComponent,
        ClientSelectionListItemComponent,
        TemplateAllowancesComponent,
        ClientNestedSelectionGroupsComponent,
        TemplateSelectedListComponent,
        TemplateSelectionGroupListComponent,
        TemplateAllowanceItemListComponent,
    ],
    imports: [CommonModule, MaterialModule, ImagesModule, PipesModule],
    exports: [
        TemplateFinancialsComponent,
        ClientSelectionItemCardComponent,
        ClientSelectionsComponent,
        ClientSelectionNotchComponent,
        ClientSelectionListItemComponent,
        TemplateAllowancesComponent,
        ClientNestedSelectionGroupsComponent,
        TemplateSelectedListComponent,
    ],
    providers: [CurrencyPipe],
})
export class CommonTemplateComponentsModule {}
