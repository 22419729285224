import { NestedPropertyDto } from '@app/services/api.services'
import { TaxRateModel } from '@app/models/common/TaxRateModel'
import { PropertyPaymentModel } from './PropertyPaymentModel'

export class PropertyModel {
    constructor(property: NestedPropertyDto) {
        this.id = property.id
        this.name = property.name
        this.price = property.price
        if (property.assignedTaxRate) {
            this.assignedTaxRate = new TaxRateModel(property.assignedTaxRate)
        }
        if (property.payments) {
            this.payments = new Array<PropertyPaymentModel>()
            property.payments.forEach((p) => {
                this.payments.push(PropertyPaymentModel.createFromDto(p))
            })
        }
    }

    id: number
    name: string
    assignedTaxRate: TaxRateModel
    price: number
    payments: Array<PropertyPaymentModel>

    get totalPaymentsAmount(): number {
        if (!this.payments) {
            return 0
        }
        return this.payments.reduce((sum, payment) => {
            return sum + payment.amount
        }, 0)
    }

    get totalRemaining(): number {
        if (!this.payments) {
            return 0
        }
        return this.payments.reduce((sum, payment) => {
            return sum + (payment.isPaid ? 0 : payment.amount)
        }, 0)
    }
}
