import { Injectable } from '@angular/core'
import { MediaPickerDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { ImageMedia, MediaDto } from '@app/services/api.services'
import { MediaPickerDialogComponent } from './media-picker-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class MediaPickerDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: MediaPickerDialogSettings): Observable<MediaDto[]> {
        const dialogRef = this.dialog.open(MediaPickerDialogComponent, {
            data: settings,
            width: '1600px',
        })

        dialogRef.afterClosed().subscribe((result) => {})

        return dialogRef.afterClosed()
    }
}
