import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TextMaskModule } from 'angular2-text-mask'
import { PipesModule } from '@app/shared/pipes/pipes.module'
import { TimehseetPropertyTaskSelectComponent } from './timehseet-property-task-select/timehseet-property-task-select.component'

@NgModule({
    declarations: [TimehseetPropertyTaskSelectComponent],
    imports: [CommonFormsModule, TextMaskModule, PipesModule],
    exports: [TimehseetPropertyTaskSelectComponent],
})
export class TimesheetSharedModule {}
