<mat-dialog-content>
    <ul class="nav nav-pills nav-pills-warning nav-pills-icons justify-content-center" role="tablist">
        <li class="nav-item">
            <a class="nav-link active show" id="select-tab" data-toggle="tab" href="#select" role="tablist">
                <i class="material-icons">insert_photo</i> Select
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#upload" role="tablist">
                <i class="material-icons">cloud_upload</i> Upload
            </a>
        </li>
    </ul>

    <div class="tab-content tab-space tab-subcategories">
        <div class="tab-pane active show" id="select">
            <app-server-paging-container
                #gridContainer
                [isLoaded]="isLoaded"
                [displayMode]="displayMode"
                (reload)="filterChanges()"
                [entityName]="'Media'"
                [persistedPagingKey]="persistedPagingKey"
            >
                <div class="row mb-2">
                    <div class="col-12">
                        <mat-form-field>
                            <mat-select
                                placeholder="Project"
                                [(value)]="selectedProjectId"
                                (selectionChange)="onProjectChange($event.value)"
                            >
                                <mat-option [value]="null"> None </mat-option>
                                <mat-option *ngFor="let project of projects" [value]="project.id">
                                    {{ project.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <mat-form-field>
                            <mat-select placeholder="Language" (selectionChange)="onLanguageChange($event.value)">
                                <mat-option [value]="'All'"> All </mat-option>
                                <mat-option
                                    *ngFor="let language of languageService.supportedLanguages"
                                    [value]="language.culture"
                                >
                                    {{ language.name }}
                                </mat-option>
                                <mat-option [value]="'Not specified'"> Not specified </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col-12">
                        <h6>Labels</h6>
                        <app-label-group-drop-down
                            [labelGroupType]="LabelGroupType.ImageType"
                            [isDisabled]="labelSelectLocked"
                            [defaultLabelIds]="defaultLabelIds"
                            [isMultiSelect]="true"
                            [placeholder]="'Label'"
                            [(selectedLabels)]="selectedLabels"
                            (selectionChange)="labelSelectionChange()"
                        >
                        </app-label-group-drop-down>
                    </div>
                </div>
                <div class="row">
                    <ng-container
                        *ngIf="this.dataSource.entities && this.dataSource.entities.length > 0; else noResults"
                    >
                        <div class="d-flex align-item-center flex-wrap">
                            <div class="p-2" *ngFor="let selectableMedia of this.dataSource.entities">
                                <ng-container *ngIf="selectableMedia.media.mediaType === imageType; else document">
                                    <app-image-display
                                        [hideCheckBox]="false"
                                        [isChecked]="selectableMedia.isSelected"
                                        (onCheckedChange)="makeSelection(selectableMedia)"
                                        [src]="selectableMedia.media.filePath"
                                        width="200"
                                        height="200"
                                    ></app-image-display>
                                </ng-container>
                                <ng-template #document>
                                    <div
                                        (click)="makeSelection(selectableMedia)"
                                        class="text-center"
                                        [ngClass]="{ 'selected-media': selectableMedia.isSelected }"
                                    >
                                        <i class="fa fa-file fa-10x"></i>
                                    </div>
                                    <div class="text-center">
                                        <a>{{ selectableMedia.media.uploadedFileName }}</a>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noResults>
                        <div class="col-12 text-center" *ngIf="isLoaded">No results for this search criteria.</div>
                    </ng-template>
                </div>
            </app-server-paging-container>
        </div>
        <div class="tab-pane" id="upload">
            <app-file-select
                [setting]="settings.fileSelectSettings"
                (fileSelected)="fileSelected($event)"
                [canSelectMultipleFiles]="settings.canUploadMultipleFiles"
                #fileSelect
            ></app-file-select>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]>Cancel</button>
        <div>
            <button class="mr-2" mat-stroked-button [loading]="isLoading" (click)="upload()">Upload</button>
            <button mat-stroked-button [disabled]="!hasRequiredSelections" (click)="completeSelections()">
                Complete
            </button>
        </div>
    </div>
</mat-dialog-actions>
