import { Injectable } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Observable, Subject } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { SimpleEntityCreateFormDialogComponent } from './simple-entity-create-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class SimpleEntityCreateFormDialogService {
    constructor(private dialog: MatDialog, private formBuilder: FormBuilder) {}

    showDialog<T>(formControlName: string, title: string, createEntity: (any) => Observable<T>): Observable<T> {
        const entitySelectedObservable = new Subject<T>()
        const formGroup = this.formBuilder.group({
            name: ['', Validators.required],
        })

        const dialogRef = this.dialog.open(SimpleEntityCreateFormDialogComponent, {
            width: '600px',
            data: {
                formGroup: formGroup,
                title: title,
                createEntity: createEntity,
                formControlName: formControlName,
            },
        })

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                entitySelectedObservable.next(result)
            }
        })

        return entitySelectedObservable
    }
}
