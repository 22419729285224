import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AddressFormComponent } from './address-form.component'
import { MaterialModule } from '@app/material.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TextMaskModule } from 'angular2-text-mask'

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MaterialModule, TextMaskModule],
    declarations: [AddressFormComponent],
    exports: [AddressFormComponent],
})
export class AddressFormModule {}
