import { Injectable } from '@angular/core'
import { NotifyService } from './notify.service'
import { Router, ActivatedRoute } from '@angular/router'
import { FixedFormControlsService } from '@app/shared/fixed-form-controls/fixed-form-controls.service'
import { FormBuilder } from '@angular/forms'
import { LanguageService } from './language.service'
import { EnumListService } from './enum-list.service'

@Injectable({
    providedIn: 'root',
})
export class FormContainerService {
    isDialogMode = false

    constructor(
        public notifyService: NotifyService,
        public router: Router,
        public fixedFormControlsService: FixedFormControlsService,
        public formBuilder: FormBuilder,
        public route: ActivatedRoute,
        public languageService: LanguageService,
        public enumListService: EnumListService
    ) {}
}
