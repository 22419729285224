import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ProductCostLineModel } from '@app/models/costLines/products/ProductCostLineModel'
import { ProductCurrencyPriceModel } from '@app/models/Products/ProductVersionPriceModel'
import { ProductPickerService } from '@app/services/entity-selections/product-picker.service'
import { LanguageService } from '@app/services/language.service'
import { CostLineType } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { CostLineDialogService } from '@app/shared/cost-line-dialog/cost-line-dialog.service'
import { CostLineListComponent, CostLineToEdit } from '@app/shared/cost-line-list/cost-line-list.component'

@Component({
    selector: 'app-product-supplier-price-cost-lines',
    templateUrl: './product-supplier-price-cost-lines.component.html',
    styleUrls: ['./product-supplier-price-cost-lines.component.scss'],
})
export class ProductSupplierPriceCostLinesComponent implements OnInit {
    costLineType = CostLineType

    @Input() isEditable: boolean
    @Input() currencyPrice: ProductCurrencyPriceModel
    @Input() productId: string
    @ViewChild('materialCostLine') materialCostLine: CostLineListComponent
    @ViewChild('linkedProductCostLine') linkedProductCostLine: CostLineListComponent

    constructor(
        private costLineDialogService: CostLineDialogService,
        private languageService: LanguageService,
        private productPickerService: ProductPickerService
    ) {}

    ngOnInit(): void {}

    editCurrencyPriceCostLine(costLineToEdit: CostLineToEdit, currencyPrice: ProductCurrencyPriceModel) {
        this.costLineDialogService
            .showEditCostLineDialog(costLineToEdit.model, true, false, null, false)
            .subscribe((costLineDialogModel) => {
                if (!costLineDialogModel) {
                    return
                }
                currencyPrice.updateCostLine(<ProductCostLineModel>costLineToEdit.model, costLineDialogModel)
                this.materialCostLine.load()
            })
    }

    addCurrencyPriceCostLine(currencyPrice: ProductCurrencyPriceModel) {
        this.costLineDialogService.showAddMaterialCostLineDialog().subscribe((costLineDialogModel) => {
            if (!costLineDialogModel) {
                return
            }
            const costLine = currencyPrice.addCostLine(costLineDialogModel)
            this.languageService.selectAnyTranslations(costLine)
            this.materialCostLine.load()
        })
    }

    addLinkedProductCostLine(currencyPrice: ProductCurrencyPriceModel) {
        this.productPickerService
            .pickProductAndPrice(currencyPrice.currencyCode, [this.productId])
            .subscribe((result) => {
                if (result) {
                    const costLine = currencyPrice.addLinkedProductCostLine(result.product, result.productPrice)
                    this.languageService.selectAnyTranslations(costLine)
                    this.linkedProductCostLine.load()
                }
            })
    }

    editLinkedProductCostLine(costLineToEdit: CostLineToEdit) {
        this.costLineDialogService
            .showEditCostLineDialog(costLineToEdit.model, false, false, null, false)
            .subscribe((costLineDialogModel) => {
                costLineToEdit.model.quantity = costLineDialogModel.quantity
                this.linkedProductCostLine.load()
            })
    }

    moveCostLineUp(index: number, currencyPrice: ProductCurrencyPriceModel) {
        currencyPrice.moveCostLineUp(index)
        this.materialCostLine.load()
    }

    moveCostLineDown(index: number, currencyPrice: ProductCurrencyPriceModel) {
        currencyPrice.moveCostLineDown(index)
        this.materialCostLine.load()
    }

    movePriceLinkCostLineUp(index: number, currencyPrice: ProductCurrencyPriceModel) {
        currencyPrice.movePriceLinkCostLineUp(index)
        this.linkedProductCostLine.load()
    }

    movePriceLinkCostLineDown(index: number, currencyPrice: ProductCurrencyPriceModel) {
        currencyPrice.movePriceLinkCostLineDown(index)
        this.linkedProductCostLine.load()
    }
}
