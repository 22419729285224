import { NgModule } from '@angular/core'
import { GenerateContractFormComponent } from './generate-contract-form/generate-contract-form.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'

@NgModule({
    imports: [CommonFormsModule],
    declarations: [GenerateContractFormComponent],
    exports: [GenerateContractFormComponent],
})
export class ContractTemplatesModule {}
