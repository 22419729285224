import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery-9'
import { BrandsService } from '@app/services/api.services'
import { SelectionItemProductLinkCostLineModel } from '@app/models/costLines/selectionItems/SelectionItemProductLinkCostLineModel'
import { SelectionItemModel } from '@app/models/template/SelectionGroupModel'

@Component({
    selector: 'app-single-product-cost-line-details',
    templateUrl: './single-product-cost-line-details.component.html',
    styleUrls: ['./single-product-cost-line-details.component.scss'],
})
export class SingleProductCostLineDetailsComponent implements OnInit {
    @Input() productOptionCostLine: SelectionItemProductLinkCostLineModel
    @Input() productPrice: number
    @Input() priceTBD: boolean
    @Input() selectionItem: SelectionItemModel
    @Input() isLoadingMedias: boolean
    @Output() loadMediasEvent: EventEmitter<any> = new EventEmitter()
    brandName: string
    galleryOptions: NgxGalleryOptions[]
    galleryImages: NgxGalleryImage[]

    constructor(private brandService: BrandsService) {}

    ngOnInit() {
        this.setupGallery()

        if (!this.productOptionCostLine.product.brandId) {
            return
        }

        this.brandService.brand(this.productOptionCostLine.product.brandId).subscribe((brand) => {
            this.brandName = brand.name
        })
    }

    loadMedias() {
        this.loadMediasEvent.emit()
    }

    setupGallery() {
        this.galleryOptions = [
            {
                width: '260px',
                height: '400px',
                thumbnailsColumns:
                    this.productOptionCostLine.getImagePaths().length >= 3
                        ? 3
                        : this.productOptionCostLine.getImagePaths().length,
                imageAnimation: NgxGalleryAnimation.Slide,
                thumbnailsAutoHide: true,
                previewCloseOnClick: true,
                previewKeyboardNavigation: true,
            },
            {
                breakpoint: 1200,
                width: '200px',
                height: '300px',
            },
            {
                breakpoint: 767,
                width: '390px',
                height: '600px',
            },
        ]

        this.galleryImages = this.productOptionCostLine.getImagePaths().map((path) => {
            // this is needed otherwise: sanitizing unsafe style value url() is ran automatically
            path = path.replace('\\', '/')
            return {
                small: path,
                medium: path,
                big: path,
            }
        })

        if (this.productOptionCostLine.getImagePaths().length === 1) {
            this.galleryOptions[0].imageArrows = false
            this.galleryOptions[0].thumbnails = false
            this.galleryOptions[0].previewArrows = false
        }
    }

    downloadMedia(url: string) {
        window.open(url, '_blank')
    }
}
