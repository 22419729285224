<h2 mat-dialog-title>{{ 'Adding Property to ' + data.addingPropertyToEntity }}</h2>
<mat-dialog-content>
    <ng-container *ngIf="isLoading; else propertyValuesList">
        <mat-spinner class="my-5 mx-auto"></mat-spinner>
    </ng-container>
    <ng-template #propertyValuesList>
        <app-server-paging-container
            #tableContainer
            [displayMode]="displayMode"
            [isLoaded]="isLoaded"
            (reload)="filterChanges()"
        >
            <table mat-table [dataSource]="tableContainer.dataSource">
                <ng-container matColumnDef="label">
                    <th class="text-center" mat-header-cell *matHeaderCellDef>Label</th>
                    <td class="text-center" mat-cell *matCellDef="let element; let i = index">
                        {{ element.labelIdentifier }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{ getPropertyDefinitionType(element.propertyDefinitionType) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="subType">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Subtype</th>
                    <td mat-cell *matCellDef="let element">
                        {{ getPropertyDefinitionSubType(element.propertyDefinitionSubType) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button (click)="addPropertyValue(element)" mat-icon-button color="success">
                            <mat-icon>add</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
        </app-server-paging-container>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button cdkFocusInitial (click)="addNew()">Add New</button>
    </div>
</mat-dialog-actions>
