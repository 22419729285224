<div class="row" *ngIf="!isInDialogMode">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header card-header-rose card-header-text">
                <div class="card-text">
                    <h4 class="card-title">Product Variation Group</h4>
                </div>
            </div>
            <div class="card-body">
                <ng-container *ngTemplateOutlet="form"></ng-container>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="isInDialogMode">
    <ng-container *ngTemplateOutlet="form"></ng-container>
</ng-container>

<ng-template #form>
    <form [formGroup]="formControl" class="form-horizontal">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input type="text" matInput formControlName="name" />
                    <mat-error *ngIf="isFieldValid('name')"> Required name </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <app-product-variation-group-detail
        [model]="model"
        [isReadOnlyMode]="false"
        [hasChangesToPropertyDefinitions]="hasChangesToPropertyDefinitions()"
        (onAddProperty)="addProperty()"
        (onAddProductVariation)="addProductVariation()"
        (onRemoveProductVariation)="removeProductVariation($event)"
    ></app-product-variation-group-detail>
</ng-template>
