<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <form [formGroup]="formGroup" autocomplete="off">
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <input type="text" matInput placeholder="Name" formControlName="name" />
                    <mat-error *ngIf=""> </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="warn" [mat-dialog-close]>Close</button>
    <button mat-stroked-button cdkFocusInitial [loading]="isCreating" (click)="add()">Add</button>
</mat-dialog-actions>
