import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { SelectionConfig, SelectionDialogComponent } from './selection-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class SelectionDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog<T>(selectionConfig: SelectionConfig): Subject<T> {
        const entitySelectedObservable = new Subject<T>()
        const dialogRef = this.dialog.open(SelectionDialogComponent, {
            width: '400px',
            data: selectionConfig,
        })

        dialogRef.afterClosed().subscribe((result) => {
            entitySelectedObservable.next(result)
        })

        return entitySelectedObservable
    }
}
