import { Component, OnInit, Inject, AfterViewInit } from '@angular/core'
import { Observable } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { SimpleListComponent } from '@app/models/listComponent'
import { NotifyService } from '@app/services/notify.service'
import { map } from 'rxjs/operators'
import { LocalSearchTableContainer, SearchDialogResult } from '../models/searchTableContainer'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'

@Component({
    selector: 'app-local-search-table',
    templateUrl: './local-search-table.component.html',
    styleUrls: ['./local-search-table.component.scss'],
})
export class LocalSearchTableComponent<IdType, ModelType>
    extends SimpleListComponent<IdType, ModelType>
    implements OnInit, AfterViewInit {
    private _result: SearchDialogResult<ModelType>

    PagingDisplayModeTypes = PagingDisplayMode
    displayedColumns: string[]

    constructor(
        @Inject(MAT_DIALOG_DATA) public settings: LocalSearchTableContainer<any>,
        public dialogRef: MatDialogRef<LocalSearchTableComponent<IdType, ModelType>>,
        notifyService: NotifyService
    ) {
        super(settings.entityName, notifyService)

        this.displayedColumns = this.settings.displayProperties.map((displayProperty) =>
            displayProperty.isInTranslation
                ? `selectedTranslation.${displayProperty.propertyName}`
                : displayProperty.propertyName
        )

        this._result = new SearchDialogResult<ModelType>()

        if (settings.displayActionColumn) {
            this.displayedColumns.push('action')
        }
    }

    deleteEntity(): Observable<void> {
        throw new Error('Method not implemented.')
    }

    loadEntities(): Observable<any[]> {
        if (this.settings.excludeList && this.settings.excludeList.length > 0) {
            return this.settings.entities.pipe(
                map((entities) => {
                    return entities.filter(
                        (entity) =>
                            this.settings.excludeList.findIndex((excludeEntity) => excludeEntity.id === entity.id) ===
                            -1
                    )
                })
            )
        } else {
            return this.settings.entities
        }
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.load()
        })
    }

    select(entity: any) {
        this._result.isPicked = true
        this._result.selectedEntities.push(entity)
        if (this.settings.closeOnSelect) {
            this.dialogRef.close(this._result)
        }
    }

    addAndCloseDialog() {
        this._result.isAddClicked = true
        this.dialogRef.close(this._result)
    }

    done() {
        this.dialogRef.close(this._result)
    }
}
