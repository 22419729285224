import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TimesheetModel, TimesheetTaskModel } from '@app/models/timesheet/TimesheetModel'
import { TimesheetTaskDialogComponent } from './timesheet-task-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class TimehseetTaskDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(
        timesheet: TimesheetModel,
        isEditing: boolean,
        timesheetTask: TimesheetTaskModel,
        validationCallback: Function
    ) {
        const dialogRef = this.dialog.open(TimesheetTaskDialogComponent, {
            width: '400px',
            data: {
                timesheet: timesheet,
                isEditing: isEditing,
                timesheetTask: timesheetTask,
                validationCallback,
            },
        })
    }
}
