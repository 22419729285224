<mat-form-field>
    <input
        type="text"
        matInput
        [disabled]="disabled"
        [placeholder]="placeholder"
        [textMask]="{ mask: numberMask }"
        [errorStateMatcher]="matcher"
        [(ngModel)]="inputModel"
        (ngModelChange)="stripAndEmitChange(inputModel)"
    />
</mat-form-field>
