import { NgModule } from '@angular/core'
import { ConfirmDialogComponent } from './confirm-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TranslateModule } from '@ngx-translate/core'

import { DirectivesModule } from '../directives/directives.module'

@NgModule({
    imports: [CommonFormsModule, TranslateModule],
    declarations: [ConfirmDialogComponent],
    entryComponents: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
