<h2 mat-dialog-title>{{ title | translate }}</h2>
<mat-dialog-content>
    {{ message | translate }}
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" (click)="dialogRef.close(false)">
            {{ noButtonText | translate }}
        </button>
        <button *ngIf="!displayLoadingUntilLoaded" type="button" mat-stroked-button (click)="dialogRef.close(true)">
            {{ yesButtonText | translate }}
        </button>

        <button *ngIf="displayLoadingUntilLoaded" mat-stroked-button [loading]="isLoading" (click)="callLoad()">
            {{ yesButtonText | translate }}
        </button>
    </div>
</mat-dialog-actions>
