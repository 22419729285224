import {
    PaginatedResult,
    ProductVariationGroupLookUpDto,
    ProductVariationGroupsService,
    GetProductVariationGroupListQuery,
} from '@app/services/api.services'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'

export class ProductVariationGroupsDataSource extends PagingDataSource<
    ProductVariationGroupLookUpDto,
    GetProductVariationGroupListQuery
> {
    constructor(private service: ProductVariationGroupsService) {
        super()
    }

    getEntitiesObservable(query: GetProductVariationGroupListQuery): Observable<PaginatedResult> {
        return this.service.query(query)
    }
}
