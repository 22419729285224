import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TableContainerComponent } from './table-container.component'
import { MaterialModule } from '@app/material.module'
import { FixedFormControlsModule } from '../fixed-form-controls/fixed-form-controls.module'
import { PipesModule } from '@app/shared/pipes/pipes.module'

@NgModule({
    imports: [CommonModule, MaterialModule, FixedFormControlsModule, PipesModule],
    declarations: [TableContainerComponent],
    exports: [TableContainerComponent],
})
export class TableContainerModule {}
