import { FormGroup, FormControl, FormArray, ValidationErrors, FormBuilder } from '@angular/forms'
import { MyErrorStateMatcher } from './myErrorStateMatcher'
import { Observable, Subscription, EMPTY, Subject } from 'rxjs'
import { Router, ActivatedRoute } from '@angular/router'
import { NotifyService } from '../services/notify.service'
import { FixedFormControlsService, FixedButton } from '@app/shared/fixed-form-controls/fixed-form-controls.service'
import { OnDestroy, HostListener, Directive } from '@angular/core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { FormContainerService } from '@app/services/form-container.service'
import { LanguageService } from '@app/services/language.service'
import { EnumListService } from '@app/services/enum-list.service'
import { ComponentCanDeactivate } from '@app/shared/guards/canDeactivateGuard'
import { SerializableError } from '@app/services/api.services'
import { stringify } from '@angular/compiler/src/util'

export abstract class CommonComponent {
    numberMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        includeThousandsSeparator: false,
    })

    currencyMask = createNumberMask({
        prefix: '$ ',
        allowDecimal: true,
    })

    compareById(f1: any, f2: any): boolean {
        return f1 && f2 && f1.id === f2.id
    }

    isFieldValidOnFormControl(formControl: FormGroup, field: string) {
        return !formControl.get(field).valid && formControl.get(field).touched
    }

    fieldHasErrorOnFormControl(formControl: FormGroup, error: string, field: string) {
        return formControl.hasError(error, field) && this.isFieldValidOnFormControl(formControl, field)
    }

    emailFieldHasError(type, formControl: FormGroup) {
        return (
            formControl.hasError('email', type) &&
            this.isFieldValidOnFormControl(formControl, type) &&
            !formControl.hasError('required', type)
        )
    }

    isInteger = (control: FormControl) => {
        // here, notice we use the ternary operator to return null when value is the integer we want.
        // you are supposed to return null for the validation to pass.

        return this.checkIfInteger(control.value)
            ? null
            : {
                  notNumeric: true,
              }
    }

    checkIfInteger(value) {
        return parseFloat(value) == parseInt(value) && !isNaN(value)
    }
    isValid(formGroup: FormGroup): boolean {
        let valid = true
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field)
            if (control instanceof FormControl && !control.disabled && !control.valid) {
                valid = false
            } else if (control instanceof FormGroup && !this.isValid(control)) {
                valid = false
            } else if (control instanceof FormArray) {
                control.controls.forEach((formArrayControl: any) => {
                    if (!this.isValid(formArrayControl)) {
                        valid = false
                    }
                })
            }
        })
        return valid
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field)
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true })
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control)
            } else if (control instanceof FormArray) {
                control.controls.forEach((formArrayControl: any) => this.validateAllFormFields(formArrayControl))
            }
        })
    }

    getFormValidationErrors(formControl: FormGroup) {
        Object.keys(formControl.controls).forEach((key) => {
            const controlErrors: ValidationErrors = formControl.get(key).errors
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    console.log(
                        'Key control: ' + key + ', keyError: ' + keyError + ', err value: ',
                        controlErrors[keyError]
                    )
                })
            }
        })
    }

    findInvalidControls(formGroup: FormGroup) {
        const invalid = []
        const controls = formGroup.controls
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name)
            }
        }
        return invalid
    }

    stripCurrencyString(currenyString: string) {
        if (typeof currenyString !== 'string') {
            return currenyString
        }
        return Number(currenyString.replace(/,/g, '').replace('$', '').replace('.00', ''))
    }

    stripHourString(hourString: string) {
        if (typeof hourString !== 'string') {
            return hourString
        }
        return Number(hourString.replace(/,/g, '').replace('h', ''))
    }

    stripPercentString(percentString: string) {
        if (typeof percentString !== 'string') {
            return percentString
        }
        return Number(percentString.replace(/,/g, '').replace('% ', ''))
    }
}

export class FormComponentSettings {
    constructor(
        public usedFixedForm: boolean,
        public returnPage: string,
        public entityName: string,
        public useLanguage: boolean = false,
        public routeBack = true
    ) {}
}

@Directive()
abstract class BaseFormComponent<ModelType> extends CommonComponent {
    fixedSavedButton: FixedButton
    routeBackOnSuccess = true
    isRoutingBack = false
    formControl: FormGroup
    isSubmitting: boolean

    private _onEditSuccessSubject: Subject<void> = new Subject()

    onEditSuccessObservable: Observable<void>

    matcher = new MyErrorStateMatcher()

    childValidationFn: () => boolean

    model: ModelType
    fixedFormSub: Subscription
    notifyService: NotifyService
    router: Router
    fixedFormControlsService: FixedFormControlsService
    formBuilder: FormBuilder
    languageService: LanguageService
    enumListService: EnumListService

    constructor(
        public formComponentSettings: FormComponentSettings,
        public formContainerService: FormContainerService
    ) {
        super()

        this.notifyService = formContainerService.notifyService
        this.router = formContainerService.router
        this.fixedFormControlsService = formContainerService.fixedFormControlsService
        this.formBuilder = formContainerService.formBuilder
        this.languageService = formContainerService.languageService
        this.enumListService = formContainerService.enumListService
        this.routeBackOnSuccess = formComponentSettings.routeBack

        if (this.formContainerService.isDialogMode) {
            return
        }

        if (formComponentSettings.useLanguage) {
            this.setupLanguageChangesButton()
        }

        if (formComponentSettings.usedFixedForm) {
            this.setupSaveChangesButton()
        }

        this.onEditSuccessObservable = this._onEditSuccessSubject.asObservable()
    }

    setupSaveChangesButton() {
        this.fixedSavedButton = this.formContainerService.fixedFormControlsService.setupSingleEditButton()
        this.fixedSavedButton.tooltip = 'Save current changes'
        this.fixedFormSub = this.fixedSavedButton.buttonClickedObservable.subscribe(() => {
            this.onSubmit()
        })
    }

    setupLanguageChangesButton() {
        this.formContainerService.fixedFormControlsService.showLanguageButton(true)
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (!this.canDeactivate()) {
            $event.returnValue = window.confirm('You have unsaved changes, do you want to discard your changes?')
        }
    }

    canDeactivate(): boolean {
        if (this.isRoutingBack) {
            return true
        }
        return this.canDeactivateBase()
    }

    // can be overriden by child formComponents
    canDeactivateBase(): boolean {
        return !this.formControl.dirty
    }

    isFieldValid(field: string) {
        return this.isFieldValidOnFormControl(this.formControl, field)
    }

    fieldHasError(error: string, field: string) {
        return this.fieldHasErrorOnFormControl(this.formControl, error, field)
    }

    emailFieldHasError(type) {
        return (
            this.formControl.hasError('email', type) &&
            this.isFieldValid(type) &&
            !this.formControl.hasError('required', type)
        )
    }

    abstract onSubmit(): void

    protected handleFailure(err) {
        // check for back-end validation errors we can display to the user
        this.isSubmitting = false
        if (err instanceof SerializableError && err.errors) {
            const errors = []

            for (const [key, value] of Object.entries(err.errors)) {
                console.log(`${key}: ${value}`)

                if (value instanceof Array) {
                    for (var error of value) {
                        errors.push(error)
                    }
                } else if (typeof value === 'string') {
                    errors.push(value)
                }
            }

            if (errors.length > 0) {
                const errorMsg = errors.join(' ')

                this.formContainerService.notifyService.fail(
                    `Failed to save due to validation error(s): ${errorMsg}`,
                    null,
                    3000 * errors.length
                )

                this.fixedSavedButton?.enable()
                return
            }
        }

        this.formContainerService.notifyService.fail(`Failed to save ${this.formComponentSettings.entityName}`)
        this.fixedSavedButton?.enable()
    }

    protected handleSuccess(result = null) {
        this.formContainerService.notifyService.success(`${this.formComponentSettings.entityName} info has been saved`)
        if (this.routeBackOnSuccess) {
            this.isRoutingBack = true
            this.formContainerService.router.navigate([`/${this.formComponentSettings.returnPage}`])
        } else if (result) {
            this.formContainerService.router.navigate([`/${this.formComponentSettings.returnPage}/edit/`, result])
        }
        this.isSubmitting = false
        this.formControl.markAsPristine() // resetting the form dirty flag
        this.fixedSavedButton?.enable()
        this._onEditSuccessSubject.next()
    }
}

@Directive()
export abstract class FormComponentId<IdType, ModelType>
    extends BaseFormComponent<ModelType>
    implements OnDestroy, ComponentCanDeactivate {
    id: IdType

    constructor(
        public formComponentSettings: FormComponentSettings,
        public formContainerService: FormContainerService
    ) {
        super(formComponentSettings, formContainerService)
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }

    abstract editEntity(): Observable<void>
    abstract createEntity(): Observable<IdType>

    onSubmit() {
        this.isSubmitting = true
        if (this.childValidationFn && !this.childValidationFn()) {
            this.validateAllFormFields(this.formControl)
            this.isSubmitting = false
            return
        }
        if (this.isValid(this.formControl)) {
            this.fixedSavedButton?.disable()
            if (this.id) {
                this.editEntity().subscribe(
                    (result) => {
                        this.handleSuccess()
                    },
                    (err) => {
                        console.log(err)
                        this.handleFailure(err)
                    }
                )
            } else {
                this.createEntity().subscribe(
                    (result) => {
                        this.handleSuccess(result)
                    },
                    (err) => {
                        console.log(err)
                        this.handleFailure(err)
                    }
                )
            }
        } else {
            this.validateAllFormFields(this.formControl)
        }
    }
}

@Directive()
export abstract class FormComponent<idType, Model>
    extends BaseFormComponent<Model>
    implements OnDestroy, ComponentCanDeactivate {
    id: idType
    matcher = new MyErrorStateMatcher()

    model: Model
    fixedFormSub: Subscription
    routeBackOnSuccess = true
    formControl: FormGroup
    notifyService: NotifyService
    router: Router
    fixedFormControlsService: FixedFormControlsService
    formBuilder: FormBuilder
    languageService: LanguageService
    enumListService: EnumListService

    isRoutingBack = false
    fixedSavedButton: FixedButton

    childValidationFn: () => boolean

    constructor(
        public formComponentSettings: FormComponentSettings,
        public formContainerService: FormContainerService
    ) {
        super(formComponentSettings, formContainerService)
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }

    abstract editEntity(): Observable<Model>
    abstract createEntity(): Observable<Model>

    onSubmit() {
        if (this.childValidationFn && !this.childValidationFn()) {
            return
        }

        if (this.formControl.valid) {
            this.fixedSavedButton.disable()
            if (this.id) {
                this.editEntity().subscribe(
                    (result) => {
                        this.handleSuccess()
                    },
                    (err) => {
                        this.handleFailure(err)
                    }
                )
            } else {
                this.createEntity().subscribe(
                    (result) => {
                        this.handleSuccess()
                    },
                    (err) => {
                        this.handleFailure(err)
                    }
                )
            }
        } else {
            this.validateAllFormFields(this.formControl)
        }
    }
}

@Directive()
export abstract class DialogCapableFormComponentId<idType, Model> extends FormComponentId<idType, Model> {
    isInDialogMode = false
    formSpecificProperties: any

    constructor(formComponentSettings: FormComponentSettings, formContainerService: FormContainerService) {
        super(formComponentSettings, formContainerService)
    }

    onSubmitFromDialog(): Observable<any> {
        if (this.formControl.valid) {
            if (this.id) {
                return this.editEntity()
            } else {
                return this.createEntity()
            }
        } else {
            this.validateAllFormFields(this.formControl)
            return EMPTY
        }
    }
}

@Directive()
export abstract class DialogCapableFormComponent<idType, Model> extends FormComponent<idType, Model> {
    isInDialogMode = false
    formSpecificProperties: any

    constructor(formComponentSettings: FormComponentSettings, formContainerService: FormContainerService) {
        super(formComponentSettings, formContainerService)
    }

    onSubmitFromDialog(): Observable<any> {
        if (this.formControl.valid) {
            if (this.id) {
                return this.editEntity()
            } else {
                return this.createEntity()
            }
        } else {
            this.validateAllFormFields(this.formControl)
            return EMPTY
        }
    }
}
