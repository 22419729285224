import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PropertySelectionsUpdateSelectorDialogComponent } from './property-selections-update-selector-dialog.component'
import { FormsModule } from '@angular/forms'
import { MaterialModule } from '@app/material.module'

@NgModule({
    declarations: [PropertySelectionsUpdateSelectorDialogComponent],
    imports: [FormsModule, CommonModule, MaterialModule],
    entryComponents: [PropertySelectionsUpdateSelectorDialogComponent],
})
export class PropertySelectionsUpdateSelectorDialogModule {}
