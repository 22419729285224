<div class="row">
    <div class="col-md-4 col-12">
        <app-currency-model
            [disabled]="!isCreatingNewPrice"
            [(inputModel)]="price.unitCostAmount"
            placeholder="Unit Cost Price"
        >
        </app-currency-model>
    </div>

    <div class="col-md-4 col-12">
        <app-currency-model
            [(inputModel)]="price.unitMarkupAmount"
            placeholder="Unit Markup Price"
            [disabled]="!isCreatingNewPrice"
        >
        </app-currency-model>
    </div>

    <div class="col-md-4 col-12">
        <app-currency-model [disabled]="!isCreatingNewPrice" [(inputModel)]="price.unitAmount" placeholder="Unit Price">
        </app-currency-model>
    </div>

    <div class="col-md-4 col-12">
        <mat-form-field>
            <mat-select placeholder="Unit Type" [(ngModel)]="price.serviceUnit" [disabled]="!isCreatingNewPrice">
                <mat-option *ngFor="let serviceUnitType of enumListService.unitType" [value]="serviceUnitType.value">
                    {{ serviceUnitType.display }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
