import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { ItemContainer, ItemState } from '@app/models/util/itemContainer'
import {
    ProductVariationDto,
    ProductVariationGroupDto,
    ProductVariationGroupsService,
} from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'

@Component({
    selector: 'app-product-variation-group-detail',
    templateUrl: './product-variation-group-detail.component.html',
    styleUrls: ['./product-variation-group-detail.component.scss'],
})
export class ProductVariationGroupDetailComponent implements OnInit {
    displayedColumns = ['name', 'action']

    dynamicDisplayedColumns: Array<{ displayLabel: string; labelIdentifier: string }> = []

    isLoadingPropertyDefinitions = true

    propertyDefinitionContainers: ItemContainer<{
        id: string
        labelIdentifier: string
    }>[] = []

    productVariations: ItemContainer<ProductVariationDto>[] = []

    @Input() model: ProductVariationGroupDto
    @Input() isReadOnlyMode: boolean
    @Input() hasChangesToPropertyDefinitions: boolean

    @Output() onAddProperty = new EventEmitter()
    @Output() onRemoveProductVariation = new EventEmitter<
        ItemContainer<{
            id: string
            labelIdentifier: string
        }>
    >()
    @Output() onAddProductVariation = new EventEmitter()

    constructor(
        private languageService: LanguageService,
        private productVariationGroupService: ProductVariationGroupsService,
        public router: Router
    ) {}

    ngOnInit(): void {
        if (this.model.id) {
            this.productVariationGroupService
                .getPropertyDefinitions(this.model.id, this.languageService.userSelectedLanguage.culture)
                .subscribe((propertyDefinitions) => {
                    this.propertyDefinitionContainers = propertyDefinitions.map(
                        (p) =>
                            new ItemContainer(
                                { id: p.id, labelIdentifier: p.displayLabelIdentifier },
                                ItemState.AlreadyAdded
                            )
                    )
                    const newDisplayColumns = ['name']
                    const dynamicDisplayedColumns = []

                    propertyDefinitions.forEach((p) => {
                        dynamicDisplayedColumns.push({
                            labelIdentifier: p.labelIdentifier,
                            displayLabel: p.displayLabelIdentifier,
                        })
                        newDisplayColumns.push(p.labelIdentifier)
                    })

                    newDisplayColumns.push('action')

                    this.displayedColumns = newDisplayColumns
                    this.dynamicDisplayedColumns = dynamicDisplayedColumns
                })

            this.productVariationGroupService.getProductVariations(this.model.id).subscribe((propertyVariants) => {
                this.productVariations = propertyVariants.map((p) => new ItemContainer(p, ItemState.AlreadyAdded))
            })
        }
    }

    goToProduct(productVariation: ProductVariationDto) {
        this.router.navigate(['products', 'edit', productVariation.productId])
    }

    getVariationValue(labelIdentifier: string, propertyValue: ProductVariationDto) {
        const productVariationValue = propertyValue.productVariationValues[labelIdentifier]

        return productVariationValue
    }
}
