import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs'
import { SelectSelectionGroupsToUsePriceDialogComponent } from './select-selection-groups-to-use-price-dialog.component'
import { SelectionGroupUsingProductDto } from '@app/services/api.services'

@Injectable({
    providedIn: 'root',
})
export class SelectSelectionGroupsToUsePriceDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(selectionGroups: SelectionGroupUsingProductDto[]): Observable<Array<string>> {
        const dialogRef = this.dialog.open(SelectSelectionGroupsToUsePriceDialogComponent, {
            width: '600px',
            data: {
                selectionGroups: selectionGroups,
            },
        })
        return dialogRef.afterClosed()
    }
}
