import { Component, OnInit, Inject, Optional } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TemplateModel } from '@app/models/template/TemplateModel'
import {
    PackagesService,
    SelectionGroupsService,
    ApplyPackageCommand,
    SelectedPackageSelectionDto,
    UnapplyPackageCommand,
} from '@app/services/api.services'
import { SelectionItemDetailsDialogService } from '../dialog/selection-item-details-dialog/selection-item-details-dialog.service'
import { SelectionGroupModel, SelectionItemModel } from '@app/models/template/SelectionGroupModel'
import { map, flatMap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { AppliedPackageModel, AppliedPackageSelectionModel } from '@app/models/packages/appliedPackageModel'
import { NotifyService } from '@app/services/notify.service'

@Component({
    selector: 'app-package-applier-dialog',
    templateUrl: './package-applier-dialog.component.html',
    styleUrls: ['./package-applier-dialog.component.scss'],
})
export class PackageApplierDialogComponent implements OnInit {
    isApplying = false
    isUnapplying = false

    disableApplyButtons

    displayedTab = 0
    propertyTemplate: TemplateModel
    appliedPackage: AppliedPackageModel
    displayModeOnly: boolean
    selectionGroup: SelectionGroupModel
    selectedAppliedPackageSelection: AppliedPackageSelectionModel

    constructor(
        @Optional() public dialogRef: MatDialogRef<PackageApplierDialogComponent>,
        private packageService: PackagesService,
        private selectionGroupsService: SelectionGroupsService,
        private selectionItemDetailsDialogService: SelectionItemDetailsDialogService,
        private notifyService: NotifyService,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        data: {
            propertyTemplate: TemplateModel
            appliedPackageObservable: Observable<AppliedPackageModel>
            displayModeOnly: boolean
        }
    ) {
        this.disableApplyButtons = data.displayModeOnly

        this.propertyTemplate = data.propertyTemplate

        data.appliedPackageObservable.subscribe((appliedPackage) => {
            this.appliedPackage = appliedPackage
        })

        this.displayModeOnly = this.displayModeOnly
    }

    replaceDefaultSelectionItem(selectionItem: SelectionItemModel) {
        this.selectedAppliedPackageSelection.replaceDefaultSelectionItem(selectionItem)
        this.displayedTab = 0
    }

    ngOnInit() {}

    toggleSelect(appliedPackageSelection: AppliedPackageSelectionModel) {
        if (this.appliedPackage.package.isApplied) {
            return
        }

        appliedPackageSelection.toggleSelect()
    }

    applyPackage() {
        const command = new ApplyPackageCommand()

        command.packageId = this.appliedPackage.package.id
        command.propertyTemplateId = this.propertyTemplate.id
        command.selectedSelectionItems = this.appliedPackage.selectedPackageSelections.map(
            (selectedPackageSelection) =>
                new SelectedPackageSelectionDto({
                    packageSelectionId: selectedPackageSelection.packageSelection.id,
                    selectedSelectionItemId: selectedPackageSelection.selectionItem.id,
                })
        )

        this.packageService.applyPackage(command).subscribe(
            () => {
                this.appliedPackage.package.apply()
                this.notifyService.success('Package applied')
                this.dialogRef.close(this.appliedPackage)
            },
            (error) => {
                console.log(error)
                this.notifyService.fail('Failed to apply package')
            }
        )
    }

    unapplyPackage() {
        const command = new UnapplyPackageCommand()
        command.packageId = this.appliedPackage.package.id
        command.propertyTemplateId = this.propertyTemplate.id

        this.packageService.unapplyPackage(command).subscribe(
            () => {
                this.appliedPackage.package.unapply()
                this.notifyService.success('Package unapplied')
                this.dialogRef.close(this.appliedPackage)
            },
            (error) => {
                console.log(error)
                this.notifyService.fail('Failed to unapply package')
            }
        )
    }

    showDetail(selectionGroupId: string, selectionItemId: string) {
        this._retrieveSelectionGroup(selectionGroupId).subscribe((selectionGroup) => {
            const selectionItem = selectionGroup.selectionItems.find((s) => s.id === selectionItemId)
            this.selectionItemDetailsDialogService.showDialog(selectionItem, selectionGroup, null, null, true)
        })
    }

    goBack() {
        this.displayedTab = 0
    }

    replaceSelectionItem(appliedPackageSelectionModel: AppliedPackageSelectionModel) {
        this.selectedAppliedPackageSelection = appliedPackageSelectionModel
        this.displayedTab = 1
        this.selectionGroup = undefined
        this._retrieveSelectionGroup(appliedPackageSelectionModel.packageSelection.selectionGroup.id).subscribe(
            (selectionGroup) => {
                this.selectionGroup = selectionGroup
            }
        )
    }

    private _retrieveSelectionGroup(selectionGroupId: string): Observable<SelectionGroupModel> {
        return this.selectionGroupsService
            .selectionGroup(selectionGroupId)
            .pipe(map((selectionGroup) => SelectionGroupModel.createFromDto(selectionGroup)))
    }
}
