import { ServiceLookupDto, GetServicesQuery, PaginatedResult, ServiceRatesService } from '@app/services/api.services'
import { Observable } from 'rxjs'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'

export class ServiceRateDataSource extends PagingDataSource<ServiceLookupDto, GetServicesQuery> {
    constructor(private service: ServiceRatesService) {
        super()
    }

    getEntitiesObservable(query: GetServicesQuery): Observable<PaginatedResult> {
        return this.service.services(query)
    }
}
