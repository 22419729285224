import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

declare const $: any

interface INotify {
    success(message: string)
    fail(message: string)
}

@Injectable()
export class NotifyService implements INotify {
    success(message: string) {
        this._snackBar.open(message, null, {
            duration: 1000,
            verticalPosition: 'top',
            panelClass: ['snackbar-success'],
        })
    }

    fail(message: string, error: string = null, duration: number = 1000) {
        this._snackBar.open(message, null, {
            duration: duration,
            verticalPosition: 'top',
            panelClass: ['snackbar-danger'],
        })

        if (error !== null) {
            console.log(error)
        }
    }

    constructor(private _snackBar: MatSnackBar) {}
}
