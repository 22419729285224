import { NgModule } from '@angular/core'
import { ManageProductVariationPropertyDialogComponent } from './manage-product-variation-property-dialog.component'
import { ManageProductVariationPropertyDialogService } from './manage-product-variation-property-dialog.service'
import { MaterialModule } from '@app/material.module'
import { CommonFormsModule } from 'common-forms/common-forms.module'

@NgModule({
    declarations: [ManageProductVariationPropertyDialogComponent],
    imports: [CommonFormsModule, MaterialModule],
    providers: [ManageProductVariationPropertyDialogService],
})
export class ManageProductVariationPropertyDialogModule {}
