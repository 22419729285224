<h2 mat-dialog-title>Selection Details</h2>
<ng-container *ngIf="hasSingleProductCostLine; else multipleCostLines">
    <app-single-product-cost-line-details
        [productOptionCostLine]="this.selectionItem.productLinkCostLines[0]"
        [productPrice]="selectionItem.totalPrice"
        [priceTBD]="selectionItem.priceTBD"
        [selectionItem]="selectionItem"
        (loadMediasEvent)="loadMedias()"
        [isLoadingMedias]="isLoadingMedias"
    >
    </app-single-product-cost-line-details>
</ng-container>
<ng-template #multipleCostLines>
    <mat-dialog-content>
        <div class="row mb-4">
            <div class="col-md-4 col-xl-3 d-flex align-items-center flex-column">
                <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>

                <div class="mt-2 text-right pull-right">
                    <div *ngIf="!selectionItem.priceTBD; else priceTBD">
                        {{ selectionItem.totalPrice | currency }}
                    </div>
                    <ng-template #priceTBD> Price TBD </ng-template>
                </div>
            </div>
            <div class="col-md-8 col-xl-9">
                <table
                    mat-table
                    [dataSource]="selectionItem.productLinkCostLines"
                    *ngIf="selectionItem.productLinkCostLines.length > 0"
                >
                    <ng-container matColumnDef="index">
                        <th class="text-center" mat-header-cell *matHeaderCellDef>#</th>
                        <td class="text-center" mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.getName() }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.getDescription() }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="productOptionCostLineDisplayedColumns"></tr>
                    <tr
                        [class.mat-row-selected]="i === selectedRowIndex"
                        mat-row
                        [className]="row.getClass()"
                        *matRowDef="let row; columns: productOptionCostLineDisplayedColumns; let i = index"
                        (click)="selectRow(i)"
                    ></tr>
                </table>

                <table mat-table [dataSource]="selectionItem.costLines" *ngIf="selectionItem.costLines.length > 0">
                    <ng-container matColumnDef="index">
                        <th class="text-center" mat-header-cell *matHeaderCellDef>#</th>
                        <td class="text-center" mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.getName() }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="optionCostLineDisplayColumns"></tr>
                    <tr
                        [class.mat-row-selected]="i === selectedRowIndex"
                        mat-row
                        [className]="row.getClass()"
                        *matRowDef="let row; columns: optionCostLineDisplayColumns; let i = index"
                        (click)="selectRow(i)"
                    ></tr>

                    <!-- <tr mat-footer-row *matFooterRowDef="['totalProductPrice']"></tr> -->
                </table>
                <div class="mt-4 d-flex w-100" *ngIf="selectionItem.hasMedia">
                    <ng-container
                        *ngIf="selectionItem.images.length == 0 && selectionItem.documents.length == 0; else loadedDocs"
                    >
                        <button mat-stroked-button (click)="loadMedias()" [disabled]="isLoadingMedias">
                            View Documents
                        </button>
                    </ng-container>
                    <ng-template #loadedDocs>
                        <strong class="mr-2">Attached Documents:</strong>
                        <mat-chip-list>
                            <ng-container *ngFor="let image of selectionItem.images; let i = index">
                                <mat-chip class="ml-2" (click)="downloadMedia(image.filePath)">
                                    {{ image.fileName }}
                                </mat-chip>
                            </ng-container>
                            <ng-container *ngFor="let doc of selectionItem.documents; let i = index">
                                <mat-chip class="ml-2" (click)="downloadMedia(doc.filePath)">
                                    {{ doc.uploadedFileName }}
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </ng-template>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<app-client-nested-selection-groups
    *ngIf="selectionItem.hasNestedOptionGroups()"
    [nestedSelectionGroups]="selectionItem.nestedSelectionGroups"
    [parentSelectionGroup]="selectionGroup"
>
</app-client-nested-selection-groups>

<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]="false">Close</button>
        <div *ngIf="!data.isDisplayOnly">
            <button
                *ngIf="!selectionItem.isSelected"
                mat-stroked-button
                cdkFocusInitial
                (click)="selectConfirmationAndCloseDialog()"
            >
                Select
            </button>

            <button
                *ngIf="selectionItem.isSelected"
                mat-stroked-button
                cdkFocusInitial
                (click)="selectConfirmationAndCloseDialog()"
            >
                Unselect
            </button>
        </div>
    </div>
</mat-dialog-actions>
