import { EmployeeApplicationSettingsDto } from '@app/services/api.services'

export class EmployeeApplicationSettingsModel {
    preferredStartTimeForTimesheetTask: string

    static create(settings: EmployeeApplicationSettingsDto): EmployeeApplicationSettingsModel {
        const settingsModel = new EmployeeApplicationSettingsModel()
        settingsModel.preferredStartTimeForTimesheetTask = settings.preferredStartTimeForTimesheetTask
        return settingsModel
    }

    createDto(): EmployeeApplicationSettingsDto {
        const settings = new EmployeeApplicationSettingsDto()
        settings.preferredStartTimeForTimesheetTask = this.preferredStartTimeForTimesheetTask
        return settings
    }
}
