import { ITranslation } from '@app/services/api.services'
import { Language } from '../language'

export abstract class BaseTranslatableModel<Model> {
    selectedTranslation?: Model | null
    translations?: Model[] | null

    supportedLanguages = [new Language('en-CA', 'English', 'EN'), new Language('fr-CA', 'French', 'FR')]

    constructor(translations: Model[]) {
        this.translations = translations
    }

    initTranslations<T>(translations: ITranslation[], createTran: () => ITranslation) {
        for (let index = 0; index < this.supportedLanguages.length; index++) {
            const translationToUse = this.supportedLanguages[index]
            const translation = createTran()
            translation.culture = translationToUse.culture
            translations.push(translation)
        }
    }
}
