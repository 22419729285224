import { NgModule } from '@angular/core'
import { SelectionDialogComponent } from './selection-dialog.component'
import { MaterialModule } from '@app/material.module'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@NgModule({
    imports: [CommonModule, MaterialModule, FormsModule],
    declarations: [SelectionDialogComponent],
    exports: [SelectionDialogComponent],
    entryComponents: [SelectionDialogComponent],
})
export class SelectionDialogModule {}
