import { Input, Directive } from '@angular/core'
import { SelectionItemModel, SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { ActivatedRoute, Router } from '@angular/router'
import { NotifyService } from '@app/services/notify.service'
import { ClientPortalSelectionService } from '@app/services/client-portal.service'
import { map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
@Directive()
export abstract class BaseSelectionComponent {
    @Input() selectionItem: SelectionItemModel
    @Input() selectionGroup: SelectionGroupModel
    @Input() parentSelectionGroup: SelectionGroupModel
    @Input() showSelectionButtons: boolean

    @Input() useDisplayImage: boolean

    constructor(
        private route: ActivatedRoute,
        protected router: Router,
        protected notifyService: NotifyService,
        private clientPortalSelectionService: ClientPortalSelectionService
    ) {}

    showNestedGroups(selectionItem: SelectionItemModel) {
        if (selectionItem.nestedSelectionGroups && selectionItem.nestedSelectionGroups.length > 0) {
            if (this.selectionGroup instanceof SelectionGroupModel) {
                this.router.navigate(['./' + selectionItem.id], { relativeTo: this.route })
            } else {
                this.router.navigate([selectionItem.id], { relativeTo: this.route })
            }
        }
    }

    getImageUrl() {
        return this.selectionItem.getDisplayImageUrl(this.useDisplayImage)
    }

    selectionConfirmationObservable(): Observable<boolean> {
        if (this.selectionGroup.isLocked) {
            this.notifyService.fail("Your selection can't be changed at this point.")
            return of(false)
        }

        return this.clientPortalSelectionService
            .toggleSelectionItem(this.selectionItem, this.selectionGroup, this.parentSelectionGroup)
            .pipe(
                map(() => {
                    return true
                })
            )
    }

    selectOption() {
        this.selectionConfirmationObservable().subscribe(
            () => {},
            (error) => {
                this.notifyService.fail('Failed to save selection')
                console.log(error)
            }
        )
    }

    // selectConfirmation() {
    //   this.selectionConfirmationObservable().subscribe((result) => {

    //   }, error => {
    //     console.log(error);
    //     this.notifyService.fail('Failed to save your selections');
    //   });
    // }
}
