<ng-container *ngIf="!isLoaded; else loaded">
    <div class="row">
        <div class="col-12">Checking if we can associate package...</div>
    </div>
    <mat-spinner class="mt-5 mx-auto"></mat-spinner>
</ng-container>

<ng-template #loaded>
    <h2 mat-dialog-title>{{ title }}</h2>
    <mat-dialog-content>
        <ng-container *ngIf="selectionGroupsToAssociate && selectionGroupsToAssociate.length > 0; else confirm">
            <div class="row">
                <div class="col-12">
                    {{ desc }}
                </div>
            </div>
            <div class="row" *ngFor="let sg of selectionGroupsToAssociate; let i = index">
                <div class="col-12 sgTitle">{{ i + 1 }} - {{ sg.formattedName }}</div>
                <div class="col-md-6" *ngIf="templateRooms">
                    <mat-form-field>
                        <mat-select placeholder="Section Room" [(value)]="sg.templateRoom">
                            <mat-option value="">None</mat-option>
                            <mat-option
                                *ngFor="
                                    let room of templateRooms | orderTranslationBy: { property: 'name', direction: 1 }
                                "
                                [value]="room"
                            >
                                {{ room.selectedTranslation.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-6" *ngIf="templateCategories">
                    <mat-form-field>
                        <mat-select placeholder="Section Category" [(value)]="sg.templateCategory">
                            <mat-option value="">None</mat-option>
                            <mat-option
                                *ngFor="
                                    let category of templateCategories
                                        | orderTranslationBy: { property: 'name', direction: 1 }
                                "
                                [value]="category"
                            >
                                {{ category.selectedTranslation.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mat-error mb-4" *ngIf="!areAllRoomsCategoriesSet">
                    All selection groups need to have a room and category selected
                </div>
            </div>
        </ng-container>
        <ng-template #confirm>
            <div class="row">
                <div class="col-12">
                    {{ confirmation }}
                </div>
            </div>
        </ng-template>
    </mat-dialog-content>
</ng-template>
<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" (click)="dialogRef.close(false)">No</button>
        <button mat-stroked-button (click)="confirmPackageApply()">Yes</button>
    </div>
</mat-dialog-actions>
