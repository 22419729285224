import { PropertyPaymentDto, PaymentType } from '@app/services/api.services'
import { Moment } from 'moment'

export class PropertyPaymentModel {
    id: string
    propertyId: number
    scheduledDateUtc: Moment
    paidDateUtc: Moment
    isPaid: boolean
    amount: number
    paymentType: PaymentType
    comment: string

    constructor(
        id: string,
        propertyId: number,
        scheduledDateUtc: Moment,
        paidDateUtc: Moment,
        isPaid: any,
        amount: number,
        paymentType: PaymentType,
        comment: string
    ) {
        this.id = id
        this.propertyId = propertyId
        this.scheduledDateUtc = scheduledDateUtc
        this.paidDateUtc = paidDateUtc
        if (isPaid instanceof String || typeof isPaid === 'string') {
            this.isPaid = isPaid === 'true'
        } else {
            this.isPaid = isPaid
        }
        this.amount = amount
        this.paymentType = paymentType
        this.comment = comment
    }

    static createFromDto(dto: PropertyPaymentDto) {
        const propertyPaymentModel = new PropertyPaymentModel(
            dto.id,
            dto.propertyId,
            dto.scheduledDateUtc,
            dto.paidDateUtc,
            dto.isPaid,
            dto.amount,
            dto.paymentType,
            dto.comment
        )
        return propertyPaymentModel
    }

    createDto() {
        return new PropertyPaymentDto(this)
    }
}
