import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { CostLineType } from '../cost-line-dialog/cost-line-dialog.component'
import { Product, TakeOffsService } from '@app/services/api.services'
import { MatTableDataSource } from '@angular/material/table'
import { BaseCostLineModel } from '@app/models/costLines/BaseCostLineModel'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { FormGroup, FormBuilder } from '@angular/forms'
import { CommonComponent } from '@app/models/formComponent'
import { EnumListService } from '@app/services/enum-list.service'

export class CostLineToEdit {
    constructor(public model: BaseCostLineModel, public index: number) {}
}

@Component({
    selector: 'app-cost-line-list',
    templateUrl: './cost-line-list.component.html',
    styleUrls: ['./cost-line-list.component.scss'],
})
export class CostLineListComponent extends CommonComponent implements OnInit {
    numberMask = createNumberMask({
        prefix: '$ ',
        allowDecimal: true,
        decimalLimit: 6,
    })

    formGroup: FormGroup = new FormGroup({})

    isEditingRow: boolean

    dataSource: MatTableDataSource<BaseCostLineModel>
    displayedColumns: string[]
    footerDisplayColumns = ['action']
    @Input() costLines: BaseCostLineModel[] = []
    @Input() costLineType: CostLineType
    @Input() minCostLineRequired = 0
    @Input() isEditable = true
    @Input() excludeProduct: Product
    @Input() useOnlyGlobalTakeOffs: boolean
    @Input() selectedRowIndex: number
    @Input() isLoading = false

    @Output() rowSelected = new EventEmitter<number>()
    @Output() addCostLineClick = new EventEmitter()
    @Output() editCostLineClick = new EventEmitter<CostLineToEdit>()
    @Output() removeCostLineClick = new EventEmitter<BaseCostLineModel>()

    @Output() costLineUpClick = new EventEmitter<number>()
    @Output() costLineDownClick = new EventEmitter<number>()
    @Output() costLineEdited = new EventEmitter()

    constructor(public enumListService: EnumListService) {
        super()
    }

    ngOnInit() {
        this.displayedColumns = [
            'index',
            'description',
            'quantity',
            'unitCostAmount',
            'unitMarkupAmount',
            'unitType',
            'totalPrice',
            'action',
        ]

        this.load()
    }

    load() {
        if (!this.costLines) {
            this.costLines = []
        }

        const actionColumnAddedIndex = this.displayedColumns.findIndex((p) => p === 'action')

        if (this.isEditable && !this.displayedColumns && actionColumnAddedIndex === -1) {
            this.displayedColumns.push('action')
        } else if (!this.isEditable && actionColumnAddedIndex !== -1) {
            this.displayedColumns.splice(actionColumnAddedIndex, 1)
        }

        this.dataSource = new MatTableDataSource(this.costLines)
    }

    editCostLine(costLine: BaseCostLineModel, index: number) {
        this.editCostLineClick.emit(new CostLineToEdit(costLine, index))
    }

    doneEdit(costLine: BaseCostLineModel, updateQuantity: boolean = true) {
        if (this.formGroup.valid) {
            costLine.isEdited = true
            if (updateQuantity) {
                costLine.quantity = this.stripCurrencyString(this.formGroup.get('quantity').value)
            }
            costLine.unitCostAmount = this.stripCurrencyString(this.formGroup.get('unitCostAmount').value)
            costLine.unitMarkupAmount = this.stripCurrencyString(this.formGroup.get('unitMarkupAmount').value)

            if (costLine.isUnitCostEditable()) {
                costLine.translations[0].description = this.formGroup.get('translations').value[0].description
                costLine.translations[1].description = this.formGroup.get('translations').value[1].description
            }

            if (costLine.isUnitTypeEditable()) {
                costLine.unitType = this.formGroup.get('unitType').value
            }

            costLine.isEditing = false
            this.isEditingRow = false
        }
    }

    removeCostLine(index) {
        this.isEditingRow = false
        this.costLines.splice(index, 1)
        this.dataSource.data = this.costLines
        this.costLineEdited.emit()
    }

    calculateTotal(): number {
        let totalPrice = 0
        this.costLines.forEach((costLine) => {
            totalPrice += costLine.totalPrice
        })
        return totalPrice
    }

    selectRow(index) {
        this.selectedRowIndex = index
        this.rowSelected.emit(index)
    }

    moveCostLineUp(index: number) {
        this.costLineUpClick.emit(index)
    }

    moveCostLineDown(index: number) {
        this.costLineDownClick.emit(index)
    }
}
