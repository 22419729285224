<h2 mat-dialog-title>
    Create Label
    <ng-container *ngFor="let language of languageService.supportedLanguages">
        <button
            *ngIf="languageService.formSelectedLanguage !== language"
            class="btn btn-sm btn-rose btn-just-icon btn-round d-block btn-lang"
            (click)="languageService.changeLanguage(language)"
        >
            {{ language.abvreiation }}
        </button>
    </ng-container>
</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <app-multi-language
            [placeholder]="'Name'"
            [controlName]="'name'"
            [translations]="form.get('translations')"
        ></app-multi-language>
    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="w-100 d-flex justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]>{{ 'CANCEL' | translate }}</button>
        <button mat-stroked-button cdkFocusInitial [loading]="isSubmitting" (click)="createLabel()">
            {{ 'CREATE' | translate }}
        </button>
    </div>
</mat-dialog-actions>
