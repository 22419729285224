import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ServiceSupplierPriceFormDialogComponent } from './service-rate-supplier-price-form-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class ServiceSupplierPriceFormDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(data: {
        createNewPrice: boolean
        serviceRateId: string
        selectedSupplier?: { id: string; name: string }
        selectedProjectId?: number
        selectedPriceId?: string | null
    }) {
        const dialogRef = this.dialogRef.open(ServiceSupplierPriceFormDialogComponent, {
            width: '1200px',
            data: {
                ...data,
            },
        })

        return dialogRef.afterClosed()
    }
}
