import { Component, OnInit } from '@angular/core'
import { Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AllowanceModel } from '@app/models/allowances/allowanceModel'
import { DialogCapableFormComponentId, FormComponentSettings } from '@app/models/formComponent'
import {
    AllowancesService,
    CreateAllowanceCommand,
    AllowanceDto,
    AllowanceTranslation,
    EditAllowanceCommand,
} from '@app/services/api.services'
import { FormContainerService } from '@app/services/form-container.service'
import { FixedButton } from '@app/shared/fixed-form-controls/fixed-form-controls.service'
import { Observable } from 'rxjs'
import { flatMap } from 'rxjs/operators'
import { TemplateStoreService } from '@app/admin-pages/templates/template-store.service'

@Component({
    selector: 'app-allowance-form-dialog',
    templateUrl: './allowance-form-dialog.component.html',
    styleUrls: ['./allowance-form-dialog.component.scss'],
})
export class AllowanceFormDialogComponent
    extends DialogCapableFormComponentId<number, AllowanceModel>
    implements OnInit {
    fixedAddAllowanceButton: FixedButton

    constructor(
        private apiService: AllowancesService,
        private templateStoreService: TemplateStoreService,
        formContainerService: FormContainerService
    ) {
        super(new FormComponentSettings(true, 'allowances', 'Allowances'), formContainerService)
    }

    ngOnInit() {
        this._setupformControl()
    }

    editEntity(): Observable<void> {
        const command = new EditAllowanceCommand()
        return this.templateStoreService.template.pipe(
            flatMap((template) => {
                this._populateCommand(command, template)
                command.id = this.id
                return this.apiService.edit(command)
            })
        )
    }

    createEntity(): Observable<number> {
        const command = new CreateAllowanceCommand()
        return this.templateStoreService.template.pipe(
            flatMap((template) => {
                this._populateCommand(command, template)
                return this.apiService.create(command)
            })
        )
    }

    private _populateCommand(command: any, template) {
        command.init(this.formControl.value)
        command.customAllowanceAmount = this.stripCurrencyString(this.formControl.get('customAllowanceAmount').value)
        command.selectionSheetId = template.id
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new AllowanceModel(null, [], [], 0, false)
            this.model.translations = []
        } else {
            this.id = this.model.id
        }

        this.languageService.initTranslations(this.model.translations, () => new AllowanceTranslation())

        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            translations: this.formBuilder.array([
                this._createTranslationForm(0, this.model),
                this._createTranslationForm(1, this.model),
            ]),
            customAllowanceAmount: [{ value: 0, disabled: true }],
            useCustomAllowanceAmount: false,
        })

        this.formControl.get('useCustomAllowanceAmount').valueChanges.subscribe((value) => {
            if (value) {
                this.formControl.get('customAllowanceAmount').enable()
            } else {
                this.formControl.get('customAllowanceAmount').disable()
            }
        })
    }

    private _createTranslationForm(index, entity) {
        return this.formBuilder.group({
            name: [{ value: entity.translations[index].name, disabled: entity.isDefault }, [Validators.required]],
            culture: entity.translations[index].culture,
            id: entity.translations[index].id,
        })
    }
}
