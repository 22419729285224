import { Injectable } from '@angular/core'
import { FormDialogSettings } from '@app/models/dialogs/formDialogSettings'
import { MatDialog } from '@angular/material/dialog'
import { Observable } from 'rxjs/internal/Observable'
import {
    Brand,
    TemplateRoom,
    TemplateCategory,
    LabelGroupDto,
    AllowanceDto,
    ContractTemplateDto
} from '@app/services/api.services'
import { BrandFormComponent } from '@app/admin-pages/brands/brand-form/brand-form.component'
import { TemplateRoomFormComponent } from '@app/admin-pages/template-rooms/template-room-form/template-room-form.component'
import { TemplateCategoryFormComponent } from '@app/admin-pages/template-categories/template-category-form/template-category-form.component'
import { TakeOffModel } from '@app/models/template/TakeOffModel'
import { TakeOffFormComponent } from '@app/admin-pages/takeoffs/take-off-form/take-off-form.component'
import {
    ReusableTakeoffFormComponent,
    TakeOffFormDialogSettings,
} from '@app/admin-pages/takeoffs/reusable-takeoff-form/reusable-takeoff-form.component'
import { PackageSelectionModel } from '@app/models/packages/packageSelectionModel'
import { PackageSelectionFormComponent } from '@app/admin-pages/packages/package-selection-form/package-selection-form.component'
import { BaseFormDialogService } from './BaseFormDialogService'
import {
    LabelGroupFormDialogSettings,
    LabelGroupFormComponent,
} from '@app/admin-pages/label-groups/label-group-form/label-group-form.component'
import { AllowanceFormDialogComponent } from '@app/admin-pages/templates/admin-template-allowances/allowance-form-dialog/allowance-form-dialog.component'
import {
    ContractTemplateDialogSettings,
    GenerateContractFormComponent,
} from '@app/admin-pages/contract-templates/generate-contract-form/generate-contract-form.component'
import { AllowanceModel } from '@app/models/allowances/allowanceModel'
import { TemplateModel } from '@app/models/template/TemplateModel'

@Injectable({
    providedIn: 'root',
})
export class FormDialogService extends BaseFormDialogService {
    constructor(dialog: MatDialog) {
        super(dialog)
    }

    showBrandFormDialog(): Observable<string> {
        const formDialogSettings = new FormDialogSettings(Brand, BrandFormComponent)
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showTemplateRoomFormDialog(): Observable<string> {
        const formDialogSettings = new FormDialogSettings(TemplateRoom, TemplateRoomFormComponent, true)
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showTemplateCategoryFormDialog(): Observable<string> {
        const formDialogSettings = new FormDialogSettings(TemplateCategory, TemplateCategoryFormComponent, true)
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showTakeOffFormDialog(): Observable<string> {
        const formDialogSettings = new FormDialogSettings(TakeOffModel, TakeOffFormComponent, false)
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showContractTemplateFormDialog(settings: ContractTemplateDialogSettings): Observable<string> {
        const formDialogSettings = new FormDialogSettings(
            ContractTemplateDto,
            GenerateContractFormComponent,
            false,
            '1800px'
        )
        formDialogSettings.injectPropertiesIntoComponent = (component) => {
            component.dialogSettings = settings
        }
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showLabelGroupDialog(settings: LabelGroupFormDialogSettings): Observable<string> {
        const formDialogSettings = new FormDialogSettings(LabelGroupDto, LabelGroupFormComponent, true)
        formDialogSettings.injectPropertiesIntoComponent = (labelGroupFormComponet) => {
            labelGroupFormComponet.dialogSettings = settings
        }
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showReusableTakeOffFormDialog(takeOff: TakeOffModel = null, template: TemplateModel = null): Observable<string> {
        const formDialogSettings = new FormDialogSettings(
            TakeOffModel,
            ReusableTakeoffFormComponent,
            false,
            '800px',
            takeOff
        )
        if (template) {
            formDialogSettings.injectPropertiesIntoComponent = (component) => {
                component.dialogSettings = new TakeOffFormDialogSettings(template)
            }
        }
        return this.showFormDialogUsingId(formDialogSettings)
    }

    showPackageSelectionFormDialog(packageSelection: PackageSelectionModel): Observable<PackageSelectionModel> {
        const formDialogSettings = new FormDialogSettings(
            PackageSelectionModel,
            PackageSelectionFormComponent,
            false,
            '1200px',
            packageSelection
        )
        return this.showFormDialog(formDialogSettings)
    }

    showAllowanceFormDialog(allowance: AllowanceModel): Observable<number> {
        const formDialogSettings = new FormDialogSettings(AllowanceDto, AllowanceFormDialogComponent, true)
        formDialogSettings.injectPropertiesIntoComponent = (component) => {
            component.model = allowance
        }
        return this.showFormDialogUsingId(formDialogSettings)
    }
}
