import { NgModule } from '@angular/core'
import { MaterialModule } from '@app/material.module'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { ProductVariationFormDialogComponent } from './product-variation-form-dialog.component'
import { LabelGroupDropDownModule } from '@app/shared/label-group-drop-down/label-group-drop-down.module'

@NgModule({
    imports: [CommonFormsModule, MaterialModule, LabelGroupDropDownModule],
    declarations: [ProductVariationFormDialogComponent],
    exports: [ProductVariationFormDialogComponent],
})
export class ProductVariationFormDialogModule {}
