<div class="row mb-4">
    <div class="col-md-4">
        <mat-slide-toggle [disabled]="!isCreatingNewPrice" [(ngModel)]="currencyPrice.hasRetailAmount"
            >Use Retail Price</mat-slide-toggle
        >
    </div>

    <div class="col-md-4">
        <mat-slide-toggle [disabled]="!isCreatingNewPrice" [(ngModel)]="currencyPrice.isUnitMarkupPercent"
            >Use Percentage Markup
        </mat-slide-toggle>
    </div>
</div>
<div class="row" *ngIf="currencyPrice.hasRetailAmount">
    <div class="col-sm-3">
        <app-currency-model
            [disabled]="!isCreatingNewPrice"
            [(inputModel)]="currencyPrice.retailAmount"
            placeholder="Retail Price"
        >
        </app-currency-model>
    </div>
    <div class="col-sm-3">
        <app-percent
            [(inputModel)]="currencyPrice.retailDiscountPercent"
            placeholder="Retail Discount Percent"
            [disabled]="!isCreatingNewPrice"
        >
        </app-percent>
    </div>
</div>
<div class="row">
    <div class="col-sm-3">
        <app-currency-model
            [disabled]="!isCreatingNewPrice || currencyPrice.hasRetailAmount"
            [(inputModel)]="currencyPrice.unitCostAmount"
            placeholder="Total Cost Price"
        >
        </app-currency-model>
    </div>

    <div class="col-sm-3">
        <app-currency-model
            [(inputModel)]="currencyPrice.unitMarkupAmount"
            placeholder="Total Markup Price"
            [disabled]="!isCreatingNewPrice || currencyPrice.isUnitMarkupPercent"
        >
        </app-currency-model>
    </div>
    <div class="col-sm-3">
        <app-percent
            [(inputModel)]="currencyPrice.unitMarkupPercent"
            placeholder="Percentage Markup"
            [disabled]="!isCreatingNewPrice || !currencyPrice.isUnitMarkupPercent"
        >
        </app-percent>
    </div>
    <div class="col-sm-3">
        <app-currency-model
            [disabled]="!isCreatingNewPrice"
            [(inputModel)]="currencyPrice.unitAmount"
            placeholder="Total Price"
        >
        </app-currency-model>
    </div>
</div>
