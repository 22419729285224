<ng-container *ngIf="labelGroup">
    <ng-container *ngIf="isMultiSelect; else singleSelect">
        <mat-form-field>
            <mat-select
                [placeholder]="placeholder"
                [disabled]="isDisabled"
                (selectionChange)="selectionChanged($event.value)"
            >
                <mat-option value=""> Add New Label </mat-option>
                <ng-container *ngFor="let label of availableLabels">
                    <mat-option [value]="label">
                        {{ label.selectedTranslation.name }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="selectedLabels && selectedLabels.length > 0">
            <mat-chip-list>
                <mat-chip *ngFor="let label of selectedLabels" [removable]="!isDisabled">
                    {{ label.selectedTranslation.name }}
                    <i class="fa fa-close ml-1" (click)="removeLabel(label)"></i>
                </mat-chip>
            </mat-chip-list>
        </ng-container>
    </ng-container>
    <ng-template #singleSelect>
        <mat-form-field>
            <mat-select
                [placeholder]="placeholder"
                [disabled]="isDisabled"
                [(ngModel)]="selectedLabel"
                [compareWith]="compareById"
                (selectionChange)="singleSelectionChange()"
            >
                <mat-option value=""> Add New Label </mat-option>
                <mat-option *ngFor="let label of labelGroup.labels" [value]="label">
                    {{ label.selectedTranslation.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>
</ng-container>
