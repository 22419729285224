import { TimesheetTaskModel, TimesheetModel } from '@app/models/timesheet/TimesheetModel'
import { CommonComponent } from '@app/models/formComponent'
import { UserConfigurationService } from '@app/services/user-configuration.service'
import { Output, Input, EventEmitter, Directive } from '@angular/core'
import { Moment } from 'moment'
import { TimesheetProjectDto, TimesheetTaskType } from '@app/services/api.services'
import { EnumListService } from '@app/services/enum-list.service'
import { TimesheetSaveService } from './timesheet/timesheet-save.service'

@Directive()
export class TimesheetBaseDirective extends CommonComponent {
    @Input() defaultProject: TimesheetProjectDto

    constructor(
        private userConfigurationService: UserConfigurationService,
        protected timesheetSaveService: TimesheetSaveService,
        private enumListService: EnumListService
    ) {
        super()
    }

    loadTimesheetSettings() {
        setTimeout(() => {
            const timesheetSettings = this.userConfigurationService.retrieveConfigValuewithDefault(
                'timesheetSettings',
                {
                    useTimePicker: this.model.useTimePicker,
                    use24Hr: this.model.use24Hr,
                    clearProjectAndPropertyOnNewTask: this.model.clearProjectAndPropertyOnNewTask,
                }
            )
            this.model.useTimePicker = timesheetSettings.useTimePicker
            this.model.use24Hr = timesheetSettings.use24Hr
        })
    }

    saveTimesheetSettings() {
        this.userConfigurationService.setConfigValue('timesheetSettings', {
            useTimePicker: this.model.useTimePicker,
            use24Hr: this.model.use24Hr,
            clearProjectAndPropertyOnNewTask: this.model.clearProjectAndPropertyOnNewTask,
        })
    }

    cacheTimesheetState() {
        this.model.hasChanges = true
        this.timesheetSaveService.cacheTimesheet(this.model.createDto())
    }

    @Output() onStartDateChange: EventEmitter<Moment> = new EventEmitter<Moment>()

    getTaskClass(task: TimesheetTaskModel): string {
        if (this.model.isSubmitted) {
            return 'disabled'
        }

        if (task.isAdded) {
            return 'added-row'
        }

        if (task.isDeleted) {
            return 'deleted-row'
        }

        if (task.isEditing) {
            return 'editing-row'
        }

        if (task.isEdited) {
            return 'edited-row'
        }

        if (task.isBreakTask) {
            return 'break-row'
        }
        return '' // default to empty string, otherwise class is undefined
    }

    @Input() model: TimesheetModel

    timesheetTaskTypeTooltip(timesheetTask: TimesheetTaskModel): string {
        return this.enumListService.findDisplayName(
            this.enumListService.timeSheetTaskTypes,
            timesheetTask.timesheetTaskType
        )
    }

    iconType(timesheetTask: TimesheetTaskModel): string {
        if (timesheetTask.isBreakTask) {
            return 'free_breakfast'
        }
        switch (timesheetTask.timesheetTaskType) {
            case TimesheetTaskType.PaidBreak:
            case TimesheetTaskType.UnpaidBreak:
                return 'free_breakfast'
            case TimesheetTaskType.PaidVacation:
            case TimesheetTaskType.NonPaidVacation:
            case TimesheetTaskType.StatutoryHoliday:
                return 'local_airport'
            case TimesheetTaskType.PaidSickDay:
            case TimesheetTaskType.NonPaidSickDay:
                return 'local_hospital'
            default:
                return null
        }
    }
}
