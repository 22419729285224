import { Component, OnInit, Inject } from '@angular/core'
import {
    FormComponent,
    FormComponentSettings,
    DialogCapableFormComponentId,
    CommonComponent,
} from '@app/models/formComponent'
import { LabelDto, LabelTranslation, LabelGroupsService, CreateLabelCommand } from '@app/services/api.services'
import { FormContainerService } from '@app/services/form-container.service'
import { Validators, FormGroup, FormBuilder } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { LanguageService } from '@app/services/language.service'
import { NotifyService } from '@app/services/notify.service'

@Component({
    selector: 'app-label-form-dialog',
    templateUrl: './label-form-dialog.component.html',
    styleUrls: ['./label-form-dialog.component.scss'],
})
export class LabelFormDialogComponent extends CommonComponent implements OnInit {
    label: LabelDto
    form: FormGroup
    formBuilder: FormBuilder = new FormBuilder()
    isSubmitting = false

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { labelGroupId: string },
        private dialogRef: MatDialogRef<LabelFormDialogComponent>,
        public languageService: LanguageService,
        private labelGroupsService: LabelGroupsService,
        private notifyService: NotifyService
    ) {
        super()
    }

    ngOnInit() {
        this._setupformControl()
    }

    public createLabel() {
        if (this.form.valid) {
            this.isSubmitting = true
            const cmd = new CreateLabelCommand()
            cmd.translations = this.form.get('translations').value.map((t) => new LabelTranslation(t))
            cmd.labelGroupId = this.data.labelGroupId
            this.labelGroupsService.createLabel(cmd).subscribe(
                (labelId) => {
                    this.notifyService.success('Label created successfully.')
                    this.isSubmitting = false
                    this.dialogRef.close(labelId)
                },
                (error) => {
                    console.log(error)
                    this.notifyService.fail('Label creation failed.')
                    this.isSubmitting = false
                }
            )
        } else {
            this.validateAllFormFields(this.form)
        }
    }

    private _setupformControl() {
        if (!this.label) {
            this.label = new LabelDto()
            this.label.translations = []
            this.label.labelGroupId = this.data.labelGroupId
            this.languageService.initTranslations(this.label.translations, () => new LabelTranslation())
        }

        this.form = this.formBuilder.group({
            id: [this.label.id],
            translations: this.formBuilder.array([
                this._createTranslationForm(0, this.label),
                this._createTranslationForm(1, this.label),
            ]),
        })
    }

    private _createTranslationForm(index, entity) {
        return this.formBuilder.group({
            name: [{ value: entity.translations[index].name, disabled: entity.isDefault }, [Validators.required]],
            culture: entity.translations[index].culture,
            id: entity.translations[index].id,
        })
    }
}
