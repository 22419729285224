import { NgModule } from '@angular/core'
import { MaterialModule } from '@app/material.module'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { UploadMediaDialogComponent } from './upload-media-form-dialog.component'
import { FileSelectModule } from '@app/shared/file-select/file-select.module'

@NgModule({
    imports: [CommonFormsModule, MaterialModule, FileSelectModule],
    declarations: [UploadMediaDialogComponent],
    exports: [UploadMediaDialogComponent],
    entryComponents: [UploadMediaDialogComponent],
})
export class UploadMediaDialogModule {}
