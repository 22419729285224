import { Component, OnInit, Inject } from '@angular/core'
import { TakeOffModel, TakeOffQuantityModel } from '@app/models/template/TakeOffModel'
import { FormComponentSettings, DialogCapableFormComponentId } from '@app/models/formComponent'
import {
    UnitType,
    TemplateType,
    TemplateService,
    EditTakeOffCommand,
    EditTakeOffQuantityCommand,
    CreateTakeOffCommand,
    CreateTakeOffQuantityCommand,
    BaseTemplateTakeOff,
} from '@app/services/api.services'
import { FormContainerService } from '@app/services/form-container.service'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { EnumListService } from '@app/services/enum-list.service'
import { FormControl, Validators } from '@angular/forms'
import { Observable, of } from 'rxjs'
import { NotifyService } from '@app/services/notify.service'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { flatMap, tap } from 'rxjs/operators'
import { UpdateTakeOffForSelectionGroupSelectorDialogService } from '@app/shared/dialog/update-take-off-for-selection-group-selector-dialog/update-take-off-for-selection-group-selector-dialog.service'
import { TemplateBuilderService } from '@app/admin-pages/templates/template-builder.service'
import { TemplateStoreService } from '@app/admin-pages/templates/template-store.service'

@Component({
    selector: 'app-take-off-form',
    templateUrl: './take-off-form.component.html',
    styleUrls: ['./take-off-form.component.scss'],
})
export class TakeOffFormComponent extends DialogCapableFormComponentId<string, TakeOffModel> implements OnInit {
    template: TemplateModel
    associatedSelectionGroups: SelectionGroupModel[]

    constructor(
        formContainerService: FormContainerService,
        public enumListService: EnumListService,
        public templateService: TemplateService,
        public templateStoreService: TemplateStoreService,
        public notifyService: NotifyService,
        public updateTakeOffForSelectionGroupSelectorDialogService: UpdateTakeOffForSelectionGroupSelectorDialogService
    ) {
        super(new FormComponentSettings(false, 'takeoffs', 'TakeOff'), formContainerService)
        this.routeBackOnSuccess = false

        this.template = this.templateStoreService.getTemplate()
        if (this.template) {
            this.model = this.template.editingTakeOff
        }
    }

    ngOnInit() {
        if (this.model) {
            this.id = this.model.id
            // this.templateService.getSelectionGroupsForTakeOff(this.model.id).subscribe(selectionGroups => {
            //   this.associatedSelectionGroups = selectionGroups.map(sGroup => SelectionGroupModel.createFromDto(sGroup));
            // });
        } else {
            this.model = new TakeOffModel(null, '', 1, UnitType.Each, false)
        }

        this._setupFormControl()
    }

    editEntity(): Observable<void> {
        return this._edit().pipe(
            tap(() => {
                this.template.completeTakeOffEdit()
            })
        )
    }

    createEntity(): Observable<string> {
        return this.templateService.createTakeOff(this._generateCreateCommandFromForm()).pipe(
            tap((takeoffId) => {
                this.model.id = takeoffId
                if (this.template) {
                    this.template.addTakeOff(this.model)
                }
            })
        )
    }

    getColumnClass() {
        return this.model.isInherited ? 'col-md-3' : 'col-md-4'
    }

    private _edit(): Observable<void> {
        return this._editRoute([])
        //   if (this.associatedSelectionGroups && this.associatedSelectionGroups.length > 0) {
        //     return this.updateTakeOffForSelectionGroupSelectorDialogService.showDialog(this.associatedSelectionGroups).pipe(
        //       flatMap(selectionGroupIds => {
        //         return this._editRoute(selectionGroupIds);
        //       })
        //     );
        //   } else {
        //     return this._editRoute([]);
        //   }
    }

    private _editRoute(selectionGroupIds: string[]): Observable<void> {
        if (this.model.isLocalTakeOff) {
            const editTakeOffCommand = new EditTakeOffCommand()
            editTakeOffCommand.init(this.formControl.value)
            if (this.template) {
                editTakeOffCommand.templateId = this.template.id
            }
            editTakeOffCommand.id = this.model.id
            this._updateModel(editTakeOffCommand)
            editTakeOffCommand.selectionGroupIdsToUpdate = selectionGroupIds

            return this.templateService.editTakeOff(editTakeOffCommand)
        } else if (this.model.takeOffQuantity.isPersisted) {
            const editTakeOffQuantityCommand = new EditTakeOffQuantityCommand()
            editTakeOffQuantityCommand.init(this.formControl.value)
            if (this.template) {
                editTakeOffQuantityCommand.templateId = this.template.id
            }
            editTakeOffQuantityCommand.selectionGroupIdsToUpdate = selectionGroupIds
            editTakeOffQuantityCommand.takeOffId = this.model.id
            this.model.takeOffQuantity.isEnabled = editTakeOffQuantityCommand.isEnabled
            this.model.takeOffQuantity.quantity = editTakeOffQuantityCommand.quantity
            return this.templateService.editTakeOffQuantity(editTakeOffQuantityCommand)
        } else if (this.formControl.get('isEnabled').value || this.model.takeOffQuantity.isPersisted) {
            const createTakeOffQuantityCommand = new CreateTakeOffQuantityCommand()
            createTakeOffQuantityCommand.init(this.formControl.value)
            if (this.template) {
                createTakeOffQuantityCommand.templateId = this.template.id
            }
            createTakeOffQuantityCommand.takeOffId = this.model.id
            this.model.takeOffQuantity.isEnabled = createTakeOffQuantityCommand.isEnabled
            this.model.takeOffQuantity.quantity = createTakeOffQuantityCommand.quantity
            return this.templateService.createTakeOffQuantity(createTakeOffQuantityCommand)
        } else {
            return of()
        }
    }

    private _setupFormControl() {
        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            name: [{ value: this.model.name, disabled: !this.model.isLocalTakeOff }, Validators.required],
            unitType: [{ value: this.model.unitType, disabled: !this.model.isLocalTakeOff }],
            defaultQuantity: [{ value: this.model.inheritedQuantity, disabled: this.model.isInherited }],
        })

        if (this.model.isInherited) {
            let takeOffQuantity = this.model.takeOffQuantity

            if (takeOffQuantity == null) {
                // creating new take off quantity if it doesn't exist
                takeOffQuantity = new TakeOffQuantityModel(this.model.id, this.model.defaultQuantity, false, false)
                this.model.takeOffQuantity = takeOffQuantity
            }

            this.formControl.addControl('isEnabled', new FormControl(takeOffQuantity.isEnabled))

            this.formControl.addControl(
                'quantity',
                new FormControl({
                    value: takeOffQuantity.isEnabled ? takeOffQuantity.quantity : this.model.defaultQuantity,
                    disabled: !takeOffQuantity.isEnabled,
                })
            )

            this.formControl.get('isEnabled').valueChanges.subscribe((value) => {
                this.model.takeOffQuantity.isEnabled = value
                value ? this.formControl.get('quantity').enable() : this.formControl.get('quantity').disable()
            })
        }
    }

    private _generateCreateCommandFromForm() {
        const command = new CreateTakeOffCommand()
        command.init(this.formControl.value)
        if (this.template) {
            command.templateId = this.template.id
        }
        this._updateModel(command)
        return command
    }

    private _updateModel(command: BaseTemplateTakeOff) {
        this.model.name = command.name
        this.model.unitType = command.unitType
        this.model.defaultQuantity = command.defaultQuantity
    }
}
