<form [formGroup]="formControl">
    <div class="row">
        <div class="col-12" *ngIf="!model.useSelectionItemName && formControl.contains('translations')">
            <app-multi-language
                [translations]="formControl.get('translations')"
                [controlName]="'name'"
                [useGlobalLanguageSelect]="false"
                [placeholder]="'Package Selection Name'"
            >
            </app-multi-language>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column">
            <mat-checkbox formControlName="useSelectionItemName">Use Selection Item Name</mat-checkbox>
            <mat-checkbox formControlName="hasToSelect">Mandatory To Be Selected</mat-checkbox>
            <mat-checkbox formControlName="canReplaceWithOtherSelection"
                >Can Be Replaced By An Other Selection</mat-checkbox
            >
            <mat-checkbox formControlName="provideDisplayImage">Provide Display Image</mat-checkbox>
        </div>
        <div class="col-12 col-md-6">
            <div
                class="card card-plane-background product-img h-100"
                *ngIf="model.provideDisplayImage"
                [style.backgroundImage]="
                    'url(' + (imageCdnService.generateCdnThumbnailUrl(model.displayImage.filePath) | async) + ')'
                "
            >
                <div class="card-body">
                    <div class="card-background-footer">
                        <button mat-raised-button color="primary" (click)="changeDisplayImage()">Change Image</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
