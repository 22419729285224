import { DataSource } from '@angular/cdk/table'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { PaginatedResult } from '@app/services/api.services'
import { CollectionViewer } from '@angular/cdk/collections'
import { catchError, finalize } from 'rxjs/operators'

export abstract class PagingDataSource<EntityModel, QueryType> implements DataSource<EntityModel> {
    private entitySubject = new BehaviorSubject<EntityModel[]>([])
    private paginationModelSubject = new BehaviorSubject<PaginatedResult>(null)
    private loadingSubject = new BehaviorSubject<boolean>(false)

    public loading$ = this.loadingSubject.asObservable()

    entities: EntityModel[] = []

    abstract getEntitiesObservable(query: QueryType): Observable<PaginatedResult>

    connect(collectionViewer: CollectionViewer): Observable<EntityModel[]> {
        return this.entitySubject.asObservable()
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.entitySubject.complete()
        this.loadingSubject.complete()
    }

    enitiesObservable(): Observable<EntityModel[]> {
        return this.entitySubject.asObservable()
    }

    paginationObservable() {
        return this.paginationModelSubject.asObservable()
    }

    loadEntities(query: QueryType, mappingFunc: (p) => void = null, filterFunc: (p) => void = null) {
        this.loadingSubject.next(true)

        this.getEntitiesObservable(query)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((result: any) => {
                if (filterFunc) {
                    result.entities = result.entities.filter((entity) => filterFunc(entity))
                }
                if (mappingFunc) {
                    result.entities = result.entities.map((entity) => mappingFunc(entity))
                }
                this.entities = result.entities
                this.paginationModelSubject.next(result)
                this.entitySubject.next(result.entities)
            })
    }
}
