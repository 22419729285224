import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core'
import {
    MediaDto,
    MediasService,
    GetAllMediaListQuery,
    PaginatedResult,
    PaginationModel,
    ProjectsService,
    ProjectDto,
    MediaType,
    LabelDto,
    LabelGroupType,
} from '@app/services/api.services'
import { ThemePalette } from '@angular/material/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MediaPickerDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { FileSelectComponent } from '@app/shared/file-select/file-select.component'
import {
    ServerPagingContainerComponent,
    PagingDisplayMode,
} from '@app/shared/server-pagination-container/server-paging-container.component'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'
import { NotifyService } from '@app/services/notify.service'
import { LanguageService } from '@app/services/language.service'

declare const $: any

export class SelectableMedia {
    constructor(public isSelected: boolean, public media: MediaDto) {}
}

export class SelectableMediaDataSource extends PagingDataSource<SelectableMedia, GetAllMediaListQuery> {
    constructor(private service: MediasService) {
        super()
    }

    getEntitiesObservable(query: GetAllMediaListQuery): Observable<PaginatedResult> {
        return this.service.medias(query)
    }
}

@Component({
    selector: 'app-media-picker-dialog',
    templateUrl: './media-picker-dialog.component.html',
    styleUrls: ['./media-picker-dialog.component.scss'],
})
export class MediaPickerDialogComponent implements OnInit, AfterViewInit {
    selectableMedias: SelectableMedia[]
    hasRequiredSelections: boolean

    imageType = MediaType.Image

    isLoading = false

    projects: ProjectDto[] = []
    selectedProjectId: number
    selectedLabels: LabelDto[] = []
    defaultLabelIds: string[] = []
    labelSelectLocked = false
    query: GetAllMediaListQuery
    dataSource: PagingDataSource<SelectableMedia, GetAllMediaListQuery>
    isLoaded: boolean
    persistedPagingKey = 'mediaList'
    displayMode: PagingDisplayMode = PagingDisplayMode.ListOnly

    @ViewChild('gridContainer') gridContainer: ServerPagingContainerComponent<SelectableMedia>

    @ViewChild('fileSelect') fileSelectComponent: FileSelectComponent

    constructor(
        private mediasService: MediasService,
        @Inject(MAT_DIALOG_DATA)
        public settings: MediaPickerDialogSettings,
        private dialogRef: MatDialogRef<MediaPickerDialogComponent>,
        private projectService: ProjectsService,
        public languageService: LanguageService,
        private notifyService: NotifyService
    ) {}

    ngOnInit() {
        this.projectService.getAll().subscribe((projects) => {
            this.projects = projects
        })
        this.labelSelectLocked = this.settings.fileSelectSettings.lockLabelSelect
        this.defaultLabelIds = this.settings.fileSelectSettings.defaultLabelIds
        this._createMediaQuery()

        this.dataSource = new SelectableMediaDataSource(this.mediasService)

        this.dataSource.loading$.subscribe((isLoading) => {
            this.isLoaded = !isLoading
        })

        this.dataSource.paginationObservable().subscribe((paginationModel) => {
            if (paginationModel) {
                this.gridContainer.length = paginationModel.count
            }
        })
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.loadEntities(this.gridContainer.generatePaginationModel())
        })
    }

    get LabelGroupType() {
        return LabelGroupType
    }

    filterChanges() {
        this.loadEntities(this.gridContainer.generatePaginationModel())
    }

    onProjectChange(projectId) {
        this.query.projectId = projectId
        this.gridContainer.resetPageIndex()
        this.filterChanges()
    }

    onLanguageChange(culture) {
        this.query.cultures = []

        if (culture === 'Not specified') {
            this.query.cultures.push(null)
        } else if (
            culture === this.languageService.supportedLanguages[0].culture ||
            culture === this.languageService.supportedLanguages[1].culture
        ) {
            this.query.cultures.push(culture)
        }

        this.gridContainer.resetPageIndex()
        this.filterChanges()
    }

    labelSelectionChange() {
        this.query.labelIds = this.selectedLabels.map((l) => l.id)
        this.gridContainer.resetPageIndex()
        this.filterChanges()
    }

    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel)

        this.dataSource.loadEntities(query, (media) => new SelectableMedia(false, media))
    }

    makeSelection(selectableMedia: SelectableMedia) {
        selectableMedia.isSelected = !selectableMedia.isSelected
        this.hasRequiredSelections = this._hasRequiredSelectionsMade()
        // todo check max selected isn't exceeded
    }

    fileSelected(event) {
        this.isLoading = !event.selected
    }

    upload() {
        this.isLoaded = true
        this.dialogRef.disableClose = true
        this.fileSelectComponent.onSubmitClick().subscribe((medias) => {
            if (medias && medias.length === this.fileSelectComponent.filesToUpload.length) {
                this.notifyService.success('File(s) uploaded successfully.')
                medias.forEach((media) => this.dataSource.entities.unshift(new SelectableMedia(true, media)))
                this.hasRequiredSelections = this._hasRequiredSelectionsMade()
                this.fileSelectComponent.reset()
                this.isLoading = true
                $('#select-tab').tab('show')
            }
            this.isLoading = false
            this.dialogRef.disableClose = false
        })
    }

    completeSelections() {
        this.dialogRef.close(this._getSelections())
    }

    private _getSelections() {
        return this.dataSource.entities
            .filter((selectableMedia) => selectableMedia.isSelected)
            .map((selectableMedia) => selectableMedia.media)
    }

    private _createMediaQuery() {
        this.query = new GetAllMediaListQuery()
        this.query.getDocuments = this.settings.fileSelectSettings.pickDocument
        this.query.getImages = this.settings.fileSelectSettings.pickImage

        this.query.requireExactImageDimensions = this.settings.fileSelectSettings.requiredImageDimensions
        this.query.requiredImageHeight = this.settings.fileSelectSettings.requiredHeight
        this.query.requiredImageWidth = this.settings.fileSelectSettings.requiredWidth
        if (this.defaultLabelIds.length > 0) {
            this.query.labelIds = this.defaultLabelIds
        }

        if (this.settings.fileSelectSettings.defaultProjectId) {
            this.selectedProjectId = this.settings.fileSelectSettings.defaultProjectId
            this.query.projectId = this.selectedProjectId
        }
    }

    private _hasRequiredSelectionsMade(): boolean {
        const selections = this._getSelections()
        return selections.length > 0
        // todo check min besides 1
    }
}
