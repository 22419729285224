import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { SelectSelectionGroupsToUsePriceDialogComponent } from './select-selection-groups-to-use-price-dialog.component'
import { MaterialModule } from '@app/material.module'
import { FormsModule } from '@angular/forms'

@NgModule({
    declarations: [SelectSelectionGroupsToUsePriceDialogComponent],
    imports: [FormsModule, CommonModule, MaterialModule],
    entryComponents: [SelectSelectionGroupsToUsePriceDialogComponent],
})
export class SelectSelectionGroupsToUsePriceDialogModule {}
