import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ImgCropperDialogComponent } from './img-cropper-dialog.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { MaterialModule } from '@app/material.module'
import { FormsModule } from '@angular/forms'

@NgModule({
    imports: [FormsModule, CommonModule, ImageCropperModule, MaterialModule],
    declarations: [ImgCropperDialogComponent],
    entryComponents: [ImgCropperDialogComponent],
    exports: [ImgCropperDialogComponent],
})
export class ImgCropperDialogModule {}
