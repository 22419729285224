import { AccessTokenDto } from '@app/services/api.services'
import * as moment from 'moment'
import { Moment } from 'moment'

export class AccessToken {
    private _token: string
    private _refreshToken: string
    private _tokenExpirationDateTime: Moment
    private _refreshExpirationDateTime: Moment

    get token(): string {
        return this._token
    }

    get refreshToken(): string {
        return this._refreshToken
    }

    get tokenExpirationDateTime(): Moment {
        return this._tokenExpirationDateTime
    }

    get refreshExpirationDateTime(): Moment {
        return this._refreshExpirationDateTime
    }

    get isRefreshTokenExpired(): boolean {
        return this.refreshExpirationDateTime <= moment()
    }

    get isTokenExpired(): boolean {
        return this.tokenExpirationDateTime <= moment()
    }

    constructor(token: string, tokenExpiration: Moment, refreshToken: string, refreshExpiration: Moment) {
        this._token = token
        this._tokenExpirationDateTime = tokenExpiration

        this._refreshToken = refreshToken
        this._refreshExpirationDateTime = refreshExpiration
    }

    toString() {
        return JSON.stringify({
            token: this.token,
            refreshToken: this.refreshToken,
            tokenExpirationDateTime: this.tokenExpirationDateTime,
            refreshTokenExpirationDateTime: this.refreshExpirationDateTime,
        })
    }

    static createFromString(accessTokenString: string) {
        const accessTokenObject = JSON.parse(accessTokenString)
        const accessToken = new AccessToken(
            accessTokenObject.token,
            moment(accessTokenObject.tokenExpirationDateTime),
            accessTokenObject.refreshToken,
            moment(accessTokenObject.refreshTokenExpirationDateTime)
        )

        return accessToken
    }

    static createFromDto(accessTokenDto: AccessTokenDto): AccessToken {
        const accessToken = new AccessToken(
            accessTokenDto.token,
            accessTokenDto.tokenExpirationDateTime,
            accessTokenDto.refreshToken,
            accessTokenDto.refreshTokenExpirationDateTime
        )

        return accessToken
    }
}
