import { Injectable } from '@angular/core'
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http'
import { Observable, ReplaySubject } from 'rxjs'
import { NotifyService } from './notify.service'

export class MediaUploadProgress {
    progressPercent: number
    isComplete: boolean
    uploadedUrl: string
}

@Injectable({
    providedIn: 'root',
})
export class MediaUploadService {
    constructor(private http: HttpClient, private notifyService: NotifyService) {}

    uploadFileToUrl(file: File, url: string): Observable<MediaUploadProgress> {
        const formData = new FormData()
        formData.append(file.name, file)

        return this._uploadFile(formData, url, new ReplaySubject<MediaUploadProgress>(), false)
    }

    uploadFile(file: File, isPrivate = false): Observable<MediaUploadProgress> {
        const formData = new FormData()
        formData.append(file.name, file)
        formData.append('isPrivate', isPrivate.toString())

        return this._uploadFile(formData, `api/medias/upload`, new ReplaySubject<MediaUploadProgress>(), true)
    }

    private _uploadFile(
        formData: FormData,
        url: string,
        subject: ReplaySubject<MediaUploadProgress>,
        hasUploadUrl: boolean
    ) {
        const uploadReq = new HttpRequest('POST', url, formData, {
            reportProgress: true,
        })

        this.http.request(uploadReq).subscribe(
            (event) => {
                const progress = new MediaUploadProgress()
                if (event.type === HttpEventType.UploadProgress) {
                    progress.progressPercent = Math.round((100 * event.loaded) / event.total)
                    subject.next(progress)
                } else if (event.type === HttpEventType.Response) {
                    if (hasUploadUrl) {
                        progress.uploadedUrl = event.body.toString()
                    }
                    progress.isComplete = true
                    subject.next(progress)
                    subject.complete()
                }
            },
            (error) => {
                console.log(error)
                this.notifyService.fail('File upload failed.')
                subject.next(null)
            }
        )
        return subject.asObservable()
    }
}
