import { Injectable } from '@angular/core'
import { CanDeactivate } from '@angular/router'

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    canDeactivate(component: ComponentCanDeactivate): boolean {
        if (!component.canDeactivate()) {
            return window.confirm('You have unsaved changes, do you want to discard your changes?')
        }
        return true
    }
}
