import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '@app/material.module'
import { FormsModule } from '@angular/forms'
import { GenerateTimesheetReportDatePickerService } from './generate-timesheet-report-date-picker.service'
import { GenerateTimesheetReportDatePickerComponent } from './generate-timesheet-report-date-picker.component'
import { NgxPermissionsModule } from 'ngx-permissions'

@NgModule({
    declarations: [GenerateTimesheetReportDatePickerComponent],
    imports: [CommonModule, MaterialModule, FormsModule, NgxPermissionsModule],
    providers: [GenerateTimesheetReportDatePickerService],
    entryComponents: [GenerateTimesheetReportDatePickerComponent],
})
export class GenerateTimesheetReportDatePickerModule {}
