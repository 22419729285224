import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { LabelFormDialogComponent } from './label-form-dialog.component'
import { Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class LabelFormDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(labelGroupId: string): Observable<string> {
        const dialogRef = this.dialog.open(LabelFormDialogComponent, {
            width: '400px',
            data: { labelGroupId },
        })

        return dialogRef.afterClosed()
    }
}
