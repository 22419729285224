import { Injectable } from '@angular/core'
import {
    TemplateService,
} from './api.services'
import { Observable, ReplaySubject } from 'rxjs'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { SelectionGroupModel, SelectionItemModel } from '@app/models/template/SelectionGroupModel'
import { PropertySelectionService } from './property-selection.service'

@Injectable({
    providedIn: 'root',
})
export class ClientPortalSelectionService {
    private _selectedTemplateModel: TemplateModel

    clientsPropertiesObservable = new ReplaySubject<any[]>()
    propertyTemplatesForProperties = {}
    selectedTemplateObservable = new ReplaySubject<TemplateModel>()

    constructor(
        private templateService: TemplateService,
        private propertySelectionService: PropertySelectionService
    ) {
      
    }

    setSelectedTemplate(templateModel: TemplateModel) {
        this._selectedTemplateModel = templateModel
        this.selectedTemplateObservable.next(templateModel)
    }

    clientPropertyTemplate(propertyId: number, showByCategory = false): Observable<TemplateModel> {
        // if (
        //     this.propertyTemplatesForProperties.hasOwnProperty(propertyId) &&
        //     this.propertyTemplatesForProperties[propertyId].groupedByCategory === showByCategory
        // ) {
        //     return of(this.propertyTemplatesForProperties[propertyId])
        // }

        // return this.templateService.propertyTemplate(propertyId, showByCategory, false).pipe(
        //     map((propertyTemplate) => {
        //         const model = TemplateModel.createFromDto(propertyTemplate)
        //         this.propertyTemplatesForProperties[propertyId] = model
        //         return model
        //     })
        // )
        return null
    }

    toggleSelectionItem(
        selectionItem: SelectionItemModel,
        selectionGroup: SelectionGroupModel,
        parentSelectionGroup: SelectionGroupModel
    ): Observable<boolean> {
        return this.propertySelectionService.toggleSelectionItem({
            templateId: this._selectedTemplateModel.id,
            selectionItem,
            selectionGroup,
            parentSelectionGroup,
        })
    }
}
