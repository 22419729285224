import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TableContainerModule } from '@app/shared/table-container/table-container.module'
import { LabelGroupFormComponent } from './label-group-form.component'

@NgModule({
    imports: [
        CommonFormsModule,
        //AddressFormModule,
        //TextMaskModule,
        TableContainerModule,
    ],
    declarations: [LabelGroupFormComponent],
    exports: [LabelGroupFormComponent],
    entryComponents: [LabelGroupFormComponent],
})
export class LabelGroupFormModule {}
