import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ProjectsService, ProjectDto, SuppliersService, SupplierDto, SupplierLookupDto } from '../api.services'
import { LocalSearchTableContainer } from '@app/shared/search-dialog/models/searchTableContainer'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'

@Injectable({
    providedIn: 'root',
})
export class SupplierPickerService {
    constructor(private suppliersService: SuppliersService, private searchDialogService: SearchDialogService) {}

    pickSupplier(): Observable<SupplierLookupDto> {
        const searchTableContainer = new LocalSearchTableContainer(this.suppliersService.suppliers(), 'Suppliers', [
            new DisplayColumn('Supplier Name', 'name'),
            new DisplayColumn('Email', 'email'),
        ])
        return this.searchDialogService.showLocalDialog(searchTableContainer)
    }
}
