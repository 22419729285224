import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import { Brand, BrandsService } from '@app/services/api.services'
import { ActivatedRoute } from '@angular/router'
import { EnumListService } from '@app/services/enum-list.service'
import { FormComponentSettings, DialogCapableFormComponentId } from '@app/models/formComponent'
import { Observable } from 'rxjs'
import { FormContainerService } from '@app/services/form-container.service'
import { map } from 'rxjs/operators'

@Component({
    selector: 'app-brand-form',
    templateUrl: './brand-form.component.html',
    styleUrls: ['./brand-form.component.scss'],
})
export class BrandFormComponent extends DialogCapableFormComponentId<string, Brand> implements OnInit, OnDestroy {
    constructor(
        private apiService: BrandsService,
        public enumListService: EnumListService,
        private route: ActivatedRoute,
        formContainerService: FormContainerService
    ) {
        super(new FormComponentSettings(true, 'brands', 'Brands'), formContainerService)
    }

    ngOnInit() {
        this.model = this.route.snapshot.data['brand']
        if (this.model) {
            this.id = this.model.id
        }
        this._setupformControl()
    }

    editEntity(): Observable<void> {
        this._generateFromForm()
        return this.apiService.edit(this.model).pipe(map((_) => null))
    }

    createEntity(): Observable<string> {
        this._generateFromForm()
        return this.apiService.create(this.model).pipe(map((b) => b.id))
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }

    private _generateFromForm() {
        const entity = new Brand()
        entity.init(this.formControl.value)

        this.model = Object.assign(this.model, entity)
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new Brand()
        }

        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            name: [this.model.name, [Validators.required]],
        })
    }
}
