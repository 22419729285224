<div class="row" *ngIf="isReadOnlyMode">
    <div class="col-12 d-flex justify-content-between my-2">
        <h4>Product Variation Group: {{ this.model.name }}</h4>
        <button
            color="primary"
            mat-stroked-button
            class="mr-2"
            (click)="router.navigate(['product-variation-groups', 'edit', model.id])"
        >
            Edit
        </button>
    </div>
</div>

<div class="row mb-4">
    <div class="col-12">
        <div class="mb-4">
            <mat-divider></mat-divider>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-between">
        <h4>Variant Properties</h4>
        <button *ngIf="!isReadOnlyMode" mat-stroked-button color="primary" class="mr-2" (click)="onAddProperty.emit()">
            Manage Property
        </button>
    </div>

    <ng-container
        *ngIf="propertyDefinitionContainers && propertyDefinitionContainers.length > 0; else noPropertyDefinitions"
    >
        <div class="col-12">
            <mat-chip-list>
                <mat-chip
                    [color]="propertyDefinitionContainer.getChipColor()"
                    selected
                    *ngFor="let propertyDefinitionContainer of propertyDefinitionContainers"
                    >{{ propertyDefinitionContainer.item.labelIdentifier }}
                    {{
                        propertyDefinitionContainer.getSubText() ? ' - ' + propertyDefinitionContainer.getSubText() : ''
                    }}</mat-chip
                >
            </mat-chip-list>
        </div>

        <div class="col-12 mt-2" *ngIf="hasChangesToPropertyDefinitions">
            Save to update the modified property definitions and to show the product variations with the adjusted
            columns
        </div>
    </ng-container>

    <ng-template #noPropertyDefinitions>
        <div class="col-12">There are no property definitions added to this variation group</div>
    </ng-template>
</div>

<div class="row mb-4">
    <div class="col-12">
        <div class="mb-4">
            <mat-divider></mat-divider>
        </div>
    </div>
    <div class="col-12">
        <h4>Product Variations</h4>
        <table mat-table [dataSource]="productVariations">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let row; let i = index">
                    {{ row.item.productName }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container [matColumnDef]="column.labelIdentifier" *ngFor="let column of dynamicDisplayedColumns">
                <th mat-header-cell *matHeaderCellDef>{{ column.displayLabel }}</th>
                <td mat-cell *matCellDef="let row">
                    {{ getVariationValue(column.labelIdentifier, row.item) | propertyValueUnit | async }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
                <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                    <button
                        *ngIf="!isReadOnlyMode"
                        (click)="onRemoveProductVariation.emit(row)"
                        mat-icon-button
                        color="warn"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button (click)="goToProduct(row.item)" mat-icon-button matTooltip="Edit Product">
                        <mat-icon>link</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef class="action-column">
                    <button *ngIf="!isReadOnlyMode" (click)="onAddProductVariation.emit()" mat-icon-button>
                        <mat-icon>add</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [className]="row.getRowColor()"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </div>
</div>
