import { ProductSupplierPriceVersionDto, UnitType } from '@app/services/api.services'
import { ProductCurrencyPriceModel } from './ProductVersionPriceModel'

export class ProductSupplierPriceVersionModel {
    id?: string | null
    projectName: string | null
    projectId: number | null
    supplierName: string
    supplierId: string
    productId: string
    versionNo: number
    isLatestVersion: boolean
    isArchived: boolean
    internalName = ''
    caseQuantity: number
    unitType: UnitType

    currencyPrices: ProductCurrencyPriceModel[] = []

    constructor(id: string, versionNo: number, isLatestVersion: boolean, unitType: UnitType, internalName: string) {
        this.id = id
        this.versionNo = versionNo
        this.isLatestVersion = isLatestVersion
        this.unitType = unitType

        if (internalName) {
            this.internalName = internalName
        } else {
            this.internalName = `Version ${versionNo}`
        }
    }

    static createFromDto(
        productSupplierPriceVersionDto: ProductSupplierPriceVersionDto
    ): ProductSupplierPriceVersionModel {
        const productSupplierPriceVersion = new ProductSupplierPriceVersionModel(
            productSupplierPriceVersionDto.id,
            productSupplierPriceVersionDto.versionNo,
            productSupplierPriceVersionDto.isLatestVersion,
            productSupplierPriceVersionDto.unitType,
            productSupplierPriceVersionDto.internalName
        )

        productSupplierPriceVersion.currencyPrices = productSupplierPriceVersionDto.currencyPrices.map((c) => {
            return ProductCurrencyPriceModel.createFromDto(c)
        })

        productSupplierPriceVersion.caseQuantity = productSupplierPriceVersionDto.caseQuantity

        return productSupplierPriceVersion
    }

    addNewPriceCurrency(currencyCode: string) {
        if (this.currencyPrices.findIndex((c) => c.currencyCode === currencyCode) >= 0) {
            throw new Error(`This product supplier price version already has a price for the currency ${currencyCode}`)
        }

        this.currencyPrices.push(new ProductCurrencyPriceModel(null, false, currencyCode, 0, 0, false))
    }
}
