import { Component, OnInit, NgZone, OnDestroy, ViewChild, AfterViewInit, Inject, Optional } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import {
    ProductsService,
    GetProductListQuery,
    PaginationModel,
    ProductLookupDto,
    LabelGroupsService,
    LabelDto,
    GetSpecificLabelGroupsQuery,
    ProductAvailabilityType,
    Brand,
    BrandsService,
    ProjectsService,
    ProjectDto,
} from '@app/services/api.services'
import { NotifyService } from '@app/services/notify.service'
import { FixedButton, FixedFormControlsService } from '@app/shared/fixed-form-controls/fixed-form-controls.service'
import { ServerPaginationListComponent } from '@app/models/serverPaginationComponent'
import { ProductsDataSource } from './products.datasource'
import { LanguageService } from '@app/services/language.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSort } from '@angular/material/sort'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { PagingPersistService } from '@app/services/paging-persistent.service'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'
import { mergeMap } from 'rxjs/operators'
import { AppSettings } from '@app/app.settings'
import { EnumListService } from '@app/services/enum-list.service'
import { UploadFileDialogService, UploadFileDialogSettings } from '@app/shared/dialog'
import { MatSelect } from '@angular/material/select'
import { saveAs } from 'file-saver'
import { ButtonListDialogService } from '@app/shared/dialog/button-list-dialog/button-list-dialog.service'
import { ModalButton } from '@app/shared/dialog/button-list-dialog/models/modalButton'

export class ProductPickerSettings {
    constructor(
        public includeAccessoryProducts: boolean = true,
        public includeStandaloneProducts: boolean = true,
        public availabilityTypeCheckboxesEnabled = false
    ) {}
}

@Component({
    selector: 'app-products',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent
    extends ServerPaginationListComponent<string, ProductLookupDto, GetProductListQuery>
    implements OnInit, OnDestroy, AfterViewInit {
    displayedColumns: string[] = [
        'thumbnail',
        'modelNumber',
        'productAvailabilityType',
        'brand',
        'productName',
        'numberOfActiveSupplierPrices',
        'numberOfActiveSupplierProjectPrices',
        'action',
    ]
    labels: LabelDto[] = []
    selectedLabels: LabelDto[] = []
    displayMode: PagingDisplayMode = PagingDisplayMode.Card
    brands: Brand[]
    projects: ProjectDto[]
    selectedBrands: Brand[] = []

    includeActiveProducts = true
    includeInactiveProducts = false

    availabilityTypeCheckboxesEnabled = true
    includeAccessoryProducts = true
    includeStandaloneProducts = true
    includeColorProducts = true
    includeFormatProducts = true
    includeFinishProducts = true
    includeAllProducts = true
    includeOnlyProductsWithPriceForProjectId?: number

    @ViewChild('labelSelect') labelSelect: MatSelect
    @ViewChild('brandSelect') brandSelect: MatSelect
    @ViewChild(MatSort) sort: MatSort
    fixedButtonModal: FixedButton
    fixedButtonModalSub: Subscription

    constructor(
        private productsService: ProductsService,
        public ngZone: NgZone,
        public router: Router,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        data: {
            displayMode: PagingDisplayMode
            excludeProductIds: string[]
            productPickerSettings: ProductPickerSettings
        },
        fixedFormControlsService: FixedFormControlsService,
        public notifyService: NotifyService,
        private languageService: LanguageService,
        private uploadFileDialogService: UploadFileDialogService,
        confirmDialogService: ConfirmDialogService,
        private labelGroupService: LabelGroupsService,
        private enumListService: EnumListService,
        private pagingPersistService: PagingPersistService,
        private brandsService: BrandsService,
        private projectsService: ProjectsService,
        private buttonListDialogService: ButtonListDialogService
    ) {
        super(router, 'products', confirmDialogService, notifyService)

        if (data) {
            this.displayMode = data.displayMode
        }

        this.query = new GetProductListQuery({
            labelIds: [],
            includeActiveProducts: this.includeActiveProducts,
            includeInactiveProducts: this.includeInactiveProducts,
        })
        if (data && data.excludeProductIds) {
            this.query.excludeProductIds = data.excludeProductIds
        }
        if (data && data.productPickerSettings) {
            this.availabilityTypeCheckboxesEnabled = data.productPickerSettings.availabilityTypeCheckboxesEnabled
            this.includeAccessoryProducts = data.productPickerSettings.includeAccessoryProducts
            this.includeStandaloneProducts = data.productPickerSettings.includeStandaloneProducts
        }

        if (this.displayMode === PagingDisplayMode.Card) {
            this.fixedAddButton = fixedFormControlsService.setupSingleAddButton()
            this.fixedFormSub = this.fixedAddButton.buttonClickedObservable.subscribe(() => this.create())

            this.fixedButtonModal = fixedFormControlsService.setupButton('menu', 1, 'primary')
            this.fixedButtonModal.tooltip = 'Import/Export Options'
            this.fixedButtonModalSub = this.fixedButtonModal.buttonClickedObservable.subscribe(() =>
                this._showModalButtons()
            )
        }

        if (this.pagingPersistService.hasFilterValue('productList')) {
            this._loadSavedFilters()
        }
    }

    private _showModalButtons() {
        const exportProductModalButton = new ModalButton('Export Products')
        const exportSupplierPricesModalButton = new ModalButton('Export Supplier Prices')

        const importProductModalButton = new ModalButton('Import Products')
        const importSupplierPricesModalButton = new ModalButton('Import Supplier Prices')

        exportProductModalButton.onClick
            .pipe(
                mergeMap(() => {
                    return this.confirmDialogService.showDialogWithLoader(
                        'Are you sure you want to export all products?',
                        this.productsService.exportProductsToCsv()
                    )
                })
            )
            .subscribe((dialogResult) => {
                if (dialogResult.success) {
                    saveAs(dialogResult.result.data, dialogResult.result.fileName)
                }
            })

        exportSupplierPricesModalButton.onClick
            .pipe(
                mergeMap(() => {
                    return this.confirmDialogService.showDialogWithLoader(
                        'Are you sure you want to export all latest product supplier prices?',
                        this.productsService.exportProductSupplierPricesToCsv()
                    )
                })
            )
            .subscribe((dialogResult) => {
                if (dialogResult.success) {
                    saveAs(dialogResult.result.data, dialogResult.result.fileName)
                }
            })

        importSupplierPricesModalButton.onClick.subscribe(() =>
            this._importFromCsv(`api/products/importProductSupplierPricesFromCsv`)
        )

        importProductModalButton.onClick.subscribe(() => this._importFromCsv(`api/products/importProductsFromCsv`))

        this.buttonListDialogService
            .showDialog([
                exportProductModalButton,
                exportSupplierPricesModalButton,
                importProductModalButton,
                importSupplierPricesModalButton,
            ])
            .subscribe()
    }

    ngOnInit() {
        const query = new GetSpecificLabelGroupsQuery()
        query.labelGroupTypes = AppSettings.PRODUCT_LABEL_GROUP_TYPES
        this.labelGroupService.getLabelGroups(query).subscribe((labelGroups) => {
            this.labels = []
            labelGroups.forEach((lg) => {
                this.labels = this.labels.concat(lg.labels)
            })
            this.selectedLabels = this.labels.filter((l) => this.query.labelIds.find((labelId) => labelId === l.id))
        })

        this.brandsService.brands().subscribe((brands) => (this.brands = brands))

        this.projectsService.getAll().subscribe((projects) => (this.projects = projects))
    }

    private _importFromCsv(url: string) {
        this.uploadFileDialogService.showDialog(new UploadFileDialogSettings(url)).subscribe((result) => {
            if (result === undefined) {
                return
            }
            if (result) {
                this.filterChanges()
                this.notifyService.success('Import was successful')
            } else {
                this.notifyService.fail('Failed to import')
            }
        })
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.load()
        })
        this.tableContainer.matSort = this.sort
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel())
    }

    getAvailableLabels() {
        return this.labels.filter(
            (label) => this.selectedLabels.findIndex((selectedLabel) => selectedLabel.id === label.id) === -1
        )
    }

    selectLabel(label: LabelDto) {
        if (this.selectedLabels.findIndex((selectedLabel) => selectedLabel.id === label.id) >= 0) {
            return
        }
        this.selectedLabels.push(label)
        this.filterChanges()
        this.labelSelect.writeValue(null)
    }

    removeLabel(label: LabelDto) {
        this.selectedLabels.splice(
            this.selectedLabels.findIndex((p) => p.id === label.id),
            1
        )
        this.filterChanges()
    }

    getAvailableBrands() {
        return this.brands.filter(
            (brand) => this.selectedBrands.findIndex((selectedBrand) => selectedBrand.id === brand.id) === -1
        )
    }

    onSelectProject(projectId?: number) {
        this.includeOnlyProductsWithPriceForProjectId = projectId
        this.filterChanges()
    }

    selectBrand(brand: Brand) {
        if (this.selectedBrands.findIndex((selectedBrand) => selectedBrand.id === brand.id) >= 0) {
            return
        }
        this.selectedBrands.push(brand)
        this.filterChanges()
        this.brandSelect.writeValue(null)
    }

    removeBrand(brand: Brand) {
        this.selectedBrands.splice(
            this.selectedBrands.findIndex((p) => p.id === brand.id),
            1
        )
        this.filterChanges()
    }

    showActiveInactiveCheckboxes(): boolean {
        return this.displayMode !== PagingDisplayMode.Dialog
    }

    toggleActiveProducts(checked: boolean) {
        this.query.includeActiveProducts = checked
        this.filterChanges()
    }

    toggleInactiveProducts(checked: boolean) {
        this.query.includeInactiveProducts = checked
        this.filterChanges()
    }

    deleteEntity(id: string): Observable<void> {
        const observable = this.productsService.delete(id)
        return observable
    }

    deactivateProduct(id: string) {
        this.confirmDialogService
            .showDialog('Are you sure you want to deactivate this product?', 'Confirm Deactivation')
            .pipe(
                mergeMap((result) => {
                    if (result) {
                        return this.productsService.deactivate(id)
                    }
                })
            )
            .subscribe(
                () => {
                    this.notifyService.success('Product deactivated successfully')
                    this.load()
                },
                (error) => {
                    this.notifyService.fail('Failed to deactivate')
                }
            )
    }

    duplicateProduct(id: string) {
        this.router.navigate(['products', 'copy', id])
    }

    loadDataSource(): ProductsDataSource {
        const dataSource = new ProductsDataSource(this.productsService)
        return dataSource
    }

    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel)
        query.requestedCulture = this.languageService.userSelectedLanguage.culture
        this.query.labelIds = this.selectedLabels.map((l) => l.id)
        this.query.brandIds = this.selectedBrands.map((b) => b.id)
        this.query.includeOnlyProductsWithPriceForProjectId = this.includeOnlyProductsWithPriceForProjectId

        this._addProductAvailabilityType(query)
        this._saveFilters()

        this.dataSource.loadEntities(query)
    }

    ngOnDestroy() {
        this.fixedFormSub?.unsubscribe()
        this.fixedButtonModalSub?.unsubscribe()
    }

    getProductAvailabilityDisplay(productAvailabilityType: ProductAvailabilityType) {
        return this.enumListService.findDisplayName(
            this.enumListService.productAvailabilityType,
            productAvailabilityType
        )
    }

    private _addProductAvailabilityType(query: GetProductListQuery) {
        query.productAvailabilityTypes = []
        if (this.includeColorProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.Color)
        }

        if (this.includeAccessoryProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.Accessory)
        }

        if (this.includeFinishProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.Finish)
        }

        if (this.includeFormatProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.Format)
        }
        if (this.includeStandaloneProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.Standalone)
        }

        if (this.includeAllProducts) {
            query.productAvailabilityTypes.push(ProductAvailabilityType.All)
        }
    }

    private _saveFilters() {
        this.pagingPersistService.saveFilters('productList', {
            selectedLabels: this.selectedLabels,
            selectedBrands: this.selectedBrands,
            includeActiveProducts: this.includeActiveProducts,
            includeInactiveProducts: this.includeInactiveProducts,
            includeAccessoryProducts: this.includeAccessoryProducts,
            includeStandaloneProducts: this.includeStandaloneProducts,
            includeColorProducts: this.includeColorProducts,
            includeFormatProducts: this.includeFormatProducts,
            includeFinishProducts: this.includeFinishProducts,
            includeAllProducts: this.includeAllProducts,
        })
    }

    private _loadSavedFilters() {
        if (this.pagingPersistService.hasFilterValue('productList')) {
            const savedFilters = this.pagingPersistService.getFiltersValue('productList')
            this.includeActiveProducts = savedFilters.includeActiveProducts
            this.includeInactiveProducts = savedFilters.includeInactiveProducts
            this.includeActiveProducts = savedFilters.includeActiveProducts
            this.includeInactiveProducts = savedFilters.includeInactiveProducts
            this.includeAccessoryProducts = savedFilters.includeAccessoryProducts
            this.includeStandaloneProducts = savedFilters.includeStandaloneProducts
            this.includeColorProducts = savedFilters.includeColorProducts
            this.includeFormatProducts = savedFilters.includeFormatProducts
            this.includeFinishProducts = savedFilters.includeFinishProducts
            this.includeAllProducts = savedFilters.includeAllProducts

            if (savedFilters.selectedBrands) {
                this.selectedBrands = savedFilters.selectedBrands
            }
            if (savedFilters.selectedLabels) {
                this.selectedLabels = savedFilters.selectedLabels
            }
        }
    }
}
