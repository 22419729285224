import {
    UnitType,
    SelectionGroupTranslations,
    SelectionItemProductLinkCostLineDto,
    CostLineTranslation,
    NestedProductCurrencyPriceDto,
    NestedLightProductDto,
} from '@app/services/api.services'
import { Guid } from 'guid-typescript'
import { SelectionItemBaseCostLineModel } from './SelectionItemBaseCostLineModel'
import { TakeOffModel } from '@app/models/template/TakeOffModel'

export class SelectionItemProductLinkCostLineModel extends SelectionItemBaseCostLineModel {
    get product(): NestedLightProductDto {
        return this.linkedProductPrice.product
    }

    get imageUrl(): string {
        if (this.product.displayImage) {
            return this.product.displayImage.filePath
        }
        return null
    }

    get hasHightlightImage(): boolean {
        return this.product.highlightImage !== undefined && this.product.highlightImage !== null
    }

    isDisplayCostLine: boolean
    linkedProductPrice?: NestedProductCurrencyPriceDto | null
    parentSelectionGroupTranslations?: SelectionGroupTranslations[]

    static createFromDto(costLine: SelectionItemProductLinkCostLineDto): SelectionItemProductLinkCostLineModel {
        const costLineModel = new SelectionItemProductLinkCostLineModel(
            costLine.id,
            costLine.quantity,
            false,
            costLine.linkedProductCurrencyPrice.unitType,
            costLine.linkedProductCurrencyPrice.unitCostAmount,
            costLine.linkedProductCurrencyPrice.unitMarkupAmount,
            costLine.linkedProductCurrencyPrice.unitMarkupPercent,
            costLine.linkedProductCurrencyPrice.isUnitMarkupPercent,
            costLine.linkedProductCurrencyPrice.product.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: costLine.linkedProductCurrencyPrice.product.useClientDisplayName
                            ? t.clientDisplayName
                            : costLine.linkedProductCurrencyPrice.product.productName,
                        culture: t.culture,
                    })
            ),
            costLine.linkedProductCurrencyPrice,
            costLine.isDisplayCostLine
        )
        costLineModel.useTakeOff = costLine.useTakeOff

        costLineModel.selectedTranslation = costLineModel.translations.find(
            (t) => t.culture === costLine.linkedProductCurrencyPrice.product.selectedTranslation.culture
        )

        if (costLine.useTakeOff) {
            costLineModel.takeOff = TakeOffModel.createFromDto(costLine.takeOff)
        }
        return costLineModel
    }

    constructor(
        id: string,
        quantity: number,
        isVisibleToClients: boolean,
        unitType: UnitType,
        unitCostAmount: number,
        unitMarkupAmount: number,
        unitMarkupPercent: number,
        isUnitMarkupPercent: boolean,
        translations: CostLineTranslation[],
        linkedProductPrice: NestedProductCurrencyPriceDto,
        isDisplayCostLine: boolean
    ) {
        super(
            id,
            quantity,
            unitType,
            unitCostAmount,
            unitMarkupAmount,
            unitMarkupPercent,
            isUnitMarkupPercent,
            isVisibleToClients,
            translations
        )

        this.isDisplayCostLine = isDisplayCostLine
        this.linkedProductPrice = linkedProductPrice
    }

    clone(generateNewId: boolean): SelectionItemProductLinkCostLineModel {
        const costLine = Object.assign(
            new SelectionItemProductLinkCostLineModel(
                this.id,
                this.quantity,
                this.isVisibleToClients,
                this.unitType,
                this.unitCostAmount,
                this.unitMarkupAmount,
                this.unitMarkupPercent,
                this.isUnitMarkupPercent,
                this.translations,
                this.linkedProductPrice,
                this.isDisplayCostLine
            ),
            this
        )

        if (generateNewId) {
            costLine.id = Guid.create().toString()
            costLine.isEdited = false
            costLine.isAdded = true
        }
        return costLine
    }

    getImagePaths(): string[] {
        const imagePaths = new Array<string>()
        if (this.product.displayImage) {
            imagePaths.push(this.product.displayImage.filePath)
        }
        // if (this.product.galleryImages) {
        //     return imagePaths.concat(
        //         this.product.galleryImages.map((imageModel) => {
        //             return imageModel.filePath
        //         })
        //     )
        // }
        return imagePaths
    }

    getName(): string {
        return this.selectedTranslation ? this.selectedTranslation.description : ''
    }

    getDescription(): string {
        return this.selectedTranslation ? this.selectedTranslation.description : ''
    }

    isUnitCostEditable(): boolean {
        return false
    }

    isUnitMarkupEditable(): boolean {
        return false
    }

    isDescriptionEditable(): boolean {
        return false
    }

    isUnitTypeEditable(): boolean {
        return false
    }

    isTakeOffsSupported(): boolean {
        return true
    }

    isLinkedCostLine(): boolean {
        return true
    }

    createDto(): SelectionItemProductLinkCostLineDto {
        const costLine = new SelectionItemProductLinkCostLineDto()
        this.populateCommonDtoFieldsFromProduct(costLine)
        costLine.linkedProductCurrencyPrice = this.linkedProductPrice
        costLine.useTakeOff = this.useTakeOff
        costLine.isDisplayCostLine = this.isDisplayCostLine

        if (this.useTakeOff) {
            costLine.takeOff = this.takeOff.createDto()
        }
        return costLine
    }
}
