import { LanguageType } from '../../../../services/api.services'
export default class EmailNoteContent {
    static generateBrokerRegisteredContent(
        fields: { brokerName: string; projectName: string; comissionPercent: number },
        language: LanguageType
    ): { subject: string; description: string } {
        let subject =
            language === LanguageType.English
                ? 'Presti homes and developements broker registration'
                : 'Presti Demeures et Développements enregistrement du courtier'

        const description =
            language == LanguageType.English
                ? `Hello ${fields.brokerName}

We hereby confirm your registration as an official broker for the ${
                      fields.projectName
                  }. As an official broker you are entitled to a commission of ${
                      fields.comissionPercent
                  }% of the land price before taxes. To be eligible for this commission your presence will be required at the first initial meeting or introduction with a new client who is not already registered in our CRM (customer relationship management) system. 

As an official broker of the ${
                      fields.projectName
                  } please see below the following link which contains the marketing material and site maps for the project. This content may be used in collaboration with your brand to help promote ${
                      fields.projectName
                  }.
         
Photographers and photoshoots will be permitted if you wish to do so. Please let us know prior so we can schedule an appointment and give you the right amount of time to create content.
         
We ask that the material you create respects our brand, encompasses class and is targeted towards the high end. We also ask at the highest degree that the content created does not offend or disrespect any one individual and or party.
         
To conclude, we also ask that the @PRESTI handle is tagged and #${fields.projectName
                      ?.replace(/\s/g, '')
                      .toUpperCase()} hashtag is included in any social media post.
         
Looking forward to collaborating with you.`
                : `Bonjour ${fields.brokerName}

Nous vous confirmons votre enregistrement comme courtier officiel du ${
                      fields.projectName
                  }. Comme courtier officiel vous avez droit à une commission de ${
                      fields.comissionPercent
                  }% sur le prix du terrain avant taxes. Pour être éligible à cette commission votre présence sera requise  lors de la première rencontre ou introduction avec un nouveau client qui n’est pas déjà enregistré dans notre système GRC (gestion de la relation client). 

Comme courtier officiel du ${
                      fields.projectName
                  } veuillez trouver ci-dessous un lien qui contient le matériel promotionnel ainsi que les plans du site. Ce contenu peut être utilisé en collaboration avec votre marque pour promouvoir ${
                      fields.projectName
                  }.

Les photographes et séances de photos seront permises si vous le désirez. Veuillez nous informer d’avance pour que nous puissions céduler un rendez-vous pour vous donner le temps nécessaire pour créer votre contenu.  
 
Nous demandons que le contenu respect notre marque, englobe classe et se dirige vers le haut de gamme. Nous vous demandons au plus grand degré que votre contenu ne soit aucunement offensif ou irrespectueux envers un individu ou groupe. 
  
Pour conclure, nous vous demandons que l’identifiant @PRESTI est tagué et que le hashtag #${fields.projectName
                      ?.replace(/\s/g, '')
                      .toUpperCase()} est inclus dans tout vos publication sur les réseaux sociaux.

Au plaisir de collaborer avec vous.  `

        return { subject, description }
    }
}
