import { Component, OnInit, Input } from '@angular/core'
import { FormGroup, FormArray } from '@angular/forms'
import { Translation } from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'
import { Language } from '@app/models/language'

@Component({
    selector: 'app-multi-language',
    templateUrl: './multi-language.component.html',
    styleUrls: ['./multi-language.component.scss'],
})
export class MultiLanguageComponent implements OnInit {
    @Input() placeholder: string

    selectedLanguage: Language
    @Input() translations: FormArray
    @Input() controlName: string
    @Input() useTextArea = false
    @Input() useGlobalLanguageSelect = true

    constructor(public languageService: LanguageService) {}

    ngOnInit() {
        this.languageService.languageObservable.subscribe((language) => {
            this.selectedLanguage = language
        })
        this.selectedLanguage = this.languageService.formSelectedLanguage
    }

    isFieldValid(group: FormGroup, field: string) {
        const control = group.get(field)
        return !control.disabled && !control.valid && control.touched
    }

    missingTranslations() {
        const invalidTranslationForms = this.translations.controls.filter((translation) =>
            this.isFieldValid(<FormGroup>translation, this.controlName)
        )
        return invalidTranslationForms
    }

    placeHolderName(culture: string): string {
        return `${this.languageService.lookByCulture(culture).abvreiation} ${this.placeholder}`
    }
}
