import { NgModule } from '@angular/core'
import { TableContainerModule } from '@app/shared/table-container/table-container.module'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { TaskFormDialogComponent } from './task-form-dialog/task-form-dialog.component'
import { TaskListComponent } from './task-list/task-list.component'
import { TaskFormDialogService } from './task-form-dialog/task-form-dialog.service'

@NgModule({
    declarations: [TaskFormDialogComponent, TaskListComponent],
    exports: [TaskFormDialogComponent, TaskListComponent],
    imports: [CommonFormsModule, TableContainerModule],
    providers: [TaskFormDialogService],
})
export class TasksModule {}
