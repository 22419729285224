import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, Subject } from 'rxjs'
import { LocalSearchTableComponent } from './local-search-table/local-search-table.component'
import {
    LocalSearchTableContainer,
    ServerSearchTableContainer,
    SearchDialogResult,
} from './models/searchTableContainer'
import { DisplayColumn } from './models/displayColumn'
import { ServerSearchTableComponent } from './server-search-table/server-search-table.component'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class SearchDialogService {
    constructor(private dialog: MatDialog) {}

    showLocalDialog<EntityModel>(data: LocalSearchTableContainer<EntityModel>): Observable<EntityModel> {
        const dialogRef = this.dialog.open(LocalSearchTableComponent, {
            width: '800px',
            data: data,
        })
        return dialogRef.afterClosed().pipe(
            map((result: SearchDialogResult<EntityModel>) => {
                if (result) {
                    return result.selectedEntity
                }
                return undefined
            })
        )
    }

    showExtendedLocalDialog<EntityModel>(
        data: LocalSearchTableContainer<EntityModel>
    ): Observable<SearchDialogResult<EntityModel>> {
        const dialogRef = this.dialog.open(LocalSearchTableComponent, {
            width: '800px',
            data: data,
        })
        return dialogRef.afterClosed()
    }

    showServerDialog<EntiyModel, QueryModel>(
        data: ServerSearchTableContainer<EntiyModel, QueryModel>
    ): Observable<SearchDialogResult<EntiyModel>> {
        const dialogRef = this.dialog.open(ServerSearchTableComponent, {
            width: '800px',
            data: data,
        })
        return dialogRef.afterClosed()
    }
}
