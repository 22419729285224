import { Component, Input, OnInit } from '@angular/core'
import { CommonComponent } from '@app/models/formComponent'
import { TimesheetTaskModel } from '@app/models/timesheet/TimesheetModel'
import { TimesheetEntityTaskDto, TimesheetEntitySubTaskDto } from '@app/services/api.services'

@Component({
    selector: 'app-timehseet-property-task-select',
    templateUrl: './timehseet-property-task-select.component.html',
    styleUrls: ['./timehseet-property-task-select.component.scss'],
})
export class TimehseetPropertyTaskSelectComponent extends CommonComponent implements OnInit {
    @Input() task: TimesheetTaskModel

    constructor() {
        super()
    }

    ngOnInit(): void {}

    isEnabled(task: TimesheetEntityTaskDto | TimesheetEntitySubTaskDto) {
        return !task.isBillable || this.task.property.canSelectBillableTasks
    }
}
