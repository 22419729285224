<h2 mat-dialog-title>Generate Timesheet Report</h2>
<mat-dialog-content>
    <div class="row">
        <div class="col-6">
            <mat-form-field>
                <input matInput [matDatepicker]="picker" placeholder="From date" [(ngModel)]="startDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between">
    <div>
        <button mat-button [mat-dialog-close]>Cancel</button>
    </div>
    <div>
        <button
            mat-button
            cdkFocusInitial
            *ngxPermissionsOnly="['GenerateEmailTimesheetReport']"
            (click)="generateReport()"
            [mat-dialog-close]
            [loading]="isSubmitting"
        >
            Generate Report
        </button>
    </div>
</mat-dialog-actions>
