import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '@app/material.module'
import { ServerSearchTableComponent } from './server-search-table.component'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'

@NgModule({
    imports: [CommonModule, MaterialModule, ServerPagingContainerModule],
    declarations: [ServerSearchTableComponent],
    exports: [ServerSearchTableComponent],
    entryComponents: [ServerSearchTableComponent],
})
export class ServerSearchTableModule {}
