import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core'
import { Validators, FormArray, FormGroup } from '@angular/forms'
import { EnumListService } from '@app/services/enum-list.service'
import { FormComponentSettings, DialogCapableFormComponentId } from '@app/models/formComponent'
import { Observable, of } from 'rxjs'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { FormContainerService } from '@app/services/form-container.service'
import { ActivatedRoute } from '@angular/router'
import {
    LabelDto,
    LabelGroupsService,
    LabelGroupDto,
    LabelTranslation,
    LabelGroupTranslation,
    EditLabelGroupCommand,
    CreateLabelGroupCommand,
    LabelGroupType,
    ProductTranslation,
    BaseLabelGroupCommand,
    LabelInput,
} from '@app/services/api.services'
import { BaseTranslatableModel } from '@app/models/template/BaseTranslatableModel'

export class LabelGroupFormDialogSettings {
    constructor(public lockedLabelGroupType: LabelGroupType) {}
}

export class LabelModel extends BaseTranslatableModel<LabelTranslation> {
    id: string

    isAdded: boolean
    isDeleted: boolean
    isEdited: boolean
    isInvalid: boolean
    isEditing: boolean

    static createFromLabelDto(labelDto: LabelDto): LabelModel {
        const label = new LabelModel(labelDto.translations, labelDto.id)
        label.selectedTranslation = labelDto.selectedTranslation
        return label
    }

    createLabelInput(): LabelInput {
        const labelInput = new LabelInput()
        labelInput.translations = this.translations.map((t) => new LabelTranslation(t))
        labelInput.id = this.id
        return labelInput
    }

    constructor(translations: LabelTranslation[], id: string = null) {
        super(translations)
        this.id = id
    }

    edit() {
        this.isEditing = true
    }

    doneEditing() {
        this.isEditing = false
    }
}

@Component({
    selector: 'app-label-group-form',
    templateUrl: './label-group-form.component.html',
    styleUrls: ['./label-group-form.component.scss'],
})
export class LabelGroupFormComponent
    extends DialogCapableFormComponentId<string, LabelGroupDto>
    implements OnInit, OnDestroy {
    displayedColumns = ['name', 'action']
    labels: LabelModel[] = []
    deletedLabels: LabelModel[] = []
    dataSource = new MatTableDataSource<LabelModel>()
    dialogSettings: LabelGroupFormDialogSettings
    isEdited = false

    constructor(
        private apiService: LabelGroupsService,

        public enumListService: EnumListService,
        private route: ActivatedRoute,
        formContainerService: FormContainerService
    ) {
        super(new FormComponentSettings(true, 'label-groups', 'Label Group', true), formContainerService)
    }

    editEntity(): Observable<void> {
        const cmd = new EditLabelGroupCommand()
        this._generateFromForm(cmd)
        cmd.id = this.model.id
        cmd.editedLabels = this.labels.filter((label) => label.isEdited).map((l) => l.createLabelInput())
        cmd.deletedLabelIds = this.deletedLabels.map((l) => l.id)
        return this.apiService.edit(cmd)
    }

    createEntity(): Observable<string> {
        const cmd = new CreateLabelGroupCommand()
        this._generateFromForm(cmd)
        cmd.labelGroupType = this.formControl.get('labelGroupType').value
        return this.apiService.create(cmd)
    }

    ngOnInit() {
        this.model = this.route.snapshot.data['item']
        if (this.model) {
            this.id = this.model.id
            this.labels = this.model.labels.map((labelDto) => LabelModel.createFromLabelDto(labelDto))
        }
        this._setupformControl()
    }

    canDeactivateBase(): boolean {
        return !this.formControl.dirty && !this.isEdited
    }

    addLabel() {
        const label = new LabelModel([])
        this.languageService.initTranslations(label.translations, () => new LabelTranslation())
        this.languageService.selectAnyTranslations(label)
        label.isAdded = true
        label.isEditing = true

        this.labels.push(label)
        const formArray = this.labelsFormArray
        formArray.push(this._creatLabelFormControl(label, formArray.length))
        this._updateDataSource()
    }

    private _updateDataSource() {
        this.dataSource.data = this.labels
    }

    removeLabel(index) {
        const deletedLabel = this.labels.splice(index, 1)[0]
        if (!deletedLabel.isAdded) {
            this.deletedLabels.push(deletedLabel)
        }
        const formArray = this.labelsFormArray
        formArray.removeAt(index)
        this._updateDataSource()
        this.isEdited = true
    }

    get labelsFormArray(): FormArray {
        return <FormArray>this.formControl.get('labels')
    }

    private _generateFromForm(command: BaseLabelGroupCommand) {
        command.init(this.formControl.value)
        command.addedLabels = this.labels.filter((label) => label.isAdded).map((l) => l.createLabelInput())
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new LabelGroupDto()
            this.model.translations = []
            this.model.labels = []
            this.model.isDefault = false

            this.languageService.initTranslations(this.model.translations, () => new LabelGroupTranslation())

            if (this.dialogSettings) {
                this.model.labelGroupType = this.dialogSettings.lockedLabelGroupType
            }
        }

        this.formControl = this._createLabelGroupForm(this.model)
        this._updateDataSource()
    }

    private _createLabelGroupForm(labelGroup: LabelGroupDto) {
        return this.formBuilder.group({
            id: [labelGroup.id],
            name: [{ value: labelGroup.name, disabled: labelGroup.isDefault }, [Validators.required]],
            labelGroupType: { value: labelGroup.labelGroupType, disabled: this._isSelectLabelGroupTypeDisabled() },
            translations: this.formBuilder.array([
                this._createTranslationForm(0, labelGroup),
                this._createTranslationForm(1, labelGroup),
            ]),
            labels: this._createLabels(this.labels),
        })
    }

    private _isSelectLabelGroupTypeDisabled() {
        if (this.id) {
            return true
        }

        if (this.dialogSettings && this.dialogSettings.lockedLabelGroupType) {
            return true
        }

        return false
    }

    private _createLabels(labels: LabelModel[]): FormArray {
        const formArray = this.formBuilder.array([])

        formArray.controls = labels.map((label, index) => this._creatLabelFormControl(label, index))
        return formArray
    }

    private _creatLabelFormControl(label: LabelModel, index: number) {
        const labelFormGroup = this.formBuilder.group({
            id: [label.id],
            translations: this.formBuilder.array([
                this._createTranslationForm(0, label),
                this._createTranslationForm(1, label),
            ]),
        })

        labelFormGroup.get('translations').valueChanges.subscribe((value) => {
            const labelModel = this.labels[index]
            if (labelModel) {
                labelModel.translations = value
                if (!labelModel.isAdded) {
                    labelModel.isEdited = true
                }
                this.languageService.selectAnyTranslations(labelModel)
            }
        })
        return labelFormGroup
    }

    private _createTranslationForm(index, entity) {
        return this.formBuilder.group({
            name: [{ value: entity.translations[index].name, disabled: entity.isDefault }, [Validators.required]],
            culture: entity.translations[index].culture,
            id: entity.translations[index].id,
        })
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }
}
