import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CommonComponent } from '@app/models/formComponent'
import { CategoricalPropertyDefinitionAllowedValueModel } from '@app/models/propertyDefinition/propertyDefinitionModel'
import {
    AllowedValueInput,
    CreateAllowedValueForPropertyDefinitionCommand,
    PropertyDefinitionService,
} from '@app/services/api.services'
import { LanguageService } from '@app/services/language.service'
import { NotifyService } from '@app/services/notify.service'

@Component({
    selector: 'app-custom-property-allowed-value-form-dialog',
    templateUrl: './custom-property-allowed-value-form-dialog.component.html',
    styleUrls: ['./custom-property-allowed-value-form-dialog.component.scss'],
})
export class CustomPropertyAllowedValueFormDialogComponent extends CommonComponent implements OnInit {
    model: CategoricalPropertyDefinitionAllowedValueModel
    formGroup: FormGroup
    isLoading = false

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: { propertyDefinitionId: string },
        protected dialogRef: MatDialogRef<CustomPropertyAllowedValueFormDialogComponent>,
        private formBuilder: FormBuilder,
        public languageService: LanguageService,
        private propertyDefinitionService: PropertyDefinitionService,
        private notifyService: NotifyService
    ) {
        super()
    }

    ngOnInit(): void {
        this.model = new CategoricalPropertyDefinitionAllowedValueModel(null, null, [])
        this._setupFormControl()
    }

    create() {
        if (this.formGroup.valid) {
            this.isLoading = true
            this.propertyDefinitionService.createAllowedValue(this._generateCommand()).subscribe(
                (id) => {
                    this.isLoading = false
                    this.notifyService.success('Allowed value created successfully.')
                    this.dialogRef.close(id)
                },
                (error) => {
                    this.notifyService.fail(
                        'Failed to create new allowed value. Make sure the internal value is unique.',
                        error,
                        3000
                    )
                    this.isLoading = false
                }
            )
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    private _setupFormControl() {
        this.formGroup = this.formBuilder.group({
            id: [this.model.id],
            valueIdentifier: [this.model.valueIdentifier, [Validators.required]],
            translations: this.formBuilder.array([
                this._createAllowedValueTranslationForm(0),
                this._createAllowedValueTranslationForm(1),
            ]),
        })
    }

    private _createAllowedValueTranslationForm(index: number) {
        return this.formBuilder.group({
            allowedValue: [this.model.translations[index].allowedValue, [Validators.required]],
            culture: this.model.translations[index].culture,
        })
    }

    private _generateCommand() {
        const cmd = new CreateAllowedValueForPropertyDefinitionCommand()
        cmd.allowedValueInput = new AllowedValueInput()
        cmd.allowedValueInput.init(this.formGroup.value)
        cmd.propertyDefinitionId = this.data.propertyDefinitionId
        return cmd
    }
}
