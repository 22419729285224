<div [formGroup]="group">
    <mat-form-field>
        <input
            type="text"
            matInput
            [placeholder]="placeholder"
            [textMask]="{ mask: numberMask }"
            [errorStateMatcher]="matcher"
            [formControlName]="controlName"
        />
        <mat-error *ngIf="group.hasError('required', [controlName])">
            {{ placeholder }} is <strong>required</strong>
        </mat-error>
    </mat-form-field>
</div>
