import { Component, OnInit, Inject, ViewChild, Optional } from '@angular/core'
import { CommonComponent } from '@app/models/formComponent'
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms'
import { EnumListService } from '@app/services/enum-list.service'
import { LanguageService } from '@app/services/language.service'
import { Language } from '@app/models/language'
import { BaseTranslatableModel } from '@app/models/template/BaseTranslatableModel'
import {
    CostLineTranslation,
    UnitType,
    TakeOffsService,
    TakeOffDto,
    GetTakeOffsListQuery,
} from '@app/services/api.services'
import { UnitPriceModel } from '@app/models/pricing/UnitPriceModel'
import { DisplayColumn } from '../search-dialog/models/displayColumn'
import { ServerSearchTableContainer } from '../search-dialog/models/searchTableContainer'
import { TakeOffModelDataSource } from '@app/admin-pages/takeoffs/reusable-takeoffs-list/takeoffs-list.datasource'
import { map, tap, flatMap } from 'rxjs/operators'
import { TakeOffModel } from '@app/models/template/TakeOffModel'
import { SearchDialogService } from '../search-dialog/search-dialog.service'
import { TemplateModel } from '@app/models/template/TemplateModel'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormDialogService } from '../dialog/form-dialog/form-dialog.service'
import { of } from 'rxjs'

export enum CostLineType {
    ProductOption,
    Product,
    Option,
    Service,
    LinkedProduct,
}

export class CostLineDialogModel extends BaseTranslatableModel<CostLineTranslation> {
    private _quantity = 0

    unitPrice: UnitPriceModel = new UnitPriceModel(0, 0, false)
    unitType = UnitType.Each
    displayRetailPriceControls = false

    isEdit: boolean
    isUpdateAllClicked = false

    description: string
    hasDescription: boolean
    useTakeOff: boolean
    allowUseOfTakeOff: boolean
    allowEditUnitType: boolean

    template: TemplateModel | null
    useOnlyGlobalTakeOffs: boolean
    takeOff: TakeOffModel

    isLinkedEntity: boolean

    linkedEntity: any

    constructor(translations: CostLineTranslation[]) {
        super(translations)
    }

    get extendedPrice(): number {
        return this.quantity * this.unitPrice.unitCostAmount
    }

    get extendedMarkupPrice(): number {
        return this.quantity * this.unitPrice.unitMarkupAmount
    }

    set totalPrice(value: number) {}

    get totalPrice(): number {
        return this.extendedPrice + this.extendedMarkupPrice
    }

    get quantity(): number {
        if (this.useTakeOff) {
            return this.takeOff.dynamicQuantity
        }

        return this._quantity
    }

    set quantity(value: number) {
        this._quantity = value
    }
}

@Component({
    selector: 'app-cost-line-dialog',
    templateUrl: './cost-line-dialog.component.html',
    styleUrls: ['./cost-line-dialog.component.scss'],
})
export class CostLineDialogComponent extends CommonComponent implements OnInit {
    formGroup: FormGroup
    selectedLanguage: Language
    model: CostLineDialogModel
    showUpdateAllBtns = true

    constructor(
        private formBuilder: FormBuilder,
        public enumListService: EnumListService,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        data: {
            costLineDialog: CostLineDialogModel
            showUpdateAll: boolean
        },
        @Optional() private dialogRef: MatDialogRef<CostLineDialogComponent>,
        private takeOffsService: TakeOffsService,
        private searchDialogService: SearchDialogService,
        public languageService: LanguageService,
        private formDialogService: FormDialogService
    ) {
        super()

        this.model = data.costLineDialog
        this.showUpdateAllBtns = data.showUpdateAll
    }

    addCostLine() {
        this.getFormValidationErrors(this.formGroup)

        if (this.formGroup.valid) {
            this._generateFromForm()
            this.dialogRef.close(this.model)
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    editCostLine(updateAll = false) {
        if (this.formGroup.valid) {
            this._generateFromForm()
            this.model.isUpdateAllClicked = updateAll
            this.dialogRef.close(this.model)
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    ngOnInit() {
        this._setupformControl()
        //  this._calculate();
    }

    hasTakeOffQuantities(): boolean {
        return false
    }

    selectTakeOff() {
        const templateIds = []

        if (this.model.template) {
            const template = this.model.template
            templateIds.push(this.model.template.id)
            if (template.parentTemplate && template.parentTemplate.id) {
                templateIds.push(template.parentTemplate.id)
                if (template.parentTemplate.parentTemplate && template.parentTemplate.parentTemplate.id) {
                    templateIds.push(template.parentTemplate.parentTemplate.id)
                }
            }
        }

        const dispalyColumns = [new DisplayColumn('Name', 'name'), new DisplayColumn('Quantity', 'dynamicQuantity')]
        const setting = new ServerSearchTableContainer<TakeOffModel, GetTakeOffsListQuery>(
            'Take Offs',
            new GetTakeOffsListQuery({ getOnlyGlobalTakeOffs: this.model.useOnlyGlobalTakeOffs, templateIds }),
            new TakeOffModelDataSource(this.takeOffsService),
            true,
            dispalyColumns,
            [],
            (p) => {
                return TakeOffModel.createFromDto(p)
            }
        )
        setting.showAddButton = true

        this.searchDialogService
            .showServerDialog(setting)
            .pipe(
                flatMap((result) => {
                    if (!result) {
                        return of(null)
                    } else if (result.isPicked) {
                        return of(result.selectedEntity)
                    } else if (result.isAddClicked) {
                        return this.formDialogService.showTakeOffFormDialog()
                    }
                    return of(null)
                })
            )
            .pipe(
                flatMap((value: string | TakeOffModel) => {
                    if (!value) {
                        return of()
                    }
                    if (value instanceof TakeOffModel) {
                        return of(value)
                    }
                    return this.takeOffsService.getTakeOff(value)
                })
            )
            .subscribe((takeOff: TakeOffModel | TakeOffDto) => {
                if (takeOff instanceof TakeOffDto) {
                    takeOff = TakeOffModel.createFromDto(takeOff)
                }
                if (takeOff) {
                    this.model.useTakeOff = true
                    this.model.takeOff = takeOff
                }
            })
    }

    clearTakeOff() {
        this.model.useTakeOff = false
        this.model.takeOff = undefined
    }

    private _generateFromForm() {
        if (this.formGroup.contains('translations')) {
            this.model.translations = this.formGroup.get('translations').value.map((t) => CostLineTranslation.fromJS(t))
        }

        // need to set this null to prevent self referencing loop

        this.model.template = null
        this.languageService.selectAnyTranslations(this.model)
    }

    private _setupformControl() {
        this.formGroup = this.formBuilder.group({})

        this.formGroup.addControl(
            'translations',
            this.formBuilder.array([this._createTranslationForm(0), this._createTranslationForm(1)])
        )
    }

    private _createTranslationForm(index) {
        return this.formBuilder.group({
            description: [this.model.translations[index].description, [Validators.required]],
            culture: this.model.translations[index].culture,
            id: this.model.translations[index].id,
        })
    }
}
