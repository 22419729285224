import { Component, OnInit, Input } from '@angular/core'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { MyErrorStateMatcher } from '@app/models/myErrorStateMatcher'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-quantity',
    templateUrl: './quantity.component.html',
    styleUrls: ['./quantity.component.scss'],
})
export class QuantityComponent implements OnInit {
    private _quantityValue: string

    get quantityValue(): string {
        return this._quantityValue
    }

    set quantityValue(value: string) {
        this._quantityValue = value
        this.group.get(this.controlName).setValue(parseFloat(value))
    }

    @Input() placeholder: string
    @Input() controlName: string
    @Input() group: FormGroup
    @Input() disabled = false
    @Input() allowDecimal = true
    @Input() hasErrors = false

    numberMask = createNumberMask({
        prefix: '',
        allowDecimal: this.allowDecimal,
        includeThousandsSeparator: false,
    })

    matcher = new MyErrorStateMatcher()

    constructor() {}

    ngOnInit() {
        this._quantityValue = this.group.get(this.controlName).value
    }
}
