import { Injectable } from '@angular/core'
import { PropertiesService, SaveSelectionCommand } from './api.services'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { NotifyService } from './notify.service'
import { SelectionItemModel, SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { flatMap, tap, map } from 'rxjs/operators'
import { Observable, of, observable, from } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { SelectionDetailsModel } from '@app/models/template/SelectionDetailsModel'

@Injectable({
    providedIn: 'root',
})
export class PropertySelectionService {
    constructor(
        private confirmDialogService: ConfirmDialogService,
        private propertiesService: PropertiesService,
        private notifyService: NotifyService
    ) {}

    toggleSelectionItem({
        templateId,
        selectionItem,
        selectionGroup,
        parentSelectionGroup,
    }: {
        templateId: string
        selectionItem: SelectionItemModel
        selectionGroup: SelectionGroupModel
        parentSelectionGroup: SelectionGroupModel
    }): Observable<boolean> {
        // If the option is already selected then this means they would want to unselect it
        if (selectionItem.isSelected) {
            return this._unselectOption(templateId, selectionItem, selectionGroup)
        } else {
            return this._selectSelectionItem(templateId, selectionItem, selectionGroup, parentSelectionGroup)
        }
    }

    private _selectSelectionItem(
        templateId: string,
        selectionItem: SelectionItemModel,
        selectionGroup: SelectionGroupModel,
        parentSelectionGroup: SelectionGroupModel
    ): Observable<boolean> {
        const currentSelections = selectionGroup.getAllSelections()

        // If the client has an existing selection, and this group has a max of one, then what we can do is an unselect on the existing one and then select the new one.
        if (currentSelections.length === 1 && selectionGroup.maximumOptionChoice === 1) {
            return this._swapSelection(templateId, selectionItem, currentSelections[0], selectionGroup)
        }

        if (selectionGroup.maximumOptionsSelectectedExceeded()) {
            this.notifyService.fail(
                "You can't make any more selections for this group, please unselect one to select this one"
            )
            return of(false)
        }

        if (selectionGroup.isLocked) {
            this.notifyService.fail("Your selection can't be changed at this point.")
            return of(false)
        }

        return this.confirmDialogService
            .showDialog('Are you sure you want to select this option?', 'Confirm Selection')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        const command = new SaveSelectionCommand()
                        command.propertyTemplateId = templateId
                        command.propertySelectionId = selectionGroup.propertySelection.id
                        command.selectedSelectionItemId = selectionItem.id
                        command.isNestedSelection = selectionGroup.isNestedSelectionGroup
                        if (selectionGroup.isNestedSelectionGroup) {
                            command.parentSelectionItemId = selectionGroup.depedantParentSelectionItem.id
                        }
                        return this.propertiesService.saveSelection(command)
                    }
                })
            )
            .pipe(
                map((selectionDetail) => {
                    // if selection group is nested, selecting parent selection item if it isn't already selected
                    if (
                        selectionGroup.isNestedSelectionGroup &&
                        parentSelectionGroup &&
                        selectionGroup.depedantParentSelectionItem &&
                        !selectionGroup.depedantParentSelectionItem.isSelected
                    ) {
                        const parentSelectionGroupSelections = parentSelectionGroup.getAllSelections()
                        // swapping parent selection
                        if (
                            parentSelectionGroupSelections.length === 1 &&
                            parentSelectionGroup.maximumOptionChoice === 1
                        ) {
                            parentSelectionGroup.swapSelection(
                                selectionGroup.depedantParentSelectionItem,
                                parentSelectionGroupSelections[0],
                                SelectionDetailsModel.createFromDto(selectionDetail.depedantParentSelectedItem)
                            )
                        } else {
                            parentSelectionGroup.selectSelectionItem(
                                selectionGroup.depedantParentSelectionItem,
                                SelectionDetailsModel.createFromDto(selectionDetail.depedantParentSelectedItem)
                            )
                        }
                    }
                    selectionGroup.selectSelectionItem(
                        selectionItem,
                        SelectionDetailsModel.createFromDto(selectionDetail)
                    )
                    this.notifyService.success('We have saved your selection')
                    return true
                })
            )
    }

    private _swapSelection(
        templateId: string,
        newSelection: SelectionItemModel,
        selectionItemToCancel: SelectionItemModel,
        selectionGroup: SelectionGroupModel
    ): Observable<boolean> {
        return this.confirmDialogService
            .showDialog('Selecting this option will unselect your existing choice, are you sure?', 'Confirm Selection')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        const command = new SaveSelectionCommand()
                        command.propertyTemplateId = templateId
                        command.propertySelectionId = selectionGroup.propertySelection.id
                        command.selectedSelectionItemId = newSelection.id
                        command.cancelledSelectedItemId = selectionItemToCancel.selectionDetails.selectedItemId
                        command.isNestedSelection = selectionGroup.isNestedSelectionGroup
                        return this.propertiesService.saveSelection(command)
                    }
                })
            )
            .pipe(
                map((selectionDetail) => {
                    selectionGroup.swapSelection(
                        newSelection,
                        selectionItemToCancel,
                        SelectionDetailsModel.createFromDto(selectionDetail)
                    )
                    this.notifyService.success('We have saved your new selection')
                    return true
                })
            )
    }

    private _unselectOption(
        templateId: string,
        selectionItemToCancel: SelectionItemModel,
        selectionGroup: SelectionGroupModel
    ): Observable<boolean> {
        return this.confirmDialogService
            .showDialog('Are you sure you want to unselect this option?', 'Confirm cancellation of selection')
            .pipe(
                flatMap((result) => {
                    if (result) {
                        const command = new SaveSelectionCommand()
                        command.propertyTemplateId = templateId
                        command.propertySelectionId = selectionGroup.propertySelection.id
                        command.cancelledSelectedItemId = selectionItemToCancel.selectionDetails.selectedItemId
                        command.isNestedSelection = selectionGroup.isNestedSelectionGroup
                        return this.propertiesService.saveSelection(command)
                    }
                })
            )
            .pipe(
                map((selectionDetail) => {
                    selectionGroup.unselectSelectionItem(
                        selectionItemToCancel,
                        SelectionDetailsModel.createFromDto(selectionDetail)
                    )
                    this.notifyService.success('We have cancelled your selection')
                    return true
                })
            )
    }
}
