import { NgModule } from '@angular/core'
import { LabelFormDialogComponent } from './label-form-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'

@NgModule({
    imports: [CommonFormsModule],
    declarations: [LabelFormDialogComponent],
    exports: [],
    entryComponents: [LabelFormDialogComponent],
})
export class LabelFormDialogModule {}
