import { Component, OnInit } from '@angular/core'
import { CommonComponent } from '@app/models/formComponent'
import { Moment } from 'moment'
import { TimesheetsService, GenerateReportCommand } from '@app/services/api.services'
import { NotifyService } from '@app/services/notify.service'

@Component({
    selector: 'app-generate-timesheet-report-date-picker',
    templateUrl: './generate-timesheet-report-date-picker.component.html',
    styleUrls: ['./generate-timesheet-report-date-picker.component.scss'],
})
export class GenerateTimesheetReportDatePickerComponent extends CommonComponent implements OnInit {
    startDate: Moment
    isSubmitting = false

    constructor(private timesheetsService: TimesheetsService, private notifyService: NotifyService) {
        super()
    }

    ngOnInit() {}

    generateReport() {
        this.isSubmitting = true
        this.timesheetsService
            .generateTimesheetReport(new GenerateReportCommand({ startDate: this.startDate }))
            .subscribe(
                () => {
                    this.isSubmitting = false
                    this.notifyService.success('Timesheet report generated successfully.')
                },
                (error) => {
                    console.log(error)
                    this.isSubmitting = false
                    this.notifyService.fail('Failed to generate timesheet report.')
                }
            )
    }
}
