import { NgModule } from '@angular/core'
import { PackageFormComponent } from './package-form.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { SelectionItemDetailsModule } from '@app/shared/selection-item-details/selection-item-details.module'
import { PackageSelectionFormComponent } from '../package-selection-form/package-selection-form.component'
import { ImagesModule } from '@app/shared/images/images.module'

@NgModule({
    declarations: [PackageFormComponent, PackageSelectionFormComponent],
    imports: [CommonFormsModule, ImagesModule, SelectionItemDetailsModule],
    exports: [PackageFormComponent],
    entryComponents: [PackageSelectionFormComponent],
})
export class PackageFormModule {}
