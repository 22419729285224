import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core'
import { CommonComponent } from '@app/models/formComponent'
import { MatTableDataSource } from '@angular/material/table'
import { TaskModel } from '@app/models/tasks/taskModel'
import { trigger, state, style, transition, animate } from '@angular/animations'
import { TaskFormDialogService } from '../task-form-dialog/task-form-dialog.service'
import { ItemContainer, ItemState } from '@app/models/util/itemContainer'
import { NotifyService } from '@app/services/notify.service'
import { MatSort, MatSortable } from '@angular/material/sort'
import { ButtonListDialogService } from '@app/shared/dialog/button-list-dialog/button-list-dialog.service'
import { ModalButton } from '@app/shared/dialog/button-list-dialog/models/modalButton'

@Component({
    selector: 'app-task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['./task-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', border: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TaskListComponent extends CommonComponent implements OnInit, AfterViewInit {
    tableColumns = [
        'index',
        'name',
        'description',
        'allocatedHours',
        'billOnAllocatedHoursExceeded',
        'totalTimesheetTasksHours',
        'totalTimesheetTasksBillableHours',
        'totalTimesheetTasksBillableAmount',
        'hasSubTasks',
        'action',
    ]

    dataSource: MatTableDataSource<ItemContainer<TaskModel>> = new MatTableDataSource<ItemContainer<TaskModel>>()

    expandedTask: ItemContainer<TaskModel>

    @Output() onShowTasksModal = new EventEmitter<TaskModel>()

    @Output() onTasksChanges = new EventEmitter<ItemContainer<TaskModel>[]>()
    @Input() isSubTasks = false
    @Input() hideFooter = false
    @Input() hideActions = false

    // buggy, can't get to work well
    // private _sort: MatSort

    // @ViewChild(MatSort) set sort(value: MatSort) {
    //     this._sort = value
    //     this.dataSource.sort = this.sort
    //     this.dataSource.sortingDataAccessor = (item, property) => {
    //         return item.item[property]
    //     }
    // }

    // get sort(): MatSort {
    //     return this._sort
    // }

    get tasks(): ItemContainer<TaskModel>[] {
        return this.dataSource.data
    }
    @Input() set tasks(value: ItemContainer<TaskModel>[]) {
        value.sort((a, b) => (a.item.name > b.item.name ? 1 : -1))
        this.dataSource.data = value
    }

    constructor(
        private formDialogService: TaskFormDialogService,
        private notifyService: NotifyService,
        private buttonListDialogService: ButtonListDialogService
    ) {
        super()
    }

    ngOnInit() {
        if (this.isSubTasks) {
            this.tableColumns.splice(
                this.tableColumns.findIndex((t) => t === 'hasSubTasks'),
                1
            )
        }

        if (this.hideActions) {
            this.tableColumns.splice(
                this.tableColumns.findIndex((t) => t === 'action'),
                1
            )
        }
    }

    showActions(row: ItemContainer<TaskModel>) {
        const editModalButton = new ModalButton('Edit')

        editModalButton.onClick.subscribe(() => {
            this.edit(row)
        })

        const deleteModalButton = new ModalButton('Delete')

        deleteModalButton.onClick.subscribe(() => {
            this.remove(row)
        })

        const showTasksModalButton = new ModalButton('Show Timesheet Tasks')

        showTasksModalButton.onClick.subscribe(() => {
            this.onShowTasksModal.emit(row.item)
        })

        const modalButtons = [editModalButton, deleteModalButton, showTasksModalButton]
        this.buttonListDialogService.showDialog(modalButtons).subscribe()
    }

    ngAfterViewInit() {}

    add() {
        this.formDialogService
            .showTaskFormDialog({ allowSubTasks: !this.isSubTasks, disableSubTasksEdit: false })
            .subscribe((task) => {
                if (task) {
                    this.tasks.push(new ItemContainer<TaskModel>(task, ItemState.NewlyAdded))
                    this._updateTasks()
                }
            })
    }

    edit(row: ItemContainer<TaskModel>) {
        const index = this.tasks.findIndex((t) => t == row)

        this.formDialogService
            .showTaskFormDialog({ allowSubTasks: !this.isSubTasks, disableSubTasksEdit: true }, this.tasks[index].item)
            .subscribe((taskModel) => {
                if (taskModel) {
                    const taskContainer = this.tasks[index]
                    taskContainer.item = taskModel
                    taskContainer.setAsEdited()
                    taskContainer.item.subTasks = [...taskModel.subTasks]
                    this._updateTasks()
                }
            })
    }

    undoRemove(row: ItemContainer<TaskModel>) {
        row.reverseAsRemoved()
    }

    remove(row: ItemContainer<TaskModel>) {
        const index = this.tasks.findIndex((t) => t == row)
        const task = this.tasks[index]

        if (task.item.numberOfTimesheetTasks > 0) {
            this.notifyService.fail("Can't removed tasks because it has associated timesheet tasks", null, 5000)
            return
        }

        task.setAsRemoved()
        if (task.state === ItemState.NotAdded) {
            this.tasks.splice(index, 1)
        }
        this._updateTasks()
    }

    expandRow(task: ItemContainer<TaskModel>) {
        if (this.isSubTasks && !task.item.hasTimeSubTasks) {
            return
        }
        this.expandedTask = this.expandedTask === task ? null : task
    }

    private _updateTasks() {
        this.dataSource = new MatTableDataSource<ItemContainer<TaskModel>>(this.tasks.filter((t) => !t.isNotAdded))
        this.onTasksChanges.emit(this.tasks)
    }
}
