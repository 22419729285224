import { Router } from '@angular/router'
import { Subscription, Observable, Subject } from 'rxjs'
import { NgZone, OnInit, ViewChild, Input, Output, Directive } from '@angular/core'
import { NotifyService } from '../services/notify.service'
import { FixedButton } from '../shared/fixed-form-controls/fixed-form-controls.service'
import { PaginationModel } from '@app/services/api.services'
import { ServerPagingContainerComponent } from '@app/shared/server-pagination-container/server-paging-container.component'
import { PagingDataSource } from './interfaces/dataSourceInterface'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'

@Directive()
export abstract class ServerPaginationListComponent<IdType, ModelType, QueryType> implements OnInit {
    @ViewChild('tableContainer') tableContainer: ServerPagingContainerComponent<ModelType>
    @Input() isSelectionMode: boolean

    dataSource: PagingDataSource<ModelType, QueryType>
    fixedFormSub: Subscription
    fixedAddButton: FixedButton
    isLoaded: boolean
    isFailed: boolean
    query: QueryType
    customRouteParams: (id) => any[]

    public ngZone: NgZone

    private _selectedEntity: ModelType
    private _entitySelectedSubject = new Subject<ModelType>()

    @Output() get onEntitySelected() {
        return this._entitySelectedSubject.asObservable()
    }

    get onEntitySelectedObservable(): Observable<ModelType> {
        return this._entitySelectedSubject.asObservable()
    }

    isEntitySelected(entity: ModelType) {
        if (!this.isSelectionMode) {
            return false
        }
        return this._selectedEntity === entity
    }

    constructor(
        public router: Router,
        public entityName: string,
        public confirmDialogService: ConfirmDialogService,
        public notifyService: NotifyService
    ) {}

    abstract loadDataSource(): PagingDataSource<ModelType, QueryType>
    abstract loadEntities(paginationModel: PaginationModel)
    abstract deleteEntity(id: IdType): Observable<void>

    load() {
        this.dataSource = this.loadDataSource()

        this.dataSource.loading$.subscribe((isLoading) => {
            this.isLoaded = !isLoading
        })

        this.dataSource.paginationObservable().subscribe((paginationModel) => {
            if (paginationModel) {
                this.tableContainer.length = paginationModel.count
            }
        })

        this.tableContainer.dataSource = this.dataSource
        this.loadEntities(this.tableContainer.generatePaginationModel())
    }

    ngOnInit() {}

    create() {
        this.router.navigate([this.entityName + '/create'])
    }

    edit(id: IdType) {
        this.router
            .navigate(this.customRouteParams ? this.customRouteParams(id) : [this.entityName + '/edit/', id])
            .catch(() => {
                this.notifyService.fail('Failed to load entity')
                this.isLoaded = true
            })
        setTimeout(() => {
            this.isLoaded = false
        })
    }

    select(entity: ModelType) {
        this._selectedEntity = entity
        this._entitySelectedSubject.next(entity)
    }

    delete(id: IdType) {
        this.confirmDialogService
            .showDialogWithLoader('Are you sure you want to delete this?', this.deleteEntity(id), 'Confirm Delete')
            .subscribe(
                (result) => {
                    if (result.success) {
                        this.notifyService.success('The entity has been deleted')
                        this.load()
                    }
                },
                (error) => {
                    this.notifyService.fail('Failed to delete')
                }
            )
    }
}
