<app-server-paging-container
    #tableContainer
    (addItem)="create()"
    [displayMode]="displayMode"
    [isFailed]="isFailed"
    [isLoaded]="isLoaded"
    [entityName]="'product-variation-groups'"
    entityDisplayName="Product Variation Groups"
    (reload)="filterChanges()"
>
    <table mat-table [dataSource]="tableContainer.dataSource" matSort (matSortChange)="filterChanges()">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>
        <ng-container matColumnDef="numberOfProductVariations">
            <th mat-header-cell *matHeaderCellDef mat-sort-header># of Product Variations</th>
            <td mat-cell *matCellDef="let row">{{ row.numberOfProductVariations }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
            <td mat-cell *matCellDef="let row">
                {{ row.createdDateTimeUtc ? row.createdDateTimeUtc.local().format('lll') : '' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="updatedDateTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>
            <td mat-cell *matCellDef="let row">
                {{ row.lastUpdatedDateTimeUtc ? row.lastUpdatedDateTimeUtc.local().format('lll') : '' }}
            </td>
        </ng-container>
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
            <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="isSelectionMode; else editButtons">
                    <button (click)="select(row)" mat-icon-button>
                        <mat-icon>done</mat-icon>
                    </button>
                </ng-container>

                <ng-template #editButtons>
                    <button (click)="edit(row.id)" mat-icon-button color="success">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button (click)="delete(row.id)" mat-icon-button color="warn" [disabled]="row.isDefault">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-template>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</app-server-paging-container>
