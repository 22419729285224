import { Component, Input, OnInit } from '@angular/core'
import { ServiceSupplierPriceVersionModel } from '@app/models/services/ServiceSupplierPriceVersionModel'
import { EnumListService } from '@app/services/enum-list.service'

@Component({
    selector: 'app-service-rate-supplier-unit-price',
    templateUrl: './service-rate-supplier-unit-price.component.html',
    styleUrls: ['./service-rate-supplier-unit-price.component.scss'],
})
export class ServiceSupplierUnitPriceComponent implements OnInit {
    @Input() isCreatingNewPrice: boolean
    @Input() price: ServiceSupplierPriceVersionModel

    constructor(public enumListService: EnumListService) {}

    ngOnInit(): void {}
}
