import { Component, OnInit } from '@angular/core'
import { DialogCapableFormComponentId, FormComponentSettings } from '@app/models/formComponent'
import { FormContainerService } from '@app/services/form-container.service'
import { PackageSelectionModel } from '@app/models/packages/packageSelectionModel'
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms'
import { of, Observable } from 'rxjs'
import { LanguageService } from '@app/services/language.service'
import { MediaPickerDialogService } from '@app/shared/dialog/media-picker-dialog/media-picker-dialog.service'
import { MediaPickerDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { MediaDto } from '@app/services/api.services'
import { ImageModel } from '@app/models/common/ImageModel'
import { ImageCdnService } from '@app/shared/images/image-cdn.service'

@Component({
    selector: 'app-package-selection-form',
    templateUrl: './package-selection-form.component.html',
    styleUrls: ['./package-selection-form.component.scss'],
})
export class PackageSelectionFormComponent
    extends DialogCapableFormComponentId<string, PackageSelectionModel>
    implements OnInit {
    constructor(
        formContainerService: FormContainerService,
        private mediaPickerDialogService: MediaPickerDialogService,
        public imageCdnService: ImageCdnService
    ) {
        super(new FormComponentSettings(true, 'packages', 'Package Selection', true, false), formContainerService)
    }

    ngOnInit() {
        if (this.model) {
            this.id = this.model.id
        } else {
        }

        this._setupFormControl()
    }

    editEntity(): Observable<void> {
        return of()
        // return of(this._generateModelFromForm());
        // TODO: return model only if this.isInDialogMode == true
        // else call backend to save
    }
    createEntity(): Observable<string> {
        // TODO: implement create
        throw new Error('Method not implemented.')
    }

    private _generateModelFromForm(): PackageSelectionModel {
        const packageSelectionModel = this.formControl.value as PackageSelectionModel
        this.model.useSelectionItemName = packageSelectionModel.useSelectionItemName
        this.model.canReplaceWithOtherSelection = packageSelectionModel.canReplaceWithOtherSelection
        this.model.hasToSelect = packageSelectionModel.hasToSelect
        this.model.provideDisplayImage = packageSelectionModel.provideDisplayImage

        if (!this.model.useSelectionItemName) {
            const translations = this.formControl.get('translations').value as FormArray

            for (let index = 0; index < this.model.translations.length; index++) {
                this.model.translations[index].culture = translations[index].culture
                this.model.translations[index].name = translations[index].name
                if (translations[index].id) {
                    this.model.translations[index].id = translations[index].id
                }
            }
        }

        this.languageService.selectAnyTranslations(this.model)
        return this.model
    }

    private _setupFormControl() {
        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            useSelectionItemName: [this.model.useSelectionItemName],
            canReplaceWithOtherSelection: [this.model.canReplaceWithOtherSelection],
            hasToSelect: [this.model.hasToSelect],
            provideDisplayImage: [this.model.provideDisplayImage],
        })

        if (!this.model.useSelectionItemName) {
            this._createTranslationFormArray()
        }

        this.formControl.get('useSelectionItemName').valueChanges.subscribe((useSelectionItemName) => {
            this.model.useSelectionItemName = useSelectionItemName
            this._addOrRemoveTranslations()

            if (!useSelectionItemName) {
                this.languageService.selectAnyTranslations(this.model)
            }
        })

        this.formControl.get('provideDisplayImage').valueChanges.subscribe((value) => {
            this.model.provideDisplayImage = value
        })
    }

    changeDisplayImage() {
        const mediaPickerSettings = new MediaPickerDialogSettings(false, true)
        mediaPickerSettings.requiredSelections = 1

        this.mediaPickerDialogService.showDialog(mediaPickerSettings).subscribe((medias: MediaDto[]) => {
            if (!medias) {
                return
            }
            this.model.displayImage = ImageModel.createFromMediaDto(medias[0])
        })
    }

    private _addOrRemoveTranslations() {
        if (this.model.useSelectionItemName && this.formControl.contains('translations')) {
            this.formControl.removeControl('translations')
        } else if (!this.model.useSelectionItemName) {
            this._createTranslationFormArray()
        }
    }

    private _createTranslationFormArray() {
        const formArray = new FormArray([])
        for (let index = 0; index < this.model.translations.length; index++) {
            formArray.push(this._createTranslationForm(index))
        }
        this.formControl.addControl('translations', formArray)
    }

    private _createTranslationForm(index) {
        return this.formBuilder.group({
            id: this.model.translations[index].id,
            culture: [this.model.translations[index].culture, []],
            name: [this.model.translations[index].name, [Validators.required]],
        })
    }
}
