import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms'
import {
    CommonMedia,
    ImageService,
    DocumentsService,
    UploadDocumentCommand,
    UploadImageCommand,
    MediaType,
    MediaDto,
    ProjectsService,
    ProjectDto,
    EditImageCommand,
    EditDocumentCommand,
    MediaCaption,
    LabelGroupsService,
    LabelDto,
    GetSpecificLabelGroupsQuery,
    LabelGroupType,
} from '@app/services/api.services'
import { EnumListService } from '@app/services/enum-list.service'
import { MediaUploadService } from '@app/services/media-upload.service'
import { FileSystemFileEntry, FileSystemDirectoryEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { CommonComponent } from '@app/models/formComponent'
import { Observable, Subject, BehaviorSubject } from 'rxjs'
import { NotifyService } from '@app/services/notify.service'
import { ImgCropperDialogSettings } from '@app/models/dialogs/imgCropperDialogSettings'
import { ImgCropperDialogService } from '../dialog/img-cropper-dialog/img-cropper-dialog.service'
import { FileSelectSettings } from '@app/models/dialogs/fileSelectSettings'
import { MatDialog } from '@angular/material/dialog'
import { LanguageService } from '@app/services/language.service'
import { Language } from '@app/models/language'

@Component({
    selector: 'app-file-select',
    templateUrl: './file-select.component.html',
    styleUrls: ['./file-select.component.scss'],
})
export class FileSelectComponent extends CommonComponent implements OnInit {
    public SUPPORTED_IMAGE_TYPES = ['.png', '.jpg', '.jpeg', '.gif']

    formGroup: FormGroup
    id: string
    filesToUpload: File[] = []
    fileEvent: any
    mediaToUploadPlaceholder: string
    image: any
    model: CommonMedia
    isDocument: boolean
    projects: ProjectDto[] = []
    languages: Language[] = []

    selectedLabels: LabelDto[] = []

    allowedFileTypes = '.png,.jpg,.jpeg,.gif'

    @Input() setting: FileSelectSettings
    @Output() fileSelected = new EventEmitter()
    @Input() isEdit = false
    @Input() mediaToEdit: MediaDto
    @Input() canSelectMultipleFiles = false
    multipleFilesSelected = false
    savedMediaDtos: MediaDto[] = []

    private _validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    private _validImageExtensions = ['gif', 'jpg', 'jpeg', 'png']
    private _validDocTypes = [
        'application/pdf',
        'application/x-pdf',
        'application/x-bzpdf',
        'application/x-gzpdf',
        'application/xml',
        'text/xml',
        'text/csv',
        'text/plain',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        'application/vnd.ms-word.document.macroEnabled.12',
        'application/vnd.ms-word.template.macroEnabled.12',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
        'application/vnd.ms-excel.template.macroEnabled.12',
        'application/vnd.ms-excel.addin.macroEnabled.12',
        'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        'application/vnd.ms-powerpoint.addin.macroEnabled.12',
        'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
    ]
    private _validDocExtensions = [
        'pdf',
        'xml',
        'csv',
        'txt',
        'doc',
        'dot',
        'docx',
        'docm',
        'dotx',
        'dotm',
        'xls',
        'xlt',
        'xlm',
        'xlsx',
        'xlsm',
        'xltx',
        'xltm',
        'ppt',
        'pot',
        'pps',
        'ppts',
        'pptm',
        'potx',
        'potm',
        'ppsx',
        'ppsm',
        'sldx',
        'sldm',
    ]

    @ViewChild('file') file

    constructor(
        public imagesService: ImageService,
        public documentsService: DocumentsService,
        public enumListService: EnumListService,
        public mediaUploadService: MediaUploadService,
        private formBuilder: FormBuilder,
        private notifyService: NotifyService,
        private imgCropperDialogService: ImgCropperDialogService,
        private projectsService: ProjectsService,
        public languageService: LanguageService
    ) {
        super()
    }

    onFilesAdded() {
        const files: FileList = this.file.nativeElement.files
        const numberOfFiles = files.length
        this.multipleFilesSelected = numberOfFiles > 1
        if (!this.canSelectMultipleFiles && this.multipleFilesSelected) {
            this.notifyService.fail('Only 1 file can be uploaded at a time.')
            return
        }
        const selectedFiles = []
        for (let i = 0; i < numberOfFiles; i++) {
            selectedFiles.push(files.item(i))
        }
        this._filesSelected(selectedFiles)
    }

    ngOnInit() {
        if (this.isEdit && this.mediaToEdit) {
            this._setupformControl()
            this.selectedLabels = this.mediaToEdit.labels
            if (this.mediaToEdit.mediaType === MediaType.Image) {
                this.mediaToUploadPlaceholder = this.mediaToEdit.filePath
            }
        }
        if (!this.setting) {
            this.setting = new FileSelectSettings()
        }
        this.projectsService.getAll().subscribe((projects) => {
            this.projects = projects
        })
        this.languageService.supportedLanguages.forEach((language) => {
            this.languages.push(language)
        })
    }

    selectFile() {
        this.file.nativeElement.click()
    }

    reset() {
        this.filesToUpload = []
        this.savedMediaDtos = []
        this.mediaToUploadPlaceholder = undefined
        this.fileSelected.emit({ selected: false })
    }

    get LabelGroupType() {
        return LabelGroupType
    }

    get filesToUploadNames(): string {
        if (!this.filesToUpload || this.filesToUpload.length === 0) {
            return ''
        }
        return this.filesToUpload.map((f) => f.name).join(', ')
    }

    onSubmitClick(): Observable<MediaDto[]> {
        const subject = new Subject<MediaDto[]>()

        if (this.formGroup.valid) {
            if (this.isEdit) {
                this._editMediaEntity(subject)
            } else {
                for (let i = 0; i < this.filesToUpload.length; i++) {
                    const file = this.filesToUpload[i]
                    this.mediaUploadService.uploadFile(file, this.setting.isPrivate).subscribe(
                        (result) => {
                            if (!result) {
                                subject.next(null)
                                return
                            } else if (result.isComplete) {
                                if (result.uploadedUrl === null) {
                                    this._fileUploadFailed(subject)
                                    return
                                } else if (this._isFileImage(file)) {
                                    this._createImageMediaEntity(file, result.uploadedUrl, subject)
                                } else {
                                    this._createDocumentMediaEntity(file, result.uploadedUrl, subject)
                                }
                            } else {
                                console.log(result.progressPercent)
                            }
                        },
                        (error) => {
                            this._fileUploadFailed(subject)
                        }
                    )
                }
            }
        } else {
            this.validateAllFormFields(this.formGroup)
            setTimeout(() => {
                subject.next(null)
            }, 100)
        }

        return subject.asObservable()
    }

    private _fileUploadFailed(subject: Subject<MediaDto[]>) {
        this.notifyService.fail('File upload failed.')
        subject.next(null)
    }

    private _editMediaEntity(subject: Subject<MediaDto[]>) {
        this.mediaToEdit.projectId = this.formGroup.get('projectId').value
        this.mediaToEdit.uploadedFileName = this.formGroup.get('filename').value
        this.mediaToEdit.internalDescription = this.formGroup.get('internalDescription').value
        this.mediaToEdit.culture = this.formGroup.get('culture').value
        this.mediaToEdit.labels = this.selectedLabels
        this.mediaToEdit.hasCaptions = this.formGroup.get('hasCaptions').value
        this.mediaToEdit.translations[0].caption = this.formGroup.get('translations').value[0].caption
        this.mediaToEdit.translations[1].caption = this.formGroup.get('translations').value[1].caption

        if (this.mediaToEdit.mediaType === MediaType.Document) {
            const command = new EditDocumentCommand()
            command.mediatDto = this.mediaToEdit
            this.documentsService.edit(command).subscribe(
                (_) => {
                    subject.next(Array(this.mediaToEdit))
                },
                (error) => {
                    this.notifyService.fail('Editing document failed')
                    subject.next(null)
                }
            )
        } else {
            const command = new EditImageCommand()
            command.mediatDto = this.mediaToEdit
            this.imagesService.edit(command).subscribe(
                (_) => {
                    subject.next(Array(this.mediaToEdit))
                },
                (error) => {
                    this.notifyService.fail('Editing image failed')
                    subject.next(null)
                }
            )
        }
    }

    private _createImageMediaEntity(file: File, uploadedUrl: string, subject: Subject<MediaDto | MediaDto[]>) {
        // = null
        const command = UploadImageCommand.fromJS({
            uploadedUrl: uploadedUrl,
            filename: file.name,
            fileType: file.type,
            fileSize: file.size,
            projectId: this.formGroup.get('projectId').value,
            culture: this.formGroup.get('culture').value,
            labels: this.selectedLabels,
            isPrivate: this.setting.isPrivate,
        })

        if (!this.multipleFilesSelected) {
            command.filename = this.formGroup.get('filename').value
            command.internalDescription = this.formGroup.get('internalDescription').value
            command.hasCaptions = this.formGroup.get('hasCaptions').value
            command.mediaCaptions = this.formGroup.get('translations').value.map((t) => MediaCaption.fromJS(t))
        }

        this.imagesService.create(command).subscribe(
            (media) => {
                this.savedMediaDtos.push(this._convertToMediaViewModel(media, MediaType.Image))
                subject.next(this.savedMediaDtos)
            },
            (error) => {
                this.notifyService.fail('File failed to uploaded')
                subject.next(null)
            }
        )
    }

    private _createDocumentMediaEntity(file: File, uploadedUrl: string, subject: Subject<MediaDto | MediaDto[]>) {
        //  = null
        const command = UploadDocumentCommand.fromJS({
            uploadedUrl: uploadedUrl,
            filename: file.name,
            fileType: file.type,
            fileSize: file.size,
            projectId: this.formGroup.get('projectId').value,
            culture: this.formGroup.get('culture').value,
            labels: this.selectedLabels,
            isPrivate: this.setting.isPrivate,
        })

        if (!this.multipleFilesSelected) {
            command.filename = this.formGroup.get('filename').value
            command.internalDescription = this.formGroup.get('internalDescription').value
            command.hasCaptions = this.formGroup.get('hasCaptions').value
            command.mediaCaptions = this.formGroup.get('translations').value.map((t) => MediaCaption.fromJS(t))
        }

        this.documentsService.create(command).subscribe(
            (media) => {
                this.savedMediaDtos.push(this._convertToMediaViewModel(media, MediaType.Document))

                subject.next(this.savedMediaDtos)
            },
            (error) => {
                this.notifyService.fail('File failed to uploaded')
                subject.next(null)
            }
        )
    }

    dropped(files: NgxFileDropEntry[]) {
        const numberOfFiles = files.length
        this.multipleFilesSelected = numberOfFiles > 1
        if (!this.canSelectMultipleFiles && this.multipleFilesSelected) {
            this.notifyService.fail('Can only upload one file at a time.')
            return
        }
        if (files.length === 1 && !files[0].fileEntry.isFile) {
            const fileEntry = files[0].fileEntry as FileSystemDirectoryEntry
            this.notifyService.fail(`${fileEntry.name} is not a file`)
        }

        const fileSystemFiles = files.filter((f) => f.fileEntry.isFile).map((f) => f.fileEntry as FileSystemFileEntry)
        const droppedFilesReady = new Subject()
        const droppedFiles = []
        droppedFilesReady.subscribe((_) => this._filesSelected(droppedFiles))

        fileSystemFiles.forEach((file, i) => {
            file.file((f) => {
                droppedFiles.push(f)
                if (i === fileSystemFiles.length - 1) {
                    // calling next after last file is loaded
                    droppedFilesReady.next()
                }
            })
        })
    }

    fileOver(event) {
        console.log(event)
    }

    fileLeave(event) {
        console.log(event)
    }

    private _convertToMediaViewModel(media: any, mediaType: MediaType): MediaDto {
        const mediaViewModel = MediaDto.fromJS(media)
        mediaViewModel.mediaType = mediaType
        return mediaViewModel
    }

    private _isAllowedFileType(file: File): boolean {
        if (this._isFileImage(file)) {
            if (this.setting.pickImage) {
                return true
            } else {
                this.notifyService.fail('Image upload is not allowed')
                return false
            }
        } else if (this._isFileValidType(file)) {
            if (this.setting.pickDocument) {
                return true
            } else {
                this.notifyService.fail('Document upload is not allowed')
                return false
            }
        }
    }

    private _filesSelected(files: File[]) {
        if (!files || files.length === 0) {
            this.reset()
            return
        }

        const notAllowed = files.filter((f) => !this._isAllowedFileType(f))
        if (notAllowed.length > 0) {
            return false
        }

        this._setupformControl()
        this.filesToUpload = files

        if (files.length === 1) {
            const file = files[0]
            let filename = file.name
            if (filename.includes('.')) {
                filename = filename.substring(0, filename.lastIndexOf('.'))
            }
            this.formGroup.get('filename').setValue(filename)

            if (this._isFileImage(file)) {
                const reader = new FileReader()

                reader.readAsDataURL(file) // read file as data url

                reader.onload = (event: any) => {
                    // called once readAsDataURL is completed
                    this.image = new Image()

                    this.image.onload = () => {
                        if (this.setting.requiredImageDimensions) {
                            if (this._isImageCorrectDimensions(this.image)) {
                                return
                            }
                            if (this._isImageCroppable(this.image)) {
                                this.cropImage(file)
                            } else {
                                this.notifyService.fail(
                                    `Image size must be ${this.setting.requiredWidth}x${this.setting.requiredHeight} or larger. 
                  If you would like to use this image, please pad it with white space or upload a higher quality image.`,
                                    null,
                                    2500
                                )
                                this.reset()
                            }
                        }
                    }
                    this.mediaToUploadPlaceholder = event.target.result
                    this.image.src = event.target.result
                }
            }
        }

        this.fileSelected.emit({ selected: true })
    }

    cropImage(file: File): any {
        const img = new Image()
        const _this = this

        img.onload = function () {
            const settings = new ImgCropperDialogSettings()
            settings.imageToCrop = file
            settings.imageFormat = file.type === 'image/jpeg' ? 'jpeg' : 'png'
            settings.height = _this.setting.requiredHeight ? _this.setting.requiredHeight : img.height
            settings.width = _this.setting.requiredWidth ? _this.setting.requiredWidth : img.width
            settings.maintainAspectRatio = _this.setting.requiredImageDimensions

            _this.imgCropperDialogService.showDialog(settings).subscribe((croppedImaged) => {
                _this._filesSelected(Array(croppedImaged))
            })
        }
        img.src = URL.createObjectURL(file)
    }

    private _isImageCorrectDimensions(image: any): boolean {
        return this.setting.requiredHeight === image.height && this.setting.requiredWidth === image.width
    }

    private _isImageCroppable(image: any): boolean {
        return this.setting.requiredHeight <= image.height && this.setting.requiredWidth <= image.width
    }

    private _isFileImage(file: File): boolean {
        if (!file.type) {
            let extension = ''
            if (file.name.includes('.')) {
                extension = file.name.substring(file.name.lastIndexOf('.') + 1)
            }
            return this._validImageExtensions.findIndex((ext) => ext === extension) >= 0
        }
        return this._validImageTypes.findIndex((validImageType) => validImageType === file.type) >= 0
    }

    private _isFileValidType(file: File): boolean {
        if (!file.type) {
            let extension = ''
            if (file.name.includes('.')) {
                extension = file.name.substring(file.name.lastIndexOf('.') + 1)
            }
            return this._validDocExtensions.findIndex((ext) => ext === extension) >= 0
        }
        return this._validDocTypes.findIndex((validDocType) => validDocType === file.type) >= 0
    }

    private _createTranslationForm(index) {
        const validators = this.mediaToEdit.hasCaptions ? [Validators.required] : []
        return this.formBuilder.group({
            culture: this.mediaToEdit.translations[index].culture,
            caption: [
                { value: this.mediaToEdit.translations[index].caption, disabled: !this.mediaToEdit.hasCaptions },
                validators,
            ],
            id: this.mediaToEdit.translations[index].id,
        })
    }

    private _handleHasCaptionsValueChange() {
        this.formGroup.get('hasCaptions').valueChanges.subscribe((hasCaptions) => {
            const translations = this.formGroup.get('translations') as FormArray
            if (hasCaptions) {
                translations.controls.forEach((translation) => {
                    const captionControl = translation.get('caption')
                    captionControl.setValidators([Validators.required])
                    captionControl.enable()
                })
            } else {
                translations.controls.forEach((translation) => {
                    const captionControl = translation.get('caption')
                    captionControl.setValidators([])
                    captionControl.setValue(null)
                    captionControl.disable()
                })
            }
            translations.updateValueAndValidity()
        })
    }

    private _setupformControl() {
        if (this.isEdit) {
            if (!this.mediaToEdit.translations || this.mediaToEdit.translations.length === 0) {
                this.languageService.initTranslations(this.mediaToEdit.translations, () => new MediaCaption())
            }

            this.formGroup = this.formBuilder.group({
                id: [this.mediaToEdit.id],
                filename: [this.mediaToEdit.uploadedFileName, [Validators.required]],
                internalDescription: [this.mediaToEdit.internalDescription],
                projectId: [this.mediaToEdit.projectId],
                hasCaptions: [this.mediaToEdit.hasCaptions],
                translations: this.formBuilder.array([this._createTranslationForm(0), this._createTranslationForm(1)]),
                culture: [this.mediaToEdit.culture],
            })

            this._handleHasCaptionsValueChange()
        } else {
            this.formGroup = this.formBuilder.group({
                id: [this.id],
                projectId: [''],
                culture: [''],
            })

            if (!this.multipleFilesSelected) {
                this.formGroup.addControl('filename', new FormControl('', [Validators.required]))
                this.formGroup.addControl('internalDescription', new FormControl())
                this.formGroup.addControl('culture', new FormControl())
                this.formGroup.addControl('hasCaptions', new FormControl(false))
                this.formGroup.addControl(
                    'translations',
                    this.formBuilder.array([
                        this.formBuilder.group({
                            id: [],
                            culture: this.languageService.supportedLanguages[0].culture,
                            caption: [{ value: null, disabled: true }],
                        }),
                        this.formBuilder.group({
                            id: [],
                            culture: this.languageService.supportedLanguages[1].culture,
                            caption: [{ value: null, disabled: true }],
                        }),
                    ])
                )
                this._handleHasCaptionsValueChange()
            }
        }
    }
}
