import { PropertySubTaskDto, PropertyTaskDto, SubTaskInput, TaskInput } from '@app/services/api.services'
import { ItemContainer } from '../util/itemContainer'

export class TaskModel {
    private _allocatedHours: number
    id: string
    name!: string
    description: string

    billOnAllocatedHoursExceeded: boolean
    hasTimeSubTasks?: boolean
    totalTimesheetTasksHours: number
    numberOfTimesheetTasks: number

    totalTimesheetTasksBillableAmount: number
    totalTimesheetTasksCostAmount: number
    totalTimesheetTasksBillableHours: number

    subTasks: ItemContainer<TaskModel>[] = []
    isSubTask: boolean

    get billOnAllocatedHoursExceededFormatted(): string {
        if (this.hasTimeSubTasks) {
            const anySubTasksWithBillOnAllocatedHoursExceeded =
                this.subTasks.filter((t) => t.item.billOnAllocatedHoursExceeded).length > 0
            const anySubTasksWithNOBillOnAllocatedHoursExceeded =
                this.subTasks.filter((t) => !t.item.billOnAllocatedHoursExceeded).length > 0

            return anySubTasksWithBillOnAllocatedHoursExceeded && anySubTasksWithNOBillOnAllocatedHoursExceeded
                ? 'Yes/No'
                : anySubTasksWithBillOnAllocatedHoursExceeded
                ? 'Yes'
                : 'No'
        }

        return this.billOnAllocatedHoursExceeded ? 'Yes' : 'No'
    }

    get allocatedHours(): number {
        if (this.hasTimeSubTasks) {
            return this.subTasks.reduce((x, task) => x + task.item.allocatedHours, 0)
        }
        return this._allocatedHours
    }

    set allocatedHours(value: number) {
        this._allocatedHours = value
    }

    static createFromDto(propertyTaskDto: PropertyTaskDto): TaskModel {
        const taskModel = new TaskModel()
        taskModel.id = propertyTaskDto.id
        taskModel.name = propertyTaskDto.name
        taskModel.description = propertyTaskDto.description
        taskModel.allocatedHours = propertyTaskDto.allocatedHours
        taskModel.billOnAllocatedHoursExceeded = propertyTaskDto.billOnAllocatedHoursExceeded
        taskModel.hasTimeSubTasks = propertyTaskDto.hasTimeSubTasks
        taskModel.totalTimesheetTasksHours = propertyTaskDto.totalTimesheetTasksHours
        taskModel.totalTimesheetTasksBillableHours = propertyTaskDto.totalTimesheetTasksBillableHours
        taskModel.numberOfTimesheetTasks = propertyTaskDto.numberOfTimesheetTasks

        taskModel.totalTimesheetTasksBillableAmount = propertyTaskDto.totalTimesheetTasksBillableAmount
        taskModel.totalTimesheetTasksCostAmount = propertyTaskDto.totalTimesheetTasksCostAmount

        if (taskModel.hasTimeSubTasks && propertyTaskDto.timeSubTasks) {
            taskModel.subTasks = propertyTaskDto.timeSubTasks?.map((t) =>
                ItemContainer.createAlreadyAdded(TaskModel.createFromSubTaskDto(t))
            )
        }

        return taskModel
    }

    static createFromSubTaskDto(propertySubTaskDto: PropertySubTaskDto): TaskModel {
        const taskModel = new TaskModel()
        taskModel.id = propertySubTaskDto.id
        taskModel.name = propertySubTaskDto.name
        taskModel.description = propertySubTaskDto.description
        taskModel.allocatedHours = propertySubTaskDto.allocatedHours
        taskModel.billOnAllocatedHoursExceeded = propertySubTaskDto.billOnAllocatedHoursExceeded
        taskModel.totalTimesheetTasksHours = propertySubTaskDto.totalTimesheetTasksHours
        taskModel.numberOfTimesheetTasks = propertySubTaskDto.numberOfTimesheetTasks
        taskModel.totalTimesheetTasksBillableHours = propertySubTaskDto.totalTimesheetTasksBillableHours

        taskModel.totalTimesheetTasksBillableAmount = propertySubTaskDto.totalTimesheetTasksBillableAmount
        taskModel.totalTimesheetTasksCostAmount = propertySubTaskDto.totalTimesheetTasksCostAmount

        return taskModel
    }

    createTaskInput(): TaskInput {
        const input = new TaskInput()
        input.id = this.id
        input.name = this.name
        input.allocatedHours = this.allocatedHours
        input.billOnAllocatedHoursExceeded = this.billOnAllocatedHoursExceeded
        return input
    }

    createSubTaskInput(): SubTaskInput {
        const propertySubTask = new TaskInput()
        propertySubTask.id = this.id
        propertySubTask.name = this.name
        propertySubTask.description = this.description
        propertySubTask.allocatedHours = this.allocatedHours
        propertySubTask.billOnAllocatedHoursExceeded = this.billOnAllocatedHoursExceeded

        return propertySubTask
    }

    createDto(): TaskInput {
        const propertyTask = new TaskInput()

        propertyTask.id = this.id
        propertyTask.name = this.name
        propertyTask.description = this.description
        propertyTask.allocatedHours = this.allocatedHours
        propertyTask.billOnAllocatedHoursExceeded = this.billOnAllocatedHoursExceeded
        propertyTask.hasTimeSubTasks = this.hasTimeSubTasks

        propertyTask.addedSubTasks = this.subTasks.filter((t) => t.isAdded).map((t) => t.item.createSubTaskInput())
        propertyTask.editedSubTasks = this.subTasks.filter((t) => t.isEdited).map((t) => t.item.createSubTaskInput())
        propertyTask.removedSubTasksIds = this.subTasks.filter((t) => t.isDeleted).map((t) => t.item.id)

        return propertyTask
    }
}
