<ng-container [ngSwitch]="selections.selectionsDisplayType">
    <div class="d-flex flex-wrap" *ngSwitchCase="selectionsDisplayType.DisplaySelectionAsSwatches">
        <div class="d-flex flex-column w-100" *ngFor="let group of selections.groupedSelectionItems">
            <ng-container *ngIf="group.selectionItems && group.selectionItems.length > 0">
                <div *ngIf="group.label" class="my-2">
                    {{ group.label.selectedTranslation.name }}
                </div>
                <div class="d-flex">
                    <ng-container *ngFor="let selectionItem of group.selectionItems">
                        <app-client-selection-notch
                            id="selectionItem-{{ selectionItem.id }}"
                            [selectionItem]="selectionItem"
                            [selectionGroup]="selections"
                            [parentSelectionGroup]="parentSelectionGroup"
                        ></app-client-selection-notch>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>

    <!-- <ng-container *ngSwitchCase="selectionsDisplayType.DisplaySelectionsAsImageAndText">
        <div class="d-flex flex-column w-100" *ngFor="let group of selections.groupedSelectionItems">
            <ng-container *ngIf="group.selectionItems && group.selectionItems.length > 0">
                <div *ngIf="group.label" class="my-2">
                    {{ group.label.selectedTranslation.name }}
                </div>
                <div class="row">
                    <div *ngFor="let selectionItem of group.selectionItems" class="col-md-6">
                        <app-client-selection-item-card
                            id="selectionItem-{{ selectionItem.id }}"
                            [selectionItem]="selectionItem"
                            [selectionGroup]="selections"
                            [parentSelectionGroup]="parentSelectionGroup"
                        ></app-client-selection-item-card>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->

    <!-- <ng-container *ngSwitchCase="selectionsDisplayType.DisplaySelectionsInList">
        <div class="d-flex flex-column w-100" *ngFor="let group of selections.groupedSelectionItems">
            <ng-container *ngIf="group.selectionItems && group.selectionItems.length > 0">
                <div *ngIf="group.label" class="my-2">
                    {{ group.label.selectedTranslation.name }}
                </div>
                <div class="row">
                    <div *ngFor="let selectionItem of group.selectionItems; let last = last" class="col-12">
                        <app-client-selection-list-item
                            id="selectionItem-{{ selectionItem.id }}"
                            [selectionItem]="selectionItem"
                            [selectionGroup]="selections"
                            [parentSelectionGroup]="parentSelectionGroup"
                            [isLast]="last"
                        ></app-client-selection-list-item>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container> -->
</ng-container>
