<h2 mat-dialog-title>{{ dialogTitle }}</h2>
<mat-dialog-content [class.loading]="isLoading">
    <div class="row">
        <div class="col-12">
            <mat-spinner class="mt-5 mb-5 mx-auto" *ngIf="isLoading"></mat-spinner>
        </div>
    </div>
    <form [formGroup]="formGroup" *ngIf="!isLoading">
        <div class="row" *ngIf="settings">
            <div class="col-md-4 col-12">
                <mat-form-field>
                    <input matInput placeholder="Receivers Name" disabled="true" value="{{ settings.personName }}" />
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field>
                    <mat-select
                        placeholder="Language"
                        formControlName="languageType"
                        (selectionChange)="fillForm($event.value)"
                    >
                        <mat-option *ngFor="let language of enumListService.languages" [value]="language.value">
                            {{ language.display }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6" *ngIf="displayPackageDropdown">
                <mat-form-field>
                    <mat-select
                        placeholder="Project"
                        formControlName="projectId"
                        name="item"
                        (selectionChange)="onProjectChange($event.value)"
                    >
                        <mat-option *ngFor="let project of projects$ | async" [value]="project.id">
                            {{ project.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field>
                    <input matInput placeholder="{{ 'SUBJECT' | translate }}" formControlName="subject" />
                    <mat-error *ngIf="isFieldValidOnFormControl(formGroup, 'subject')">
                        {{ 'VALIDATION.GENERIC.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="isProjectSelectedAndRequired; else projectRequired">
                <mat-form-field>
                    <textarea
                        matInput
                        cdkTextareaAutosize
                        cdkAutosizeMinRows="8"
                        placeholder="Email message to client"
                        formControlName="description"
                        cdkAutosizeMaxRows="10"
                    ></textarea>
                </mat-form-field>
            </div>

            <ng-template #projectRequired>
                <div class="col-12">
                    <p class="d-flex align-items-center">
                        <mat-icon color="warn" class="mr-2">info</mat-icon> Select a project to auto-generate a message
                    </p>
                </div>
            </ng-template>
        </div>
        <div class="row" *ngIf="attachments && attachments.length > 0">
            <div class="col-12">
                <button
                    *ngFor="let attachment of attachments"
                    class="btn btn-primary btn-sm"
                    type="button"
                    (click)="removeAttachment(attachment)"
                >
                    {{ attachment.uploadedFileName }}
                    <i class="ml-2 material-icons">cancel</i>
                </button>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between">
    <div>
        <button mat-button [mat-dialog-close]>{{ 'CANCEL' | translate }}</button>
    </div>
    <div>
        <button mat-button (click)="addAttachment()" [disabled]="isSubmitting">Add Attachment</button>
        <button
            mat-stroked-button
            cdkFocusInitial
            [disabled]="!isProjectSelectedAndRequired"
            [loading]="isSubmitting"
            (click)="onSubmitClick()"
        >
            Send
        </button>
    </div>
</mat-dialog-actions>
