import { Component, OnInit, Inject, ViewChild, AfterViewInit, ElementRef } from '@angular/core'
import { CommonComponent } from '@app/models/formComponent'
import {
    ProjectsService,
    ProjectDto,
    MediaType,
    BuilderClientsService,
    BuilderClientDto,
    NotesService,
    NoteType,
    EditNoteCommand,
    CreateNoteCommand,
    NoteDto,
    NoteCommand,
} from '@app/services/api.services'
import { NotifyService } from '@app/services/notify.service'
import { FormBuilder, Validators, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { EnumListService } from '@app/services/enum-list.service'
import { DialogResult, DialogResultActions } from '@app/models/dialogResult'
import { NoteFormDialogSettings } from '@app/models/dialogs/noteFormDialogSettings'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { map, mergeMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { OwlDateTimeComponent } from '@danielmoncada/angular-datetime-picker'
import { Router } from '@angular/router'
import * as moment from 'moment'

import { UploadMediaFormSettings } from '@app/models/dialogs/uploadMediaFormSettings'
import { UploadMediaFormDialogService } from '@app/shared/dialog'

export abstract class BaseNoteFormDialog extends CommonComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public settings: NoteFormDialogSettings,
        protected dialogRef: MatDialogRef<NoteFormDialogComponent>,
        public enumListService: EnumListService,
        protected router: Router
    ) {
        super()
    }

    viewAttachedEntity() {
        if (this.settings.attachedEntity.builderClientId) {
            this.router.navigate(['/clients/edit/', this.settings.attachedEntity.builderClientId])
        } else {
            this.router.navigate(['/brokers/edit/', this.settings.attachedEntity.brokerId])
        }

        this.dialogRef.close()
    }

    onNoClick(): void {
        this.dialogRef.close()
    }
}

@Component({
    selector: 'app-note-form-dialog',
    templateUrl: './note-form-dialog.component.html',
    styleUrls: ['./note-form-dialog.component.scss'],
})
export class NoteFormDialogComponent extends BaseNoteFormDialog implements OnInit, AfterViewInit {
    formGroup: FormGroup
    model: NoteDto
    projects: ProjectDto[] = []
    isSubmitting = false

    @ViewChild('createdDatePicker') createdDatePicker: OwlDateTimeComponent<Date>
    @ViewChild('followUpDatePicker') followUpDatePicker: OwlDateTimeComponent<Date>
    @ViewChild('appointmentDatePicker') appointmentDatePicker: OwlDateTimeComponent<Date>
    @ViewChild('callOccuredDatePicker') callOccuredDatePicker: OwlDateTimeComponent<Date>

    constructor(
        @Inject(MAT_DIALOG_DATA)
        settings: NoteFormDialogSettings,
        dialogRef: MatDialogRef<NoteFormDialogComponent>,
        enumListService: EnumListService,
        router: Router,
        private notesService: NotesService,
        private notifyService: NotifyService,
        private confirmDialogService: ConfirmDialogService,
        private formBuilder: FormBuilder,
        private projectsService: ProjectsService,
        private uploadMediaFormDialogService: UploadMediaFormDialogService,
        private builderClientService: BuilderClientsService
    ) {
        super(settings, dialogRef, enumListService, router)
    }

    ngOnInit() {
        this.projectsService.getAll().subscribe((projects) => {
            this.projects = projects
        })
        this._setupformControl()
    }

    onDeleteClick() {
        this.confirmDialogService
            .showDialog('Are you sure you want to delete this?', 'Delete')
            .pipe(
                mergeMap((isConfirmed) => {
                    if (isConfirmed) {
                        return this.notesService.deleteNote(this.model.id).pipe(map((result) => true))
                    } else {
                        return of(false)
                    }
                })
            )
            .subscribe(
                (isDeleted) => {
                    if (!isDeleted) {
                        return
                    }
                    return this.dialogRef.close(new DialogResult(DialogResultActions.Delete, this.model))
                },
                (error) => {
                    this.notifyService.fail('Failed to delete note', error)
                }
            )
    }

    ngAfterViewInit(): void {
        if (!this.model.id) {
            return
        }
        setTimeout(() => {
            let dateToSet: Date
            this.createdDatePicker.select(this.model.createdDateTime.toDate())
            this.createdDatePicker.confirmSelect()
            switch (this.model.noteType) {
                case NoteType.Appointment:
                    dateToSet = this.model.appointmentDateTime.toDate()
                    if (dateToSet) {
                        this.appointmentDatePicker.select(dateToSet)
                        this.appointmentDatePicker.confirmSelect(dateToSet)
                    }
                    break
                case NoteType.FollowUp:
                    dateToSet = this.model.followUpDateTime.toDate()
                    if (dateToSet) {
                        this.followUpDatePicker.select(dateToSet)
                        this.followUpDatePicker.confirmSelect()
                    }
                    break
                case NoteType.Call:
                    dateToSet = this.model.callOccuredDateTime.toDate()
                    if (dateToSet) {
                        this.callOccuredDatePicker.select(dateToSet)
                        this.callOccuredDatePicker.confirmSelect()
                    }
                    break
                default:
                    break
            }
        })
    }

    onSubmitClick(): void {
        if (this.formGroup.valid) {
            this.isSubmitting = true
            if (this.settings.isEdit) {
                const command = new EditNoteCommand()
                command.id = this.model.id
                this._populateCommand(command, this.model.noteType)
                this.notesService
                    .edit(command)
                    .pipe(
                        mergeMap(() => {
                            return this.notesService.get(this.model.id)
                        })
                    )
                    .subscribe((entity) => {
                        this.isSubmitting = false
                        this.dialogRef.close(new DialogResult(DialogResultActions.Edit, entity))
                    })
            } else {
                const command = new CreateNoteCommand()
                command.noteType = this.formGroup.get('noteType').value
                this._populateCommand(command, command.noteType)
                this.notesService
                    .create(command)
                    .pipe(
                        mergeMap((id) => {
                            return this.notesService.get(id)
                        })
                    )
                    .subscribe((entity) => {
                        this.isSubmitting = false
                        this.dialogRef.close(new DialogResult(DialogResultActions.Create, entity))
                    })
            }
        } else {
            this.validateAllFormFields(this.formGroup)
        }
    }

    addAttachment() {
        const settings = new UploadMediaFormSettings()
        settings.isEdit = false
        settings.isPrivate = true

        this.uploadMediaFormDialogService.showDialog(settings).subscribe((result) => {
            if (!result.entity || result.entity.length === 0) {
                return
            }
            const media = result.entity[0]

            if (media.mediaType === MediaType.Document) {
                this.model.attachedImage = null
                this.model.attachedDocument = media
            } else {
                this.model.attachedDocument = null
                this.model.attachedImage = media
            }
        })
    }

    private _populateCommand(noteCommand: NoteCommand, noteType: NoteType) {
        noteCommand.description = this.formGroup.get('description')?.value as string
        noteCommand.subject = this.formGroup.get('subject')?.value as string
        noteCommand.createdDateTime = this.formGroup.get('createdDateTime')?.value
        
        noteCommand.projectId = this.formGroup.get('projectId')?.value

  
        if (this.model.attachedDocument) {
            noteCommand.attachedDocument = this.model.attachedDocument
        }

        if (this.model.attachedImage) {
            noteCommand.attachedImage = this.model.attachedImage
        }

        switch (noteType) {
            case NoteType.Appointment:
                noteCommand.dateTimeOfNoteUtc = this.formGroup.get('appointmentDateTime').value
                break
            case NoteType.Call:
                noteCommand.dateTimeOfNoteUtc = this.formGroup.get('callOccuredDateTime').value
                break
            case NoteType.FollowUp:
                noteCommand.dateTimeOfNoteUtc = this.formGroup.get('followUpDateTime').value
                break
            default:
                break
        }

        if (!this.settings.allowClientLeadIdSelection) {
            //this.model.id = this.settings.builderClientId
        }

        debugger;
        noteCommand.brokerId = this.settings.attachedEntity.brokerId
        noteCommand.builderClientId = this.settings.attachedEntity.builderClientId
    }

    private _setupformControl() {
        if (!this.settings.isEdit) {
            this.model = new NoteDto()
            this.model.noteType = NoteType.Comment
            this.model.createdDateTime = moment()
        } else {
            this.model = this.settings.model
        }

        this.formGroup = this.formBuilder.group({
            id: [this.model.id],
            description: [this.model.description],
            
            projectId: [this.model.projectId],
            createdDateTime: [this.model.createdDateTime.toDate(), Validators.required],
            noteType: [
                this.settings.allowNoteTypeSelection
                    ? this.model.noteType
                    : {
                          value: this.settings.isEdit ? this.model.noteType : this.settings.requiredNoteTypeSelection,
                          disabled: true,
                      },
                [Validators.required],
            ],
        })

        if (this.settings.allowClientLeadIdSelection) {
            // this.formGroup.addControl(
            //     'clientLeadId',
            //     this.formBuilder.control([this.model.clientLeadId, [Validators.required]])
            // )
        }

        this.formGroup.get('noteType').valueChanges.subscribe((noteType) => {
            this.model.noteType = noteType
            this._addSpecificFormControlsForType()
        })

        this._addSpecificFormControlsForType()

        if (!this.settings.isEdit && this.settings.attachedEntity.builderClientId) {
            this.builderClientService
                .clientLead(this.settings.attachedEntity.builderClientId)
                .subscribe((clientLead: BuilderClientDto) => {
                    if (clientLead.projectId) {
                        this.formGroup.get('projectId').setValue(clientLead.projectId)
                    }
                })
        }
    }

    get NoteType() {
        return NoteType
    }

    private _addSpecificFormControlsForType() {
        const type = this.formGroup.get('noteType').value

        if (type !== NoteType.Comment) {
            this.formGroup.addControl('subject', this.formBuilder.control(this.model.subject, [Validators.required]))
        } else {
            this.formGroup.removeControl('subject')
        }
        this.formGroup.removeControl('followUpDateTime')
        this.formGroup.removeControl('appointmentDateTime')
        this.formGroup.removeControl('callOccuredDateTime')

        switch (type) {
            case NoteType.Appointment:
                if (!this.model.appointmentDateTime) {
                    this.model.appointmentDateTime = moment()
                }
                this.formGroup.addControl(
                    'appointmentDateTime',
                    this.formBuilder.control([this.model.appointmentDateTime.toDate(), [Validators.required]])
                )
                break
            case NoteType.Call:
                if (!this.model.callOccuredDateTime) {
                    this.model.callOccuredDateTime = moment()
                }
                this.formGroup.addControl(
                    'callOccuredDateTime',
                    this.formBuilder.control([this.model.callOccuredDateTime.toDate(), [Validators.required]])
                )
                break
            case NoteType.FollowUp:
                if (!this.model.followUpDateTime) {
                    this.model.followUpDateTime = moment()
                }
                this.formGroup.addControl(
                    'followUpDateTime',
                    this.formBuilder.control([this.model.followUpDateTime.toDate(), [Validators.required]])
                )
                break
            case NoteType.Comment:
            case NoteType.DocumentUpload:
            case NoteType.Other:
                break
        }
    }
}
