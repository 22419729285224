<h2 mat-dialog-title>Enter Quantity</h2>
<mat-dialog-content>
    <mat-form-field>
        <input placeholder="Quantity" matInput [(ngModel)]="quantity" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button color="warn" [mat-dialog-close]="null">Cancel</button>
        <button mat-stroked-button [mat-dialog-close]="quantity">Done</button>
    </div>
</mat-dialog-actions>
