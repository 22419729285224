import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ClientPortalLayoutComponent } from './client-portal-layout.component'
import { RouterModule } from '@angular/router'
import { MaterialModule } from '@app/material.module'
import { FixedFormControlsModule } from '@app/shared/fixed-form-controls/fixed-form-controls.module'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    imports: [CommonModule, RouterModule, MaterialModule, FixedFormControlsModule, TranslateModule],
    declarations: [ClientPortalLayoutComponent],
    exports: [ClientPortalLayoutComponent],
})
export class ClientPortalModule {}
