import { Injectable } from '@angular/core'
import {
    ServiceRatesService,
    ServiceRatePricesService,
    ServiceDto,
    ServiceLookupDto,
    GetServicesQuery,
    ServiceSupplierPriceVersionDto,
} from '@app/services/api.services'
import { SearchDialogService } from '../../shared/search-dialog/search-dialog.service'
import { EMPTY, Observable } from 'rxjs'
import {
    LocalSearchTableContainer,
    ServerSearchTableContainer,
} from '../../shared/search-dialog/models/searchTableContainer'
import { DisplayColumn } from '../../shared/search-dialog/models/displayColumn'
import { map, mergeMap } from 'rxjs/operators'
import { NotifyService } from '@app/services/notify.service'
import { ServiceRateDataSource } from '@app/admin-pages/service-rates/service-rate-list/service-rate-list.datasource'

@Injectable({
    providedIn: 'root',
})
export class ServiceRatePickerService {
    constructor(
        private serviceRatesService: ServiceRatesService,
        private serviceRatePricesService: ServiceRatePricesService,
        private searchDialogService: SearchDialogService,
        private notifyService: NotifyService
    ) {}

    pickServiceRate(): Observable<ServiceDto> {
        const query = new GetServicesQuery()
        const setting = new ServerSearchTableContainer<ServiceLookupDto, GetServicesQuery>(
            'Services',
            query,
            new ServiceRateDataSource(this.serviceRatesService),
            true,
            [new DisplayColumn('Internal Name', 'internalName')],
            []
        )

        return this.searchDialogService
            .showServerDialog(setting)
            .pipe(
                mergeMap(
                    (service) =>
                        service && service.isPicked && this.serviceRatesService.getService(service.selectedEntity.id)
                )
            )
    }

    pickServiceAndPrice(
        constrainedToProjectId: number = null,
        excludeServiceRateIds: string[] = null
    ): Observable<{ servicePrice: ServiceSupplierPriceVersionDto; service: ServiceDto }> {
        return this.pickServiceRate().pipe(
            mergeMap((service: ServiceDto) => {
                if (
                    service.numberOfActiveSupplierPrices === 0 &&
                    constrainedToProjectId !== null &&
                    service.numberOfActiveSupplierProjectPrices === 0
                ) {
                    return this._showNoPrices()
                }

                return this.pickServicePrice(service.id, constrainedToProjectId).pipe(
                    map((servicePrice) => {
                        return { servicePrice: servicePrice, service: service }
                    })
                )
            })
        )
    }

    pickServicePrice(serviceRateId: string, projectId: number = null): Observable<ServiceSupplierPriceVersionDto> {
        const searchTableContainer = new LocalSearchTableContainer(
            this.serviceRatePricesService.getActivePrices(serviceRateId, true, projectId),
            'Product Prices',
            [
                new DisplayColumn('Supplier', 'supplierName'),
                new DisplayColumn('Unit Cost', 'unitCostAmount', false, true),
                new DisplayColumn('Unit Markup', 'unitMarkupAmount', false, true),
                new DisplayColumn('Total', 'unitAmount', false, true),
            ],
            'This service has no price versions. Try a different service.'
        )

        return this.searchDialogService.showLocalDialog<ServiceSupplierPriceVersionDto>(searchTableContainer)
    }

    private _showNoPrices() {
        this.notifyService.fail(`This service has no prices`)
        return EMPTY
    }
}
