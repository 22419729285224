import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UpdateTakeOffForSelectionGroupSelectorDialogComponent } from './update-take-off-for-selection-group-selector-dialog.component'
import { FormsModule } from '@angular/forms'
import { MaterialModule } from '@app/material.module'

@NgModule({
    declarations: [UpdateTakeOffForSelectionGroupSelectorDialogComponent],
    imports: [FormsModule, CommonModule, MaterialModule],
    entryComponents: [UpdateTakeOffForSelectionGroupSelectorDialogComponent],
})
export class UpdateTakeOffForSelectionGroupSelectorDialogModule {}
