import { UnitPriceDto } from '@app/services/api.services'

export class UnitPriceModel {
    private _isUnitMarkupPercent: boolean
    private _unitCostAmount = 0
    private _unitMarkupAmount = 0
    private _unitMarkupPercent = 0
    private _hasRetailAmount: boolean
    private _retailAmount = 0
    private _retailDiscountPercent = 0

    static createFromDto(unitPriceDto: UnitPriceDto): UnitPriceModel {
        const unitPrice = new UnitPriceModel(
            unitPriceDto.unitCostAmount,
            unitPriceDto.isUnitMarkupPercent ? unitPriceDto.unitMarkupPercent : unitPriceDto.unitMarkupAmount,
            unitPriceDto.isUnitMarkupPercent
        )

        unitPrice.hasRetailAmount = unitPriceDto.hasRetailAmount
        unitPrice.retailAmount = unitPriceDto.retailAmount
        unitPrice.retailDiscountPercent = unitPriceDto.retailDiscountPercent
        return unitPrice
    }

    constructor(unitCostAmount: number, unitMarkup: number, isUnitMarkupPercent: boolean) {
        this.isUnitMarkupPercent = isUnitMarkupPercent
        this.unitCostAmount = unitCostAmount

        if (isUnitMarkupPercent) {
            this.unitMarkupPercent = unitMarkup
        } else {
            this.unitMarkupAmount = unitMarkup
        }
    }

    get hasRetailAmount(): boolean {
        return this._hasRetailAmount
    }

    set hasRetailAmount(value: boolean) {
        this._hasRetailAmount = value
        this.retailAmount = 0
        this.retailDiscountPercent = 0
        if (value) {
            this.recalculateUnitCostAmount()
        }
    }

    get retailAmount(): number {
        return this._retailAmount
    }

    set retailAmount(value: number) {
        this._retailAmount = value
        if (this.hasRetailAmount) {
            this.recalculateUnitCostAmount()
        }
    }

    get retailDiscountPercent(): number {
        return this._retailDiscountPercent
    }

    set retailDiscountPercent(value: number) {
        this._retailDiscountPercent = value
        if (this.hasRetailAmount) {
            this.recalculateUnitCostAmount()
        }
    }

    recalculateUnitCostAmount() {
        this.unitCostAmount = this.retailAmount * (1 - this.retailDiscountPercent / 100)
    }

    get unitAmount(): number {
        if (this._isUnitMarkupPercent) {
            return this.unitCostAmount + (this.unitCostAmount * this.unitMarkupPercent) / 100
        }
        return this.unitCostAmount + this.unitMarkupAmount
    }

    set unitAmount(value: number) {
        const markup = value - this._unitCostAmount
        if (markup < 0) {
            return
        }
        this._unitMarkupAmount = markup
        this._unitMarkupPercent = (markup / this._unitCostAmount) * 100
    }

    get unitCostAmount(): number {
        return this._unitCostAmount
    }

    set unitCostAmount(value: number) {
        this._unitCostAmount = value
        if (value === 0) {
            return
        }
        if (this._isUnitMarkupPercent) {
            this._unitMarkupAmount = (this.unitCostAmount * this.unitMarkupPercent) / 100
        } else {
            this._unitMarkupPercent = (this._unitMarkupAmount / this.unitCostAmount) * 100
        }
    }

    get unitMarkupAmount(): number {
        return this._unitMarkupAmount
    }

    set unitMarkupAmount(value: number) {
        if (this._isUnitMarkupPercent) {
            return
        }
        this._unitMarkupAmount = value
        if (this.unitCostAmount === 0) {
            this._unitMarkupPercent = 0
            return
        }
        this._unitMarkupPercent = (this._unitMarkupAmount / this.unitCostAmount) * 100
    }

    get unitMarkupPercent(): number {
        return this._unitMarkupPercent
    }

    set unitMarkupPercent(value: number) {
        if (!this._isUnitMarkupPercent) {
            return
        }
        this._unitMarkupPercent = value
        this._unitMarkupAmount = (this.unitCostAmount * this.unitMarkupPercent) / 100
    }

    get isUnitMarkupPercent(): boolean {
        return this._isUnitMarkupPercent
    }

    set isUnitMarkupPercent(value: boolean) {
        this._isUnitMarkupPercent = value
    }

    createDto(): UnitPriceDto {
        const unitPriceDto = new UnitPriceDto()
        unitPriceDto.unitCostAmount = this.unitCostAmount
        unitPriceDto.isUnitMarkupPercent = this.isUnitMarkupPercent
        unitPriceDto.unitMarkupAmount = this.unitMarkupAmount
        unitPriceDto.unitMarkupPercent = this.unitMarkupPercent
        unitPriceDto.hasRetailAmount = this.hasRetailAmount
        unitPriceDto.retailAmount = this.retailAmount
        unitPriceDto.retailDiscountPercent = this.retailDiscountPercent
        return unitPriceDto
    }
}
