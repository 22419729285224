import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormDialogComponent } from './form-dialog.component'
import { MaterialModule } from '@app/material.module'
import { ContractTemplatesModule } from '@app/admin-pages/contract-templates/contract-templates.module'

@NgModule({
    imports: [CommonModule, MaterialModule, ContractTemplatesModule],
    declarations: [FormDialogComponent],
    exports: [FormDialogComponent],
    entryComponents: [FormDialogComponent],
})
export class FormDialogModule {}
