import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmDialogComponent, ConfirmDialogResult } from './confirm-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(message, title = 'Confirm', yesButtonText = 'Yes', noButtonText = 'No'): Observable<boolean> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
        })
        dialogRef.componentInstance.title = title
        dialogRef.componentInstance.message = message
        dialogRef.componentInstance.yesButtonText = yesButtonText
        dialogRef.componentInstance.noButtonText = noButtonText

        return dialogRef.afterClosed()
    }

    showDialogWithLoader(
        message: string,
        observableLoaded: Observable<any>,
        title = 'Confirm',
        yesButtonText = 'Yes',
        noButtonText = 'No'
    ): Observable<ConfirmDialogResult> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '400px',
        })
        dialogRef.componentInstance.title = title
        dialogRef.componentInstance.message = message
        dialogRef.componentInstance.yesButtonText = yesButtonText
        dialogRef.componentInstance.noButtonText = noButtonText
        dialogRef.componentInstance.displayLoadingUntilLoaded = true
        dialogRef.componentInstance.observableLoad = observableLoaded

        return dialogRef.afterClosed()
    }
}
