import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { SelectionGroupModel } from '@app/models/template/SelectionGroupModel'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSelectionList } from '@angular/material/list'

export class SelectionGroupOption {
    selected: boolean
    selectionGroup: SelectionGroupModel

    constructor(selected: boolean, selectionGroup: SelectionGroupModel) {
        this.selected = selected
        this.selectionGroup = selectionGroup
    }
}

@Component({
    selector: 'app-update-take-off-for-selection-group-selector-dialog',
    templateUrl: './update-take-off-for-selection-group-selector-dialog.component.html',
    styleUrls: ['./update-take-off-for-selection-group-selector-dialog.component.scss'],
})
export class UpdateTakeOffForSelectionGroupSelectorDialogComponent implements OnInit {
    selectionGroupOptions: SelectionGroupOption[]
    @ViewChild('selectionList') selectionList: MatSelectionList

    constructor(
        public dialogRef: MatDialogRef<UpdateTakeOffForSelectionGroupSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            selectionGroups: SelectionGroupModel[]
        }
    ) {
        this.selectionGroupOptions = data.selectionGroups.map((sGroup) => new SelectionGroupOption(false, sGroup))
    }

    ngOnInit() {}

    finishSelection() {
        // return all selected values
        this.dialogRef.close(this.selectionList.selectedOptions.selected.map((selectedOption) => selectedOption.value))
    }
}
