import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AppSettings } from '@app/app.settings'
import { ProductSupplierPriceVersionModel } from '@app/models/Products/ProductSupplierPriceVersionModel'
import { ProductCurrencyPriceModel } from '@app/models/Products/ProductVersionPriceModel'
import {
    CreateProductSupplierPriceVersionCommand,
    CurrenciesService,
    CurrencyDto,
    CurrencyPriceInput,
    ProductPricesService,
    SupplierPricesDto,
    ProjectDto,
    SupplierPriceDto,
    SupplierProjectPriceDto,
    UnitType,
} from '@app/services/api.services'
import { NotifyService } from '@app/services/notify.service'
import { CostLineType } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { SelectionConfig } from '@app/shared/selection-dialog/selection-dialog.component'
import { SelectionDialogService } from '@app/shared/selection-dialog/selection-dialog.service'
import { mergeMap } from 'rxjs/operators'
import { SupplierPickerService, ProjectPickerService } from '@app/services/entity-selections'
import { EnumListService } from '@app/services/enum-list.service'

@Component({
    selector: 'app-product-supplier-price-form-dialog',
    templateUrl: './product-supplier-price-form-dialog.component.html',
    styleUrls: ['./product-supplier-price-form-dialog.component.scss'],
})
export class ProductSupplierPriceFormDialogComponent implements OnInit {
    private _selectedProject: SupplierProjectPriceDto = undefined
    private _selectedSupplier: SupplierPricesDto
    private _selectedPriceVersion: SupplierPriceDto
    private _loadedPrices: ProductSupplierPriceVersionModel[] = []
    private _latestVersionToRevertTo: SupplierPriceDto
    private _isForProjectOnly: boolean
    private _currencies: CurrencyDto[]

    costLineType = CostLineType
    projects: ProjectDto[]

    get selectedProject(): SupplierProjectPriceDto {
        return this._selectedProject
    }

    set selectedProject(value: SupplierProjectPriceDto) {
        this._selectedProject = value
        this.supplierPriceVersions = value.prices
        this.selectedPriceVersion = value.prices.find((s) => s.isLatestVersion)
    }

    get selectedSupplier(): SupplierPricesDto {
        return this._selectedSupplier
    }

    set selectedSupplier(value: SupplierPricesDto) {
        this._selectedSupplier = value
        this.supplierPriceVersions = value.prices
        this.selectedPriceVersion = value.prices.find((s) => s.isLatestVersion)
    }

    get selectedPriceVersion(): SupplierPriceDto {
        return this._selectedPriceVersion
    }

    set selectedPriceVersion(value: SupplierPriceDto) {
        this._selectedPriceVersion = value
        this._loadProductPrice()
    }

    set isForProjectOnly(value: boolean) {
        this._isForProjectOnly = value
        if (this.selectedSupplier.projectPrices.length > 0)
            this.selectedProject = this.selectedSupplier.projectPrices[0]
    }

    get isForProjectOnly(): boolean {
        return this._isForProjectOnly
    }

    get selectedPriceHasProjectPrices(): boolean {
        return (
            this.selectedSupplier &&
            this.selectedSupplier.projectPrices &&
            this.selectedSupplier.projectPrices.length > 0
        )
    }

    supplierPrices: SupplierPricesDto[] = []
    selectedLoadedPriceVersion: ProductSupplierPriceVersionModel
    supplierPriceVersions: SupplierPriceDto[] = []
    isLoading = true
    isSavingNewlyCreatingPrice = false
    isCreatingNewPrice = false

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            createNewPrice: boolean
            productId: string
            selectedProjectId: number | null
            selectedSupplier: { id: string; name: string }
            selectedPriceId?: string | null
        },
        private notifyService: NotifyService,
        private productPricesService: ProductPricesService,
        private supplierPickerService: SupplierPickerService,
        private projectPickerService: ProjectPickerService,
        private selectionDialogService: SelectionDialogService,
        private currenciesService: CurrenciesService,
        public enumListService: EnumListService
    ) {
        //  this.selectedPrice = data.productSupplierPriceVersionModel

        this.productPricesService.getSupplierPricesForProduct(data.productId).subscribe((supplierPrices) => {
            this.supplierPrices = supplierPrices
            this.isLoading = false
            if (this.data.createNewPrice) {
            } else {
                this._selectPrice(this.data.selectedSupplier.id, this.data.selectedProjectId, this.data.selectedPriceId)
            }
        })

        this.currenciesService.currencies().subscribe((currencies) => {
            this._currencies = currencies
        })
    }

    ngOnInit(): void {
        if (this.data.createNewPrice) {
            this.addSupplier()
        }
    }

    clear() {
        let prices: SupplierPriceDto[]
        if (this.selectedProject) {
            prices = this.selectedProject.prices
        } else {
            prices = this.selectedSupplier.prices
        }

        prices.splice(
            prices.findIndex((s) => s.isLatestVersion),
            1
        )

        this._latestVersionToRevertTo.isLatestVersion = true
        this.selectedPriceVersion = this._latestVersionToRevertTo
        this._latestVersionToRevertTo = undefined
        this.isCreatingNewPrice = false
    }

    create() {
        const command = new CreateProductSupplierPriceVersionCommand()

        this.isSavingNewlyCreatingPrice = true

        command.productId = this.selectedLoadedPriceVersion.productId
        command.supplierId = this.selectedSupplier.supplierId
        command.caseQuantity = this.selectedLoadedPriceVersion.caseQuantity
        command.unitType = this.selectedLoadedPriceVersion.unitType

        command.projectId = this.selectedProject?.projectId

        command.currencyPrices = this.selectedLoadedPriceVersion.currencyPrices.map((currencyPrice) => {
            const currencyPriceInput = new CurrencyPriceInput()

            currencyPriceInput.currencyCode = currencyPrice.currencyCode
            currencyPriceInput.useCostLinePricing = currencyPrice.useCostLinePricing

            if (currencyPriceInput.useCostLinePricing) {
                currencyPriceInput.productLinkCostLines =
                    currencyPrice.priceLinkCostLines?.map((c) => c.createInput()) ?? []
                currencyPriceInput.costLines = currencyPrice.costLines.map((c) => c.createInput()) ?? []
            }

            currencyPriceInput.unitCostAmount = currencyPrice.unitCostAmount
            currencyPriceInput.unitMarkupAmount = currencyPrice.unitMarkupAmount
            currencyPriceInput.unitMarkupPercent = currencyPrice.unitMarkupPercent

            currencyPriceInput.hasRetailAmount = currencyPrice.hasRetailAmount
            currencyPriceInput.retailAmount = currencyPrice.retailAmount
            currencyPriceInput.retailDiscountPercent = currencyPrice.retailDiscountPercent

            return currencyPriceInput
        })

        this.productPricesService
            .create(this.selectedLoadedPriceVersion.productId, command)
            .pipe(
                mergeMap((priceId) => {
                    return this.productPricesService.get(command.productId, priceId)
                })
            )
            .subscribe(
                (price) => {
                    this.isSavingNewlyCreatingPrice = false
                    this.isCreatingNewPrice = false
                    this.selectedPriceVersion.id = price.id
                    this.selectedLoadedPriceVersion = ProductSupplierPriceVersionModel.createFromDto(price)
                    this.notifyService.success('Successfully created price')
                },
                (err) => {
                    console.log(err)
                    this.isSavingNewlyCreatingPrice = false
                    this.notifyService.fail('Failed to create price')
                }
            )
    }

    getCostPerCase(currencyPrice: ProductCurrencyPriceModel) {
        return this.selectedLoadedPriceVersion.caseQuantity * currencyPrice.unitAmount
    }

    addNewVersion() {
        this._addNewPrice(
            {
                id: this.selectedSupplier.supplierId,
                name: this.selectedSupplier.supplierName,
            },
            this.selectedProject
                ? {
                      id: this.selectedProject.projectId,
                      name: this.selectedProject.projectName,
                  }
                : undefined
        )
    }

    addSupplier() {
        this.supplierPickerService.pickSupplier().subscribe(
            (supplier) => {
                this._addNewPrice({
                    id: supplier.id,
                    name: supplier.name,
                })
            },
            (error) => {
                this.notifyService.fail('Failed to add supplier', error)
            }
        )
    }

    addProject() {
        this.projectPickerService.pickProject().subscribe(
            (project) => {
                if (this.isCreatingNewPrice) {
                    // todo adjust version no
                    this.selectedLoadedPriceVersion.projectId = project.id
                    this.selectedLoadedPriceVersion.projectName = project.name

                    return
                }

                this._addNewPrice(
                    {
                        id: this.data.selectedSupplier.id,
                        name: this.data.selectedSupplier.name,
                    },
                    {
                        id: project.id,
                        name: project.name,
                    }
                )
            },
            (error) => {
                this.notifyService.fail('Failed to add supplier', error)
            }
        )
    }

    addNewCurrencyPrice() {
        const currenciesToDisplay = this.getCurrenciesToDisplay()
        const selectionConfig = new SelectionConfig(currenciesToDisplay, 'code', currenciesToDisplay[0], 'Currencies')
        this.selectionDialogService.showDialog<CurrencyDto>(selectionConfig).subscribe((selectedCurrency) => {
            if (selectedCurrency) {
                this.selectedLoadedPriceVersion.addNewPriceCurrency(selectedCurrency.code)
                // removing the added price currency from list of currencies to display
                // this.selectedLoadedPriceVersion = this.currenciesToDisplay.filter((currency) => {
                //     return currency.code !== selectedCurrency.code
                // })
            }
        })
    }

    getCurrenciesToDisplay() {
        return this._currencies.filter((currency) => {
            if (!this.selectedLoadedPriceVersion) {
                return []
            }
            return (
                this.selectedLoadedPriceVersion.currencyPrices.findIndex((price) => {
                    return price.currencyCode === currency.code
                }) === -1
            )
        })
    }

    private _addNewPrice(
        supplier: { id: string; name: string },
        project: { id: number; name: string } | undefined = undefined
    ) {
        let supplierPrice = this.supplierPrices.find((s) => s.supplierId === supplier.id)

        this.isCreatingNewPrice = true

        // Supplier price doesn't exist
        if (supplierPrice && supplierPrice.supplierId !== this.selectedSupplier?.supplierId) {
            this.selectedSupplier = this.supplierPrices.find((s) => s.supplierId === supplierPrice.supplierId)
        } else if (!supplierPrice) {
            supplierPrice = this._createNewSupplierPrice(supplierPrice, supplier)
            this.supplierPrices.push(supplierPrice)
            this.selectedSupplier = supplierPrice
            this.supplierPriceVersions = []
        }

        let projectPrice: SupplierProjectPriceDto = null
        if (project) {
            let projectPrice = supplierPrice.projectPrices.find((p) => p.projectId === project.id)

            if (!projectPrice) {
                projectPrice = this._createNewSupplierProjectPrice(projectPrice, project)
                supplierPrice.projectPrices.push(projectPrice)
                this.supplierPriceVersions = []
            }
            this.selectedProject = projectPrice
            this._isForProjectOnly = true
        }

        this._latestVersionToRevertTo = this.supplierPriceVersions.find((s) => s.isLatestVersion)

        const newPrice = new ProductSupplierPriceVersionModel(
            null,
            this._latestVersionToRevertTo ? this._latestVersionToRevertTo.versionNo + 1 : 1,
            true,
            UnitType.Each,
            null
        )

        newPrice.addNewPriceCurrency(AppSettings.DEFAULT_CURRENCY)
        newPrice.supplierId = supplier.id
        newPrice.productId = this.data.productId
        newPrice.projectId = projectPrice?.projectId

        const supplierPriceDto = new SupplierPriceDto()
        supplierPriceDto.isLatestVersion = true
        supplierPriceDto.versionNo = newPrice.versionNo

        if (project) {
            this.selectedProject.prices.push(supplierPriceDto)
            this.supplierPriceVersions = this.selectedProject.prices
        } else {
            this.selectedSupplier.prices.push(supplierPriceDto)
            this.supplierPriceVersions = this.selectedSupplier.prices
        }

        this.selectedPriceVersion = supplierPriceDto

        if (this._latestVersionToRevertTo) this._latestVersionToRevertTo.isLatestVersion = false
        this.selectedLoadedPriceVersion = newPrice
    }

    private _createNewSupplierProjectPrice(
        projectPrice: SupplierProjectPriceDto,
        project: { id: number; name: string }
    ) {
        projectPrice = new SupplierProjectPriceDto()
        projectPrice.projectId = project.id
        projectPrice.projectName = project.name
        projectPrice.prices = []
        return projectPrice
    }

    private _createNewSupplierPrice(supplierPrice: SupplierPricesDto, supplier: { id: string; name?: string | null }) {
        supplierPrice = new SupplierPricesDto()
        supplierPrice.supplierId = supplier.id
        supplierPrice.supplierName = supplier.name
        supplierPrice.prices = []
        supplierPrice.projectPrices = []
        return supplierPrice
    }

    private _selectPrice(supplierId: string, projectId: number | null, priceId: string | null) {
        this.selectedSupplier = this.supplierPrices.find((s) => s.supplierId === supplierId)

        if (projectId) {
            this.selectedProject = this.selectedSupplier.projectPrices.find((p) => p.projectId === projectId)
            this.selectedPriceVersion = this.selectedProject.prices.find((p) => p.id === priceId)
            this.supplierPriceVersions = this.selectedProject.prices
        } else {
            this.selectedPriceVersion = this.selectedSupplier.prices.find((p) => p.id === priceId)
            this.supplierPriceVersions = this.selectedSupplier.prices
        }

        this._loadProductPrice()
    }

    private _loadProductPrice() {
        if (this.isCreatingNewPrice) {
            // ignore because we're creating a new price so no need to load it
            return
        }

        const price = this._loadedPrices.find((p) => p.id === this.selectedPriceVersion.id)

        if (price) {
            this.selectedLoadedPriceVersion = price
            return
        }

        this.isLoading = true
        this.selectedLoadedPriceVersion = undefined

        this.productPricesService.get(this.data.productId, this.selectedPriceVersion.id).subscribe((loadedPrice) => {
            this.selectedLoadedPriceVersion = ProductSupplierPriceVersionModel.createFromDto(loadedPrice)
            this._loadedPrices.push(this.selectedLoadedPriceVersion)
            this.isLoading = false
        })
    }
}
