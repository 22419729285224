import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { BaseFormDialogService } from 'app/shared/dialog/form-dialog/BaseFormDialogService'
import { Observable } from 'rxjs'
import { TaskFormDialogComponent } from './task-form-dialog.component'
import { TaskModel } from '@app/models/tasks/taskModel'

@Injectable({
    providedIn: 'root',
})
export class TaskFormDialogService extends BaseFormDialogService {
    constructor(dialog: MatDialog) {
        super(dialog)
    }

    showTaskFormDialog(
        settings: { allowSubTasks: boolean; disableSubTasksEdit: boolean },
        task: TaskModel = null
    ): Observable<TaskModel> {
        const dialogRef = this.dialog.open(TaskFormDialogComponent, {
            width: '800px',
            data: { task: task, settings: settings },
        })

        return dialogRef.afterClosed()
    }
}
