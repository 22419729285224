import { NgModule } from '@angular/core'
import { CustomPropertiesFormModule } from '@app/shared/custom-properties-form/custom-properties-form.module'
import { CommonFormsModule } from '../../../../common-forms/common-forms.module'
import { MultiProductPropertyValueSelectionDialogComponent } from './multi-product-property-value-selection-dialog.component'

@NgModule({
    declarations: [MultiProductPropertyValueSelectionDialogComponent],
    imports: [CommonFormsModule, CustomPropertiesFormModule],
    entryComponents: [MultiProductPropertyValueSelectionDialogComponent],
})
export class MultiProductPropertyValueSelectionDialogModule {}
