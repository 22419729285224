import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { DialogResult } from '@app/models/dialogResult'
import { Observable } from 'rxjs'
import { UploadFileDialogComponent, UploadFileDialogSettings } from './upload-file-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class UploadFileDialogService {
    constructor(private dialog: MatDialog) {}

    showDialog(settings: UploadFileDialogSettings): Observable<boolean> {
        const dialogRef = this.dialog.open(UploadFileDialogComponent, {
            width: '1600px',
            data: settings,
        })

        return dialogRef.afterClosed()
    }
}
