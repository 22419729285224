import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FixedFormControlsComponent } from './fixed-form-controls.component'
import { NgxPermissionsModule } from 'ngx-permissions'
import { PipesModule } from '../pipes/pipes.module'
import { MaterialModule } from '@app/material.module'

@NgModule({
    imports: [CommonModule, NgxPermissionsModule, PipesModule, MaterialModule],
    declarations: [FixedFormControlsComponent],
    exports: [FixedFormControlsComponent],
})
export class FixedFormControlsModule {}
