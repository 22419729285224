<ng-container [formGroup]="formGroup">
    <ng-container *ngIf="isLoading; else costLinesTable">
        <mat-spinner class="my-5 mx-auto"></mat-spinner>
    </ng-container>
    <ng-template #costLinesTable>
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="index">
                <th class="text-center" mat-header-cell *matHeaderCellDef>#</th>
                <td class="text-center" mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                <td class="text-center" mat-footer-cell *matFooterCellDef>Totals</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef style="min-width: 100px">Description</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="!element.isDescriptionEditable()">
                        {{ element.getName() }}
                    </ng-container>
                    <ng-container style="margin-right: 30px" *ngIf="element.isDescriptionEditable()">
                        <app-multi-language
                            [placeholder]="'Description'"
                            [controlName]="'description'"
                            [translations]="formGroup.get('translations')"
                        ></app-multi-language>
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="th-number">Quantity</th>
                <td mat-cell *matCellDef="let element" style="width: 100px">
                    <ng-container *ngIf="element.useTakeOff; else quantityInput">
                        {{ element.formattedName }}
                    </ng-container>
                    <ng-template #quantityInput>
                        <ng-container *ngIf="element.isEditing">
                            <app-quantity [group]="formGroup" [controlName]="'quantity'"></app-quantity>
                        </ng-container>
                        <ng-container style="margin-right: 30px" *ngIf="!element.isEditing">
                            {{ element.quantity }}
                        </ng-container>
                    </ng-template>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="unitMarkupAmount">
                <th mat-header-cell *matHeaderCellDef class="th-number">Unit Markup</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container style="margin-right: 30px" *ngIf="element.isUnitMarkupEditable()">
                        <app-currency [group]="formGroup" [controlName]="'unitMarkupAmount'"></app-currency>
                    </ng-container>
                    <ng-container *ngIf="!element.isUnitMarkupEditable()">
                        {{ element.unitMarkupAmount | currency }}
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="unitCostAmount">
                <th mat-header-cell *matHeaderCellDef class="th-number">Unit Cost</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container style="margin-right: 30px" *ngIf="element.isUnitCostEditable()">
                        <app-currency [group]="formGroup" [controlName]="'unitCostAmount'"></app-currency>
                    </ng-container>
                    <ng-container *ngIf="!element.isUnitCostEditable()">
                        {{ element.unitCostAmount | currency }}
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="unitType">
                <th mat-header-cell *matHeaderCellDef class="th-number">Unit Type</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container style="margin-right: 30px" *ngIf="element.isUnitTypeEditable()">
                        <mat-form-field>
                            <mat-select placeholder="Unit Type" formControlName="unitType">
                                <mat-option *ngFor="let unitType of enumListService.unitType" [value]="unitType.value">
                                    {{ unitType.display }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="!element.isUnitTypeEditable()">
                        {{ element.unitType }}
                    </ng-container>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef class="th-number">Total</th>
                <td mat-cell *matCellDef="let element">{{ element.totalPrice | currency }}</td>
                <td mat-footer-cell *matFooterCellDef>{{ calculateTotal() | currency }}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
                <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                    <button *ngIf="!row.isEditing && !isEditingRow" (click)="editCostLine(row, i)" mat-icon-button>
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button (click)="removeCostLine(row)" mat-icon-button color="warn">
                        <mat-icon>delete</mat-icon>
                    </button>

                    <button *ngIf="row.isEditing" (click)="doneEdit(row)" mat-icon-button color="success">
                        <mat-icon>done</mat-icon>
                    </button>

                    <button mat-icon-button (click)="moveCostLineUp(i)">
                        <mat-icon>arrow_upward</mat-icon>
                    </button>

                    <button mat-icon-button (click)="moveCostLineDown(i)">
                        <mat-icon>arrow_downward</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef class="action-column">
                    <button mat-icon-button (click)="addCostLineClick.emit($event)" color="primary">
                        <mat-icon>add</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr>
                <td>Product Totals</td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                [class.mat-row-selected]="i === selectedRowIndex"
                mat-row
                [className]="row.getClass()"
                *matRowDef="let row; columns: displayedColumns; let i = index"
                (click)="selectRow(i)"
            ></tr>
            <ng-container *ngIf="costLines.length > 1 || isEditable">
                <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
            </ng-container>

            <!-- <tr mat-footer-row *matFooterRowDef="['totalProductPrice']"></tr> -->
        </table>
    </ng-template>
</ng-container>
