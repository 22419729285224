import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { MyErrorStateMatcher } from '@app/models/myErrorStateMatcher'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { CommonComponent } from '@app/models/formComponent'

@Component({
    selector: 'app-percent',
    templateUrl: './percent.component.html',
    styleUrls: ['./percent.component.scss'],
})
export class PercentComponent extends CommonComponent implements OnInit {
    numberMask = createNumberMask({
        prefix: '% ',
        allowDecimal: true,
        includeThousandsSeparator: false,
        decimalLimit: 2,
    })

    @Input() placeholder: string
    @Input() disabled = false
    @Input() decimalLimit: number = 2

    @Input() inputModel: any
    @Output() inputModelChange = new EventEmitter<any>()

    matcher = new MyErrorStateMatcher()

    constructor() {
        super()
    }

    ngOnInit() {
        this.numberMask.decimalLimit = this.decimalLimit
    }

    stripAndEmitChange(value) {
        this.inputModelChange.emit(this.stripPercentString(value))
    }
}
