import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import {
    TemplateRoomsService,
    TemplateRoomTranslation,
    TemplateRoomDto,
    ImageMediaDto,
    EditTemplateRoomCommand,
    CreateTemplateRoomCommand,
} from '@app/services/api.services'
import { ActivatedRoute } from '@angular/router'
import { FormComponentSettings, DialogCapableFormComponentId } from '@app/models/formComponent'
import { Observable } from 'rxjs'
import { ImgCropperDialogService } from '@app/shared/dialog/img-cropper-dialog/img-cropper-dialog.service'
import { FormContainerService } from '@app/services/form-container.service'

@Component({
    selector: 'app-template-room-form',
    templateUrl: './template-room-form.component.html',
    styleUrls: ['./template-room-form.component.scss'],
})
export class TemplateRoomFormComponent
    extends DialogCapableFormComponentId<string, TemplateRoomDto>
    implements OnInit, OnDestroy {
    isEdited = false

    constructor(
        private apiService: TemplateRoomsService,
        public imgCropperDialogService: ImgCropperDialogService,
        private route: ActivatedRoute,
        formContainerService: FormContainerService
    ) {
        super(new FormComponentSettings(true, 'template-rooms', 'Section Room', true), formContainerService)
    }

    editEntity(): Observable<void> {
        this._generateFromForm()
        const command = new EditTemplateRoomCommand()
        command.templateRoom = this.model

        return this.apiService.edit(command)
    }

    createEntity(): Observable<string> {
        this._generateFromForm()
        const command = new CreateTemplateRoomCommand()
        command.templateRoom = this.model
        return this.apiService.create(command)
    }

    ngOnInit() {
        this.model = this.route.snapshot.data['room']
        if (this.model) {
            this.id = this.model.id
        }
        this._setupformControl()
    }

    canDeactivateBase(): boolean {
        return !this.formControl.dirty && !this.isEdited
    }

    getImageUrl(): string {
        if (this.model.templateRoomImage && this.model.templateRoomImage.filePath) {
            return this.model.templateRoomImage.filePath
        }
        return ''
    }

    getTranslations(): TemplateRoomTranslation[] {
        return this.formControl.get('translations').value
    }

    ngOnDestroy() {
        if (this.fixedFormSub) {
            this.fixedFormSub.unsubscribe()
        }
    }

    private _generateFromForm() {
        const entity = new TemplateRoomDto()
        const templateRoomImage = this.model.templateRoomImage
        entity.init(this.formControl.value)

        this.model = Object.assign(this.model, entity)
        this.model.templateRoomImage = templateRoomImage
    }

    private _setupformControl() {
        if (!this.model) {
            this.model = new TemplateRoomDto()
            this.model.translations = []

            this.languageService.initTranslations(this.model.translations, () => new TemplateRoomTranslation())
        }

        if (!this.model.templateRoomImage) {
            this.model.templateRoomImage = new ImageMediaDto()
        }

        this.formControl = this.formBuilder.group({
            id: [this.model.id],
            internalName: [this.model.internalName, [Validators.required]],
            translations: this.formBuilder.array([this._createTranslationForm(0), this._createTranslationForm(1)]),
        })
    }

    private _createTranslationForm(index) {
        return this.formBuilder.group({
            name: [this.model.translations[index].name, [Validators.required]],
            description: [this.model.translations[index].description, []],
            culture: this.model.translations[index].culture,
            id: this.model.translations[index].id,
        })
    }
}
