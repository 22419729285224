import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { PropertyDefinitionLookupDto } from '@app/services/api.services'
import { Observable } from 'rxjs'
import { CustomPropertiesListDialogComponent } from './custom-properties-list-dialog.component'

@Injectable({
    providedIn: 'root',
})
export class CustomPropertiesListDialogService {
    constructor(private dialogRef: MatDialog) {}

    showDialog(addingPropertyToEntity: string, propertyIdsToExclude: any[]): Observable<PropertyDefinitionLookupDto> {
        const dialogRef = this.dialogRef.open(CustomPropertiesListDialogComponent, {
            width: '800px',
            data: {
                addingPropertyToEntity,
                propertyIdsToExclude,
            },
        })

        return dialogRef.afterClosed()
    }
}
