import { NgModule } from '@angular/core'
import { TimesheetTaskDialogComponent } from './timesheet-task-dialog.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { MatDialogModule } from '@angular/material/dialog'
import { PipesModule } from '@app/shared/pipes/pipes.module'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { TimesheetSharedModule } from '../timesheet-shared/timesheet-shared.module'

@NgModule({
    declarations: [TimesheetTaskDialogComponent],
    imports: [TimesheetSharedModule, CommonFormsModule, MatDialogModule, PipesModule, NgxMaterialTimepickerModule],
    entryComponents: [TimesheetTaskDialogComponent],
})
export class TimesheetTaskDialogModule {}
