<mat-dialog-content>
    <mat-form-field>
        <mat-select [placeholder]="selectionConfig.placeholder" [(ngModel)]="selectionConfig.selectedOption">
            <mat-option *ngFor="let selectionOption of selectionConfig.optionList" [value]="selectionOption">
                {{ selectionOption[selectionConfig.displayProperty] }}
            </mat-option>
        </mat-select>

        <!-- <mat-select [placeholder]="selectionConfig.placeholder" [(ngModel)]="selectionConfig.selectedOption">
                <ng-container *ngFor="let selectionOption of selectionConfig.optionList">
                    <mat-option *ngIf="selectionOption.selectionConfig === null; else selectionConfigTemplate" [value]="selectionOption">
                        {{selectionOption[selectionConfig.displayProperty]}}
                    </mat-option>
                    <ng-template #selectionConfigTemplate>
                        <mat-optgroup [label]="selectionOption[selectionConfig.displayProperty]">
                            <mat-option *ngFor="let nestedSelectionOption of selectionOption.selectionConfig.optionList" [value]="nestedSelectionOption">
                                {{nestedSelectionOption[selectionConfig.displayProperty]}}
                            </mat-option>
                        </mat-optgroup>
                    </ng-template>
                </ng-container>
            </mat-select> -->
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="warn" [mat-dialog-close]>Close</button>
    <button class="pull-right" mat-stroked-button [mat-dialog-close]="selectionConfig.selectedOption">Select</button>
</mat-dialog-actions>
