export class ArrayHelper {
    static moveArrayItemUp(array: any[], index: number) {
        let indexToSwap = index - 1
        if (index === 0) {
            indexToSwap = array.length - 1
        }
        ArrayHelper.swap(array, index, indexToSwap)
    }

    static moveArrayItemDown(array: any[], index: number) {
        let indexToSwap = index + 1
        if (index === array.length - 1) {
            indexToSwap = 0
        }
        ArrayHelper.swap(array, index, indexToSwap)
    }

    static swap(array: any[], firstSectionIndex: number, secondSectionIndex: number) {
        const sectionToSwap = array[firstSectionIndex]
        array[firstSectionIndex] = array[secondSectionIndex]
        array[secondSectionIndex] = sectionToSwap
    }
}
