import { PropertySelectionModel } from '@app/models/template/PropertySelectionModel'
import { SelectionDetailsModel } from './SelectionDetailsModel'
import { SelectionGroupPricingModel as SelectionGroupPricingSettingsModel } from './SelectionGroupPricingModel'
import { BaseTranslatableModel } from './BaseTranslatableModel'
import { AppSettings } from '@app/app.settings'
import {
    SelectionGroupTranslations,
    TemplateType,
    SelectionsDisplayType,
    SelectionItemTranslation,
    SelectionGroupDto,
    SelectionItemDto,
    CostLineTranslation,
    ProductDto,
    NestedProductCurrencyPriceDto,
    NameTranslation,
    SelectionItemCostLine,
    LabelDto,
    SelectionGroupPricing,
    SelectionGroupStatus,
    ServiceSupplierPriceVersionDto,
    ServiceDto,
    LightProductCurrencyPriceDto,
} from '@app/services/api.services'
import { Moment } from 'moment'
import { Guid } from 'guid-typescript'
import { TemplateCategoryModel } from './TemplateCategoryModel'
import { TemplateRoomModel } from './TemplateRoomModel'
import { SelectionItemProductLinkCostLineModel } from '../costLines/selectionItems/SelectionItemProductLinkCostLineModel'
import { SelectionItemCostLineModel } from '../costLines/selectionItems/SelectionItemCostLineModel'
import { SelectionItemServiceCostLineModel } from '../costLines/selectionItems/SelectionItemServiceCostLineModel'
import { CostLineDialogModel } from '@app/shared/cost-line-dialog/cost-line-dialog.component'
import { ArrayHelper } from '@app/shared/helpers/arrayHelper'
import { ImageModel } from '../common/ImageModel'
import { DocumentModel } from '../common/DocumentModel'
import { MatCheckboxChange } from '@angular/material/checkbox'

export class SelectionGroupModel extends BaseTranslatableModel<SelectionGroupTranslations> {
    selectionGroupVersionsId: string
    id?: string | null
    templateCategory?: TemplateCategoryModel | null
    templateRoom?: TemplateRoomModel | null
    selectionItems?: SelectionItemModel[] | null = []
    fromTemplate?: TemplateType | null
    propertySelectionIdsToUpdateToNewVersion: string[]
    propertyTemplateIdsToAddNewSelectionGroup: string[]
    internalName: string
    rebatePriceForSupplyOwnProduct?: number
    index?: number

    isAdded: boolean
    isEdited: boolean
    isVersionChanged: boolean
    isDeleted: boolean
    isLocked: boolean
    isDetached: boolean
    hasChangesBeenMade: boolean
    versionNo: number
    isCategoryOnly: boolean
    isNestedSelectionGroup: boolean
    supportedCurrency: string
    depedantParentSelectionItem: SelectionItemModel
    propertySelection: PropertySelectionModel
    isPropertySelectionVersionChanged: boolean
    isConstrainedToTemplate: boolean
    isSelectionItemsEdited: boolean
    isSelectionGroupEdited = false
    groupedSelectionItems: Array<{ label: LabelDto; selectionItems: SelectionItemModel[] }> = []
    defaultTemplateCategory?: TemplateCategoryModel | null
    defaultTemplateRoom?: TemplateRoomModel | null
    isPublished: boolean

    displayImage: ImageModel

    isConstrainedToProject: boolean
    constrainedToProjectId?: number
    constrainToProjectName: string

    pricing: SelectionGroupPricingSettingsModel

    private _isEditable: boolean
    private _minimumOptionChoice?: number
    private _maximumOptionChoice?: number
    private _allowSupplyOwnProduct?: boolean
    private _allowMaximumSelections: boolean
    private _selectionsDisplayType: SelectionsDisplayType
    private _noSelectionRequired: boolean
    isInclusionGroup: boolean

    static createFromDto(selectionGroupDto: SelectionGroupDto, isDuplicate = false): SelectionGroupModel {
        const selectionGroupModel = new SelectionGroupModel(
            selectionGroupDto.id,
            selectionGroupDto.minimumOptionChoice,
            selectionGroupDto.maximumOptionChoice,
            selectionGroupDto.fromTemplate,
            selectionGroupDto.translations,
            selectionGroupDto.isEditable
        )

        selectionGroupModel.versionNo = selectionGroupDto.versionNo
        selectionGroupModel.supportedCurrency = selectionGroupDto.supportedCurrency
        selectionGroupModel.isConstrainedToTemplate = selectionGroupDto.isConstrainedToTemplate

        selectionGroupModel.constrainedToProjectId = selectionGroupDto.constrainedToProjectId
        selectionGroupModel.displayImage = selectionGroupDto.displayImage
            ? ImageModel.createFromMediaDto(selectionGroupDto.displayImage)
            : ImageModel.placeholderImage

        selectionGroupModel.isNestedSelectionGroup = selectionGroupDto.isNestedSelectionGroup

        selectionGroupModel._allowSupplyOwnProduct = selectionGroupDto.allowSupplyOwnProduct
        selectionGroupModel.rebatePriceForSupplyOwnProduct = selectionGroupDto.rebatePriceForSupplyOwnProduct

        selectionGroupModel.selectedTranslation = selectionGroupDto.selectedTranslation
        selectionGroupModel.internalName = selectionGroupDto.internalName
        selectionGroupModel.isPublished = selectionGroupDto.selectionGroupStatus === SelectionGroupStatus.Published

        selectionGroupModel.isInclusionGroup = selectionGroupDto.isInclusionGroup

        if (selectionGroupDto.constrainedToProjectId) {
            selectionGroupModel.constrainedToProjectId = selectionGroupDto.constrainedToProjectId
            selectionGroupModel.constrainToProjectName = selectionGroupDto.constrainedToProjectName
            selectionGroupModel.isConstrainedToProject = true
        }

        if (selectionGroupDto.templateRoom) {
            selectionGroupModel.templateRoom = TemplateRoomModel.createFromDto(selectionGroupDto.templateRoom)
        }

        if (selectionGroupDto.templateCategory) {
            selectionGroupModel.templateCategory = TemplateCategoryModel.createFromDto(
                selectionGroupDto.templateCategory
            )
        }

        if (selectionGroupDto.defaultTemplateCategory) {
            selectionGroupModel.defaultTemplateCategory = TemplateCategoryModel.createFromDto(
                selectionGroupDto.defaultTemplateCategory
            )
        }
        if (selectionGroupDto.defaultTemplateRoom) {
            selectionGroupModel.defaultTemplateRoom = TemplateRoomModel.createFromDto(
                selectionGroupDto.defaultTemplateRoom
            )
        }

        selectionGroupModel._selectionsDisplayType = selectionGroupDto.selectionsDisplayType

        selectionGroupModel._isEditable = selectionGroupDto.isEditable

        selectionGroupModel.selectionGroupVersionsId = selectionGroupDto.selectionGroupVersionsId

        let index = 0
        selectionGroupModel.selectionItems = selectionGroupDto.selectionItems.map((selectionItem) =>
            SelectionItemModel.createFromDto(selectionItem, index++)
        )
        selectionGroupModel._allowMaximumSelections = selectionGroupDto.allowMaximumSelections

        if (selectionGroupDto.propertySelection) {
            selectionGroupModel.propertySelection = PropertySelectionModel.createFromDto(
                selectionGroupDto.propertySelection
            )
            selectionGroupModel.isLocked = selectionGroupModel.propertySelection.isLocked
            selectionGroupModel.isDetached = selectionGroupModel.propertySelection.isDetached

            selectionGroupModel.selectionItems.forEach((selectionItem) => {
                if (selectionItem.hasNestedSelectionGroups) {
                    selectionItem.nestedSelectionGroups.forEach((selectionGroup) => {
                        selectionGroup.propertySelection = selectionGroupModel.propertySelection
                        selectionGroup.depedantParentSelectionItem = selectionItem
                    })
                }
            })
        }

        if (isDuplicate) {
            selectionGroupModel.id = null
            selectionGroupModel.translations.forEach((translation, i) => {
                translation.id = null
                selectionGroupModel.translations[i] = translation
            })
            selectionGroupModel.generateIdsForEntities(false) // make new versions of selectionItems
        }

        selectionGroupModel.pricing = new SelectionGroupPricingSettingsModel(
            selectionGroupDto.selectionGroupPricing,
            selectionGroupDto.hasCustomMarkup,
            selectionGroupDto.excludeTaxesInClientPricing,
            selectionGroupDto.customMarkupPercent,
            selectionGroupDto.clientPrice,
            selectionGroupModel.calculateIncludedPrice()
        )

        selectionGroupModel.selectionItems.forEach((selectionItem) => {
            selectionItem.pricing = selectionGroupModel.pricing
        })

        return selectionGroupModel
    }

    get isDisabled(): boolean {
        return this.propertySelection && this.propertySelection.isDisabled
    }

    get noSelectionRequired(): boolean {
        return this._noSelectionRequired
    }

    set noSelectionRequired(value: boolean) {
        if (value) {
            this._minimumOptionChoice = 0
        } else {
            this._minimumOptionChoice = 1
        }
        this._noSelectionRequired = value
        this.isSelectionGroupEdited = true
    }

    get minimumOptionChoice(): number {
        return this._minimumOptionChoice
    }

    set minimumOptionChoice(value: number) {
        this._minimumOptionChoice = value
        if (value === 0) {
            this._noSelectionRequired = true
        }
        this.isSelectionGroupEdited = true
    }

    get maximumOptionChoice(): number {
        return this._maximumOptionChoice
    }

    set maximumOptionChoice(value: number) {
        this._maximumOptionChoice = value
        this.isSelectionGroupEdited = true
    }

    get allowMaximumSelections(): boolean {
        return this._allowMaximumSelections
    }

    set allowMaximumSelections(value: boolean) {
        this._allowMaximumSelections = value
        if (value === true) {
            this._maximumOptionChoice =
                this.selectionItems.length > 0 ? this.selectionItems.length : this.minimumOptionChoice
        } else {
            this._maximumOptionChoice = this.minimumOptionChoice
        }
        this.isSelectionGroupEdited = true
    }

    get canEdit(): boolean {
        return (this._isEditable || this.isDetached) && !this.isLocked
    }

    get formattedName(): string {
        if (!this.isPublished) {
            return `${this.name} - v${this.versionNo} Draft`
        }
        if (this.isDetached) {
            return `${this.name} - v${this.versionNo} Detached`
        }
        if (this.isLocked) {
            return `${this.name} - v${this.versionNo} Locked`
        }
        if (this.versionNo) {
            return `${this.name} - v${this.versionNo}`
        }
        return `${this.name}`
    }

    get name(): string {
        return this.selectedTranslation.name
    }

    get mustProvideName(): boolean {
        return this.isCategoryOnly
    }

    get isParentSelectionMade(): boolean {
        return (
            !this.isNestedSelectionGroup ||
            (this.depedantParentSelectionItem && this.depedantParentSelectionItem.isSelected)
        )
    }

    get allowSupplyOwnProduct(): boolean {
        return this._allowSupplyOwnProduct
    }

    set allowSupplyOwnProduct(value: boolean) {
        this._allowSupplyOwnProduct = value
        this.isSelectionGroupEdited = true
    }

    get selectionsDisplayType(): SelectionsDisplayType {
        return this._selectionsDisplayType
    }

    set selectionsDisplayType(value: SelectionsDisplayType) {
        this._selectionsDisplayType = value
        this.isSelectionGroupEdited = true
    }

    constructor(
        id: string,
        minimumOptionChoice: number,
        maximumOptionChoice: number,
        fromTemplate: TemplateType,
        translations: SelectionGroupTranslations[],
        canEdit: boolean
    ) {
        super(translations)
        this.id = id
        this._minimumOptionChoice = minimumOptionChoice
        this._maximumOptionChoice = maximumOptionChoice

        if (this.minimumOptionChoice === 0) {
            this._noSelectionRequired = true
        }
        this.fromTemplate = fromTemplate
        this._isEditable = canEdit

        if (this.translations === undefined || this.translations === null || this.translations.length === 0) {
            this.translations = []
            this.initTranslations(this.translations, () => new SelectionGroupTranslations())
        }

        this.displayImage = ImageModel.placeholderImage

        this.selectionItems = []
        this.pricing = new SelectionGroupPricingSettingsModel(
            SelectionGroupPricing.DifferenceFromDefaultPrice,
            false,
            false,
            0,
            0,
            0
        )
    }

    clone(generateNewId: boolean): SelectionGroupModel {
        const selectionGroupModel = Object.assign(
            new SelectionGroupModel(
                this.id,
                this.minimumOptionChoice,
                this.maximumOptionChoice,
                this.fromTemplate,
                this.translations,
                this.canEdit
            ),
            this
        )

        if (generateNewId) {
            selectionGroupModel.id = Guid.create().toString()
            selectionGroupModel.versionNo = 1
            selectionGroupModel.isAdded = true
            selectionGroupModel.isEdited = false
        }

        selectionGroupModel.selectionItems.map((selectionItem) => selectionItem.clone(generateNewId))
        selectionGroupModel.allowMaximumSelections = this.allowMaximumSelections

        return selectionGroupModel
    }

    public calculateIncludedPrice() {
        const defaultOptions = this.selectionItems
            .filter((option) => option.isDefault)
            .sort((option) => option.totalPrice)
        if (defaultOptions.length > 0) {
            // tood need to handle when there are multiple defaults
            return defaultOptions[0].totalPrice
        }
        return 0
    }

    getTotalPriceForSelections(): number {
        const selectedOptions = this.getAllSelectionItems().filter((option) => option.isSelected)
        return selectedOptions.reduce((sum, option) => {
            return sum + option.totalPrice
        }, 0)
    }

    getTotalPriceForDefaultSelections(): number {
        const defaultOptions = this.getAllSelectionItems().filter((option) => option.isDefault)
        return defaultOptions.reduce((sum, option) => {
            return sum + option.totalPrice
        }, 0)
    }

    getTotalPriceForSelectionsWithTaxes(): number {
        const selectedOptions = this.getAllSelectionItems().filter((option) => option.isSelected)
        return selectedOptions.reduce((sum, option) => {
            return sum + option.totalPriceWithTax
        }, 0)
    }

    getTotalClientPriceForSelections(): number {
        const selectedSelectionItems = this.getAllSelectionItems().filter((option) => option.isSelected)
        return selectedSelectionItems.reduce((sum, option) => {
            return sum + option.totalPrice
        }, 0)
    }

    canLock(): boolean {
        return this.requiredSelectionsLeftInGroup() === 0
    }

    canDetach() {
        return !this.isDetached && !this.isLocked && !this.canEdit
    }

    canReattach() {
        return this.isDetached && !this.isLocked
    }

    groupSelectionItemsByLabel() {
        const map = new Map<LabelDto, SelectionItemModel[]>()
        map.set(null, [])
        this.groupedSelectionItems = []
        this.selectionItems.forEach((selectionItem) => {
            if (!selectionItem.label) {
                map.get(null).push(selectionItem)
            } else {
                let hasLabel = false
                map.forEach((value, key) => {
                    if (key && key.id === selectionItem.label.id) {
                        value.push(selectionItem)
                        hasLabel = true
                    }
                })
                if (!hasLabel) {
                    map.set(selectionItem.label, [selectionItem])
                }
            }
        })

        map.forEach((key, value) => {
            this.groupedSelectionItems.push({ label: value, selectionItems: key })
        })
    }

    generateIdsForEntities(recordPreviousVersion = true) {
        this.id = Guid.create().toString()
        this.selectionItems.forEach((selectionItem) => {
            if (selectionItem.isPersisted) {
                if (recordPreviousVersion) {
                    selectionItem.previousSelectionItemId = selectionItem.id
                }
                selectionItem.id = Guid.create().toString()
            }

            selectionItem.translations.forEach((translation: SelectionItemTranslation) => {
                translation.id = Guid.create().toString()
            })

            selectionItem.costLines.forEach((costLine: SelectionItemCostLine) => {
                costLine.id = Guid.create().toString()
                costLine.translations.forEach((translation) => {
                    if (translation.id) {
                        translation.id = Guid.create().toString()
                    }
                })
            })

            selectionItem.productLinkCostLines.forEach((costLine: SelectionItemProductLinkCostLineModel) => {
                costLine.id = Guid.create().toString()
                costLine.translations.forEach((translation) => {
                    if (translation.id) {
                        translation.id = Guid.create().toString()
                    }
                })
            })
        })
    }

    lock(lockedByEmployeeName: string, lockedOnDateTimeUtc: Moment) {
        if (!this.canLock()) {
            throw new Error("Can't lock because required selections are not made")
        }
        this.propertySelection.isLocked = true
        this.propertySelection.lockedByEmployeeName = lockedByEmployeeName
        this.propertySelection.lockedOnDateTimeUtc = lockedOnDateTimeUtc
        this.isLocked = true
    }

    unlock() {
        this.isLocked = false
    }

    loadNestedSelectionGroups(nestedSelectionGroups: SelectionGroupModel[]) {
        for (const selectionItem of this.selectionItems) {
            selectionItem.loadNestedSelectionGroups(nestedSelectionGroups)
        }
    }

    addSelectionItem(): SelectionItemModel {
        const selectionItem = new SelectionItemModel(
            Guid.create().toString(),
            false,
            false,
            false,
            0,
            [],
            false,
            this.pricing
        )
        this.selectionItems.push(selectionItem)
        if (this.allowMaximumSelections) {
            this.maximumOptionChoice++
        }
        this.isSelectionItemsEdited = true
        return selectionItem
    }

    createSelectionItemsFromProducts(products: ProductDto[], productCurrencyPrices: LightProductCurrencyPriceDto[]) {
        products.forEach((product) => {
            const selectionItem = new SelectionItemModel(
                Guid.create().toString(),
                false,
                false,
                false,
                0,
                [],
                false,
                this.pricing
            )
            const productCurrencyPriceDto = productCurrencyPrices.find((c) => c.productId === product.id)
            this.selectionItems.push(selectionItem)
            const productCurrencyPrice = new NestedProductCurrencyPriceDto()
            productCurrencyPrice.id = productCurrencyPrice.id
            productCurrencyPrice.useCostLinePricing = productCurrencyPriceDto.useCostLinePricing
            productCurrencyPrice.currencyCode = productCurrencyPrice.currencyCode
            productCurrencyPrice.unitCostAmount = productCurrencyPrice.unitCostAmount
            productCurrencyPrice.unitMarkupAmount = productCurrencyPrice.unitMarkupAmount
            productCurrencyPrice.isUnitMarkupPercent = productCurrencyPrice.isUnitMarkupPercent
            productCurrencyPrice.unitMarkupPercent = productCurrencyPrice.unitMarkupPercent
            productCurrencyPrice.unitAmount = productCurrencyPrice.unitAmount
            productCurrencyPrice.unitType = productCurrencyPrice.unitType

            selectionItem.addLinkedProductCostLine(product, productCurrencyPrice)
        })
        if (this.allowMaximumSelections) {
            this.maximumOptionChoice += products.length
        }
        this.isSelectionItemsEdited = true
    }

    getAllCostLines(): any[] {
        return this.selectionItems
            .map((o) => o.getAllCostLines())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    hasSelections(): boolean {
        const selections = this.getAllSelections()
        return selections && selections.length > 0
    }

    getAllSelections(): SelectionItemModel[] {
        return this.selectionItems.filter((option) => option.isSelected)
    }

    getAllSelectionItems(): SelectionItemModel[] {
        const options = [
            ...this.selectionItems,
            ...this.selectionItems
                .map((selectionItem) => selectionItem.getAllSelectionItems())
                .reduce((arr, t) => {
                    return arr.concat(t)
                }, []),
        ]
        return options
    }

    loadPropertySelections(propertySelection: PropertySelectionModel) {
        for (const selectionItem of this.selectionItems) {
            this.propertySelection = propertySelection
            selectionItem.loadPropertySelections(propertySelection)
        }
    }

    createDto(): SelectionGroupDto {
        const selectionGroupDto = new SelectionGroupDto()

        if (this.templateRoom) {
            selectionGroupDto.templateRoom = this.templateRoom.createDto()
        }
        if (this.templateCategory) {
            selectionGroupDto.templateCategory = this.templateCategory.createDto()
        }

        if (this.defaultTemplateRoom) {
            selectionGroupDto.defaultTemplateRoom = this.defaultTemplateRoom.createDto()
        }
        if (this.defaultTemplateCategory) {
            selectionGroupDto.defaultTemplateCategory = this.defaultTemplateCategory.createDto()
        }

        selectionGroupDto.id = this.id
        selectionGroupDto.versionNo = this.versionNo
        selectionGroupDto.allowSupplyOwnProduct = this.allowSupplyOwnProduct

        selectionGroupDto.supportedCurrency = this.supportedCurrency

        selectionGroupDto.selectionGroupPricing = this.pricing.selectionGroupPricing
        selectionGroupDto.hasCustomMarkup = this.pricing.hasCustomMarkup
        selectionGroupDto.customMarkupPercent = this.pricing.customMarkupPercent
        selectionGroupDto.excludeTaxesInClientPricing = this.pricing.excludeTaxesInClientPricing
        selectionGroupDto.clientPrice = this.pricing.manualIncludedAmount

        selectionGroupDto.constrainedToProjectId = this.constrainedToProjectId

        selectionGroupDto.isAdded = this.isAdded
        selectionGroupDto.isEdited = this.isEdited
        selectionGroupDto.isVersionChanged = this.isVersionChanged
        selectionGroupDto.isDeleted = this.isDeleted

        selectionGroupDto.minimumOptionChoice = this.minimumOptionChoice
        selectionGroupDto.maximumOptionChoice = this.maximumOptionChoice
        selectionGroupDto.allowMaximumSelections = this.allowMaximumSelections

        selectionGroupDto.selectionsDisplayType = this.selectionsDisplayType

        selectionGroupDto.fromTemplate = this.fromTemplate

        selectionGroupDto.translations = this.translations
        selectionGroupDto.internalName = this.internalName

        selectionGroupDto.isInclusionGroup = this.isInclusionGroup

        selectionGroupDto.propertySelectionIdsToChange = this.propertySelectionIdsToUpdateToNewVersion
        selectionGroupDto.propertyTemplateIdsToAddNewSelectionGroup = this.propertyTemplateIdsToAddNewSelectionGroup
        selectionGroupDto.selectionGroupVersionsId = this.selectionGroupVersionsId
        selectionGroupDto.selectionGroupStatus = this.isPublished
            ? SelectionGroupStatus.Published
            : SelectionGroupStatus.Draft

        if (this.selectionItems) {
            selectionGroupDto.selectionItems = this.selectionItems.map((option) => option.createDto())
        } else {
            this.selectionItems = []
        }

        selectionGroupDto.isNestedSelectionGroup = this.isNestedSelectionGroup

        selectionGroupDto.displayImage = this.displayImage?.createDto()

        return selectionGroupDto
    }

    makeASelectionItemDefault(selectionItem: SelectionItemModel, event: MatCheckboxChange) {
        selectionItem.isDefault = event.checked

        this.pricing.calculatedIncludedPrice = this.calculateIncludedPrice()
    }

    removeSelectionItem(option: SelectionItemModel) {
        const index = this.selectionItems.findIndex((o) => o === option)
        this.isSelectionItemsEdited = true
        this.selectionItems.splice(index, 1)
        if (this.allowMaximumSelections) {
            this.maximumOptionChoice--
        }
    }

    getNumberOfDefaultOptions(): number {
        return this.selectionItems.filter((option) => option.isDefault).length
    }

    areDefaultSelectionsValid(): boolean {
        return !this.minimumOptionsNotSelected() && !this.maximumOptionsSelectectedExceeded()
    }

    minimumOptionsNotSelected(): boolean {
        const numberOfDefaultOptions = this.getNumberOfDefaultOptions()
        return numberOfDefaultOptions < this.minimumOptionChoice
    }

    remainingDefaultsRequired(): number {
        if (this.minimumOptionsNotSelected) {
            return this.minimumOptionChoice - this.getNumberOfDefaultOptions()
        }
        if (this.maximumOptionsSelectectedExceeded) {
            return this.getNumberOfDefaultOptions() - this.maximumOptionChoice
        }
        return 0
    }

    canMakeSelections(): boolean {
        return (
            !this.maximumOptionsSelectectedExceeded() &&
            !this.isLocked &&
            this.isParentSelectionMade &&
            this.isPublished
        )
    }

    areThereAnyLinkedCostLinesPrices() {
        const allSelectionItems = this.getAllSelectionItems()

        return (
            allSelectionItems
                .map((s) => s.productLinkCostLines)
                .reduce((arr, t) => {
                    return arr.concat(t)
                }, []).length > 0 ||
            allSelectionItems
                .map((s) => s.serviceLinkCostLines)
                .reduce((arr, t) => {
                    return arr.concat(t)
                }, []).length > 0
        )
    }

    maximumOptionsSelectectedExceeded(): boolean {
        const numberOfDefaultOptions = this.getAllSelections().length
        return numberOfDefaultOptions > this.maximumOptionChoice
    }

    selectSelectionItem(selectionItem: SelectionItemModel, selectionDetail: SelectionDetailsModel) {
        const selectedOptions = this.selectionItems.filter((o) => o.isSelected)

        if (selectedOptions.length >= this.maximumOptionChoice) {
            throw Error('Invalid selection, maximum amount of selections allowed exceeded')
        }

        selectionItem.isSelected = true
        selectionItem.selectionDetails = selectionDetail
    }

    unselectSelectionItem(selectionItem: SelectionItemModel, selectionDetails: SelectionDetailsModel) {
        selectionItem.isSelected = false
        selectionItem.selectionDetails = selectionDetails
        selectionItem.nestedSelectionGroups.forEach((nestedSelectionGroup) => {
            // in the backend the nested selections are also unselected
            nestedSelectionGroup.unselectAllSelectionItems()
        })
    }

    unselectAllSelectionItems() {
        this.selectionItems.forEach((selectionItem) => {
            selectionItem.isSelected = false
            selectionItem.nestedSelectionGroups.forEach((nestedSelectionGroup) => {
                nestedSelectionGroup.unselectAllSelectionItems()
            })
        })
    }

    swapSelection(
        newSelectionItem: SelectionItemModel,
        unselectedSelectionItem: SelectionItemModel,
        selectionDetail: SelectionDetailsModel
    ) {
        unselectedSelectionItem.isSelected = false
        if (unselectedSelectionItem.hasNestedSelectionGroups) {
            unselectedSelectionItem.nestedSelectionGroups.forEach((selectionGroup) => {
                selectionGroup.unselectAllSelectionItems()
            })
        }
        newSelectionItem.isSelected = true
        newSelectionItem.selectionDetails = selectionDetail
    }

    canSelectDefaults(): boolean {
        const defaults = this.selectionItems.filter((option) => option.isDefault)
        return defaults.length < this.maximumOptionChoice
    }

    requiredSelectionsLeftInGroup(): number {
        return (
            this.minimumOptionChoice -
            (this.selectionItems ? this.selectionItems.filter((o) => o.isSelected).length : 0)
        )
    }

    moveSelectionGroupUp(selectionItemIndex: number) {
        let otherOptionToSwap = selectionItemIndex - 1
        if (selectionItemIndex === 0) {
            otherOptionToSwap = this.selectionItems.length - 1
        }
        this._swapSection(selectionItemIndex, otherOptionToSwap)
    }

    moveSelectionGroupDown(optionIndex: number) {
        let otherOptionToSwap = optionIndex + 1
        if (optionIndex === this.selectionItems.length - 1) {
            otherOptionToSwap = 0
        }
        this._swapSection(optionIndex, otherOptionToSwap)
    }

    private _swapSection(firstSectionIndex: number, secondSectionIndex) {
        const sectionToSwap = this.selectionItems[firstSectionIndex]
        this.selectionItems[firstSectionIndex] = this.selectionItems[secondSectionIndex]
        this.selectionItems[secondSectionIndex] = sectionToSwap
    }
}

export class SelectionItemModel extends BaseTranslatableModel<SelectionItemTranslation> {
    private _isSelected: boolean
    private _displayProductLinkCostLine: SelectionItemProductLinkCostLineModel
    private _productLinkCostLines: SelectionItemProductLinkCostLineModel[] = []
    private _useLabel: boolean
    private _label: LabelDto

    nestedSelectionGroupIds: string[]

    id?: string | null
    nestedSelectionGroups?: SelectionGroupModel[] | null = []
    isDefault?: boolean
    costLines?: any[] | null = []
    serviceLinkCostLines?: any[] | null = []
    useProductNameAsDisplayName?: boolean
    name: string
    index?: number
    displayNestedOptionGroups = true
    selectionDetails: SelectionDetailsModel
    previousSelectionItemId: string
    isSelectionSaved: boolean
    isPersisted: boolean
    parentSelectionGroupId: string

    showNestedSelectionGroups: boolean = false
    priceTBD: boolean
    hasMedia: boolean
    images?: ImageModel[] = []
    documents?: DocumentModel[] = []

    numberOfNestedSelectionGroups: number

    pricing: SelectionGroupPricingSettingsModel

    displayImage: ImageModel

    static createFromDto(selectionItemDto: SelectionItemDto, index: number): SelectionItemModel {
        const selectionItem = new SelectionItemModel(
            selectionItemDto.id,
            selectionItemDto.isDefault,
            selectionItemDto.isSelected,
            selectionItemDto.useProductNameAsDisplayName,
            selectionItemDto.index,
            selectionItemDto.translations,
            selectionItemDto.priceTBD,
            null
        )

        selectionItem.selectionDetails = selectionItemDto.selectionDetails
            ? SelectionDetailsModel.createFromDto(selectionItemDto.selectionDetails)
            : undefined
        selectionItem.isPersisted = true

        selectionItem.selectedTranslation = selectionItemDto.selectedTranslation

        selectionItem.productLinkCostLines = selectionItemDto.productLinkCostLines.map((costLine) =>
            SelectionItemProductLinkCostLineModel.createFromDto(costLine)
        )
        selectionItem.costLines = selectionItemDto.costLines.map((costLine) =>
            SelectionItemCostLineModel.createFromDto(costLine)
        )

        selectionItem.serviceLinkCostLines = selectionItemDto.serviceLinkCostLines.map((costLine) =>
            SelectionItemServiceCostLineModel.createFromDto(costLine)
        )

        selectionItem.nestedSelectionGroupIds = selectionItemDto.nestedSelectionGroupIds
        selectionItem.nestedSelectionGroups = selectionItemDto.nestedSelectionGroups.map((nestedSG) =>
            SelectionGroupModel.createFromDto(nestedSG)
        )

        selectionItem.numberOfNestedSelectionGroups = selectionItemDto.numberOfNestedSelectionGroups

        selectionItem.index = index
        selectionItem.previousSelectionItemId = selectionItemDto.previousSelectionItemId

        selectionItem.parentSelectionGroupId = selectionItemDto.parentSelectionGroupId

        if (selectionItemDto.label) {
            selectionItem.label = selectionItemDto.label
        }

        selectionItem.hasMedia = selectionItemDto.hasMedia
        if (selectionItemDto.images) {
            selectionItem.images = selectionItemDto.images.map((image) => ImageModel.createFromDto(image))
        }

        if (selectionItemDto.documents) {
            selectionItem.documents = selectionItemDto.documents.map((doc) => DocumentModel.createFromDto(doc))
        }

        selectionItem.displayImage = selectionItemDto.displayImage
            ? ImageModel.createFromMediaDto(selectionItemDto.displayImage)
            : ImageModel.placeholderImage

        return selectionItem
    }

    constructor(
        id: string,
        isDefault: boolean,
        isSelected: boolean,
        useProductNameAsDisplayName: boolean,
        index: number,
        translations: SelectionItemTranslation[],
        priceTBD: boolean = false,
        pricingSettings: SelectionGroupPricingSettingsModel
    ) {
        super(translations)
        this.id = id
        this.isDefault = isDefault
        this.useProductNameAsDisplayName = useProductNameAsDisplayName
        this.index = index
        this._isSelected = isSelected
        this.isSelectionSaved = this.isSelected
        this.priceTBD = priceTBD
        this.pricing = pricingSettings

        this.displayImage = ImageModel.placeholderImage

        if (this.translations === null || this.translations.length === 0) {
            this.initTranslations(this.translations, () => new SelectionItemTranslation())
        }
    }

    get hasAddedMedias(): boolean {
        const hasAddedImages = this.addedImages.length > 0
        return hasAddedImages ? hasAddedImages : this.addedDocuments.length > 0
    }

    get addedImages(): ImageModel[] {
        return this.images.filter((i) => i.isAdded)
    }

    get addedDocuments(): DocumentModel[] {
        return this.documents.filter((i) => i.isAdded)
    }

    get deletedImages(): ImageModel[] {
        return this.images.filter((i) => i.isDeleted)
    }

    get deletedDocuments(): DocumentModel[] {
        return this.documents.filter((i) => i.isDeleted)
    }

    get hasDeletedMedias(): boolean {
        return this.deletedImages.length > 0 || this.deletedDocuments.length > 0
    }

    get productLinkCostLines(): SelectionItemProductLinkCostLineModel[] {
        return this._productLinkCostLines
    }

    updateHasMedia() {
        this.hasMedia =
            this.images.filter((i) => !i.isDeleted).length > 0 || this.documents.filter((d) => !d.isDeleted).length > 0
    }

    set productLinkCostLines(value: SelectionItemProductLinkCostLineModel[]) {
        this._productLinkCostLines = value
        this._displayProductLinkCostLine = value.find((c) => c.isDisplayCostLine)
    }

    get useLabel(): boolean {
        return this._useLabel
    }

    set useLabel(value: boolean) {
        this._useLabel = value
    }

    get label(): LabelDto {
        return this._label
    }

    set label(value: LabelDto) {
        if (value) {
            this._useLabel = true
        }
        this._label = value
    }

    clone(generateNewId: boolean): SelectionItemModel {
        const selectionItemModel = new SelectionItemModel(
            Guid.create().toString(),
            this.isDefault,
            this.isSelected,
            this.useProductNameAsDisplayName,
            this.index,
            this.translations.map((translation) => {
                const clonedTranslation = new SelectionItemTranslation()
                clonedTranslation.culture = translation.culture
                clonedTranslation.id = Guid.create().toString()
                clonedTranslation.name = translation.name
                return clonedTranslation
            }),
            this.priceTBD,
            this.pricing
        )

        if (generateNewId) {
            selectionItemModel.id = Guid.create().toString()
        }

        selectionItemModel.nestedSelectionGroups = this.nestedSelectionGroups.map((nestedSelectionGroup) =>
            nestedSelectionGroup.clone(false)
        )

        selectionItemModel.displayImage = this.displayImage

        selectionItemModel.costLines = this.costLines.map((costLine) => costLine.clone(generateNewId))
        selectionItemModel.productLinkCostLines = this.productLinkCostLines.map((costLine) =>
            costLine.clone(generateNewId)
        )

        return selectionItemModel
    }

    get hasDisplayProductCostLine(): boolean {
        return this._displayProductLinkCostLine !== undefined
    }

    get hasHighlightImage() {
        return this.hasDisplayProductCostLine && this._displayProductLinkCostLine.hasHightlightImage
    }

    get hasNestedSelectionGroups(): boolean {
        return this.nestedSelectionGroups && this.nestedSelectionGroups.length > 0
    }

    get displayProductLinkCostLine(): SelectionItemProductLinkCostLineModel {
        return this._displayProductLinkCostLine
    }

    changeDisplayProductLinkCostLine(newDisplayProductLinkCostLine: SelectionItemProductLinkCostLineModel) {
        if (this.hasDisplayProductCostLine) {
            this._displayProductLinkCostLine.isDisplayCostLine = false
        }
        newDisplayProductLinkCostLine.isDisplayCostLine = true
        this._displayProductLinkCostLine = newDisplayProductLinkCostLine
    }

    cloneNestedSelectionGroups(propertySelection: PropertySelectionModel) {
        this.nestedSelectionGroups = this.nestedSelectionGroups.map((nestedSelectionGroup) =>
            nestedSelectionGroup.clone(false)
        )

        for (const nestedSelectionGroup of this.nestedSelectionGroups) {
            nestedSelectionGroup.loadPropertySelections(propertySelection)
            nestedSelectionGroup.depedantParentSelectionItem = this

            if (this.selectionDetails) {
                this.loadNestedSelections(this.selectionDetails)
            }
        }
    }

    getName(): string {
        if (this.useProductNameAsDisplayName && this._displayProductLinkCostLine) {
            return this._displayProductLinkCostLine.getName()
        }
        return this.selectedTranslation ? this.selectedTranslation.name : ''
    }

    getNameWithIndex(): string {
        return `Selection #${this.index} - ${this.getName()}`
    }

    get isSelected(): boolean {
        return this._isSelected
    }

    set isSelected(value) {
        this._isSelected = value
    }

    get totalPrice(): number {
        if (this.pricing?.selectionGroupPricing === SelectionGroupPricing.InclusionPrice) {
            return 0
        }

        return this.totalPriceWithoutIncluded - (this.pricing?.includedAmount ?? 0)
    }

    get totalMarkup(): number {
        const priceWithoutMarkup = this.totalPriceExcludingMarkup

        return this.pricing.hasCustomMarkup
            ? priceWithoutMarkup * this.pricing.customMarkupPercent
            : this.totalPrice - priceWithoutMarkup
    }

    get totalPriceWithoutIncluded(): number {
        if (this.priceTBD) {
            return 0
        }
        let price = 0

        if (this.costLines) {
            this.costLines.forEach((costLine) => {
                price += costLine.totalPrice
            })
        }

        if (this.productLinkCostLines) {
            this.productLinkCostLines.forEach((costLine) => {
                price += costLine.totalPrice
            })
        }

        return price
    }

    get totalPriceExcludingMarkup(): number {
        if (this.priceTBD) {
            return 0
        }
        let price = 0

        if (this.costLines) {
            this.costLines.forEach((costLine) => {
                price += costLine.extendedPrice
            })
        }

        if (this.productLinkCostLines) {
            this.productLinkCostLines.forEach((costLine) => {
                price += costLine.extendedPrice
            })
        }

        return price
    }

    get totalTax(): number {
        let price = 0

        if (this.costLines) {
            this.costLines.forEach((costLine) => {
                price += costLine.taxAmount
            })
        }

        if (this.productLinkCostLines) {
            this.productLinkCostLines.forEach((costLine) => {
                price += costLine.taxAmount
            })
        }

        return price
    }

    get totalPriceWithTax(): number {
        let price = 0

        if (this.costLines) {
            this.costLines.forEach((costLine) => {
                price += costLine.totalPriceWithTax
            })
        }

        if (this.productLinkCostLines) {
            this.productLinkCostLines.forEach((costLine) => {
                price += costLine.totalPriceWithTax
            })
        }

        return price - this.pricing.includedAmount
    }

    createDto(): SelectionItemDto {
        const selectionItem = new SelectionItemDto()

        selectionItem.id = this.id
        selectionItem.isDefault = this.isDefault
        selectionItem.useProductNameAsDisplayName = this.useProductNameAsDisplayName
        selectionItem.translations = this.translations

        selectionItem.productLinkCostLines = this.productLinkCostLines.map((costLine) => costLine.createDto())
        selectionItem.costLines = this.costLines.map((costLine) => costLine.createDto())
        selectionItem.serviceLinkCostLines = this.serviceLinkCostLines.map((costLine) => costLine.createDto())

        selectionItem.nestedSelectionGroupIds = this.nestedSelectionGroups.map(
            (nestedSelectionGroup) => nestedSelectionGroup.id
        )

        selectionItem.previousSelectionItemId = this.previousSelectionItemId
        selectionItem.priceTBD = this.priceTBD
        selectionItem.parentSelectionGroupId = this.parentSelectionGroupId
        selectionItem.label = this.label
        selectionItem.hasMedia = this.hasMedia
        selectionItem.images = this.images.filter((i) => !i.isDeleted).map((image) => image.createDto())
        selectionItem.documents = this.documents.filter((i) => !i.isDeleted).map((doc) => doc.createDto())

        selectionItem.displayImage = this.displayImage?.createDto()

        return selectionItem
    }

    loadNestedSelections(selectionDetail: SelectionDetailsModel) {
        for (const nestedSelectionGroup of this.nestedSelectionGroups) {
            for (const selectionItem of nestedSelectionGroup.selectionItems) {
                const nestedSelectionDetail = selectionDetail.nestedSelectionDetails.find(
                    (s) => s.selectionItemId === selectionItem.id
                )
                if (nestedSelectionDetail) {
                    selectionItem.loadSelectionDetail(nestedSelectionDetail)
                }
            }
        }
    }

    loadSelectionDetail(selectionDetail: SelectionDetailsModel) {
        this.selectionDetails = selectionDetail
        this.isSelected = true
    }

    loadPropertySelections(propertySelection: PropertySelectionModel) {
        for (const nestedSelectionGroup of this.nestedSelectionGroups) {
            nestedSelectionGroup.loadPropertySelections(propertySelection)
        }
    }

    postLoadNestedSelectionGroups(nestedSelectionGroups: SelectionGroupModel[]) {
        this.nestedSelectionGroups = nestedSelectionGroups
    }

    loadNestedSelectionGroups(nestedSelectionGroups: SelectionGroupModel[]) {
        if (!this.nestedSelectionGroupIds) {
            return
        }

        for (const nestedSelectionGroupId of this.nestedSelectionGroupIds) {
            const nestedSelectionGroup = nestedSelectionGroups.find(
                (selectionGroup) => selectionGroup.id === nestedSelectionGroupId
            )
            this.nestedSelectionGroups.push(nestedSelectionGroup)
        }
    }

    hasNestedOptionGroups(): boolean {
        if (this.numberOfNestedSelectionGroups > 0) {
            return true
        }
        return false
    }

    addLinkedProductCostLine(product: ProductDto, productPrice: NestedProductCurrencyPriceDto): any {
        productPrice.product = product
        const costLine = new SelectionItemProductLinkCostLineModel(
            null,
            1,
            true,
            productPrice.unitType,
            productPrice.unitCostAmount,
            productPrice.unitMarkupAmount,
            productPrice.unitMarkupPercent,
            productPrice.isUnitMarkupPercent,
            product.translations.map(
                (t) =>
                    new CostLineTranslation({
                        description: product.useClientDisplayName ? t.clientDisplayName : product.productName,
                        culture: t.culture,
                    })
            ),
            productPrice,
            false
        )
        costLine.isAdded = true
        this.productLinkCostLines.push(costLine)
        return costLine
    }

    updateProductCostLineByIndex(index: number, costLineDialog: CostLineDialogModel) {
        const productCostLine = this.productLinkCostLines[index]
        if (!productCostLine.isAdded) {
            productCostLine.isEdited = true
        }
        productCostLine.useTakeOff = costLineDialog.useTakeOff
        productCostLine.takeOff = costLineDialog.takeOff
        productCostLine.quantity = costLineDialog.quantity
    }

    addCostLine(costLineDialog: CostLineDialogModel): SelectionItemCostLineModel {
        const costLine = SelectionItemCostLineModel.createFromDialogModel(costLineDialog)
        costLine.isAdded = true
        this.costLines.push(costLine)
        return costLine
    }

    addServiceCostLine(service: ServiceDto, price: ServiceSupplierPriceVersionDto): SelectionItemServiceCostLineModel {
        const costLine = SelectionItemServiceCostLineModel.createFromSupplierPrice(service, price)
        costLine.isAdded = true
        this.serviceLinkCostLines.push(costLine)
        return costLine
    }

    updateServiceCostLineByIndex(index: number, costLineDialog: CostLineDialogModel) {
        const serviceCostLine = this.serviceLinkCostLines[index]
        if (!serviceCostLine.isAdded) {
            serviceCostLine.isEdited = true
        }
        serviceCostLine.useTakeOff = costLineDialog.useTakeOff
        serviceCostLine.takeOff = costLineDialog.takeOff
        serviceCostLine.quantity = costLineDialog.quantity
    }

    updateCostLine(costLine: SelectionItemCostLineModel, costLineDialogModel: CostLineDialogModel) {
        const updatedCostLine = SelectionItemCostLineModel.createFromDialogModel(costLineDialogModel)
        updatedCostLine.id = costLine.id
        if (!updatedCostLine.isAdded) {
            updatedCostLine.isEdited = true
        }
        updatedCostLine.selectedTranslation = costLineDialogModel.selectedTranslation
        const index = this.costLines.findIndex((c) => c.id === costLine.id)
        this.costLines[index] = updatedCostLine
    }

    updateCostLineByIndex(index: number, costLineDialogModel: CostLineDialogModel) {
        const updatedCostLine = SelectionItemCostLineModel.createFromDialogModel(costLineDialogModel)
        if (!updatedCostLine.isAdded) {
            updatedCostLine.isEdited = true
        }
        updatedCostLine.id = this.costLines[index].id
        const updatedTranslations = updatedCostLine.translations.map((translation) => {
            const oldTranslation = this.costLines[index].translations.find((t) => t.culture === translation.culture)
            const newTranslation = new CostLineTranslation()
            newTranslation.culture = translation.culture
            newTranslation.description = translation.description
            newTranslation.id = oldTranslation.id
            return newTranslation
        })
        updatedCostLine.translations = updatedTranslations
        updatedCostLine.selectedTranslation = costLineDialogModel.selectedTranslation

        this.costLines[index] = updatedCostLine
    }

    getAllCostLines(): any[] {
        const arr = [...this.costLines, ...this.productLinkCostLines]
        return this.nestedSelectionGroups
            .map((o) => o.getAllCostLines())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, arr)
    }

    removeNestedOptionGroup(selectionGroup: SelectionGroupModel) {
        const index = this.nestedSelectionGroups.findIndex((o) => o === selectionGroup)
        this.nestedSelectionGroups.splice(index, 1)
    }

    getAllSelectionItems(): SelectionItemModel[] {
        return this.nestedSelectionGroups
            .map((selectionGroup) => selectionGroup.getAllSelectionItems())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    hasNestedSelections(): boolean {
        return this.getAllNestedSelections().length > 0
    }

    getAllNestedSelections(): SelectionItemModel[] {
        let selectedSelectionItems = []
        this.nestedSelectionGroups.forEach((selectionGroup) => {
            selectedSelectionItems = selectedSelectionItems.concat(selectionGroup.getAllSelections())
        })
        return selectedSelectionItems
    }

    hasImage(): boolean {
        return this.getDisplayImageUrl() !== AppSettings.IMAGE_PLACEHOLDER_URL
    }

    generateNameTranslations(): NameTranslation[] {
        if (this.useProductNameAsDisplayName) {
            return this.displayProductLinkCostLine.translations.map(
                (t) => new NameTranslation({ culture: t.culture, name: t.description })
            )
        }
        return this.translations.map((t) => new NameTranslation({ culture: t.culture, name: t.name }))
    }

    getDisplayImageUrl(returnDisplayImage: boolean = false, returnSwatchImage: boolean = false): string {
        if (this.displayProductLinkCostLine) {
            if (returnSwatchImage && this.displayProductLinkCostLine.linkedProductPrice.product.swatchImage) {
                return this.displayProductLinkCostLine.linkedProductPrice.product.swatchImage.filePath
            }

            if (returnDisplayImage && this.displayProductLinkCostLine.linkedProductPrice.product.displayImage) {
                return this.displayProductLinkCostLine.linkedProductPrice.product.displayImage.filePath
            }

            if (this.displayProductLinkCostLine.linkedProductPrice.product.thumbnailImage) {
                return this.displayProductLinkCostLine.linkedProductPrice.product.thumbnailImage.filePath
            }
        }
        return AppSettings.IMAGE_PLACEHOLDER_URL
    }

    getImagePaths(): string[] {
        const imagePaths = new Array<string>()
        const productCostLine = this.displayProductLinkCostLine
        if (productCostLine) {
            if (productCostLine.product.displayImage) {
                imagePaths.push(productCostLine.product.displayImage.filePath)
            }
            // if (productCostLine.product.galleryImages) {
            //     return imagePaths.concat(
            //         productCostLine.product.galleryImages.map((imageModel) => {
            //             return imageModel.filePath
            //         })
            //     )
            // }
        }
        return imagePaths
    }

    removeProductOptionCostLine() {
        // const index = this.productOptionCostLines.indexOf(costLine);
        // this.productOptionCostLines.splice(index, 1);
    }

    removeOptionCostLine() {
        // const index = this.optionCostLines.indexOf(costLine);
        // this.optionCostLines.splice(index, 1);
    }

    moveServiceCostLineUp(index: number) {
        ArrayHelper.moveArrayItemUp(this.serviceLinkCostLines, index)
    }

    moveServiceCostLineDown(index: number) {
        ArrayHelper.moveArrayItemDown(this.serviceLinkCostLines, index)
    }

    moveCostLineUp(index: number) {
        ArrayHelper.moveArrayItemUp(this.costLines, index)
    }

    moveCostLineDown(index: number) {
        ArrayHelper.moveArrayItemDown(this.costLines, index)
    }

    moveLinkedProductCostLineUp(index: number) {
        ArrayHelper.moveArrayItemUp(this.productLinkCostLines, index)
    }

    moveLinkedProductCostLineDown(index: number) {
        ArrayHelper.moveArrayItemDown(this.productLinkCostLines, index)
    }
}
