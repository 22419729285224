import { query } from '@angular/animations'
import { Injectable } from '@angular/core'
import {
    ProductsService,
    ProductDto,
    ProductCurrencyPriceDto,
    ProductVariationGroupLookUpDto,
    ProductPricesService,
    LightProductCurrencyPriceDto,
    GetProductListQuery,
    ProductLookupDto,
} from '@app/services/api.services'

import { EMPTY, Observable, of } from 'rxjs'

import { map, mergeMap, catchError } from 'rxjs/operators'
import { MatDialog } from '@angular/material/dialog'
import {
    ProductListComponent,
    ProductPickerSettings,
} from '@app/admin-pages/products/product-list/product-list.component'
import { ProductVariationGroupListComponent } from '@app/admin-pages/product-variation-groups/product-variation-group-list/product-variation-group-list.component'
import { NotifyService } from '@app/services/notify.service'
import { SearchDialogService } from '@app/shared/search-dialog/search-dialog.service'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'
import { DisplayColumn } from '@app/shared/search-dialog/models/displayColumn'
import {
    LocalSearchTableContainer,
    ServerSearchTableContainer,
} from '@app/shared/search-dialog/models/searchTableContainer'
import { error } from 'console'
import { ProductsDataSource } from '@app/admin-pages/products/product-list/products.datasource'
import { LanguageService } from '../language.service'

@Injectable({
    providedIn: 'root',
})
export class ProductPickerService {
    constructor(
        private productsService: ProductsService,
        private productPricesService: ProductPricesService,
        private searchDialogService: SearchDialogService,
        private dialog: MatDialog,
        private notifyService: NotifyService,
        private languageService: LanguageService
    ) {}

    pickProductVariationGroup(
        excludeProductVariationGroupIds: string[] = null
    ): Observable<ProductVariationGroupLookUpDto> {
        const dialogRef = this.dialog.open(ProductVariationGroupListComponent, {
            width: '1800px',
            data: {
                excludeProductVariationGroupIds,
            },
        })

        dialogRef.componentInstance.isSelectionMode = true
        dialogRef.componentInstance.displayMode = PagingDisplayMode.Dialog

        return dialogRef.componentInstance.onEntitySelectedObservable.pipe(
            mergeMap((productVariationGroup) => {
                dialogRef.close()
                return of(productVariationGroup)
            })
        )
    }

    pickProductNew(): Observable<ProductLookupDto> {
        const dispalyColumns = [
            new DisplayColumn('Model No', 'modelNumber'),
            new DisplayColumn('Brand', 'brand'),
            new DisplayColumn('Name', 'productName'),
        ]
        const query = new GetProductListQuery()
        query.requestedCulture = this.languageService.userSelectedLanguage.culture

        const setting = new ServerSearchTableContainer<ProductLookupDto, GetProductListQuery>(
            'Products',
            query,
            new ProductsDataSource(this.productsService),
            true,
            dispalyColumns,
            []
        )
        setting.showAddButton = false

        return this.searchDialogService.showServerDialog(setting).pipe(
            map((p) => {
                return p.selectedEntity
            })
        )
    }

    pickProduct(
        excludeProductIds: string[] = null,
        productPickerSettings: ProductPickerSettings = null
    ): Observable<ProductDto> {
        const dialogRef = this.dialog.open(ProductListComponent, {
            width: '1800px',
            data: {
                displayMode: PagingDisplayMode.Dialog,
                excludeProductIds,
                productPickerSettings,
            },
        })

        dialogRef.componentInstance.isSelectionMode = true
        dialogRef.componentInstance.displayMode = PagingDisplayMode.Dialog

        return dialogRef.componentInstance.onEntitySelectedObservable.pipe(
            mergeMap((productPicked) => {
                dialogRef.close()
                if (productPicked) {
                    return this.productsService.getProduct(productPicked.id)
                } else {
                    return of(null)
                }
            })
        )
    }

    pickProductAndPrice(
        currencyCode: string,
        excludeProductIds: string[] = null,
        constrainedToProjectId: number = null
    ): Observable<{ productPrice: ProductCurrencyPriceDto; product: ProductDto }> {
        const settings = new ProductPickerSettings()

        return this.pickProduct(excludeProductIds, settings).pipe(
            mergeMap((product: ProductDto) => {
                if (
                    product.numberOfActiveSupplierPrices === 0 &&
                    constrainedToProjectId !== null &&
                    product.numberOfActiveSupplierProjectPrices === 0
                ) {
                    return this._showNoCurrency(currencyCode)
                }

                return this.pickProductPrice(product.id, currencyCode, constrainedToProjectId).pipe(
                    map((productCurrencyPrice) => {
                        return { productPrice: productCurrencyPrice, product: product }
                    })
                )
            })
        )
    }

    pickProductPrice(
        productId: string,
        currencyCode: string,
        projectId?: number
    ): Observable<LightProductCurrencyPriceDto> {
        const searchTableContainer = new LocalSearchTableContainer(
            this.productPricesService.getActiveCurrencyPrices(productId, true, currencyCode, projectId),
            'Product Prices',
            [
                new DisplayColumn('Supplier', 'supplierName'),
                new DisplayColumn('Unit Cost', 'unitCostAmount', false, true),
                new DisplayColumn('Unit Markup', 'unitMarkupAmount', false, true),
                new DisplayColumn('Total', 'unitAmount', false, true),
            ],
            'This product has no price versions with currency ' + currencyCode + '. Try a different product.'
        )

        return this.searchDialogService.showLocalDialog<LightProductCurrencyPriceDto>(searchTableContainer)
    }

    private _showNoCurrency(currencyCode) {
        this.notifyService.fail(`This product has no prices in the currency ${currencyCode}`)
        return EMPTY
    }
}
