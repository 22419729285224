<h2 mat-dialog-title>Update selection groups using this price</h2>
<mat-dialog-content>
    <mat-selection-list #selectionList>
        <mat-list-option
            *ngFor="let sGroup of selectionGroups"
            [value]="sGroup.selectionGroup.id"
            [(selected)]="sGroup.selected"
        >
            <h3 matLine>{{ sGroup.selectionGroup.name }}</h3>
            <div matLine>{{ sGroup.selectionGroup.templateName }}</div>
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" (click)="dialogRef.close(null)">Cancel</button>
        <button class="mr-2" mat-stroked-button (click)="done()">Done</button>
    </div>
</mat-dialog-actions>
