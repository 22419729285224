<form [formGroup]="formControl">
    <div class="row">
        <div class="col-12" [ngClass]="getColumnClass()">
            <mat-form-field>
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" />
            </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="getColumnClass()">
            <mat-form-field>
                <mat-label>Unit Type</mat-label>
                <mat-select formControlName="unitType">
                    <mat-option *ngFor="let unitType of enumListService.unitType" [value]="unitType.value">
                        {{ unitType.display }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12" [ngClass]="getColumnClass()">
            <app-quantity
                [placeholder]="model.isLocalTakeOff ? 'Default Quantity' : 'Inherited Quantity'"
                [group]="formControl"
                controlName="defaultQuantity"
                [allowDecimal]="false"
            ></app-quantity>
        </div>

        <div *ngIf="model.isInherited" class="col-12" [ngClass]="getColumnClass()">
            <app-quantity
                placeholder="Overridden Quantity"
                [group]="formControl"
                controlName="quantity"
                [allowDecimal]="false"
            ></app-quantity>
        </div>
    </div>

    <div class="row" *ngIf="model.isInherited">
        <div class="col-12">
            <div class="pull-right">
                <mat-slide-toggle formControlName="isEnabled">Override takeoff quantity</mat-slide-toggle>
            </div>
        </div>
    </div>
</form>
