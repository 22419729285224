import { Injectable } from '@angular/core'
import { AccountManageService } from './accountManage.service'
import { LanguageService } from './language.service'

@Injectable({
    providedIn: 'root',
})
export class ApiConfigService {
    constructor(private languageService: LanguageService) {}

    selectAnyTranslations(item: any) {
        this.languageService.selectAnyTranslations(item)
    }
}
