import {
    ParentTemplateDto,
    TemplateType,
    ChildTemplateDto,
    TemplateDto,
    UnitType,
    TakeOffDto,
    SelectionItem,
    TemplatePackageLookupDto,
} from '@app/services/api.services'
import { TemplateSectionModel } from './TemplateSectionModel'
import { TakeOffModel, TakeOffQuantityModel } from './TakeOffModel'
import { SelectionItemModel, SelectionGroupModel } from './SelectionGroupModel'
import { PropertyModel } from '../property/propertyModel'
import { TaxRateModel } from '../common/TaxRateModel'
import { Subject, Observable } from 'rxjs'
import { ArrayHelper } from '@app/shared/helpers/arrayHelper'
import { PackageModel } from '../packages/packageModel'
import { AllowanceModel } from '../allowances/allowanceModel'

export class TemplateModel {
    private _selectedTemplateSection: TemplateSectionModel
    private _editingLocalTakeOff: TakeOffModel
    private _editingInheritedTakeOff: TakeOffModel
    private _isSelectionModeOn: boolean
    private _selectionModeChange = new Subject<boolean>()

    id?: string | null
    name?: string | null
    parentTemplate?: ParentTemplateDto | null
    templateSections?: TemplateSectionModel[] | null
    templateType?: TemplateType
    overrideParentSortOrder: boolean
    childTemplates?: ChildTemplateDto[] | null
    isDisabled: boolean
    supportedCurrencies: string[] = []
    supportedCurrency: string
    associatedPackages: TemplatePackageLookupDto[] = []
    localTakeOffs: TakeOffModel[]
    inheritedTakeOffs: TakeOffModel[]
    nestedSelectionGroups: SelectionGroupModel[] = []
    showOnlyClientPrice = false
    property: PropertyModel
    hasChanges: boolean
    showDisabledGroups: boolean
    groupedByCategory: boolean
    projectId: number
    allowances: AllowanceModel[]
    projectName: string

    static createFromDto(templateDto: TemplateDto) {
        const template = new TemplateModel(
            templateDto.id,
            templateDto.name,
            templateDto.templateType,
            templateDto.projectId
        )

        template.supportedCurrency = templateDto.supportedCurrency
        template.localTakeOffs = []
        template.inheritedTakeOffs = []
        template.overrideParentSortOrder = templateDto.overrideParentSortOrder
        template.supportedCurrencies = templateDto.supportedCurrencies
        template.projectName = templateDto.projectName

        templateDto.localTakeOffs.forEach((takeOff) => {
            template.localTakeOffs.push(TakeOffModel.createFromDto(takeOff))
        })

        templateDto.inheritedTakeOffs.forEach((takeOff) => {
            const takeOffModel = TakeOffModel.createFromDto(takeOff)
            takeOffModel.isInherited = true
            template.inheritedTakeOffs.push(takeOffModel)
        })

        if (templateDto.associatedPackages) {
            template.associatedPackages = templateDto.associatedPackages
        }

        template.parentTemplate = templateDto.parentTemplate

        template.nestedSelectionGroups = templateDto.nestedSelectionGroups.map((nestedSelectionGroup) =>
            SelectionGroupModel.createFromDto(nestedSelectionGroup)
        )

        template.templateSections = templateDto.sections.map((templateSection) =>
            TemplateSectionModel.createFromDto(templateSection, template.templateType)
        )
        template.childTemplates = templateDto.childTemplates

        if (template.templateType === TemplateType.Property) {
            template.property = new PropertyModel(templateDto.property)

            if (templateDto.property && templateDto.property.assignedTaxRate) {
                template._loadTaxes(template.property.assignedTaxRate)
            }
        }
        template.groupedByCategory = templateDto.groupedByCategory
        return template
    }

    get selectionModeOnChange(): Observable<boolean> {
        return this._selectionModeChange
    }

    get isSelectionModeOn(): boolean {
        return this._isSelectionModeOn
    }

    set isSelectionModeOn(value: boolean) {
        this._isSelectionModeOn = value
    }

    get editingTakeOff(): TakeOffModel {
        return this._editingLocalTakeOff ? this._editingLocalTakeOff : this._editingInheritedTakeOff
    }

    constructor(id: string | null, name: string, templateType: TemplateType, projectId: number) {
        this.id = id
        this.name = name
        this.templateType = templateType
        this.projectId = projectId
    }

    hasTemplateSectionForRoom(roomId: string) {
        return (
            this.templateSections.findIndex((section) => section.templateRoom && section.templateRoom.id === roomId) >=
            0
        )
    }

    hasTemplateSectionForCategory(categoryId: string) {
        return (
            this.templateSections.findIndex(
                (section) => section.templateCategory && section.templateCategory.id === categoryId
            ) >= 0
        )
    }

    hasTemplateSectionForSelectionGroup(selectionGroup: SelectionGroupModel) {
        if (selectionGroup.templateRoom) {
            return this.hasTemplateSectionForRoom(selectionGroup.templateRoom.id)
        }
        return this.hasTemplateSectionForCategory(selectionGroup.templateCategory.id)
    }

    addToTemplateSection(selectionGroup: SelectionGroupModel) {
        selectionGroup.isAdded = true
        let templateSection
        if (selectionGroup.templateRoom) {
            templateSection = this.templateSections.find(
                (section) => section.templateRoom && section.templateRoom.id === selectionGroup.templateRoom.id
            )
        } else {
            templateSection = this.templateSections.find(
                (section) =>
                    section.templateCategory && section.templateCategory.id === selectionGroup.templateCategory.id
            )
        }
        templateSection.selectionGroups.push(selectionGroup)
    }

    get selectedTemplateSection(): TemplateSectionModel {
        return this._selectedTemplateSection
    }

    get isEdited(): boolean {
        return (
            this.templateSections.filter((section) => section.isAdded || section.isEdited || section.isDeleted).length >
            0
        )
    }

    get isPropertyTemplate(): boolean {
        return this.templateType === TemplateType.Property
    }

    get showSelections(): boolean {
        return this.templateType === TemplateType.Property
    }

    get totalPriceIncludeTax(): number {
        return (
            (this.property ? this.property.price : 0) +
            this.templateSections.reduce((sum, section) => {
                return sum + section.selectedOptionsTotalPriceWithTaxes
            }, 0)
        )
    }

    get totalPrice(): number {
        return (
            (this.property ? this.property.price : 0) +
            this.templateSections.reduce((sum, section) => {
                return sum + section.selectedOptionsTotalPrice
            }, 0)
        )
    }

    get totalClientPrice(): number {
        return (
            (this.property ? this.property.price : 0) +
            this.templateSections.reduce((sum, section) => {
                return sum + section.selectedOptionsTotalClientPrice
            }, 0)
        )
    }

    get totalRemaining(): number {
        return this.totalPriceIncludeTax - (this.property ? this.property.totalPaymentsAmount : 0)
    }

    getNestedSelectionGroup(nestedSelectionGroupId: string) {
        return this.nestedSelectionGroups.find(
            (nestedSelectionGroup) => nestedSelectionGroup.id === nestedSelectionGroupId
        )
    }

    getAllTakeOffs(): TakeOffModel[] {
        return [...this.localTakeOffs, ...this.inheritedTakeOffs]
    }

    toggleSelectionMode() {
        this.isSelectionModeOn = !this.isSelectionModeOn
    }

    addSupportedCurrency(currencyCode: string) {
        this.supportedCurrencies.push(currencyCode)
    }

    removeSupportedCurrency(currencyCode: string) {
        const index = this.supportedCurrencies.findIndex((c) => c === currencyCode)
        this.supportedCurrencies.splice(index, 1)
    }

    getAllSelectionItems(): SelectionItemModel[] {
        return this.templateSections
            .map((section) => section.getAllSelectionItems())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    getAllSelectionGroups(): SelectionGroupModel[] {
        return this.templateSections
            .map((section) => section.selectionGroups)
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
            .concat(this.nestedSelectionGroups)
    }

    getSelectionGroups(): SelectionGroupModel[] {
        return this.templateSections
            .map((section) => section.selectionGroups)
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
    }

    getAllSelectionsWithHighlights(): SelectionItemModel[] {
        return this.getAllSelectionItems().filter(
            (selection) =>
                (selection.isSelected && selection.hasHighlightImage) ||
                (selection.isDefault && selection.hasHighlightImage)
        )
    }

    getAllSelections(): SelectionItemModel[] {
        return this.getAllSelectionItems().filter((option) => option.isSelected)
    }

    getAllNewSelections(): SelectionItemModel[] {
        return this.getAllSelectionItems().filter((option) => option.isSelected && !option.isSelectionSaved)
    }

    getAllUnselectedSelections() {
        return this.getAllSelectionItems().filter((option) => !option.isSelected && option.isSelectionSaved)
    }

    enableSelections() {}

    addTakeOff(takeOff: TakeOffModel) {
        this.localTakeOffs.push(takeOff)
        this.localTakeOffs = Object.assign([], this.localTakeOffs)
    }

    clearEditingTakeOffs() {
        this._editingLocalTakeOff = undefined
        this._editingInheritedTakeOff = undefined
    }

    editTakeOff(takeOff: TakeOffModel) {
        if (takeOff.isLocalTakeOff) {
            this._editingLocalTakeOff = takeOff.clone()
        } else {
            this._editingInheritedTakeOff = takeOff.clone()
        }
    }

    completeTakeOffEdit() {
        if (this._editingLocalTakeOff) {
            this._completeEditLocalTakeOff()
        } else if (this._editingInheritedTakeOff) {
            this._completeEditInheritedTakeOff()
        }
    }

    private _completeEditLocalTakeOff() {
        const takeOffIndex = this.localTakeOffs.findIndex((t) => t.id === this._editingLocalTakeOff.id)
        const takeOffToUpdate = this.localTakeOffs[takeOffIndex]

        takeOffToUpdate.name = this._editingLocalTakeOff.name
        takeOffToUpdate.unitType = this._editingLocalTakeOff.unitType
        takeOffToUpdate.defaultQuantity = this._editingLocalTakeOff.defaultQuantity
        takeOffToUpdate.isDynamic = this._editingLocalTakeOff.isDynamic
        takeOffToUpdate.calculationOperator = this._editingLocalTakeOff.calculationOperator
        takeOffToUpdate.takeOffUsedForCalculation = this._editingLocalTakeOff.takeOffUsedForCalculation
        takeOffToUpdate.valueForDynamicCalculation = this._editingLocalTakeOff.valueForDynamicCalculation

        this._editingLocalTakeOff = undefined
        this.localTakeOffs = Object.assign([], this.localTakeOffs)
    }

    private _completeEditInheritedTakeOff() {
        const takeOffIndex = this.inheritedTakeOffs.findIndex((t) => t.id === this._editingInheritedTakeOff.id)
        const takeOffToUpdate = this.inheritedTakeOffs[takeOffIndex]

        if (takeOffToUpdate.takeOffQuantity) {
            takeOffToUpdate.takeOffQuantity.quantity = this._editingInheritedTakeOff.takeOffQuantity.quantity
            takeOffToUpdate.takeOffQuantity.isEnabled = this._editingInheritedTakeOff.takeOffQuantity.isEnabled
        } else {
            takeOffToUpdate.takeOffQuantity = new TakeOffQuantityModel(
                takeOffToUpdate.id,
                this._editingInheritedTakeOff.takeOffQuantity.quantity,
                true,
                true
            )
        }

        this._editingInheritedTakeOff = undefined
        this.inheritedTakeOffs = Object.assign([], this.inheritedTakeOffs)
    }

    removeTakeOff(takeOff: TakeOffModel) {
        const takeOffIndex = this.localTakeOffs.findIndex((t) => t.id === takeOff.id)
        this.localTakeOffs.splice(takeOffIndex, 1)
        this.localTakeOffs = Object.assign([], this.localTakeOffs)
    }

    canLockSelections() {
        return this.templateType === TemplateType.Property
    }

    isSortingEnabled(): boolean {
        if (this.templateType === TemplateType.Master) {
            return true
        }
        return this.overrideParentSortOrder
    }

    removeTemplateSection(index: number) {
        this.templateSections[index].isDeleted = true
    }

    setSelectedTemplateSection(templateSectionId: string) {
        this._selectedTemplateSection = this.templateSections.find((section) => section.id === templateSectionId)
    }

    clearSelectedTemplateSection() {
        this._selectedTemplateSection = null
    }

    addAssociatedPackage(templatePackage: TemplatePackageLookupDto) {
        this.associatedPackages.push(templatePackage)
        this.associatedPackages = Object.assign([], this.associatedPackages)
    }

    removeAssociatedPackage(packageId: string) {
        const index = this.associatedPackages.findIndex((p) => p.id === packageId)
        this.associatedPackages.splice(index, 1)
        this.associatedPackages = Object.assign([], this.associatedPackages)
    }

    createDto(): TemplateDto {
        const templateDto = new TemplateDto()

        templateDto.id = this.id
        templateDto.name = this.name
        templateDto.templateType = this.templateType
        templateDto.overrideParentSortOrder = this.overrideParentSortOrder

        templateDto.sections = this.templateSections.map((section) => section.createDto())

        return templateDto
    }

    loadNestedSelectionGroups() {
        this.getAllSelectionGroups().forEach((selectionGroup) =>
            selectionGroup.loadNestedSelectionGroups(this.nestedSelectionGroups)
        )

        var selectionGroups = this.getSelectionGroups()

        for (var selectionGroup of selectionGroups) {
            for (var selectionItem of selectionGroup.selectionItems) {
                selectionItem.cloneNestedSelectionGroups(selectionGroup.propertySelection)
            }
        }
    }

    private _loadTaxes(assignedTaxRate: TaxRateModel) {
        const costLines = this._getAllCostLines()
        costLines.forEach((costLine) => {
            costLine.loadTaxRateIntoCostLine(assignedTaxRate)
        })
    }

    private _getAllCostLines(): any[] {
        const costLines = this.templateSections
            .map((section) => section.getAllCostLines())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
        return costLines
    }

    moveTemplateSectionUp(templateSectionIndex: number) {
        ArrayHelper.moveArrayItemUp(this.templateSections, templateSectionIndex)
    }

    moveTemplateSectionDown(templateSectionIndex: number) {
        ArrayHelper.moveArrayItemDown(this.templateSections, templateSectionIndex)
    }

    private _loadTakeOffsIntoCostLines() {
        const costLines = this.templateSections
            .map((section) => section.getAllCostLines())
            .reduce((arr, t) => {
                return arr.concat(t)
            }, [])
        const allTakeOffs = this.getAllTakeOffs()
        costLines.forEach((costLine) => {
            if (costLine.useTakeOff) {
                costLine.takeOff = allTakeOffs.find((t) => t.id === costLine.takeOff.id)
            }
        })
    }

    private _swapSection(firstSectionIndex: number, secondSectionIndex: number) {
        const sectionToSwap = this.templateSections[firstSectionIndex]
        this.templateSections[firstSectionIndex] = this.templateSections[secondSectionIndex]
        this.templateSections[secondSectionIndex] = sectionToSwap
    }
}
