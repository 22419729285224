import { NgModule } from '@angular/core'
import { FileSelectComponent } from './file-select.component'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { MaterialModule } from '@app/material.module'
import { NgxFileDropModule } from 'ngx-file-drop'
import { LabelGroupDropDownModule } from '../label-group-drop-down/label-group-drop-down.module'

@NgModule({
    declarations: [FileSelectComponent],
    imports: [CommonFormsModule, MaterialModule, NgxFileDropModule, LabelGroupDropDownModule],
    exports: [FileSelectComponent],
})
export class FileSelectModule {}
