import { BaseSelectionItemComponent } from '@app/shared/common-template-components/base-selection-item.component'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ClientPortalSelectionService } from '@app/services/client-portal.service'
import { NotifyService } from '@app/services/notify.service'
import { SelectionItemDetailsDialogService } from '@app/shared/dialog/selection-item-details-dialog/selection-item-details-dialog.service'
import { CurrencyPipe } from '@angular/common'
import { ImageCdnService } from '@app/shared/images/image-cdn.service'

@Component({
    selector: 'app-client-selection-notch',
    templateUrl: './client-selection-notch.component.html',
    styleUrls: ['./client-selection-notch.component.scss'],
})
export class ClientSelectionNotchComponent extends BaseSelectionItemComponent implements OnInit {
    constructor(
        selectionItemDetailsDialogService: SelectionItemDetailsDialogService,
        route: ActivatedRoute,
        router: Router,
        notifyService: NotifyService,
        clientPortalSelectionService: ClientPortalSelectionService,
        public imageCdnServie: ImageCdnService
    ) {
        super(selectionItemDetailsDialogService, route, router, notifyService, clientPortalSelectionService)
    }

    ngOnInit() {}

    getImageUrl() {
        return this.selectionItem.getDisplayImageUrl(this.useDisplayImage, true)
    }
}
