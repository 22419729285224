import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { AppComponent } from './app.component'
import { FooterModule } from './shared/footer/footer.module'
import { AddressFormModule } from './shared/address-form/address-form.module'
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component'
import { AppRoutes, EmployeeAuthGuardCanActivate, ClientAuthGuardCanActivate } from './app.routing'
import { AccountManageService } from './services/accountManage.service'
import {
    AccountService,
    EmployeesService,
    BuilderClientsService,
    PropertiesService,
    ProjectsService,
    CompaniesService,
    TimesheetsService,
    ProductsService,
    BrandsService,
    TaskTemplatesService,
    DocumentsService,
    TemplateCategoriesService,
    SuppliersService,
    RolesService,
    ServiceRatePricesService,
    PropertyTemplateService,
    TemplateRoomsService,
    ReferrersService,
    MediasService,
    ImageService,
    TemplateService,
    TaxesService,
    EmployeeSettingsService,
    AuthService,
    SelectionGroupsService,
    CurrenciesService,
    TakeOffsService,
    PackagesService,
    LabelGroupsService,
    ProductVariationGroupsService,
    ProductAccessoriesService,
    BuyersService,
    AllowancesService,
    ContractTemplatesService,
    ProductPricesService,
    ServiceRatesService,
    PropertyDefinitionService,
    PropertyValuesService,
    NotesService,
} from './services/api.services'
import { API_BASE_URL } from './services/api.services'
import { CountriesService } from './services/countries.service'
import { NotifyService } from './services/notify.service'
import { EnumListService } from './services/enum-list.service'
import { TableContainerModule } from './shared/table-container/table-container.module'
import { SearchDialogService } from './shared/search-dialog/search-dialog.service'
import { ImgCropperDialogService } from './shared/dialog/img-cropper-dialog/img-cropper-dialog.service'
import { ImgCropperDialogModule } from './shared/dialog/img-cropper-dialog/img-cropper-dialog.module'
import { FixedFormControlsService } from './shared/fixed-form-controls/fixed-form-controls.service'
import { LanguageService } from './services/language.service'
import { SelectionDialogService } from './shared/selection-dialog/selection-dialog.service'
import { SelectionDialogModule } from './shared/selection-dialog/selection-dialog.module'
import { CostLineDialogService } from './shared/cost-line-dialog/cost-line-dialog.service'
import { CostLineDialogModule } from './shared/cost-line-dialog/cost-line-dialog.module'
import { UserConfigurationService } from '@app/services/user-configuration.service'
import { ApiConfigService } from './services/api-config.service'
import { NgxPermissionsModule } from 'ngx-permissions'
import { SimpleEntityCreateFormDialogService } from './shared/simple-entity-create-form-dialog/simple-entity-create-form-dialog.service'
import { SimpleEntityCreateFormDialogModule } from './shared/simple-entity-create-form-dialog/simple-entity-create-form-dialog.module'
import { ClientPortalNavbarModule } from './shared/client-portal-navbar/client-portal-navbar.module'
import { ConfirmDialogModule } from '@app/shared/confirm-dialog/confirm-dialog.module'
import { ConfirmDialogService } from './shared/confirm-dialog/confirm-dialog.service'
import { ClientPortalSelectionService } from './services/client-portal.service'
import { SelectionItemDetailsDialogService } from './shared/dialog/selection-item-details-dialog/selection-item-details-dialog.service'
import { SelectionItemDetailsDialogModule } from './shared/dialog/selection-item-details-dialog/selection-item-details-dialog.module'
import { AdminModule } from './layouts/admin/admin.module'
import { ClientPortalModule } from './layouts/client-portal/client-portal.module'
import { NoteFormDialogService } from './admin-pages/notes/note-form-dialog/note-form-dialog.service'
import { CalendarModule } from './shared/calendar/calendar.module'
import { ClientLeadNoteEventLoaderService } from './services/event-loaders/client-lead-note-event-loader.service'
import { FormContainerService } from './services/form-container.service'
import { FormDialogModule } from './shared/dialog/form-dialog/form-dialog.module'
import { BrandFormModule } from './admin-pages/brands/brand-form/brand-form.module'
import { TemplateRoomFormModule } from './admin-pages/template-rooms/template-room-form/template-room-form.module'
import { PendingChangesGuard } from './shared/guards/canDeactivateGuard'
import { TemplateCategoryFormModule } from './admin-pages/template-categories/template-category-form/template-category-form.module'
import { MediaUploadService } from './services/media-upload.service'
import { MediaPickerDialogModule } from './shared/dialog/media-picker-dialog/media-picker-dialog.module'
import { MediaPickerDialogService } from './shared/dialog/media-picker-dialog/media-picker-dialog.service'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { PagingPersistService } from './services/paging-persistent.service'
import { LocalSearchTableModule } from './shared/search-dialog/local-search-table/local-search-table.module'
import { ServerSearchTableModule } from './shared/search-dialog/server-search-table/server-search-table.module'
import {
    ServiceRatePickerService,
    ProductPickerService,
    ProjectPickerService,
    SupplierPickerService
} from './services/entity-selections'
import { EmployeeSettingsStoreService } from './services/employee-settings-store.service'
import { CommonTemplateComponentsModule } from './shared/common-template-components/common-template-components.module'
import { TimehseetTaskDialogService } from './admin-pages/timesheets/timesheet-task-dialog/timehseet-task-dialog.service'
import { TimesheetTaskDialogModule } from './admin-pages/timesheets/timesheet-task-dialog/timesheet-task-dialog.module'
import { TimesheetSharedModule } from './admin-pages/timesheets/timesheet-shared/timesheet-shared.module'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { PropertySelectionService } from './services/property-selection.service'
import { PropertySelectionsUpdateSelectorDialogModule } from './admin-pages/templates/property-selections-update-selector-dialog/property-selections-update-selector-dialog.module'
import { SelectSelectionGroupsToUsePriceDialogModule } from './admin-pages/products/select-selection-groups-to-use-price-dialog/select-selection-groups-to-use-price-dialog.module'
import { SelectionItemDetailsModule } from './shared/selection-item-details/selection-item-details.module'
import { ButtonListDialogModule } from './shared/dialog/button-list-dialog/button-list-dialog.module'
import { ButtonListDialogService } from './shared/dialog/button-list-dialog/button-list-dialog.service'
import { BearerTokenInjectorInterceptor } from './interceptors/bearer-token-injector.interceptor'
import { UpdateTakeOffForSelectionGroupSelectorDialogModule } from './shared/dialog/update-take-off-for-selection-group-selector-dialog/update-take-off-for-selection-group-selector-dialog.module'
import { SelectSelectionGroupsToUsePriceDialogService } from '././admin-pages/products/select-selection-groups-to-use-price-dialog/select-selection-groups-to-use-price-dialog.service'
import { ClientViewModeInterceptor } from './interceptors/client-view-mode.interceptor'
import { SelectionGroupDialogService } from './admin-pages/selection-groups/selection-group-form/selection-group-form-dialog.service'
import { TakeOffFormsModule } from './admin-pages/takeoffs/take-offs-forms.module'
import { ProductListModule } from './admin-pages/products/product-list/product-list.module'
import { SimpleComponentWrapperDialogModule } from './shared/dialog/simple-component-wrapper-dialog/simple-component-wrapper-dialog.module'
import { QuantityDialogModule } from './shared/dialog/quantity-dialog/quantity-dialog.module'
import { QuantityDialogService } from './shared/dialog/quantity-dialog/quantity-dialog.service'
import { SelectionGroupSelectionDialogModule } from './shared/selection-group-selection-dialog/selection-group-selection-dialog.module'
import { PackageFormModule } from './admin-pages/packages/package-form/package-form.module'
import { PackageApplierDialogService } from './shared/package-applier-dialog/package-applier-dialog.service'
import { PackageApplierDialogModule } from './shared/package-applier-dialog/package-applier-dialog.module'
import { GenerateTimesheetReportDatePickerModule } from './shared/dialog/generate-timesheet-report-date-picker/generate-timesheet-report-date-picker.module'
import { PackageAssociationConfirmationDialogModule } from './shared/package-applier-dialog/package-association-confirmation-dialog/package-association-confirmation-dialog.module'
import { ProductVariationGroupFormModule } from './admin-pages/product-variation-groups/product-variation-group-form/product-variation-group-form.module'
import { ProductVariationFormDialogModule } from './admin-pages/product-variation-groups/product-variation-form-dialog/product-variation-form-dialog.module'
import { LabelFormDialogService } from './shared/label-group-drop-down/label-form-dialog/label-form-dialog.service'
import { ProductVariationGroupListModule } from './admin-pages/product-variation-groups/product-variation-group-list/product-variation-group-list.module'
import { LabelGroupFormModule } from './admin-pages/label-groups/label-group-form/label-group-form.module'
import { ProductVariationGroupFormDialogService } from './admin-pages/product-variation-groups/product-variation-group-form/product-variation-group-form-dialog.service'
import { ImageCdnService } from './shared/images/image-cdn.service'
import { HammerModule } from '@angular/platform-browser'


import { ContractTemplatesModule } from './admin-pages/contract-templates/contract-templates.module'
import { DirectivesModule } from './shared/directives/directives.module'

import { UploadFileDialogModule, UploadMediaDialogModule, UploadMediaFormDialogService } from './shared/dialog'
import { ProductSupplierPriceFormDialogService } from './admin-pages/products/product-supplier-price-form-dialog/product-supplier-price-form-dialog.service'
import { ServiceSupplierPriceFormDialogService } from './admin-pages/service-rates/service-rate-supplier-price-form-dialog/service-rate-supplier-price-form-dialog.service'
import { CustomPropertiesListDialogService } from './shared/dialog/custom-properties-list-dialog/custom-properties-list-dialog.service'
import { CustomPropertiesListDialogModule } from './shared/dialog/custom-properties-list-dialog/custom-properties-list-dialog.module'
import { CustomPropertyFormDialogService } from './shared/dialog/custom-property-form-dialog/custom-property-form-dialog.service'
import { CustomPropertyAllowedValueFormDialogService } from './shared/dialog/custom-property-allowed-value-form-dialog/custom-property-allowed-value-form-dialog.service'
import { CustomPropertyFormDialogModule } from './shared/dialog/custom-property-form-dialog/custom-property-form-dialog.module'
import { CustomPropertyAllowedValueFormDialogModule } from './shared/dialog/custom-property-allowed-value-form-dialog/custom-property-allowed-value-form-dialog.module'
import { ManageProductVariationPropertyDialogModule } from '@app/admin-pages/product-variation-groups/manage-product-variation-property-dialog/manage-product-variation-property-dialog.module'
import { MultiProductPropertyValueSelectionDialogModule } from '@app/shared/dialog/multi-product-property-value-selection-dialog/multi-product-property-value-selection-dialog.module'
import { MultiProductPropertyValueSelectionDialogService } from '@app/shared/dialog/multi-product-property-value-selection-dialog/multi-product-property-value-selection-dialog.service'
import { TasksModule } from '@app/admin-pages/tasks/tasks.module'
import { NotesModule } from '@app/admin-pages/notes/notes.module'

export function getBaseUrl() {
    const baseUrl = document.getElementsByTagName('base')[0].href
    return baseUrl.substring(0, baseUrl.length - 1)
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http)
}

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
        HttpClientModule,
        ClientPortalNavbarModule,
        FooterModule,
        AddressFormModule,
        TableContainerModule,
        LocalSearchTableModule,
        ServerSearchTableModule,
        ImgCropperDialogModule,
        SelectionDialogModule,
        CostLineDialogModule,
        SimpleEntityCreateFormDialogModule,
        ConfirmDialogModule,
        SelectionItemDetailsModule,
        NgxPermissionsModule.forRoot(),
        AdminModule,
        ClientPortalModule,
        CalendarModule,
        FormDialogModule,
        BrandFormModule,
        TemplateRoomFormModule,
        TemplateCategoryFormModule,
        UploadMediaDialogModule,
        MediaPickerDialogModule,
        UploadFileDialogModule,
        NgxMaterialTimepickerModule,
        ProductVariationFormDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TimesheetTaskDialogModule,
        PropertySelectionsUpdateSelectorDialogModule,
        SelectSelectionGroupsToUsePriceDialogModule,
        ButtonListDialogModule,
        UpdateTakeOffForSelectionGroupSelectorDialogModule,
        TakeOffFormsModule,
        ProductListModule,
        SimpleComponentWrapperDialogModule,
        QuantityDialogModule,
        SelectionGroupSelectionDialogModule,
        PackageApplierDialogModule,
        PackageAssociationConfirmationDialogModule,
        PackageFormModule,
        GenerateTimesheetReportDatePickerModule,
        ProductVariationGroupFormModule,
        ProductVariationGroupListModule,
        LabelGroupFormModule,
        HammerModule,
        ContractTemplatesModule,
        DirectivesModule,
        CustomPropertiesListDialogModule,
        CustomPropertyAllowedValueFormDialogModule,
        ManageProductVariationPropertyDialogModule,
        CustomPropertyFormDialogModule,
        MultiProductPropertyValueSelectionDialogModule,
        TasksModule,
        TimesheetSharedModule,
        NotesModule,
    ],
    declarations: [AppComponent, AuthLayoutComponent],
    providers: [
        { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
        { provide: 'API_BASE_URL', useValue: getBaseUrl() },
        { provide: API_BASE_URL, useValue: getBaseUrl() },
        { provide: LOCALE_ID, useValue: 'en' },
        AccountManageService,
        AccountService,
        BuilderClientsService,
        EmployeesService,
        CountriesService,
        NotifyService,
        EnumListService,
        EmployeeAuthGuardCanActivate,
        ClientAuthGuardCanActivate,
        PendingChangesGuard,
        PropertiesService,
        ProjectsService,
        CompaniesService,
        SearchDialogService,
        ImgCropperDialogService,
        FixedFormControlsService,
        TimesheetsService,
        LanguageService,
        ProductsService,
        ProductPricesService,
        BrandsService,
        TaskTemplatesService,
        SelectionDialogService,
        CostLineDialogService,
        DocumentsService,
        UserConfigurationService,
        TemplateCategoriesService,
        SuppliersService,
        RolesService,
        ApiConfigService,
        SimpleEntityCreateFormDialogService,
        ConfirmDialogService,
        ServiceRatePricesService,
        PropertyTemplateService,
        ClientPortalSelectionService,
        TemplateRoomsService,
        SelectionItemDetailsDialogService,
        ReferrersService,
        NoteFormDialogService,
        ClientLeadNoteEventLoaderService,
        FormContainerService,
        MediasService,
        UploadMediaFormDialogService,
        ImageService,
        MediaUploadService,
        MediaPickerDialogService,
        PagingPersistService,
        TemplateService,
        ProductPickerService,
        ServiceRatePickerService,
        TaxesService,
        EmployeeSettingsService,
        EmployeeSettingsStoreService,
        CommonTemplateComponentsModule,
        TimehseetTaskDialogService,
        PropertySelectionService,
        SelectionGroupsService,
        SelectionGroupDialogService,
        SelectSelectionGroupsToUsePriceDialogService,
        ButtonListDialogService,
        AuthService,
        CurrenciesService,
        TakeOffsService,
        QuantityDialogService,
        PackagesService,
        PackageApplierDialogService,
        LabelGroupsService,
        ProductVariationGroupsService,
        LabelFormDialogService,
        ProductAccessoriesService,
        ProductVariationGroupFormDialogService,
        ImageCdnService,
        BuyersService,
        AllowancesService,
        ContractTemplatesService,
        ProductSupplierPriceFormDialogService,
        ServiceSupplierPriceFormDialogService,
        ServiceRatesService,
        ProjectPickerService,
        SupplierPickerService,
        CustomPropertiesListDialogService,
        PropertyValuesService,
        CustomPropertyFormDialogService,
        PropertyDefinitionService,
        CustomPropertyAllowedValueFormDialogService,
        MultiProductPropertyValueSelectionDialogService,
        NotesService,
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInjectorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ClientViewModeInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
