import { SelectionGroupPricing } from '@app/services/api.services'

export class SelectionGroupPricingModel {
    constructor(
        public selectionGroupPricing: SelectionGroupPricing,
        public hasCustomMarkup: boolean,
        public excludeTaxesInClientPricing: boolean,
        public customMarkupPercent: number,
        public manualIncludedAmount: number,
        public calculatedIncludedPrice: number
    ) {}

    get includedAmount(): number {
        switch (this.selectionGroupPricing) {
            case SelectionGroupPricing.DefaultPrice:
                return this.manualIncludedAmount
            case SelectionGroupPricing.DifferenceFromDefaultPrice:
                return this.calculatedIncludedPrice
            case SelectionGroupPricing.ExactSelectionPrice:
                return 0
            case SelectionGroupPricing.InclusionPrice:
                return 0;
        }
    }

    get displayDefaultPrice(): boolean {
        return this.selectionGroupPricing === SelectionGroupPricing.DefaultPrice
    }
}
