<h2 mat-dialog-title>{{ settings.isEdit ? 'Edit Media' : 'Upload' }}</h2>
<mat-dialog-content>
    <app-file-select
        #fileSelect
        [isEdit]="settings.isEdit"
        [mediaToEdit]="settings.mediaToEdit"
        [canSelectMultipleFiles]="settings.canUploadMultipleFiles"
        (fileSelected)="fileSelected($event)"
    ></app-file-select>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button type="button" mat-button color="warn" [mat-dialog-close]>Cancel</button>
        <button class="ml-2" mat-stroked-button [loading]="isLoading" (click)="onSubmitClick()">
            {{ buttonText }}
        </button>
    </div>
</mat-dialog-actions>
