<h2 mat-dialog-title>Variant Properties</h2>
<mat-dialog-content>
    <ng-container *ngIf="isLoading; else loadedPropertyDefinitions">
        <mat-spinner class="my-5 mx-auto"></mat-spinner>
    </ng-container>
    <ng-template #loadedPropertyDefinitions>
        <div class="row" *ngIf="propertyDefinitions">
            <div class="col-12">
                <ng-container *ngIf="propertyDefinitions.length > 0; else noPropertyDefinitions">
                    <table mat-table [dataSource]="propertyDefinitions">
                        <ng-container matColumnDef="propertyLabel">
                            <th mat-header-cell *matHeaderCellDef>Property Label</th>
                            <td mat-cell *matCellDef="let row">{{ row.item.selectedTranslation.label }}</td>
                        </ng-container>

                        <ng-container matColumnDef="numberOfProducts">
                            <th mat-header-cell *matHeaderCellDef style="width: 100px"># Products</th>
                            <td mat-cell *matCellDef="let row">{{ row.item.numberOfProducts }}</td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef class="action-column">Action</th>
                            <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                                <button *ngIf="row.canBeAdded()" mat-icon-button (click)="row.setAsAdd()">
                                    <mat-icon>add</mat-icon>
                                </button>
                                <button
                                    *ngIf="row.canBeRemoved()"
                                    mat-icon-button
                                    color="warn"
                                    (click)="row.setAsRemoved()"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </ng-container>

                <ng-template #noPropertyDefinitions>
                    <ng-container
                        *ngIf="
                            data.productVariationGroup.productVariantProductIds.length === 0;
                            else variantsHaveNoProperties
                        "
                    >
                        There are no product variants to discover property definitions that can be used, add some first
                    </ng-container>
                    <ng-template #variantsHaveNoProperties>
                        The product variants have no property values, you can add some by clicking add values
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <button mat-button color="warn" [mat-dialog-close]>{{ 'CANCEL' | translate }}</button>
    <div>
        <button mat-stroked-button cdkFocusInitial (click)="addPropertyDefinition()">Add Values</button>
        <button mat-stroked-button cdkFocusInitial (click)="done()">Done</button>
    </div>
</mat-dialog-actions>
