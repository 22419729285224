import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Optional, Inject } from '@angular/core'
import { ServerPaginationListComponent } from '@app/models/serverPaginationComponent'
import {
    PaginationModel,
    ProductVariationGroupsService,
    GetProductVariationGroupListQuery,
    ProductVariationGroupLookUpDto,
} from '@app/services/api.services'
import { PagingDataSource } from '@app/models/interfaces/dataSourceInterface'
import { Observable } from 'rxjs'
import { ConfirmDialogService } from '@app/shared/confirm-dialog/confirm-dialog.service'
import { FixedFormControlsService } from '@app/shared/fixed-form-controls/fixed-form-controls.service'
import { Router } from '@angular/router'
import { NotifyService } from '@app/services/notify.service'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSort } from '@angular/material/sort'
import { ProductVariationGroupsDataSource } from './product-variation-group-list.datasource'
import { PagingDisplayMode } from '@app/shared/server-pagination-container/server-paging-container.component'

@Component({
    selector: 'app-product-variation-group-list',
    templateUrl: './product-variation-group-list.component.html',
    styleUrls: ['./product-variation-group-list.component.scss'],
})
export class ProductVariationGroupListComponent
    extends ServerPaginationListComponent<string, ProductVariationGroupLookUpDto, GetProductVariationGroupListQuery>
    implements OnInit, AfterViewInit, OnDestroy {
    displayedColumns: string[] = ['name', 'numberOfProductVariations', 'createdDateTime', 'updatedDateTime', 'action']
    @ViewChild(MatSort) sort: MatSort
    displayMode: PagingDisplayMode = PagingDisplayMode.Card

    constructor(
        private service: ProductVariationGroupsService,
        @Optional()
        @Inject(MAT_DIALOG_DATA)
        data: { displayMode: PagingDisplayMode; excludeProductVariationGroupIds: string[] },
        confirmDialogService: ConfirmDialogService,
        fixedFormControlsService: FixedFormControlsService,
        public router: Router,
        public notifyService: NotifyService
    ) {
        super(router, 'product-variation-groups', confirmDialogService, notifyService)

        this.query = new GetProductVariationGroupListQuery()
        if (data && data.excludeProductVariationGroupIds) {
            this.query.excludeProductVariationGroupIds = data.excludeProductVariationGroupIds
        }

        if (this.displayMode === PagingDisplayMode.Card) {
            this.fixedAddButton = fixedFormControlsService.setupSingleAddButton()
            this.fixedFormSub = this.fixedAddButton.buttonClickedObservable.subscribe(() => this.create())
        }
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            this.load()
        })
        this.tableContainer.matSort = this.sort
    }

    loadDataSource(): PagingDataSource<ProductVariationGroupLookUpDto, GetProductVariationGroupListQuery> {
        return new ProductVariationGroupsDataSource(this.service)
    }

    loadEntities(paginationModel: PaginationModel) {
        const query = Object.assign(this.query, paginationModel)
        this.dataSource.loadEntities(query)
    }

    filterChanges() {
        this.loadEntities(this.tableContainer.generatePaginationModel())
    }

    deleteEntity(id: string): Observable<void> {
        return this.service.delete(id)
    }

    ngOnDestroy() {
        this.fixedFormSub.unsubscribe()
    }
}
