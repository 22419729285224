<h2 mat-dialog-title>Set Property Value for Products</h2>
<mat-dialog-content>
    <ng-container *ngIf="isLoading; else loaded">
        <mat-spinner class="my-5 mx-auto"></mat-spinner>
    </ng-container>
    <ng-template #loaded>
        <table mat-table [dataSource]="productPropertyValues">
            <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef>Product Name</th>
                <td mat-cell *matCellDef="let row; let i = index">
                    {{ row.productName }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef class="values-column">Values</th>
                <td mat-cell *matCellDef="let row; let i = index" class="action-column">
                    <app-custom-property-input
                        [selectedLanguage]="selectedLanguage"
                        [propertyValue]="row.propertyValue"
                    ></app-custom-property-input>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="d-flex w-100 justify-content-between">
        <button mat-button [mat-dialog-close]>Cancel</button>
        <button mat-stroked-button cdkFocusInitial (click)="savePropertyValues()" [loading]="isSaving">Complete</button>
    </div>
</mat-dialog-actions>
