import { NoteType, LanguageType, NoteDto } from '@app/services/api.services'
import { Moment } from 'moment'

export class NoteFormDialogSettings {
    constructor() {}

    allowNoteTypeSelection = true
    requiredNoteTypeSelection: NoteType
    allowClientLeadIdSelection = false

    selectedLanguage: LanguageType
    model: NoteDto

    isEdit: boolean
    startOnDateTime: Moment
    allowDelete = false
    showCientLead = false

    attachedEntity: {
        builderClientId?: number
        brokerId?: string
        hasBroker?: boolean
        name: string
    }

    get canForwardToBroker(): boolean {
        return !!this.attachedEntity.builderClientId && this.attachedEntity?.hasBroker
    }
}
