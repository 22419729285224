import { SelectionItemBaseCostLineModel } from './SelectionItemBaseCostLineModel'
import { UnitType, CostLineTranslation, SelectionItemCostLineDto } from '@app/services/api.services'
import { TakeOffModel } from '@app/models/template/TakeOffModel'
import { Guid } from 'guid-typescript'
import { CostLineDialogModel } from '@app/shared/cost-line-dialog/cost-line-dialog.component'

export class SelectionItemCostLineModel extends SelectionItemBaseCostLineModel {
    static createFromDto(costLine: SelectionItemCostLineDto): SelectionItemCostLineModel {
        const costLineModel = new SelectionItemCostLineModel(
            costLine.id,
            costLine.quantity,
            costLine.unitType,
            costLine.unitCostAmount,
            costLine.unitMarkupAmount,
            costLine.unitMarkupPercent,
            costLine.isUnitMarkupPercent,
            costLine.isVisibleToClients,
            costLine.translations
        )

        costLineModel.selectedTranslation = costLine.selectedTranslation
        costLineModel.unitType = costLine.unitType

        costLineModel.useTakeOff = costLine.useTakeOff
        if (costLine.useTakeOff) {
            costLineModel.takeOff = TakeOffModel.createFromDto(costLine.takeOff)
        }

        return costLineModel
    }

    static createFromDialogModel(costLineDialogModel: CostLineDialogModel): SelectionItemCostLineModel {
        const costLine = new SelectionItemCostLineModel(
            Guid.create().toString(),
            costLineDialogModel.quantity,
            costLineDialogModel.unitType,
            costLineDialogModel.unitPrice.unitCostAmount,
            costLineDialogModel.unitPrice.unitMarkupAmount,
            costLineDialogModel.unitPrice.unitMarkupPercent,
            costLineDialogModel.unitPrice.isUnitMarkupPercent,
            false,
            costLineDialogModel.translations
        )

        costLine.useTakeOff = costLineDialogModel.useTakeOff
        costLine.takeOff = costLineDialogModel.takeOff

        return costLine
    }

    constructor(
        id: string,
        quantity: number,
        unitType: UnitType,
        unitCostAmount: number,
        unitMarkupAmount: number,
        unitMarkupPercent: number,
        isUnitMarkupPercent: boolean,
        isVisibleToClients: boolean,
        translations: CostLineTranslation[]
    ) {
        super(
            id,
            quantity,
            unitType,
            unitCostAmount,
            unitMarkupAmount,
            unitMarkupPercent,
            isUnitMarkupPercent,
            isVisibleToClients,
            translations
        )

        if (!this.translations || this.translations.length == 0) {
            this.initTranslations(this.translations, () => new CostLineTranslation())
        }
    }

    clone(generateNewId: boolean): SelectionItemCostLineModel {
        const clonedTranslations = this.translations.map((translation) => {
            const clonedTranslation = new CostLineTranslation()
            clonedTranslation.description = translation.description
            clonedTranslation.culture = translation.culture
            clonedTranslation.id = Guid.create().toString()

            return clonedTranslation
        })
        const costLine = new SelectionItemCostLineModel(
            this.id,
            this.quantity,
            this.unitType,
            this.unitCostAmount,
            this.unitMarkupAmount,
            this.unitMarkupPercent,
            this.isUnitMarkupPercent,
            this.isVisibleToClients,
            clonedTranslations
        )

        if (generateNewId) {
            costLine.id = Guid.create().toString()
            costLine.isEdited = false
            costLine.isAdded = true
        }

        return costLine
    }

    getName(): string {
        if (!this.selectedTranslation) {
            return ''
        }
        return this.selectedTranslation.description
    }

    isUnitCostEditable(): boolean {
        return true && this.isEditing
    }

    isUnitMarkupEditable(): boolean {
        return true && this.isEditing
    }

    isDescriptionEditable(): boolean {
        return true && this.isEditing
    }

    isTakeOffsSupported(): boolean {
        return true
    }

    isUnitTypeEditable(): boolean {
        return true && this.isEditing
    }

    isLinkedCostLine(): boolean {
        return false
    }

    createDto(): SelectionItemCostLineDto {
        const costLine = new SelectionItemCostLineDto()
        this.populateCommonDtoFieldsFromStandalone(costLine)

        costLine.unitType = this.unitType
        costLine.translations = this.translations
        costLine.useTakeOff = this.useTakeOff

        if (this.useTakeOff) {
            costLine.takeOff = this.takeOff.createDto()
        }

        return costLine
    }
}
