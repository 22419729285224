import { NgModule } from '@angular/core'
import { CommonFormsModule } from 'common-forms/common-forms.module'
import { NgxPermissionsModule } from 'ngx-permissions'
import { PipesModule } from '@app/shared/pipes/pipes.module'
import { NoteListComponent } from '@app/admin-pages/notes/note-list/note-list.component'
import { NoteFormDialogComponent } from './note-form-dialog/note-form-dialog.component'
import { EmailNoteFormDialogComponent } from './note-form-dialog/email-note-form-dialog/email-note-form-dialog.component'
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker'

@NgModule({
    declarations: [NoteListComponent, NoteFormDialogComponent, EmailNoteFormDialogComponent],
    exports: [NoteListComponent, NoteFormDialogComponent, EmailNoteFormDialogComponent],
    imports: [CommonFormsModule, NgxPermissionsModule, PipesModule, OwlDateTimeModule, OwlNativeDateTimeModule],
    entryComponents: [NoteFormDialogComponent, EmailNoteFormDialogComponent],
})
export class NotesModule {}
