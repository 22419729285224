import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core'
import { LabelGroupType, LabelGroupDto, LabelDto } from '@app/services/api.services'
import { LabelGroupService } from '@app/services/label-group.service'
import { CommonComponent } from '@app/models/formComponent'
import { LabelFormDialogService } from './label-form-dialog/label-form-dialog.service'
import { flatMap } from 'rxjs/operators'

@Component({
    selector: 'app-label-group-drop-down',
    templateUrl: './label-group-drop-down.component.html',
    styleUrls: ['./label-group-drop-down.component.scss'],
})
export class LabelGroupDropDownComponent extends CommonComponent implements OnInit, AfterViewInit {
    @Input() labelGroupType: LabelGroupType
    @Input() labelGroupId: string
    @Input() isDisabled = false
    @Input() isMultiSelect = false
    @Input() placeholder: string
    labelGroup: LabelGroupDto
    @Output() selectionChange: EventEmitter<void> = new EventEmitter<void>()

    @Input() defaultLabelIds: string[] = []

    @Input() selectedLabels: LabelDto[] = []
    @Output() selectedLabelsChange: EventEmitter<LabelDto[]> = new EventEmitter<LabelDto[]>()

    @Input() selectedLabel: LabelDto
    @Output() selectedLabelChange: EventEmitter<LabelDto> = new EventEmitter<LabelDto>()

    constructor(private labelGroupService: LabelGroupService, private labelFormDialogService: LabelFormDialogService) {
        super()
    }

    ngOnInit() {}

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.labelGroupId) {
                this.labelGroupService.getLabelGroupById(this.labelGroupId).subscribe((labelGroup) => {
                    this._setupLabelDropDown(labelGroup)
                })
            } else if (this.labelGroupType) {
                this.labelGroupService.getLabelGroupByType(this.labelGroupType).subscribe((labelGroup) => {
                    this._setupLabelDropDown(labelGroup)
                })
            }
        })
    }

    private _setupLabelDropDown(labelGroup: LabelGroupDto) {
        this.labelGroup = labelGroup
        if (this.defaultLabelIds.length > 0) {
            const selectedLabels = this.labelGroup.labels.filter(
                (label) => this.defaultLabelIds.findIndex((l) => l === label.id) > -1
            )
            if (this.isMultiSelect) {
                this.selectedLabelsChange.emit(selectedLabels)
            } else {
                this.selectedLabelChange.emit(selectedLabels[0])
            }
        }
    }

    createNewLabel() {
        let newLabelId = null
        this.labelFormDialogService
            .showDialog(this.labelGroup.id)
            .pipe(
                flatMap((labelId) => {
                    newLabelId = labelId
                    if (this.labelGroupId) {
                        return this.labelGroupService.getLabelGroupById(this.labelGroupId, true)
                    } else {
                        return this.labelGroupService.getLabelGroupByType(this.labelGroupType, true)
                    }
                })
            )
            .subscribe((labelGroup) => {
                this.labelGroup = labelGroup
                if (newLabelId) {
                    const newLabel = this.labelGroup.labels.find((l) => l.id === newLabelId)
                    if (this.isMultiSelect) {
                        this.selectedLabelsChange.emit([...this.selectedLabels, newLabel])
                        this.selectedLabels.push(newLabel)
                    } else {
                        this.selectedLabelChange.emit(this.selectedLabel)
                        this.selectedLabel = newLabel
                    }
                    this.selectionChange.emit()
                }
            })
    }

    selectionChanged(label: LabelDto) {
        if (label) {
            this.selectedLabelsChange.emit([...this.selectedLabels, label])
            this.selectedLabels.push(label)
            this.selectionChange.emit()
        } else {
            this.createNewLabel()
        }
    }

    removeLabel(label: LabelDto) {
        if (this.isDisabled) {
            return
        }
        this.selectedLabelsChange.emit([...this.selectedLabels.filter((l) => l.id !== label.id)])
        this.selectedLabels.splice(
            this.selectedLabels.findIndex((l) => l.id === label.id),
            1
        )
        this.selectionChange.emit()
    }

    get availableLabels(): LabelDto[] {
        return this.labelGroup.labels.filter((label) => this.selectedLabels.findIndex((l) => l.id === label.id) === -1)
    }

    singleSelectionChange() {
        if (this.selectedLabel) {
            this.selectedLabelChange.emit(this.selectedLabel)
            this.selectionChange.emit()
        } else {
            this.createNewLabel()
        }
    }
}
