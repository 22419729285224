import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MaterialModule } from '@app/material.module'
import { TableContainerModule } from '@app/shared/table-container/table-container.module'
import { LocalSearchTableComponent } from './local-search-table.component'

@NgModule({
    imports: [CommonModule, MaterialModule, TableContainerModule],
    declarations: [LocalSearchTableComponent],
    exports: [LocalSearchTableComponent],
    entryComponents: [LocalSearchTableComponent],
})
export class LocalSearchTableModule {}
