import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MediaPickerDialogComponent } from './media-picker-dialog.component'
import { FormsModule } from '@angular/forms'
import { ImageCropperModule } from 'ngx-image-cropper'
import { MaterialModule } from '@app/material.module'
import { FileSelectModule } from '@app/shared/file-select/file-select.module'
import { ServerPagingContainerModule } from '@app/shared/server-pagination-container/server-paging-container.module'
import { LabelGroupDropDownModule } from '@app/shared/label-group-drop-down/label-group-drop-down.module'
import { ImagesModule } from '@app/shared/images/images.module'

@NgModule({
    declarations: [MediaPickerDialogComponent],
    imports: [
        FormsModule,
        CommonModule,
        ImageCropperModule,
        ImagesModule,
        MaterialModule,
        FileSelectModule,
        ServerPagingContainerModule,
        LabelGroupDropDownModule,
    ],
    exports: [MediaPickerDialogComponent],
    entryComponents: [MediaPickerDialogComponent],
})
export class MediaPickerDialogModule {}
