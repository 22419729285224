import {
    PropertyDefinitionTranslation,
    PropertyAllowedValueTranslationDto,
    PropertyDefinitionType,
    PropertyDefinitionSubType,
} from 'app/services/api.services'
import { BaseTranslatableModel } from '../template/BaseTranslatableModel'

export class PropertyDefinitionModel extends BaseTranslatableModel<PropertyDefinitionTranslation> {
    id?: string | null
    labelIdentifier?: string | null
    type: PropertyDefinitionType
    subType: PropertyDefinitionSubType
    allowedValues: CategoricalPropertyDefinitionAllowedValueModel[]

    constructor(
        id: string,
        labelIdentifier: string,
        type: PropertyDefinitionType,
        subType: PropertyDefinitionSubType,
        translations: PropertyDefinitionTranslation[] = [],
        allowedValues: CategoricalPropertyDefinitionAllowedValueModel[] = null
    ) {
        super(translations)
        this.id = id
        this.labelIdentifier = labelIdentifier
        this.type = type
        this.subType = subType
        this.allowedValues = allowedValues
        if (!this.translations || this.translations.length === 0) {
            this.initTranslations(this.translations, () => new PropertyDefinitionTranslation())
        }
    }
}

export class CategoricalPropertyDefinitionAllowedValueModel extends BaseTranslatableModel<
    PropertyAllowedValueTranslationDto
> {
    id: string
    valueIdentifier: string

    constructor(id: string, valueIdentifier: string, translations: PropertyAllowedValueTranslationDto[] = []) {
        super(translations)
        this.id = id
        this.valueIdentifier = valueIdentifier
        if (!this.translations || this.translations.length === 0) {
            this.initTranslations(this.translations, () => new PropertyAllowedValueTranslationDto())
        }
    }
}
